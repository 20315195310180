<template>
  <svg
    width="46"
    height="44"
    viewBox="0 0 46 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.9976 4.22378H26.7257V0.165894H23.6924L0.359863 4.2339V39.7298L23.5555 43.7877H26.7257V39.745H41.9527C42.815 39.7044 43.7737 39.7704 44.5142 39.2429C45.1076 38.335 44.9555 37.1987 45.0062 36.1741V6.1918C45.1127 5.03532 44.1541 4.10708 42.9976 4.22378ZM17.281 28.8701C16.434 29.3114 15.1659 28.8497 14.1616 28.9207C13.487 25.5629 12.7007 22.2254 12.0972 18.8523C11.5037 22.129 10.7327 25.3752 10.053 28.6316C9.07917 28.5809 8.10026 28.52 7.12126 28.4541C6.27919 23.9904 5.29017 19.5573 4.50392 15.0835C5.37128 15.043 6.2437 15.0075 7.11106 14.977C7.63348 18.2081 8.22698 21.4239 8.68356 24.6601C9.39872 21.3427 10.1291 18.0255 10.8393 14.7082C11.803 14.6524 12.7667 14.6119 13.7305 14.5611C14.4051 17.9849 15.0949 21.4036 15.8253 24.8122C16.3985 21.2921 17.0325 17.782 17.6463 14.2669C18.6607 14.2314 19.6752 14.1756 20.6845 14.1147C19.5382 19.0298 18.5339 23.9855 17.281 28.8701ZM42.5921 37.336H26.7257V34.1503H39.9136V32.1214H26.7257V29.5852H39.9136V27.5563H26.7257V25.0201H39.9136V22.9913H26.7257V20.4551H39.9136V18.4262H26.7257V15.89H39.9136V13.8611H26.7257V11.325H39.9136V9.29607H26.7257V6.61767H42.5921V37.336Z"
      fill="#7C7F81"
    />
  </svg>
</template>
