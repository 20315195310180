import { reactive } from "vue";

export enum DeviceSize {
  mobile,
  tablet,
  laptop,
  large,
  bigScreen,
  xl,
}

export type DeviceInfo = {
  windowWidth: number;
  size: DeviceSize;
};

const calcSize = (width: number): DeviceSize => {
  switch (true) {
    case width <= 430:
      return DeviceSize.mobile;
    case width < 640:
      return DeviceSize.tablet;
    case width < 1024:
      return DeviceSize.laptop;
    case width < 1280:
      return DeviceSize.large;
    case width < 2048:
      return DeviceSize.bigScreen;
    default:
      return DeviceSize.xl;
  }
};

const deviceInfo = reactive({
  windowWidth: window.innerWidth,
  size: DeviceSize.mobile,
});

deviceInfo.size = calcSize(window.innerWidth);

window.addEventListener("resize", () => {
  const width = window.innerWidth;
  deviceInfo.windowWidth = width;
  deviceInfo.size = calcSize(width);
});

export const useDevice = (): DeviceInfo => {
  return deviceInfo;
};

export default useDevice;
