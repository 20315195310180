<script lang="ts" setup generic="ERROR_PAYLOAD_T extends object">
import NimbusDragDots from './icons/NimbusDragDots.vue';
import DeleteXIcon from './icons/DeleteXIcon.vue';
import { ref, watch } from 'vue';
import type { WidgetControllerError } from '@/types/widget-error-types';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  headerTitle: {
    type: String,
    required: false
  },
  footerTitle: {
    type: String,
    required: false
  }
})

const { title: __headerTitle, titleChangeHandler: headerTitleChageHandler } = useTitle(() => props.headerTitle);
const { title: __footerTitle, titleChangeHandler: footerTitleChangeHandler } = useTitle(() => props.footerTitle);

const emit = defineEmits<{
  (e: 'delete-widget'): void
  (e: 'widget-error', error: WidgetControllerError<ERROR_PAYLOAD_T> | null): void
}>()

function handleDeleteHanldeClicked() {
  emit('delete-widget');
}

const error = ref<WidgetControllerError<ERROR_PAYLOAD_T> | null>()

function setErrorHandler(errorObject: WidgetControllerError<ERROR_PAYLOAD_T> | null) {
  error.value = errorObject;
  emit('widget-error', errorObject);
}
</script>

<template>
  <div 
    class="base-widget-wrapper flex justify-center items-center h-full w-full relative"
    :class="{'base-widget-error': !!error}"
  >
    <slot :onFooterTitleChange="footerTitleChangeHandler" :onHeaderTitleChange="headerTitleChageHandler" :onErrorChange="setErrorHandler" />
    <div 
      v-if="__headerTitle"
      class="base-widget-header-container">
      <span class="base-widget-header-title">{{ __headerTitle }}</span>
    </div>
    <div 
      v-if="editMode" 
      class="move-handle absolute left-0 top-0 base-handle"
      ><NimbusDragDots :width="18" :height="18" /></div>
    <div 
      v-if="editMode" 
      class="delete-handle absolute top-0 right-0 base-handle hover:cursor-pointer active:opacity-5 active:bg-neutral-600"
      @click="handleDeleteHanldeClicked"
      ><DeleteXIcon :width="18" :height="18" /></div>    
    <div 
      v-if="__footerTitle"
      class="base-widget-footer-container">
      <span class="base-widget-footer-title">{{ __footerTitle }}</span>
    </div>
  </div>
</template>

<style scoped>

.base-widget-wrapper {
  /*
  height: 396px;
  width: 306px;
  */
  border-radius: 25px;
  background: linear-gradient(0deg, #313435 0%, #464A4F 100%);
  box-shadow: -1px 18px 15px 19px rgba(0,0,0,0.53);
}
.base-handle {
  width: 18px;
  height: 18px;
}
.move-handle {
  margin-left: 12px;
  margin-top: 12px;
}
.delete-handle {
  margin-right: 12px;
  margin-top: 12px;
}

.base-widget-header-container {
  @apply absolute left-0 top-0 right-0 flex flex-row justify-center items-center px-8;
  margin-top: 12px;
}

.base-widget-footer-container {
  @apply absolute left-0 bottom-0 right-0 flex flex-row justify-center items-baseline px-8;  
  margin-bottom: 12px;
}

.base-widget-header-title {  
  /*height: 38px;*/
  /*width: 160px;*/
  color: #FFFFFF;
  font-family: "Avenir Next";
  font-size: 15.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;  

  text-wrap: nowrap;
  overflow: hidden;
}

.base-widget-footer-title { 
  /*height: 38px;*/
  width: max-content;
  /*width: 160px;*/
  color: #FFFFFF;
  font-family: "Avenir Next";
  font-size: 15.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;

  text-wrap: nowrap;
  overflow: hidden;
}

.base-widget-error {
  border: 3px solid rgba(214, 22, 22, 1);
}

</style>

<script lang="ts" generic="ERROR_PAYLOAD_T extends object">

function useTitle(titleSelector: () => string | undefined) {
  const title = ref(titleSelector());
  
  watch( titleSelector, (newTitle) => title.value = newTitle);
  function titleChangeHandler(newTitle: string) {
    title.value = newTitle;
  }

  return {
    title,
    titleChangeHandler
  }
}
</script>