 export type PREFERENCE_TYPES = {
  'dashboard': {
    [x: string]: string
  },
  'dashboard-test': {
    [x: string]: string
  }

}


export interface TotalApplicationPreferencesResponse<APPLICATION_KEY extends keyof PREFERENCE_TYPES, PREFERENCE_KEYS extends keyof PREFERENCE_TYPES[APPLICATION_KEY]> {
  preferences: ApplicationPreference<APPLICATION_KEY, PREFERENCE_KEYS>
}

export interface PreferencesResponse<APPLICATION_KEY extends keyof PREFERENCE_TYPES, PREFERENCE_KEYS extends keyof PREFERENCE_TYPES[APPLICATION_KEY]> {
  preferences: Preference<APPLICATION_KEY, PREFERENCE_KEYS>
}

export interface PreferenceResponse<APPLICATION_KEY extends keyof PREFERENCE_TYPES, PREFERENCE_KEYS extends keyof PREFERENCE_TYPES[APPLICATION_KEY]> {
  preference: Preference<APPLICATION_KEY, PREFERENCE_KEYS>;
}

export type ApplicationPreference<APPLICATION_KEY extends keyof PREFERENCE_TYPES, PREFERENCE_KEYS extends keyof PREFERENCE_TYPES[APPLICATION_KEY]> = {
  [KEY in APPLICATION_KEY]: Preference<APPLICATION_KEY, PREFERENCE_KEYS>
}

export type Preference<APPLICATION_KEY extends keyof PREFERENCE_TYPES, PREFERENCE_KEYS extends keyof PREFERENCE_TYPES[APPLICATION_KEY]> = {
  [KEY in keyof PREFERENCE_KEYS]: PREFERENCE_TYPES[APPLICATION_KEY][PREFERENCE_KEYS]
}


////// Specific types 
export type APP_KEYS = keyof PREFERENCE_TYPES;
export const DASHBOARD_APP_KEY: APP_KEYS  = 'dashboard';

export type DashboardPreference = PREFERENCE_TYPES[typeof DASHBOARD_APP_KEY];
export type ApplicationDashboardPreference = ApplicationPreference<typeof DASHBOARD_APP_KEY, PREF_KEYS<typeof DASHBOARD_APP_KEY>>;
export type DashboardPreferencesResponse = PreferencesResponse<typeof DASHBOARD_APP_KEY, PREF_KEYS<typeof DASHBOARD_APP_KEY>>
export type DashboardPreferenceResponse = PreferenceResponse<typeof DASHBOARD_APP_KEY, PREF_KEYS<typeof DASHBOARD_APP_KEY>>;

export type PREF_KEYS<APPLICATION_KEY extends APP_KEYS> = keyof PREFERENCE_TYPES[APPLICATION_KEY];
export type DASHBOARD_KEYS = keyof PREFERENCE_TYPES[typeof DASHBOARD_APP_KEY];
