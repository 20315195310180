<script lang="ts" setup generic="DESC_TAGS_T extends string = string, MAPPED_TAG_T = DefaultMappedTagType<DESC_TAGS_T>">
import { ref, type PropType, watch } from 'vue';
import { type Tag } from './TagsList.vue';
import StationObjectParamSearch, { defaultTagMapper, type AutoParametersSelectors, type SelectDropdownCombination, type DefaultMappedTagType } from './StationObjectParamSearch.vue';
import { useSmarthubBreakpoints } from '@/composables/smarthubBreakpoints';

const props = defineProps({
  multiselect: {
    type: Boolean,
    default: true
  },
  initialSelection: {
    type: Array as PropType<Tag[]>,
    required: false,
  },
  tagMapper: {   //should return reduced data to be persited with layout
    type: Function as PropType<(tags: Tag, descTag?: DESC_TAGS_T) => MAPPED_TAG_T>,
    default: defaultTagMapper
  },
  tagDescription: {
    type: String,    
    requried: false
  },
  globallySelectedLocationId: {
    type: String as PropType<string | null>,
    default:  null,
  },
  title: {
    type: String,
    default: "Select instalation parameters:"
  },
  autoParametersSelectors: {
    type: Object as PropType<AutoParametersSelectors<DESC_TAGS_T>>,
    required: false
  },
  autoParametersSelectorsTrigger: {
    type: Object as PropType<any>,
    required: false
  },
  selectDropdownCombination: {
    type: String as PropType<SelectDropdownCombination>,    
    default: 'location-object-tag'
  },
})

const emit = defineEmits<{
  //main event that shoud be used (contains location id, object id and tag id when all three are selected)
  (e: 'selection-change', selected: Array<MAPPED_TAG_T>): void;

  (e: 'location-selected', selectedLocationId: string | null): void;
  (e: 'object-selected', selectedObjectId: string | null): void;
  (e: 'tag-selected', selectedTagId: string | null): void;
  (e: 'auto-select-params-matched', isMatched: boolean): void;
}>();

const { isLessThen480 } = useSmarthubBreakpoints();

const selectedLocationId = ref<string | null>(props.globallySelectedLocationId);
watch( () => props.globallySelectedLocationId, (newSelectedLocationId) => {
  selectedLocationId.value = newSelectedLocationId;
});

function selectedLocationChangedHandler(newSelectedLocationId: string | null) {
  selectedLocationId.value = newSelectedLocationId;
  emit('location-selected', newSelectedLocationId);
}

function selectedLocationChangedDestkopHandler(newSelectedLocationId: string | null) {
  if(!isLessThen480.value) {    
    selectedLocationId.value = newSelectedLocationId;
    emit('location-selected', newSelectedLocationId);
  }
}
</script> 
<!-- Interface of this component (props and signals should match StationObjectSearch component props and signals) -->
<template>
  <div class="flex flex-col w-full">
    <StationObjectParamSearch      
      v-if="isLessThen480"
      class="z-[11]"
      :title="'Select location'"      
      :multiselect="false"
      :select-dropdown-combination="'location'"
      :globally-selected-location-id="globallySelectedLocationId"      
      @location-selected="selectedLocationChangedHandler"
    />    
    <StationObjectParamSearch
      v-bind="$props"           
      :class="{'object-tag-search': isLessThen480}" 
      :globally-selected-location-id="globallySelectedLocationId ?? selectedLocationId"
      :select-dropdown-combination="isLessThen480 ? 'object-tag': props.selectDropdownCombination"
      @location-selected="selectedLocationChangedDestkopHandler"
      @selection-change="$emit('selection-change', $event)"
      @object-selected="$emit('object-selected', $event)"
      @tag-selected="$emit('tag-selected', $event)"
      @auto-select-params-matched="$emit('auto-select-params-matched', $event)"
    />
  </div>
</template>

<style scoped>

.object-tag-search:deep(.station-obj-param-title) {
  @apply mt-0;
}

</style>