import EventEmitter from "events";
import { CachedData } from '@/utils/CachedData'

export function useCachedServiceCall<   
  PARAMS_T extends Array<any>,   
  MODEL_T
>(
  bucketKey: string,
  keySelector: (...params: PARAMS_T) => string,
  cachedData: CachedData,
  serviceCallFunction: (...args: PARAMS_T) => Promise<MODEL_T>,
  ttlOffset?: number,
  bucketSize?: number
  ) {

  type OpState = 'pending' | 'settled';
  
  const subscribers: EventEmitter = new EventEmitter();
  const stateObj : { state: OpState} = {
    state: 'settled'
  }  

  const pendingRequests = new Set<string>();

  function generateRequestKey(bucketKey: string, key: string) {
    return `${bucketKey}_${key}`
  }
  
  const serviceCall = async function(...args: PARAMS_T): Promise<MODEL_T> {
  
  function callbackFactory(resolve: Function, reject: Function) {
    return function(error: any, result: any) {
      if(!error) {
        resolve(result);
      } else {
        reject(error);
      }
    }
  }
    
  return new Promise( (resolve, reject) => {    
      const requestStateKey = generateRequestKey(bucketKey, keySelector(...args))
      const isPending = pendingRequests.has(requestStateKey);

      if(isPending) {
        subscribers.once(requestStateKey, callbackFactory(resolve, reject))
        return;
      } else {
        const cachedResult = cachedData.get<MODEL_T>(bucketKey, keySelector(...args));
        if(cachedResult) {
          resolve(cachedResult)
          return;
        }
        //stateObj.state = 'pending';
        pendingRequests.add(requestStateKey);
        serviceCallFunction(...args)          
          .then( result => {
            resolve(result)
            return result;
          })
          .then( result => {
            cachedData.set(bucketKey, keySelector(...args), result, ttlOffset, bucketSize);
            subscribers.emit(requestStateKey, null, result);
          })
          .catch( error => {
            reject(error);
            subscribers.emit(requestStateKey, error, null);
          })
          .finally(() => {
            //stateObj.state = 'settled';
            pendingRequests.delete(requestStateKey)
          })          
      }
    })
  }

  return {
    serviceCall    
  }  
}