<script lang="ts">
export type DashboardViewItem = object & {
  viewId: string;
  viewName: string;
}
</script>

<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import Select, { type DEFAULT_KEYS_MAPPING_TYPE, type DropDownPosition, type SELECT_ITEM_TYPE } from '@/components/Select.vue'
import PlusIcon from '@/components/icons/PlusIcon.vue';
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue';
import type { DashboardViewSimple } from '@/models/DashboardModel';

const props = defineProps({
  selectedItem: Object as PropType<DashboardViewSimple | null>,
  items: Object as PropType<DashboardViewSimple[]>,
  defaultHeaderTitle: {
    type: String,
    default: 'Select View'
  },
  dropDownPosition: {
    type: String as PropType<DropDownPosition>
  }
})

const emit = defineEmits<{
  (e: 'view-selected', layoutViewItem: DashboardViewItem, index: number ): void
  (e: 'create-view'): void
}>();


const layoutItems = computed(() => props.items?.map(itm => {
  const { viewName, ...restitem } = itm;
  return {
    title: viewName,
    ...restitem
  }
}) ?? []);

function selectedItemHandler(item: SELECT_ITEM_TYPE<DEFAULT_KEYS_MAPPING_TYPE>, index: number) {
  const { title, ...restItem } = item;  
  emit('view-selected', { viewName: title, ...restItem} as DashboardViewItem, index);
  //emit('view-selected', item as unknown as DashboardViewItem, index);

}

function actionClickHandler() {
  emit('create-view');
}

</script>

<template>
  <Select       
    :button-label="selectedItem?.viewName ?? defaultHeaderTitle" 
    :button-icon-component="ArrowRightIcon"   
    :button-icon-placement="'right'"
    :show-icon="false"
    :show-desc="false"    
    :items="layoutItems as unknown as SELECT_ITEM_TYPE<DEFAULT_KEYS_MAPPING_TYPE>[]"        
    :drop-down-content-action="{ label: 'Add new view', iconComponent: PlusIcon}"    
    :drop-down-position="dropDownPosition"
    :title-only-item-content-additional-width="'34px'"
    @item-selected="selectedItemHandler"
    @action-click="actionClickHandler"
  />
</template>