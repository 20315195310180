import { isPast } from "date-fns"
import { TOKEN_PLACEMENT } from "@/config/environment"

export interface TokenData {
  access_token: string
  token_type: string
  expires: number
  expires_in: number
  refresh_token : string
}

const ACCESS_TOKEN_ENTRIES = {
  access_token: "access_token",
  token_type: "token_type",
  expires: "expires",
  expires_in: "expires_in",
  refresh_token : "refresh_token",
}

const TOKEN_DATA_KEYS = new Set(Object.keys(ACCESS_TOKEN_ENTRIES));

export class TokenService {
  constructor(private storage: typeof window.localStorage | typeof window.sessionStorage) {}

  setTokenData(tokenData: TokenData) {
    const that = this;
    Object.entries(tokenData)
      .forEach( ([key,value]) => {
        if(TOKEN_DATA_KEYS.has(key)) {
          that.storage.setItem(key, value)
        }
      });
  }

  getTokenData(): TokenData | null {
    const that = this;
    const tokenEntries: Array<[string, string | number]> = [];

    TOKEN_DATA_KEYS.forEach( key => {
      let tokenEntryData: string | number | null = that.storage.getItem(key);
      if(tokenEntryData != null) {
        tokenEntryData = key?.startsWith('expires')
          ? parseInt(tokenEntryData)
          : tokenEntryData;

        tokenEntries.push([key, tokenEntryData])
      }
    });

    if(tokenEntries.length == 0) {
      return null;
    }else {
      return Object.fromEntries(tokenEntries) as unknown as TokenData;
    }
    
  }

  setTokenDataEntry(key: string, value: string | number) {
    if(TOKEN_DATA_KEYS.has(key)) {
      this.storage.setItem(key, value.toString())
    }
  }

  hasNonExpiredToken(): boolean {
    return !!this.storage.getItem(ACCESS_TOKEN_ENTRIES.access_token) && !isTokenExpired(Number(this.storage.getItem(ACCESS_TOKEN_ENTRIES.expires)))
  }

  hasToken(): boolean {
    return !!this.getAccessToken();
  }

  getAccessToken() {
    return this.storage.getItem(ACCESS_TOKEN_ENTRIES.access_token);
  }

  getRefreshToken() {
    return this.storage.getItem(ACCESS_TOKEN_ENTRIES.refresh_token);
  }

  clearTokenData() {
    const that = this;
    TOKEN_DATA_KEYS.forEach( key => that.storage.removeItem(key))
  }

}

export function isTokenExpired(expiresIn: number): boolean {
  return isPast( new Date(expiresIn * 1000))
}

let storage = null;
if(TOKEN_PLACEMENT == 'localstorage'){
  storage = window.localStorage;
}else if(TOKEN_PLACEMENT == 'sessionstorage') {
  storage = window.sessionStorage;
}else {
  throw new Error("Unknown token storage");
}

export const tokenService = new TokenService(storage);