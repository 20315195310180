<script setup lang="ts">
import mark_unread_chat from '../../public/assets/navbar/mark_unread_chat.svg';
import cloud_white from '../../public/assets/header_dropdown/cloud_white.svg';
import code_white from '../../public/assets/header_dropdown/code_white.svg';
import help_outline from '../../public/assets/header_dropdown/help_outline.svg';
import light_mode from '../../public/assets/header_dropdown/light_mode.svg';
import person_white from '../../public/assets/header_dropdown/person_white.svg';
import redeem_white from '../../public/assets/header_dropdown/redeem_white.svg';
import settings_ethernet from '../../public/assets/header_dropdown/settings_ethernet.svg';
import { onClickOutside } from '@vueuse/core';
import { onMounted, ref } from 'vue';
import HeaderSearch from './HeaderSearch.vue';
import { useHeaderStore } from '../../../smarthub/src/stores/header';
import { storeToRefs } from 'pinia';
import ChevronLeftIcon from '../icons/ChevronLeftIcon.vue';
import { useUserInfoStore } from '../../../smarthub/src/stores/userInfo';
import { useSmarthubBreakpoints } from '../../../smarthub/src/composables/smarthubBreakpoints';

const props = defineProps({
  userName: {
    type: String,
    default: 'Andreas',
  },
  title: {
    type: String,
    default: 'Pumping Stations - Central City Maps',
  },
  isDropdownOpen: {
    type: Boolean,
    default: false,
  },
  notificationNumber: {
    type: Number,
    default: 3,
  }
});

const emit = defineEmits(['toggle', 'logout']);
const handleToggle = () => emit('toggle');
const dropdownMenuRef = ref(null);
const profilePicRef = ref(null);
const isInputFullWidthMobile = ref(false);

const headerStore = useHeaderStore();
const { selectedLocation, selectLocationEnabled } = storeToRefs(headerStore);
const userInfoStore = useUserInfoStore();
const { userInfo } = storeToRefs(userInfoStore);
const { isMobile } = useSmarthubBreakpoints();

function getInitials(name: string) {
  if (name) {
    const nameArray = name.split(' ');
    const firstNameIn = nameArray[0].charAt(0).toUpperCase();
    const lastNameIn = nameArray[nameArray.length - 1].charAt(0).toUpperCase();
    return firstNameIn + lastNameIn;
  }
}

onMounted(() => {
  onClickOutside(dropdownMenuRef, handleToggle, { ignore: [profilePicRef] });
});

function logoutHandler() {
  handleToggle();
  return emit('logout');
}

function goBackHandler() {
  headerStore.removeSelectedItem();
}

function toggleInputWidthHandler() {
  isInputFullWidthMobile.value = !isInputFullWidthMobile.value;
}

function reduceInputWidthHandler() {
  isInputFullWidthMobile.value = false;
}
</script>
<template>
  <header class="fixed top-0 left-0 right-0 text-white pt-4 pb-5 px-5 z-[1000] header">
    <div class="flex items-center">
      <HeaderSearch
        :disabled="!selectLocationEnabled" 
        :input-full-width="isInputFullWidthMobile" 
        @toggle-input-width="toggleInputWidthHandler" 
        @reduce-input-width="reduceInputWidthHandler"
      />
      <div
        :class="[{'hidden': isMobile && selectedLocation && isInputFullWidthMobile}, 
        'header__location__title', 'mx-auto', 'flex', 'items-center']"
      >
        <template v-if="selectedLocation?.name">
          <button           
            :class="[{'header__location_disabled': !selectLocationEnabled }, 'mr-4']"
            :tabIndex="!selectLocationEnabled ? -1 : undefined"
            @click="goBackHandler">
            <ChevronLeftIcon />
          </button>
            <h2>
              {{ selectedLocation.name }}
            </h2>
        </template>
      </div>
      <div :class="[{'max-shub-md:ml-auto': isMobile && selectedLocation && isInputFullWidthMobile}, 'flex items-center']">
        <div class="max-shub-md:hidden text-[14px]">
          {{ props.userName }}
          <div class="text-right opacity-50 text-[12px]">
            {{ userInfo?.domain }}
          </div>
        </div>
        <div
          ref="profilePicRef"
          class="cursor-pointer ml-8"
          @click="handleToggle"
        >
          <div class="flex justify-center items-center w-10 h-10 rounded-full border-2 border-[#15B1CA] bg-[#545657] p-2 text-[14px] font-semibold">
            <p>
              {{ getInitials(props.userName) }}
            </p>
          </div>
        </div>
      </div>
      <!-- Hidden for now -->
      <!-- <div class="relative max-lg:order-3 ml-8 max-lg:ml-0">
        <img
          :src="mark_unread_chat"
          alt="conversation icon"
        >
        <span
          v-if="notificationNumber > 0"
          class="absolute flex justify-center items-center w-[16.5px] rounded-full bg-[red] text-center text-[11px] right-[-4px] top-[-5px]"
        >
          {{ notificationNumber }}
        </span>
      </div> -->
    </div>

    <div
      v-if="isDropdownOpen"
      ref="dropdownMenuRef"
      class="header__profile-dropdown fixed right-[5px] top-20 px-5 py-3 rounded-25 w-[300px] z-10 max-sm:top-0 max-sm:right-0 max-sm:bottom-0 max-sm:flex max-sm:flex-col max-sm:w-full max-sm:rounded-none"
    >
      <div
        class="flex items-center mt-2"
        @click="handleToggle"
      >
      <span class="w-6 h-6 flex items-center justify-center text-[10px] rounded-full border border-[#15B1CA] bg-[#545657] mr-2 pointer-events-none">
            {{ getInitials(props.userName) }}
          </span>
        <span>{{ props.userName }}</span>
        <div
          class="absolute top-[15%] opacity-[48%] left-0 w-full h-px bg-[#979797] max-sm:top-[60px]"
        />
        <div
          class="absolute bottom-[12%] opacity-[48%] left-0 w-full h-px bg-[#979797] max-sm:bottom-[50px]"
        />
      </div>
      <nav>
        <ul class="my-6">
          <li class="mb-4 opacity-40">
            <a
              href="#"
              class="flex gap-4"
            >
              <img
                :src="person_white"
                alt="conversation icon"
                class="w-4"
              >
              My profile
            </a>
          </li>
          <li class="mb-4 opacity-40">
            <a
              href="#"
              class="flex gap-4"
            >
              <img
                :src="help_outline"
                alt="conversation icon"
                class="w-4"
              >
              Get help
            </a>
          </li>
          <li class="mb-4 opacity-40">
            <a
              href="#"
              class="flex gap-4"
            >
              <img
                :src="redeem_white"
                alt="conversation icon"
                class="w-4"
              >
              What's new
            </a>
          </li>
          <li class="mb-4 opacity-40">
            <a
              href="#"
              class="flex gap-4"
            >
              <img
                :src="code_white"
                alt="conversation icon"
                class="w-4"
              >
              Developer
            </a>
          </li>
          <li class="mb-4 opacity-40">
            <a
              href="#"
              class="flex gap-4"
            >
              <img
                :src="cloud_white"
                alt="conversation icon"
                class="w-4"
              >
              Integrations
            </a>
          </li>
          <li class="mb-4 opacity-40">
            <a
              href="#"
              class="flex gap-4"
            >
              <img
                :src="settings_ethernet"
                alt="conversation icon"
                class="w-4"
              >
              Automations
            </a>
          </li>
          <li class="mb-4 opacity-40">
            <a
              href="#"
              class="flex gap-4"
            >
              <img
                :src="light_mode"
                alt="conversation icon"
                class="w-4"
              >
              Switch to bright mode
            </a>
          </li>
        </ul>
      </nav>
      <div class="flex max-sm:mt-auto">
        <div class="w-50 header__log-out-btn">
          <button 
            class="flex items-center" 
            @click="logoutHandler"
          >
            <img
              :src="mark_unread_chat"
              alt="conversation icon"
              class="w-4 mr-4"              
            >
            Log out
          </button>
        </div>
        <div class="w-50">
          <button class="flex items-center opacity-40" disabled>
            <img
              :src="mark_unread_chat"
              alt="conversation icon"
              class="w-4 mx-3"
            >
            Kiosk mode
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
header {
	background: linear-gradient(0deg, #313435 0%, #464a4f 100%);
	/*box-shadow: -1px 5px 20px 8px rgba(0, 0, 0, 0.53);*/
  border-bottom: solid 1px rgb(32, 34, 36); /*#2A2D2F*/;
}

.header__profile-dropdown {
	background: linear-gradient(0deg, #313435 0%, #464a4f 100%);

	&::before {
		content: '';
		position: absolute;
		z-index: -1;
		background-color: #464a4f;
		height: 30px;
		width: 30px;
		top: -10px;
		right: 20px;
		transform: rotate(45deg);
	}

	@media (max-width: 576px) {
		left: 0;
	}
}

.header__log-out-btn {
	position: relative;

	&::after {
		position: absolute;
		content: '';
		right: 0;
		bottom: -50%;
		background-color: #979797;
		opacity: 48%;
		width: 1px;
		height: 200%;
	}
}

.header__location_disabled {
  pointer-events: none;
  user-select: none;
}
</style>
