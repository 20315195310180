import { useLocationsApi } from '@/api/locationsApi';
import { useLoading } from '@/composables/loading';
import type { LocationAlarmsModel } from '@/models/AlarmMode';

export interface ILocationAlarmsService {
	getLocations(): Promise<Array<LocationAlarmsModel>>;
}

export function useLocationService() {
	const locationsApi = useLocationsApi();
	const { loading, setLoading, errors, clearErrors } = useLoading();

	async function getLocations() {
		try {
            const locationsPromise = locationsApi.getLocations();
            return locationsPromise;
		} catch (error) {
			console.error('An error occurred:', error);
			throw error;
		}
    }
    
    return {
        getLocations,
        loading,
        errors,
        setLoading,
        clearErrors
    }
}
