<script lang="ts" setup generic="ERROR_PAYLOAD_T extends object ">
import DottedBackground from './DottedBackground.vue';
import { GridLayout, GridItem } from 'vue3-grid-layout';
import SmarthubBaseWidget from './SmarthubBaseWidget.vue';
import { getWidgetDescriptor } from '../utils/widgets-map';
import { type LayoutItem } from '@/types/widget-layout-types';
import type { WidgetDescriptor } from '@/types/widgets-map-types';
import  { type WidgetControllerError, WidgetLayoutItemError } from '@/types/widget-error-types';
import { computed, type Component, type PropType, watch } from 'vue';

type RenderingLayoutItem = LayoutItem & { component: Component};

const props = defineProps({
  layouts: {
    type: Array as PropType<LayoutItem[]>     
  },
  editMode: {
    type: Boolean,
    default: true,
  },
  connectedMode: {
    type: Boolean,
    default: false
  },
  isGlobalDashboardView: {
    type: Boolean,
  }
})

const emit = defineEmits<{
  (e: 'layout-updated', newLayout: any): void
  (e: 'widget-deleted', widgetDescriptor: RenderingLayoutItem): void
  (e: 'widget-error', widgetError: WidgetLayoutItemError<ERROR_PAYLOAD_T>): void
}>();

function layoutUpdatedHandler(newLayout: any) {
  emit('layout-updated', newLayout);
}

function widgetDeletedHandler(layoutItem: RenderingLayoutItem) {
  emit('widget-deleted', layoutItem);
}

function widgetErrorHandler(layoutItem: RenderingLayoutItem, widgetControllerError: WidgetControllerError<ERROR_PAYLOAD_T> | null) {
  emit('widget-error', new WidgetLayoutItemError<ERROR_PAYLOAD_T>(layoutItem, widgetControllerError?.message ?? "", widgetControllerError?.payload ?? null))
}

const layout = computed<RenderingLayoutItem[]>( () => props.layouts?.map(
  layoutItem => {
    const resolvedWidgetDesc = getWidgetDescriptor(layoutItem.key);

    return {
      ...layoutItem,
      component: resolvedWidgetDesc.component,
      w: layoutItem.w ?? resolvedWidgetDesc.w,
      h: layoutItem.h ?? resolvedWidgetDesc?.h,
      componentProps: {
        ...(resolvedWidgetDesc?.componentProps ?? {}),
        ...(layoutItem?.componentProps ?? {}),
        config: {
          ...(resolvedWidgetDesc?.componentProps?.config ?? {}),
          ...(layoutItem?.componentProps?.config ?? {})
        }
      }  
    } 
  }) ?? [] );

const DEBUG_GRID = false;
</script>

<template>
  <DottedBackground>
    <GridLayout 
      :class="{'grid-test': DEBUG_GRID}"
      :layout="layout"     
      :col-num="12"
      :row-height="27"
      :is-draggable="editMode"
      :is-resizable="editMode"
      :is-mirrored="false"
      :responsive="true"
      :vertical-compact="true"
      :margin="[10, 10]"
      :use-css-transforms="true" 
      @layout-updated="layoutUpdatedHandler"
>
    <GridItem
      v-for="layoutItem in layout"
      :key="layoutItem.i"
      :x="layoutItem.x"
      :y="layoutItem.y"
      :w="layoutItem.w"
      :h="layoutItem.h"
      :i="layoutItem.i"
      :minH="layoutItem.mH"
      :minW="layoutItem.mW"

    >
  
    <SmarthubBaseWidget 
      :header-title="layoutItem?.componentHeaderTitle"
      :footer-title="layoutItem?.componentFooterTitle"
      :edit-mode="editMode" 
      @delete-widget="() => widgetDeletedHandler(layoutItem)"
      @widget-error="(event) => widgetErrorHandler(layoutItem, event as WidgetControllerError<ERROR_PAYLOAD_T>)"
      v-slot="slotProps" 
    >
      <component 
        :is="layoutItem.component"        
        v-bind="{...slotProps, ...layoutItem.componentProps, editMode, connectedMode, isGlobalDashboardView}" />
    </SmarthubBaseWidget>            

    </GridItem>
  </GridLayout>
  </DottedBackground>
</template>

<style lang="postcss">
.grid-test::before {
  content: '';
  background-size: calc(calc(100% - 5px) / 12) 40px;
  background-image: linear-gradient(
      to right,
      lightgrey 1px,
      transparent 1px
  ),
  linear-gradient(to bottom, lightgrey 1px, transparent 1px);
  height: calc(100% - 5px);
  width: calc(100% - 5px);
  /*height: 100%;*/
  /*width: 100%;*/
  position: absolute;
  background-repeat: repeat;
  margin:5px;
}

.vue-grid-item {
  touch-action: none;
}
.vue-grid-item.vue-grid-placeholder {
  border-radius: 25px !important;

  /*@apply bg-teal-600 !important;*/
  background-color: rgb(21,177, 202, 0.75) !important;
  
}
.vue-grid-item .vue-resizable-handle {
  @apply p-3 !important;
  /* default */
  /*background-image: url('data:image/svg+xml;base64,PHN2ZyBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjYiIGhlaWdodD0iNiI+PHBhdGggZD0iTTYgNkgwVjQuMmg0LjJWMEg2djZ6IiBmaWxsPSJ3aGl0ZSIvPjwvc3ZnPg==') !important;*/
  /* less opacity*/
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMSAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggaWQ9IlZlY3RvciA1IiBvcGFjaXR5PSIwLjgiIGQ9Ik0xLjQ5OTUxIDExLjA2NzZDNS44ODA2MSAxMS4wNjc2IDkuODEyMjEgNy45NTQ5NCA5LjgxMjIxIDEuNzcwMDIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=') !important;
  
  /* more opacity*/
  /*background-image: url('data:image/svg+xml;base64,PHN2ZyBzdHJva2Utd2lkdGg9JzInIHN0cm9rZT0nI2ZmZicgb3BhY2l0eT0nMC44JyB3aWR0aD0iMTEiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMSAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggaWQ9IlZlY3RvciA1IiBvcGFjaXR5PSIwLjgiIGQ9Ik0xLjQ5OTUxIDExLjA2NzZDNS44ODA2MSAxMS4wNjc2IDkuODEyMjEgNy45NTQ5NCA5LjgxMjIxIDEuNzcwMDIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=') !important;*/
  z-index: 18 !important;
}
.vue-grid-item {
  -webkit-transition: unset !important;
  transition: unset !important;
  -webkit-transition-property: unset !important;
  transition-property: unset !important;
}
</style>

