<script lang="ts" setup>
import { computed, watch, type PropType, watchEffect, toRaw, ref } from 'vue';
import CaretIcon from '@/components/icons/CaretIcon.vue';

import { createLogger } from "@/utils/logger";
import { IS_DEVELOPMENT } from '@/config/environment';
import OnPlaceInput from '@/components/OnPlaceInput.vue';
const logger = createLogger("Dashboard");
if(!IS_DEVELOPMENT) {
  logger.setLoggerEnabled(false);
}
logger.setLoggerEnabled(false);


const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  connectedMode: {
    type: Boolean,
    default: false
  },

  value: {
    type: Number,
    default: 10
  },
  unitSymbol: {
    type: String,
    default: "N/A"
  },
  valueDescription: {
    type: String, 
    default: "Config object not valid"
  },

  numberOfValueDivisions: {
    type: Number,
    default: 9,
    required: true
  },
  updateValueBy: {
    type: String as PropType<'division' | 'percentage'>,
    default: 'percentage'
  },
  updateValueQuantity: {
    type: Number,
    default: 5    //if percentage it will be 5% of totalUpperBound - totalLowerBound
  },
  cicrlesOffset:{
    type: Number,
    default: 22.11
  },
  totalUpperBoundaryValue: {
    type: Number,
    required: true,
  },
  totalLowerBoundaryValue: {
    type: Number,
    required: true,
  },

  upperBoundValue: {
    type: Number,
    required: false,
  },
  setUpperBoundValue: {
    type: Number,
    required: false,  
  },
  lowerBoundValue: {
    type: Number,
    required: false,
  },
  setLowerBoundValue: {
    type: Number, 
    required: false
  },
  type: {
    type: String as PropType<'boundries' | 'value'>,
    default: 'value'
  },
  upperBoundTitle: {
    type: String,
    default: 'ALARM'
  },
  lowerBoundTitle: {
    type: String,
    default: 'ALARM'
  },
  displayFormat: {
    type: String as PropType<'absolute-value' | 'percentage' >,
    default: 'absolute-value'
  },
  switchUpperAndLowerLevels: {
    type: Boolean,
    default:  false
  }
});

const emit = defineEmits<{
  (e: 'upper-bound-update', newValue: number): void;  
  (e: 'lower-bound-update', newValue: number): void;    
}>();


const divisionResolution = computed( () => (props.totalUpperBoundaryValue - props.totalLowerBoundaryValue) / props.numberOfValueDivisions);

const upperBoundResolved = computed( () => Math.round((props.upperBoundValue ?? 0) / divisionResolution.value));
const setUpperBoundResolved = computed( () => Math.round((props.setUpperBoundValue ?? 0) / divisionResolution.value))
const lowerBoundResolved = computed( () => Math.round((props.lowerBoundValue ?? 0) / divisionResolution.value));
const setLowerBoundResolved = computed( () => Math.round((props.setLowerBoundValue ?? 0) / divisionResolution.value))


const valueResolved = computed( () => Math.round((props.value ?? 0) / divisionResolution.value))

const formater = new Intl.NumberFormat(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});

const valueToDisplay = computed( () => props.displayFormat === 'absolute-value' 
  ? formater.format(toRaw(props.value))
  : Math.round((props.value ?? 0) / divisionResolution.value / props.numberOfValueDivisions * 100 ))
const upperBoundToDisplay = computed( () => props.displayFormat === 'absolute-value' 
  ? formater.format(toRaw(props.upperBoundValue as number))
  : Math.round((props.upperBoundValue ?? 0) / divisionResolution.value / props.numberOfValueDivisions * 100));

const setUpperBoundToDisplay = computed( () => props.displayFormat === 'absolute-value' 
  ? formater.format(toRaw(props.setUpperBoundValue as number))
  : Math.round((props.setUpperBoundValue ?? 0) / divisionResolution.value / props.numberOfValueDivisions * 100));


const lowerBoundToDisplay = computed( () => props.displayFormat === 'absolute-value' 
  ? formater.format(props.lowerBoundValue as number)
  : Math.round((props.lowerBoundValue ?? 0) / divisionResolution.value / props.numberOfValueDivisions * 100));

const setLowerBoundToDisplay = computed( () => props.displayFormat === 'absolute-value' 
  ? formater.format(props.setLowerBoundValue as number)
  : Math.round((props.setLowerBoundValue ?? 0) / divisionResolution.value / props.numberOfValueDivisions * 100));


type Circle = {
  color: 'white' | 'blue' | 'pink';
}

function resolveImgUrl(color: Circle['color']){
  switch(color) {
    case "white": return 'assets/widgets/white_radar_level_circle.png';
    case "blue": return 'assets/widgets/blue_radar_level_circle.png';
    case "pink": return 'assets/widgets/pink_radar_level_circle.png';
    default: throw new Error("Unknown asset color");
  }
}

function generateCricles(): Circle[] {
  let resultCircles: Circle[] = [];  
  if(props.type === 'value') {
    for(let i = 1; i <= props.numberOfValueDivisions; i++){
      if(valueResolved.value <= i && props.type === 'value') {
        resultCircles.push({color: 'blue'})
      } else {
        resultCircles.push({ color: 'white'});
      }
    }

  } else if(props.type === 'boundries') {
    for(let i = 1; i <= props.numberOfValueDivisions; i++){
      if((upperBoundResolved.value === i || lowerBoundResolved.value === i) && props.type === 'boundries') {
        resultCircles.push({ color: 'pink'})
      } else {
        resultCircles.push({ color: 'white'});
      }
    }
  }

    return resultCircles;    
}

type UpdateType = 'increment' | 'decrement';

function calculateUpdateValue(type: UpdateType): number | typeof NaN {
  const updateValue = props.updateValueBy === 'division'
    ? props.updateValueQuantity * divisionResolution.value
    : (props.updateValueQuantity / 100) * (props.totalUpperBoundaryValue - props.totalLowerBoundaryValue)

  if(type === 'increment') {
    return updateValue;
  }else if(type === 'decrement') {
    return -(updateValue)
  }

  return NaN;
}

type BoundType = 'upper' | 'lower'

function updateValue(boundType: BoundType, value: number) {
  if(boundType == 'upper') {
    emit('upper-bound-update', value)
  } else if(boundType == 'lower') {
    emit('lower-bound-update', value);
  }      
}

function updateValueHandler(boundType: BoundType, updateType: UpdateType) {  
  if(boundType == 'upper') {
    updateValue(boundType, (props.setUpperBoundValue ?? 0) + calculateUpdateValue(updateType))
  } else if(boundType == 'lower') {
    updateValue(boundType, (props.setLowerBoundValue ?? 0) + calculateUpdateValue(updateType));
  }      
}

const radarLevelCircles = computed( () => generateCricles());

const circlesContainerHeight = computed(() => `${((props.numberOfValueDivisions - 1) * props.cicrlesOffset) + 68.54}px`)

const actualUpperBoundValue = computed( () => !props.switchUpperAndLowerLevels
  ? props.upperBoundValue
  : props.lowerBoundValue);

const actualLowerBoundValue = computed( () => !props.switchUpperAndLowerLevels
  ? props.lowerBoundValue
  : props.upperBoundValue);

const actualSetUpperBoundValue = computed( () => !props.switchUpperAndLowerLevels
  ? props.setUpperBoundValue
  : props.setLowerBoundValue);

const actualSetLowerBoundValue = computed( () => !props.switchUpperAndLowerLevels
  ? props.setLowerBoundValue
  : props.setUpperBoundValue);

const actualUpperBoundToDisplay = computed(() => !props.switchUpperAndLowerLevels
  ? upperBoundToDisplay.value
  : lowerBoundToDisplay.value)

const actualSetUpperBoundToDisplay = computed(() => !props.switchUpperAndLowerLevels
  ? setUpperBoundToDisplay.value
  : setLowerBoundToDisplay.value)

const actualLowerBoundToDisplay = computed(() => !props.switchUpperAndLowerLevels
  ? lowerBoundToDisplay.value
  : upperBoundToDisplay.value);

const actualSetLowerBoundToDisplay = computed(() => !props.switchUpperAndLowerLevels
  ? setLowerBoundToDisplay.value
  : setUpperBoundToDisplay.value);


const actualUpperBoundType = computed<BoundType>(() => !props.switchUpperAndLowerLevels
  ? 'upper'
  : 'lower');

const actualLowerBoundType = computed<BoundType>(() => !props.switchUpperAndLowerLevels
  ? 'lower'
  : 'upper');

const upperBoundEditMode = ref(false);

function setUpperBoundEditMode(isEdit: boolean) {
  logger.log("Clicked to enter edit mode", isEdit)
  upperBoundEditMode.value = isEdit;
}

const lowerBoundEditMode = ref(false);

function setLowerBoundEditMode(isEdit: boolean) {
  lowerBoundEditMode.value = isEdit;
}

function exitBoundariesEditMode() {
  logger.log("Clicked");
  setUpperBoundEditMode(false);
  setLowerBoundEditMode(false);
}

function boundValueValidator(value: string) {
  return !isNaN( Number(value));
}

function boundValueSumittedHandler(boundType: BoundType, value: string) {
  updateValue(boundType, Number(value));
}

</script>

<template>
  <div 
    class="w-full h-full rounded-25 flex flex-col justify-center items-center relative radar-level-widget-container"
    @click="exitBoundariesEditMode"
  >
    <div class="relative -left-1.5 circles-container">
      <img         
        v-for="(circle, index) in radarLevelCircles"        
        :key="index"
        draggable="false"
        :style= "{transform: `translateY(${cicrlesOffset * index}px)`}"
        class="inline-block absolute object-center object-fill circle-image"
        :src="resolveImgUrl(circle.color)"
      >      
    </div>    
    <slot name="tabs-container"/>

    <div 
      v-if="type == 'boundries'" 
      class="boundary-container left-0 top-0 items-end pr-2 left-boundary-container"
      :class="{'pointer-events-none': editMode}"
      >
      <div class="boundary-item">
        <CaretIcon class="inline-flex boundary-item-controls-arrow-up" @click.stop="updateValueHandler(actualUpperBoundType, 'increment')" />        
        <template v-if="!upperBoundEditMode" >
          <span 
            v-if="actualUpperBoundValue == actualSetUpperBoundValue" 
            class="inline-flex boundary-item-value-number"
            @click.stop="setUpperBoundEditMode(true)"
          >{{ actualUpperBoundToDisplay }}{{ displayFormat === 'percentage' ? '%' : ""}}</span>        
          <span v-else 
            class="inline-flex boundary-item-value-number pending-update"
            @click.stop="setUpperBoundEditMode(true)"
          >{{ actualSetUpperBoundToDisplay }}{{ displayFormat === 'percentage' ? '%' : ""}}</span>        
        </template>
        <OnPlaceInput          
          v-else
          :focus-on-mount="true"
          class="inline-flex boundary-item-value-number pending-update"
          :initial-value="actualSetUpperBoundToDisplay"
          :value-validator="boundValueValidator"          
          @value-submited="boundValueSumittedHandler(actualUpperBoundType, $event)"
          @exit="setUpperBoundEditMode(false)"
         />
        <span class="inline-flex boundary-item-value-title">{{ lowerBoundTitle }}</span>
        <CaretIcon class="inline-flex boundary-item-controls-arrow-down" @click.stop="updateValueHandler(actualUpperBoundType, 'decrement')" />
      </div>
      <div class="boundary-item">
        <CaretIcon class="inline-flex boundary-item-controls-arrow-up" @click.stop="updateValueHandler(actualLowerBoundType, 'increment')" />
        <template v-if="!lowerBoundEditMode">
          <span 
            v-if="actualLowerBoundValue == actualSetLowerBoundValue" 
            class="inline-flex boundary-item-value-number"
            @click.stop="setLowerBoundEditMode(true)"
          >{{ actualLowerBoundToDisplay }}{{ displayFormat === 'percentage' ? '%' : ""}}</span>
          <span 
            v-else 
            class="inline-flex boundary-item-value-number pending-update"
            @click.stop="setLowerBoundEditMode(true)"
            >{{ actualSetLowerBoundToDisplay }}{{ displayFormat === 'percentage' ? '%' : ""}}</span>        
        </template>
        <OnPlaceInput          
          v-else
          :focus-on-mount="true"
          class="inline-flex boundary-item-value-number pending-update"
          :initial-value="actualSetLowerBoundToDisplay"
          :value-validator="boundValueValidator"          
          @value-submited="boundValueSumittedHandler(actualLowerBoundType, $event)"
          @exit="setLowerBoundEditMode(false)"
         />

        <span class="inline-flex boundary-item-value-title">{{ upperBoundTitle }}</span>
        <CaretIcon class="inline-flex boundary-item-controls-arrow-down" @click.stop="updateValueHandler(actualLowerBoundType, 'decrement')" />
      </div>

    </div>
    <div class="boundary-container right-0 top-0 items-start pl-[1px] right-boundary-container">
      <div class="boundary-item">        
        <span class="inline-flex boundary-item-value-number">{{ valueToDisplay }}{{ displayFormat === 'percentage' ? '%' : ""}}</span>
        <span v-if="displayFormat === 'absolute-value'" class="inline-flex boundary-item-unit-symbol">{{ unitSymbol }}</span>
        <span class="inline-flex boundary-item-value-title">CURRENT</span>        
      </div>
    </div>    
    
  </div>      
</template>

<style scoped lang="scss">
.radar-level-widget-container {
  box-sizing: border-box;  
  font-family: "Avenir Next LT Pro" ;

  background: linear-gradient(0deg, #313435 0%, #464A4F 100%);
  overflow: hidden;    
}
.circles-container {
  width: 165px;  
  height: v-bind(circlesContainerHeight);  
  
  justify-self: center;
  align-self: center;  
}

.circle-image {
  width: 165px;
  height: 68.54px;
  mix-blend-mode: lighten;
  @apply w-full select-none;
}

.boundary-container {
  @apply absolute h-full flex flex-col flex-1;
  width: calc((100% - 164px) / 2);
  overflow: hidden;
}

.left-boundary-container {  
  @apply justify-around;
}

.right-boundary-container {  
  @apply justify-center
}

.boundary-item {
  @apply flex flex-col justify-center items-center gap-0.5 select-none w-max;
}

.boundary-item-controls-arrow-up {
  box-sizing: border-box;
  opacity: 0.62;
  transform: rotate(180deg);
  @apply boundary-item-control;
}

.boundary-item-controls-arrow-down {
  box-sizing: border-box;
  opacity: 0.62;
  @apply boundary-item-control;
}

.boundary-item-control:active {
  scale: 1.2;
  opacity: 1;
}

.boundary-item-control:hover {  
  opacity: 1;  
}


.boundary-item-value-title {
  height: 15px;
  /*width: 53px;*/
  opacity: 0.6;
  color: #A5ACAF;
  font-size: 11.2px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  justify-self: center;
}

.boundary-item-unit-symbol {
  height: 15px;  
  opacity: 0.75;
  color: #EAEAEA;
  font-size: 11.2px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;  
  text-align: center;
  justify-self: center;
}

.boundary-item-value-number {
  height: 33px;
  /*width: 45px; */ /*!!!!*/
  width: 53px;
  color: #EAEAEA;  
  font-family: 'Avenir Next LT Pro'; /*'Avenir Light';*/
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  justify-content: center;

}

.boundary-item-value-number.pending-update {
  color: rgba(255, 172, 68, 0.8);  
}


</style>