<script setup lang="ts">
import { ref } from 'vue';
import { type PropType } from 'vue';
const isOpen = ref(false);

const props = defineProps({
	isTopLevel: {
		type: Boolean as PropType<boolean>,
	},
	showChevron: {
		type: Boolean as PropType<boolean>,
	},
});

const toggleAccordion = () => (isOpen.value = !isOpen.value);
</script>

<template>
	<div>
		<button
			@click="toggleAccordion()"
			:class="{ 'top-level': props.isTopLevel }"
			class="relative flex items-center justify-between w-full bg-[#1c1e1f] text-white py-4 px-3 hover:bg-[#282a2c] transition duration-200 cursor-pointer"
			:aria-expanded="isOpen"
		>
			<slot name="title" />
			<svg
				class="w-3 transition-all duration-200 transform"
				:class="{
					'-rotate-90': isOpen,
					'rotate-0': !isOpen,
				}"
				fill="none"
				stroke="currentColor"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 16 10"
				aria-hidden="true"
				v-if="props.showChevron"
			>
				<path d="M15 1.2l-7 7-7-7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
		</button>
		<div :class="[isOpen ? 'opacity-100' : 'h-[0px] opacity-0 overflow-hidden', 'transition-all duration-200 ease-in-out']">
			<slot name="content" />
		</div>
	</div>
</template>

<style scoped lang="scss">
.top-level {
	background: linear-gradient(0deg, #313435 0%, #464a4f 100%);
	&::before {
		position: absolute;
		content: '';
		left: 0;
		width: 6.29px;
		height: 100%;
		background-color: #15b1ca;
	}
}

.accordion__content {
	transition: all 0.2s ease-out;
}
</style>
