import { usePreferencesApi, type IPreferencesApi } from "@/api/preferencesApi";
import { type IKeyValueStorage } from "./KeyValueBrowserStorage";
import { KeyValuePreferenceStorage } from "./KeyValuePreferenceStorage";
import { DASHBOARD_APP_KEY, type APP_KEYS, type PREF_KEYS, type PreferencesResponse } from "@/api/dtos/PreferenceDto";

export interface IKeyValueBatchOperation {
  startBatch(): Promise<boolean>;
  commit(): Promise<void>;
  rollback(): Promise<void>;
  executeWithoutBatch<RESULT_T>( callback: ( keyValueStorage: IKeyValueStorage) => Promise<RESULT_T>): Promise<RESULT_T>
}



export class KeyValueBatchPreferenceStorage<
    APPLICATION_KEY extends APP_KEYS, 
    PREFERENCE_KEYS extends PREF_KEYS<APPLICATION_KEY>
  > implements IKeyValueStorage, IKeyValueBatchOperation {

  private preferenceApi: IPreferencesApi;
  private readonly mergeReadAndWrite: boolean;
  readonly PREFERENCE_APPLICATION_KEY: APPLICATION_KEY;

  private readCache: PreferencesResponse<APP_KEYS, PREF_KEYS<APP_KEYS>>['preferences'];
  private writeCache: PreferencesResponse<APP_KEYS, PREF_KEYS<APP_KEYS>>['preferences'];
  private batchMode: boolean;

  private readInitialized: boolean;

  private nonBatchKeyValuePrefStorage:  IKeyValueStorage;

  private readonly throwErrorOnInconsistentStateCalls: boolean;

  constructor(applicationKey: APPLICATION_KEY, mergeReadAndWrite: boolean = true, throwErrorOnInconsistentStateCalls: boolean = false){        
    this.PREFERENCE_APPLICATION_KEY = applicationKey
    this.mergeReadAndWrite = mergeReadAndWrite;
    this.nonBatchKeyValuePrefStorage = new KeyValuePreferenceStorage<APPLICATION_KEY, PREFERENCE_KEYS>(this.PREFERENCE_APPLICATION_KEY);

    this.preferenceApi = usePreferencesApi();    
    this.readCache = {} as PreferencesResponse<APP_KEYS, PREF_KEYS<APP_KEYS>>['preferences'];
    this.writeCache = {} as PreferencesResponse<APP_KEYS, PREF_KEYS<APP_KEYS>>['preferences'];  
    this.batchMode = false;  

    this.readInitialized = false;
    this.throwErrorOnInconsistentStateCalls = throwErrorOnInconsistentStateCalls;
  }

  protected resetState() {
    this.readCache = {} as PreferencesResponse<APP_KEYS, PREF_KEYS<APP_KEYS>>['preferences'];
    this.writeCache = {} as PreferencesResponse<APP_KEYS, PREF_KEYS<APP_KEYS>>['preferences'];  
    this.batchMode = false;  
    this.readInitialized = false;
  }

  protected throwCallInconsistent() {
    if(this.throwErrorOnInconsistentStateCalls) {
      throw new Error("Batch calls are not in expected consistent state")
    }
  }

  startBatch(): Promise<boolean> {    
    if(!this.batchMode) {
      this.resetState();
      this.batchMode = true;
      return Promise.resolve(true);
    } 

    this.throwCallInconsistent();

    return Promise.resolve(true);
  }
  async commit(): Promise<any> {
    if(this.batchMode) {
      const result = await this.performWrite();      
      this.batchMode = false;
      return result;
    }    

    this.throwCallInconsistent();    
  }
  
  rollback(): Promise<void> {
    if(this.batchMode) {
      this.resetState();       
      return Promise.resolve();
    } 

    this.throwCallInconsistent();    

    return Promise.resolve();
  }
  protected async initRead(): Promise<any> {
    const result = (this.readCache = (await this.preferenceApi.getAllPreferencesForApplicaionKey(this.PREFERENCE_APPLICATION_KEY)).preferences ?? {});
    this.readInitialized = true;
    return result;
  }

  protected async performWrite(): Promise<any> {
    if(this.batchMode) {
      if(this.mergeReadAndWrite && !this.readInitialized) {
        await this.initRead();
      }
      
      const result = (await this.preferenceApi.replaceAllPreferencesForApplicaionKey(
        this.PREFERENCE_APPLICATION_KEY,
        (this.mergeReadAndWrite 
          ? { 
          ...this.readCache,
          ...this.writeCache 
        } 
        : this.writeCache)
        )).preferences;

      return result;
    }    
  }

  async setItem(key: string, value: any): Promise<void> {    
    if(this.batchMode) {
      this.writeCache[key] = value;              
    } else {
      this.throwCallInconsistent();
      return this.nonBatchKeyValuePrefStorage.setItem(key, value);
    }
  }
  async getItem(key: string): Promise<any | null> {    
    if(this.batchMode) {
      if(!this.readInitialized) {
        await this.initRead();
      }

      return this.writeCache[key] || this.readCache[key];
    } else {
      this.throwCallInconsistent();
      return this.nonBatchKeyValuePrefStorage.getItem(key);
    }
  }

  async removeItem(key: string): Promise<boolean> {
    if(this.batchMode) {
      const readCacheResult = delete this.readCache[key];
      const writeCacheResult = delete this.writeCache[key];
      return writeCacheResult || readCacheResult;      
    }else {
      this.throwCallInconsistent();
      return this.nonBatchKeyValuePrefStorage.removeItem(key);
    }        
  }

  async executeWithoutBatch<RESULT_T>( callback: ( keyValueStorage: IKeyValueStorage) => Promise<RESULT_T>): Promise<RESULT_T> {
    return callback(this.nonBatchKeyValuePrefStorage);
  }

}


let __keyValueBatchPreferenceStorage: KeyValueBatchPreferenceStorage<APP_KEYS, PREF_KEYS<APP_KEYS>> | null = null;

export function createDashboardBatchPreferenceStorage(singleton: boolean = true): KeyValueBatchPreferenceStorage<APP_KEYS, PREF_KEYS<APP_KEYS>> {
  if(singleton) {
    if(!__keyValueBatchPreferenceStorage) {
      __keyValueBatchPreferenceStorage = new KeyValueBatchPreferenceStorage(DASHBOARD_APP_KEY, true, true);
    }

    return __keyValueBatchPreferenceStorage;
  } else {
    return new KeyValueBatchPreferenceStorage(DASHBOARD_APP_KEY, true, true);
  }
}