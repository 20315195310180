<template>
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.03418 11.6328C8.81168 11.6328 8.59417 11.5632 8.40916 11.4327C8.22416 11.3022 8.07996 11.1167 7.99481 10.8998C7.90967 10.6828 7.88739 10.444 7.9308 10.2136C7.9742 9.98329 8.08135 9.7717 8.23868 9.60562C8.39602 9.43955 8.59647 9.32645 8.8147 9.28063C9.03293 9.23481 9.25913 9.25833 9.4647 9.34821C9.67027 9.43809 9.84597 9.59029 9.96958 9.78557C10.0932 9.98086 10.1592 10.2104 10.1592 10.4453C10.1592 10.7603 10.0407 11.0623 9.82967 11.285C9.6187 11.5077 9.33255 11.6328 9.03418 11.6328ZM10.1592 5.39844C10.1592 5.16357 10.0932 4.93398 9.96958 4.7387C9.84597 4.54342 9.67027 4.39121 9.4647 4.30133C9.25913 4.21145 9.03293 4.18794 8.8147 4.23376C8.59647 4.27958 8.39602 4.39267 8.23868 4.55875C8.08135 4.72482 7.9742 4.93642 7.9308 5.16677C7.88739 5.39712 7.90967 5.63589 7.99482 5.85287C8.07996 6.06986 8.22416 6.25532 8.40916 6.38581C8.59417 6.51629 8.81168 6.58594 9.03418 6.58594C9.33255 6.58594 9.6187 6.46083 9.82967 6.23813C10.0407 6.01543 10.1592 5.71338 10.1592 5.39844ZM10.1592 15.4922C10.1592 15.2573 10.0932 15.0277 9.96958 14.8324C9.84597 14.6372 9.67026 14.485 9.4647 14.3951C9.25913 14.3052 9.03293 14.2817 8.8147 14.3275C8.59647 14.3733 8.39602 14.4864 8.23868 14.6525C8.08135 14.8186 7.9742 15.0302 7.9308 15.2605C7.88739 15.4909 7.90967 15.7296 7.99481 15.9466C8.07996 16.1636 8.22416 16.3491 8.40916 16.4796C8.59417 16.61 8.81168 16.6797 9.03418 16.6797C9.33255 16.6797 9.6187 16.5546 9.82967 16.3319C10.0407 16.1092 10.1592 15.8071 10.1592 15.4922Z"
      fill="white"
    />
  </svg>
</template>
