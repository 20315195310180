import { useApi } from '@/composables/api';
import { type TagsResponse, type TagResponse, type TagDto, type TagSettingDotNetsted } from './dtos/TagDto';

export interface ITagsApi {
	getTagsForObject(locationId: string): Promise<TagsResponse>;
	getTag(tagId: string): Promise<TagResponse>;
	getTagsForLocation(locationId: string, queryParams?: ITagQueryParams): Promise<TagsResponse>;
	modifyTagValue(tagId: string, value: string): Promise<TagResponse<'value'>>
}

export interface ITagQueryParams {
	fields?: Array<keyof TagDto | TagSettingDotNetsted>;
	id?: Array<string>;
	type?: string;
}

const API_VERSIONING = 'api/v1';

export function useTagsApi(): ITagsApi {
	const api = useApi();

	async function getTagsForObject(objectId: string): Promise<TagsResponse> {
		return api.get(`${API_VERSIONING}/objects/${objectId}/tags`);
	}

	async function getTag(tagId: string): Promise<TagResponse> {
		return api.get(`${API_VERSIONING}/tags/${tagId}`);
	}

	async function getTagsForLocation(locationId: string, queryParams?: ITagQueryParams): Promise<TagsResponse> {
		const { id, fields, ...rest } = queryParams ?? {};
		return api.get(`${API_VERSIONING}/locations/${locationId}/tags`, {}, { params: {
			id: queryParams?.id?.join(','),
			fields: queryParams?.fields?.join(','),
			...rest
		}})
	}

	async function modifyTagValue(tagId: string, value: string): Promise<TagResponse<'value'>> {
		return api.put(`${API_VERSIONING}/tags/${tagId}`,
		{ value},  
		{ headers: {'Content-Type': 'application/json'}})
	}

	return {
		getTagsForObject,
		getTag,
		getTagsForLocation,
		modifyTagValue
	};
}
