import { useApi, type ApiLoadingType } from "@/composables/api";
import type { EventsResponse } from "./dtos/EventsDto";

export interface EventsQueryParams {
  s?: number
  e?: number
}


export interface IEvents {
  getEventsForLocation(locationId: string, queryParams?: EventsQueryParams): Promise<EventsResponse>;
}


const API_VERSIONING = 'api/v1';

export function useEventsApi() : IEvents & ApiLoadingType {
  const api = useApi();
  const { loading,  errors, clearErrors } = api;

  async function getEventsForLocation(locationId: string, queryParams?: EventsQueryParams): Promise<EventsResponse> {
    return api.get(`${API_VERSIONING}/locations/${locationId}/useractions`, {}, {
      params: queryParams
    });
  }

  return {
    loading,
    errors,
    clearErrors,
    getEventsForLocation
  }

}