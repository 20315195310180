import { useBreakpoints } from "@vueuse/core";
import { computed } from "vue";

export const useSmarthubBreakpoints = function() {
  const breakpoints = useBreakpoints({
    sm: 360,
    mobile: 480,    
    md: 768,
    lg: 1024,
    xl: 1280
  });

/*
  const isLegacyMobile = computed( () => breakpoints.smaller('sm').value);
  const isMobile = computed( () => breakpoints.smaller('mobile').value);
  const isTablet = computed( () => breakpoints.between('mobile', 'lg').value);
  const isLaptop = computed( () => breakpoints.between('lg', 'xl').value);
  const isDesktop = computed( () => breakpoints.greaterOrEqual('xl').value);  
*/
  
  const isLegacyMobile = computed( () => breakpoints.smaller('sm').value);
  const isLessThen480 = computed( () => breakpoints.smaller('mobile').value);
  const isMobile = computed( () => breakpoints.smaller('md').value);
  const isTablet = computed( () => breakpoints.between('md', 'lg').value);
  const isLaptop = computed( () => breakpoints.between('lg', 'xl').value);
  const isTabletOrLaptop = computed( () => breakpoints.between('md', 'xl').value);
  const isDesktop = computed( () => breakpoints.greaterOrEqual('xl').value);  

  return {
    isLegacyMobile,
    isLessThen480,
    isMobile,    
    isTablet,
    isLaptop,
    isTabletOrLaptop,
    isDesktop,    

    breakpoints,
  }
}