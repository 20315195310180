<template>
  <svg
    width="10"
    height="9"
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.7705 1.06255L7.17619 0.468239C6.79308 0.0851312 6.1693 0.0851312 5.7862 0.468239L4.46988 1.78456L0.20166 6.05277V8.03708H2.18596L6.47874 3.7443L7.7705 2.45254C8.15852 2.06943 8.15852 1.44566 7.7705 1.06255ZM1.7783 7.05475H1.18399V6.46044L5.43747 2.20696L6.03178 2.80127L1.7783 7.05475ZM4.13097 8.03708L6.09563 6.07242H9.04261V8.03708H4.13097Z"
      fill="white"
    />
  </svg>
</template>
