<template>
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.01664 11.1645C4.06664 6.79251 8.02129 4.5 11.9832 4.5C16.6362 4.5 20.6444 7.53129 23.0091 11.1836L23.0102 11.1853C23.1667 11.4285 23.2499 11.7115 23.2499 12.0007C23.2499 12.2893 23.167 12.5719 23.0111 12.8147C20.6484 16.5141 16.6661 19.5 11.9832 19.5C7.25034 19.5 3.34485 16.5203 0.987934 12.8317C0.827785 12.583 0.744722 12.2925 0.749216 11.9968C0.753721 11.7002 0.846018 11.4117 1.01444 11.1676L1.01664 11.1645ZM2.24905 12.0196L2.25112 12.0228C4.42805 15.4304 7.91718 18 11.9832 18C16.0036 18 19.5679 15.4195 21.7474 12.0065L21.7489 12.0043C21.7496 12.0032 21.7499 12.002 21.7499 12.0007C21.7499 12.0002 21.7499 11.9996 21.7497 11.9991C21.7496 11.9985 21.7493 11.9979 21.749 11.9973C19.5612 8.61915 15.9676 6 11.9832 6C8.63899 6 5.09929 7.93555 2.24905 12.0196ZM11.9991 9C10.3422 9 8.99909 10.3431 8.99909 12C8.99909 13.6569 10.3422 15 11.9991 15C13.6559 15 14.9991 13.6569 14.9991 12C14.9991 10.3431 13.6559 9 11.9991 9ZM7.49909 12C7.49909 9.51472 9.51381 7.5 11.9991 7.5C14.4844 7.5 16.4991 9.51472 16.4991 12C16.4991 14.4853 14.4844 16.5 11.9991 16.5C9.51381 16.5 7.49909 14.4853 7.49909 12Z"
			fill="white"
		/>
	</svg>
</template>
