<script lang="ts" setup>
import { computed, type Component, type PropType } from 'vue';
import Select, { type SELECT_ITEM_TYPE, type DropDownPosition } from '@/components/Select.vue'
import PlusIcon from '@/components/icons/PlusIcon.vue';
import type { WidgetPack } from '@/types/widgets-map-types';

const props = defineProps({
  items: Array as PropType<WidgetPack[]>,
  headerComponent: {
    type: Object as PropType<Component>,
    required: false
  },
  headerIconComponent: {
    type: Object as PropType<Component>,    
    default: () => PlusIcon    
  },
  dropDownPosition: {
    type: String as PropType<DropDownPosition>
  },
  dropDownShadow: {
    type: String
  }
});

const emit = defineEmits<{
  (e: 'widget-selected', widgetItem: WidgetPack, index: number): void
}>()

function itemSelectedHandler(item: SELECT_ITEM_TYPE<{title: 'title'; desc: 'desc'}>, index: number) {
  const { title, desc, ...restObj } = item;  
  emit('widget-selected', {...restObj} as WidgetPack, index);
}

const widgetItems = computed( () => props.items?.map( item => {
  const { descriptor: itemDescriptor } = item;
  return {
    ...item,
    title: itemDescriptor.componentName,
    desc: itemDescriptor.componentDesc,  
    ...(itemDescriptor.componentIcon ? {iconComponent: itemDescriptor.componentIcon} : {})
  }
}) ?? [] );


</script>

<template>
  <Select       
      button-label="Add Widget"
      :header-component="headerComponent"
      :header-icon-component="headerIconComponent"
      :items="widgetItems"
      :drop-down-position="dropDownPosition"
      :drop-down-shadow="dropDownShadow"
      @item-selected="itemSelectedHandler"            
  />
</template>