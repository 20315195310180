import type { LocationModel } from "@/models/LocationModel";
import { type LocationQueryParams, useLocationsApi } from "../api/locationsApi";
import { useObjectsApi } from "../api/objectsApi";
import { useTagsApi } from "../api/tagsApi";
import { useServiceCallWithLoading } from "@/composables/serviceCallWithLoading";
import type {  LocationsResponse } from "@/api/dtos/LocationDto";
import type { Ref } from "vue";
import type { ObjectModel } from "@/models/ObjectModel";
import type { ObjectsResponse } from "@/api/dtos/ObjectDto";
import type { TagsResponse } from "@/api/dtos/TagDto";
import type { TagModel } from "@/models/TagModel";

export interface TagParams {
	type: string;
}

export interface ILocationObjectTagsService {
    getLocations(queryParams?: LocationQueryParams): Promise<LocationModel[]>;
    getLocationsLoading: Ref<boolean>;
    getLocationsErrors:  Ref<any | any[]>;
    getLocationsClearErrors: () => void;

    getObjectsForLocation(locationId: string): Promise<ObjectModel[]>;
    getObjectsForLocationLoading: Ref<boolean>;
    getObjectsForLocationErrors:  Ref<any | any[]>;
    getObjectsForLocationClearErrors: () => void;

    getTagsForObject(objectId: string): Promise<TagModel[]>;
    getTagsForObjectLoading: Ref<boolean>;
    getTagsForObjectErrors:  Ref<any | any[]>;
    getTagsForObjectClearErrors: () => void;
  
    getTagsForLocation(locationId: string, params?: TagParams): Promise<TagModel[]>;
    getTagsForLocationLoading: Ref<boolean>;
    getTagsForLocationErrors:  Ref<any | any[]>;
    getTagsForLocationClearErrors: () => void;
}

export const useLocationObjectTagsService = function(): ILocationObjectTagsService {
  const locationsApi = useLocationsApi();
  const objectsApi = useObjectsApi();
  const tagsApi = useTagsApi();

  const {
    serviceCall: getLocations,
    loading: getLocationsLoading,
    errors: getLocationsErrors,
    clearErrors: getLocationsClearErrors,

  } = useServiceCallWithLoading<Parameters<ILocationObjectTagsService['getLocations']> ,LocationsResponse, LocationModel[]>(
    locationsApi.getLocations,
    async (locationsDtos ) => locationsDtos.locations.map( oneLocationResponse => oneLocationResponse.location)
  );

  const {
    serviceCall: getObjectsForLocation,
    loading: getObjectsForLocationLoading,
    errors: getObjectsForLocationErrors,
    clearErrors: getObjectsForLocationClearErrors,

  } = useServiceCallWithLoading<Parameters<ILocationObjectTagsService['getObjectsForLocation']> ,ObjectsResponse, ObjectModel[]>(
    objectsApi.getObjectsForLocation,
    async (objectsDto ) => objectsDto.objects.map( oneObjectResponse => oneObjectResponse.object)
  );

  const {
    serviceCall: getTagsForObject,
    loading: getTagsForObjectLoading,
    errors: getTagsForObjectErrors,
    clearErrors: getTagsForObjectClearErrors,

  } = useServiceCallWithLoading<Parameters<ILocationObjectTagsService['getTagsForObject']> ,TagsResponse, TagModel[]>(
    tagsApi.getTagsForObject,
    async (tagsDto ) => tagsDto.tags.map( oneTagResponse => oneTagResponse.tag)
    );
  
  const {
    serviceCall: getTagsForLocation,
    loading: getTagsForLocationLoading,
    errors: getTagsForLocationErrors,
    clearErrors: getTagsForLocationClearErrors,

  } = useServiceCallWithLoading<Parameters<ILocationObjectTagsService['getTagsForLocation']>, TagsResponse, TagModel[]>(
    tagsApi.getTagsForLocation,
    async (tagsDto ) => tagsDto.tags.map( oneTagResponse => oneTagResponse.tag)
  );
  
  return {
    getLocations,
    getLocationsLoading,
    getLocationsErrors,
    getLocationsClearErrors,

    getObjectsForLocation,
    getObjectsForLocationLoading,
    getObjectsForLocationErrors,
    getObjectsForLocationClearErrors,

    getTagsForObject,
    getTagsForObjectLoading,
    getTagsForObjectErrors,
    getTagsForObjectClearErrors,

    getTagsForLocation,
    getTagsForLocationLoading,
    getTagsForLocationErrors,
    getTagsForLocationClearErrors
  }
}