<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.12434 12.8347C9.12434 13.1055 9.15621 13.3684 9.19603 13.6313H1.95525C1.53273 13.6313 1.12751 13.4634 0.828739 13.1647C0.529969 12.8659 0.362122 12.4607 0.362122 12.0381V2.47936C0.362122 1.59517 1.07106 0.88623 1.95525 0.88623H6.73465L8.32778 2.47936H14.7003C15.1228 2.47936 15.528 2.64721 15.8268 2.94598C16.1256 3.24475 16.2934 3.64997 16.2934 4.07249V8.70054C15.8075 8.42174 15.2738 8.23056 14.7003 8.12701V4.07249H1.95525V12.0381H9.19603C9.15621 12.301 9.12434 12.5639 9.12434 12.8347ZM14.7003 12.0381V9.64845H13.1072V12.0381H10.7175V13.6313H13.1072V16.021H14.7003V13.6313H17.09V12.0381H14.7003Z"
      fill="white"
    />
  </svg>
</template>
