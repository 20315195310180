<script lang="ts" setup>
import { ref, type PropType, toRaw, onMounted } from 'vue';

const props = defineProps({
  placeholder: {
    type: String,    
  },
  initialValue: {
    type: String as PropType<any>,
  },
  modelValue: {
    type: String as PropType<String | null>,
  },
  textAlign: {
    type: String as PropType<'left' | 'center' | 'right'>,
    default: 'center'
  },
  valueValidator: {
    type: Function as PropType<(value: string) => boolean>, 
    required: false,
  },
  focusOnMount: {
    type: Boolean,
    default: false
  },
  closeOnSubmit: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits<{  
  (e: 'value-submited', value: any): void
  (e: 'exit'): void
}>();

const state = ref(toRaw(props.initialValue));

const containerRef = ref();
const inputRef = ref();


onMounted(() => {  
  if(props.focusOnMount) {
    containerRef.value.focus();
    inputRef.value.focus();
  }  
})

function valueSubmitHandler() {
  if(props.valueValidator) {
    if(! props.valueValidator(toRaw(state.value))) {
      return;
    }
  }

  emit('value-submited', toRaw(state.value));
  if(props.closeOnSubmit){
    emit('exit');
  }
}

function exitHandler() {
  emit('exit');
}

defineExpose({
  inputRef
});

</script>
<template>
  <div 
    ref="containerRef"
    class="relative on-place-input"
    >
    <input    
      ref="inputRef"
      class="absolute on-place-input on-place-input-input"    
      :class="$attrs.class"
      :placeholder="placeholder"      
      @keyup.enter.prevent="valueSubmitHandler"
      @keyup.esc.prevent="exitHandler"      
      v-model="state"      
    />      
  </div>
</template>

<style scoped>
.on-place-input {
  background-color: unset;
  text-align: v-bind(textAlign);
}

.on-place-input {
  opacity: 0.9;
  color: white;  
}

.on-place-input-input {
  width: 125%;
  height: 86%;
  justify-self: center;
  align-self: center;
}
.on-place-input-input:hover {
  /*border: solid 2px rgba(255, 172, 68, 1);;*/
  /*outline: solid 1px rgba(255, 172, 68, 1);;*/
  color: rgba(255, 172, 68, 0.8);
  border-radius: 4px;
  outline-offset: 1px;  
/* rgba(255, 172, 68, 1); */
}

.on-place-input-input:focus {
  /*border: solid 2px rgba(255, 172, 68, 1);;*/
  outline: solid 1px rgba(255, 172, 68, 1);;  
  color: rgba(255, 172, 68, 0.9);
  border-radius: 4px;
  outline-offset: 1px;  
/* rgba(255, 172, 68, 1); */
}


</style>