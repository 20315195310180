<template>
  <svg
    width="30px"
    height="31px"
    viewBox="0 0 30 31"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>2B8DBE8E-D5C2-4744-9CB2-0CE6A387864F</title>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="InterAct-Dashboard-Pump-UX-Alarm-Message-Mobile"
        transform="translate(-362, -820)"
        fill="#FFFFFF"
        fill-rule="nonzero"
      >
        <g id="Group-3" transform="translate(-7, 814)">
          <g id="specified-scope-copy-2" transform="translate(369, 6)">
            <path
              d="M26.4872553,18.3469388 C24.5484108,18.3469388 22.9787234,19.9042289 22.9787234,21.8286458 C22.9787234,23.7499033 24.5483568,25.3061224 26.4872553,25.3061224 C28.4271801,25.3061224 30,23.7499033 30,21.8286458 C30,19.9042289 28.42718,18.3469388 26.4872553,18.3469388 Z"
              id="Path"
            />
            <path
              d="M15,6.95918367 C16.9410049,6.95918367 18.5106383,5.40343139 18.5106383,3.48167958 C18.5106383,1.55575228 16.9410049,0 15,0 C13.0589951,0 11.4893617,1.55575228 11.4893617,3.48167958 C11.4893617,5.40343139 13.0590491,6.95918367 15,6.95918367 Z"
              id="Path"
            />
            <path
              d="M27.1276734,16.309793 C27.9353182,16.4145941 28.6857993,16.677687 29.3541282,17.0816327 C29.3552339,17.030322 29.3617021,16.9811912 29.3617021,16.9298806 C29.3617021,11.0806897 25.8859382,6.04475587 20.9168413,3.79591837 C20.898487,4.59285441 20.7278804,5.34826125 20.4255319,6.03497443 C24.2285222,7.93330087 26.9020039,11.7968031 27.1276734,16.309793 Z"
              id="Path"
            />
            <path
              d="M7.0212766,21.8286184 C7.0212766,19.9047788 5.44635024,18.3469388 3.5106383,18.3469388 C1.5686072,18.3469388 0,19.9048323 0,21.8286184 C0,23.7492995 1.5686072,25.3061224 3.5106383,25.3061224 C5.44629625,25.3061224 7.0212766,23.7492995 7.0212766,21.8286184 Z"
              id="Path"
            />
            <path
              d="M2.87206403,16.3108403 C3.09986285,11.7971098 5.77198007,7.93409168 9.57446809,6.03534186 C9.27326066,5.3485719 9.10267412,4.59298521 9.08321643,3.79591837 C4.11470356,6.04406274 0.638297872,11.0818851 0.638297872,16.9331539 C0.638297872,16.9833549 0.644765351,17.0314316 0.645870889,17.0816327 C1.31307102,16.6787947 2.06451416,16.4145406 2.87206403,16.3108403 Z"
              id="Path"
            />
            <path
              d="M15.3185897,28.6675579 C12.4818904,28.6675579 9.87571531,27.6425662 7.80474537,25.9387755 C7.21165751,26.4877158 6.51477228,26.9056894 5.74468085,27.1698968 C8.29185477,29.5433899 11.6408298,31 15.3185897,31 C18.9963496,31 22.3453246,29.5433899 24.893617,27.1687889 C24.122463,26.9056894 23.4255778,26.488882 22.8324899,25.9387755 C20.761464,27.6425662 18.155233,28.6675579 15.3185897,28.6675579 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
