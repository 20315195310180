const WELCOME_OVERLAY_SEEN_KEY = 'NF_OVERLAY_SEEN'

class WelcomeOverlayService { 
  constructor(private storage: typeof window.localStorage | typeof window.sessionStorage) {}

  setWelcomeOverlaySeen(overlaySeen: boolean){
    this.storage.setItem(WELCOME_OVERLAY_SEEN_KEY, String(overlaySeen))
  }

  getWelcomeOverlaySeen(): boolean {    
    const overlaySeen = this.storage.getItem(WELCOME_OVERLAY_SEEN_KEY);
    return (overlaySeen === null || overlaySeen === undefined)
      ? false
      : (overlaySeen === 'true' || overlaySeen === 'TRUE');
  }
}

export const welcomeOverlayService = new WelcomeOverlayService(window.localStorage);