<script lang="ts">
export type {
  GetUnitsByPosResponse,
  GetGISDataResponse,
} from '@wisionmonorepo/api-client-v1/src/responses';
export type {
  UnitStatusNames
} from '@wisionmonorepo/core-device-support/src/enums';
</script>
<script setup lang="ts">
import { onMounted, PropType, ref, toRaw, watch } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer';
import {
  GetUnitsByPosResponse,
  GetGISDataResponse,
} from '@wisionmonorepo/api-client-v1/src/responses';
import {
  UnitColor,
  UnitStatusNames,
} from '@wisionmonorepo/core-device-support/src/enums';
//import { getUnitStatusName } from '@wisionmonorepo/core-device-support/src/status';
import { featureStyle, filterGis } from '../../core/gis';
import map_3D_controls from '/assets/map/map_3D_controls.svg';
import MarkerWindow from '../composites/MarkerWindow.vue';
import { useFooterStore } from '@wisionmonorepo/smarthub/src/stores/footer';
import { useDevice, DeviceSize } from '../../../smarthub/src/utils/DeviceSize';
import { storeToRefs } from 'pinia';
import { useWindowSize } from '@vueuse/core';
const { width } = useWindowSize();

const device = useDevice();

const mapStyling: google.maps.MapTypeStyle[] = [
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36,
      },
      {
        color: '#000000',
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#000000',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8b9198',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#323336',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#414954',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#2e2f31',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7a7c80',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#7d7f81',
      },
      {
        lightness: '-10',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#202022',
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#393a3f',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#202022',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#393a3f',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#202022',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#202124',
      },
    ],
  },
];

interface Segment {
  color: string;
  dashLength: number;
  dashOffset: number;
}

const colors = [
  UnitColor.ok,
  UnitColor.problem,
  UnitColor.alarm,
  UnitColor.inactive,
];

const props = defineProps({
  devices: {
    type: Array as PropType<GetUnitsByPosResponse[]>,
    required: true,
  },
  type: {
    type: String,
    default: 'unit',
  },
  mapStartPosition: {
    type: Object as PropType<{ lat: number, lng: number }>,
    default: () => ({
      lat: 52.1326,
      lng: 5.2913
    })
  },
  mapStartZoom: {
    type: Number,
    default: 9
  },
  mapTypeSatellite: {
    type: Boolean,
    default: false,
  },
  getUnitStatusName: {
    type: Function,
    required: true
  },
  gisOptions: {
    type: Object,
  },
  selectedDeviceData: {
    type: Object,
  },
  selectedUnit: {
    type: Object,
  },
  geoJson: {
    type: Array as PropType<GetGISDataResponse[]>,
  },
  setLocation: {
    type: Function,
    required: true,
  },
  setAlarm: {
    type: Function,
    required: true,
  },
  setSelectedDate: {
    type: Function,
    required: true,
  },
  selectedLocationStore: {
    type: Object
  },
  dotsNet: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['openInfoWindow']);

const map = ref();
const mapUnits = ref();
const unitsAlarm = ref();
const unitsOk = ref();
const unitsProblem = ref();
const markerCluster = ref();
const unitMarkers = ref({});
const markerPopupOpen = ref(false);
const markerPopupRef = ref();
const markerCoordinates = ref<google.maps.LatLng | google.maps.LatLngLiteral>({ lat: 0, lng: 0 });

const mapIntialized = ref(false);
const popupCleanup = ref<(() => void) | null>(null);

function closeMarkerPopup() {
  markerPopupOpen.value = false;
  markerCoordinates.value = { lat: 0, lng: 0 };
}

const footerStore = useFooterStore();
const { isFooterVisible } = storeToRefs(footerStore);
const { toogleFooter } = footerStore;

watch([
  () => markerPopupOpen.value,
  () => markerPopupRef.value,
  () => markerCoordinates.value],
([newPopupOpen, newPopupRef, newCoord]) => {
  if (newPopupOpen && newPopupRef && newCoord) {
    if (popupCleanup.value) {
      popupCleanup.value();
      popupCleanup.value = null;
    }
    popupCleanup.value = createPopupOverlay(
      toRaw(map.value),
      toRaw(newPopupRef),
      newCoord,
    );
  }

}, { flush: 'post' });

watch([
  () => markerPopupOpen.value,
  () => markerPopupRef,
  () => markerCoordinates.value],
([newPopupOpen, newPopupRef, newCoord]) => {
  if (!newPopupOpen) {
    popupCleanup.value && popupCleanup.value();
    popupCleanup.value = null;
  }

}, { flush: 'pre' });

//lazy defined class
let Popup: ReturnType<typeof definePopupClass>;
let BackgroundOverlay: ReturnType<typeof defineBackgroundOverlayClass>;

const gisFilter = {
  AlarmWire: false,
  BoilerCentral: false,
  DistributionLine: false,
  MainLine: false,
  MeasuringPoint: false,
  MonitoringStation: false,
  MonitoringStationArea: false,
  ServiceLine: false,
  ValveChamber: false,
  ValveWell: false,
};

onMounted(() => {
  initialiseMap();  
});

watch(() => props.devices, () => {
  updateMap();   
});

const updateGeoData = (): void => {
  filterGis(map.value, props.geoJson[0], gisFilter);
};

const initializeGISData = () => {
  gisFilter['AlarmWire'] = props.gisOptions.AlarmWire;
  gisFilter['BoilerCentral'] = props.gisOptions.BoilerCentral;
  gisFilter['DistributionLine'] = props.gisOptions.DistributionLine;
  gisFilter['MainLine'] = props.gisOptions.MainLine;
  gisFilter['MeasuringPoint'] = props.gisOptions.MeasuringPoint;
  gisFilter['MonitoringStation'] = props.gisOptions.MonitoringStation;
  gisFilter['MonitoringStationArea'] = props.gisOptions.MonitoringStationArea;
  gisFilter['ServiceLine'] = props.gisOptions.ServiceLine;
  gisFilter['ValveChamber'] = props.gisOptions.ValveChamber;
  gisFilter['ValveWell'] = props.gisOptions.ValveWell;
  //updateGeoData();
};

const updateMapType = () => {
  if (props.mapTypeSatellite) {
    map.value.setMapTypeId('satellite');
  } else {
    map.value.setMapTypeId('roadmap');
  }
};

const initialiseMap = (): void => {
  const loader = new Loader({
    apiKey: 'AIzaSyBv2WZyARcGV0ct-14PIngxB9VBobEVbaQ',
  });

  loader
    .load()
    .then(async (google) => {
      Popup = definePopupClass();
      BackgroundOverlay = defineBackgroundOverlayClass();
      map.value = getMap('map');
      updateMap();
      updateMapType();
      map.value.data.setStyle(featureStyle);
      initializeGISData();
    })
    .catch((e) => {
      console.error(e);
    });
};

const updateMap = (): void => {
  if (!mapIntialized.value && props.devices?.length > 0) {
    getUnits();
    clearClusterArrays();
    mapUnits.value.forEach(addUnitToClusterArray);
    initialiseCluster();
    updateClusters();
    updateBounds();
    mapIntialized.value = true;
    //updateMapType();
  }
};

const getUnits = (): void => {
  mapUnits.value = props.devices;
};

const clearClusterArrays = (): void => {
  unitsAlarm.value = [];
  unitsOk.value = [];
  unitsProblem.value = [];
};

const addUnitToClusterArray = (unit: GetUnitsByPosResponse): void => {
  if (unit.UnitGPSLatitude !== 0 && unit.UnitGPSLongitude !== 0) {
    switch (props.getUnitStatusName(unit)) {
    case UnitStatusNames.Alarm: {
      unitsAlarm.value.push(unit);
      break;
    }
    case UnitStatusNames.Problem: {
      unitsProblem.value.push(unit);
      break;
    }
    case UnitStatusNames.Ok: {
      unitsOk.value.push(unit);
      break;
    }
    default:
    }
  }
};

const initialiseCluster = (): void => {
  markerCluster.value = markerCluster.value || getCluster();
};

const calculateClusterIcon = (clusterMarkers) => {
  let okCount = 0;
  let alarmCount = 0;
  let problemCount = 0;
  let inactiveCount = 0;

  clusterMarkers.forEach((marker) => {
    switch (marker.color) {
    case UnitColor.alarm: {
      alarmCount++;
      break;
    }
    case UnitColor.problem: {
      problemCount++;
      break;
    }
    case UnitColor.ok: {
      okCount++;
      break;
    }
    case UnitColor.inactive: {
      inactiveCount++;
      break;
    }
    }
  });

  return renderDonutChartIconUrl(
    okCount,
    problemCount,
    alarmCount,
    inactiveCount
  );
};

const renderDonutChartIconUrl = (
  ok = 0,
  problem = 0,
  alarm = 0,
  inactive = 0
) => {
  const clusterSegmentValues = [ok, problem, alarm, inactive];
  const total = clusterSegmentValues.reduce((x, y) => x + y, 0);
  const initialValue: Segment[] = [];

  const iconSegments = clusterSegmentValues
    .map((count: number): number => (count / total) * 100)
    .reduce((segments: Segment[], value: number, index: number): Segment[] => {
      const prevSegment = segments[index - 1];
      const lastValue = prevSegment?.dashLength || 0;
      const lastOffset = prevSegment ? prevSegment?.dashOffset : 25;

      return segments.concat([
        {
          color: colors[index],
          dashLength: value,
          dashOffset: (lastOffset + 100 - lastValue) % 100,
        },
      ]);
    }, initialValue);
    
  let iconString = `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 42 42">
    <circle cx="21" cy="21" r="15.91549430918954" fill-opacity="0.5"></circle>`;
  iconSegments.forEach((segment) => {
    iconString += `<circle
              key="${segment.color}"
              cx="21" cy="21"
              r="15.91549430918954"
              fill="transparent"
              stroke="${segment.color}"
              stroke-width="8"
              stroke-dasharray="${segment.dashLength} ${
  100 - segment.dashLength
}"
              stroke-dashoffset="${segment.dashOffset}">
            </circle>`;
  });
  iconString += '</svg>';
  return 'data:image/svg+xml;charset=UTF-8;base64,' + window.btoa(iconString);
};

const getCluster = (): MarkerClusterer => {
  const clusterRenderer = {
    render: function ({
      markers,
      count,
      position,
    }: Cluster): google.maps.Marker {
      const url = calculateClusterIcon(markers);
      return new google.maps.Marker({
        position,
        icon: {
          url: url.toString(),
          scaledSize: new google.maps.Size(50, 50),
        },
        label: {
          text: String(count),
          color: 'rgba(255,255,255,0.9)',
          fontSize: '12px',
        },
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      });
    },
  };

  const markerClusterer = new MarkerClusterer({
    map: map.value,
    markers: [],
    renderer: clusterRenderer,
  });

  google.maps.event.addListener(markerClusterer, 'clusterclick', (cluster) => {
    map.value.fitBounds(cluster.getBounds());
  });

  return markerClusterer;
};

const updateCluster = (
  cluster: MarkerClusterer,
  units: GetUnitsByPosResponse[],
  color: string
): void => {
  units.forEach(
    (unit: GetUnitsByPosResponse) =>
      (unitMarkers.value[unit.UnitID] = getMarker(unit, color))
  );
  cluster.addMarkers(
    units.map((unit: GetUnitsByPosResponse) => unitMarkers.value[unit.UnitID])
  );
};

const updateClusters = (): void => {
  markerCluster.value.clearMarkers();

  if (props.type !== 'alarms') {
    updateCluster(markerCluster.value, unitsOk.value, UnitColor.ok);
    updateCluster(markerCluster.value, unitsProblem.value, UnitColor.problem);
  }

  updateCluster(markerCluster.value, unitsAlarm.value, UnitColor.alarm);
};

const getMarker = (
  unit: GetUnitsByPosResponse,
  color: string
): google.maps.Marker => {
  let icon: string;
  switch (color) {
  case UnitColor.ok:
    icon = 'pin_green.svg';
    break;
  case UnitColor.alarm:
    icon = 'pin_red.svg';
    break;
  case UnitColor.inactive:
    icon = 'pin_white.svg';
    break;
  case UnitColor.problem:
    icon = 'pin_orange.svg';
    break;
  default:
    throw new Error('unsupported marker color');
  }
  const options: google.maps.MarkerOptions = {
    icon: `/assets/map/${icon}`,
    map: map.value,
    label: {
      text: unit?.zonecode,
      color: 'white',
    },
    position: new google.maps.LatLng(
      unit.UnitGPSLatitude,
      unit.UnitGPSLongitude
    ),
  };

  const classStatus = UnitColor.alarm === color ? 'Alarm' : UnitColor.problem === color ? 'Warning' : 'OK';

  // Add custom property
  options['color'] = color;
  options['title'] = classStatus;

  const marker: google.maps.Marker = new google.maps.Marker(options);

  const markerPostion = (marker: google.maps.Marker) => {
    const position = new google.maps.LatLng(
      marker.getPosition()?.lat(),
      width.value < 713 && width.value > 430 ? 
        marker.getPosition().lng() + 0.03 :
        marker.getPosition().lng()
    ); 
    return position;
  };

  marker.addListener('click', () => {
    toogleFooter();
    props.setLocation(unit);
    emit('openInfoWindow', unit);
    map.value.setCenter(markerPostion(marker));

    if (map.value.getZoom() <= 12) {
      map.value.setZoom(13);
    }
 
    if (props.selectedLocationStore?.location?.id === unit?.id && markerPopupOpen.value) {
      markerPopupOpen.value = false;
      props.setLocation(null);
      return;
    }
    markerPopupOpen.value = true;
    markerCoordinates.value = { lat: unit.UnitGPSLatitude, lng: unit.UnitGPSLongitude };
  });

  return marker;
};

const updateBounds = (): void => {
  if (mapUnits.value.length === 1) {
    const oneUnit: GetUnitsByPosResponse = mapUnits.value[0];
    const singleUnit: google.maps.LatLng = new google.maps.LatLng(
      oneUnit.UnitGPSLatitude,
      oneUnit.UnitGPSLongitude
    );
    map.value.setCenter(singleUnit);
    map.value.setZoom(13);
  } else {
    const bounds: google.maps.LatLngBounds = mapUnits.value
      .filter((unit: GetUnitsByPosResponse) => unit?.UnitGPSLongitude && unit?.UnitGPSLatitude)
      .map((unit: GetUnitsByPosResponse) => new google.maps.LatLng(unit.UnitGPSLatitude, unit.UnitGPSLongitude))
      .reduce((bounds: google.maps.LatLngBounds, position: google.maps.LatLng) => bounds.extend(position), new google.maps.LatLngBounds());

    map.value.fitBounds(bounds);
  }
};

const getMap = (id: string): google.maps.Map => {
  const element: HTMLElement = document.getElementById(id) as HTMLElement;  
  const mapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    panControl: true,
    rotateControl: false,
    scaleControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    gestureHandling: 'cooperative',
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
    },
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoom: props?.mapStartZoom || 6,
    styles: mapStyling,
    center: props.mapStartPosition,
    disableDefaultUI: true
  };

  const map = new google.maps.Map(element, mapOptions);

  if (props.dotsNet) {
    const backgroundOverlay = new BackgroundOverlay(map, document.getElementById('map_overlay_with_dots') as HTMLDivElement);
    backgroundOverlay.setMap(map);
  }

  const overlayView = new google.maps.OverlayView();
  overlayView.setMap(map);
  
  google.maps.event.addListener(map, 'click', function() {
    !isFooterVisible.value && toogleFooter();
    props.setLocation(null);
    markerPopupOpen.value = false;
  });

  return map;
};

function closePopup() {
  toogleFooter();
  props.setLocation(null);
  markerPopupOpen.value = false;
}

function createPopupOverlay(
  map: google.maps.Map, 
  element: HTMLDivElement, 
  position: google.maps.LatLng | google.maps.LatLngLiteral) {
  
  const popup = new Popup(    
    position,    
    element
  );
  popup.setMap(map);
  
  return function() {
    popup && popup.setMap(null);    
  };
}
function definePopupClass() {
  class Popup extends google.maps.OverlayView {
    position: google.maps.LatLng | google.maps.LatLngLiteral;
    containerDiv: HTMLDivElement | null;
    resizeObserver: ResizeObserver | null;

    constructor(position: google.maps.LatLng | google.maps.LatLngLiteral, containerElement: HTMLDivElement) {
      super();
      this.position = position;
      this.containerDiv = containerElement;
      
      const that = this;
      this.resizeObserver = new ResizeObserver(function(entries: ResizeObserverEntry[]) {
        const containerEntry = entries.find( (entry: ResizeObserverEntry) => entry.target === containerElement );    
        const { contentRect } = containerEntry as ResizeObserverEntry;            
        that.draw();        
      });

      this.resizeObserver.observe(containerElement);

      // Optionally stop clicks, etc., from bubbling up to the map.
      Popup.preventMapHitsAndGesturesFrom(this.containerDiv);      
    }

    // Called when the popup is added to the map.
    onAdd() {              
        this.getPanes()!.floatPane.appendChild(toRaw(this.containerDiv!));
    }

    // Called when the popup is removed from the map.
    onRemove() {
      if (this.resizeObserver) {
        this.resizeObserver.unobserve(this.containerDiv!);
        this.resizeObserver = null;
      }

      // vue is in control for this
      /*
        if (this.containerDiv.parentElement) {
          this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
        */
      this.containerDiv = null;
    }

    // Called each frame when the popup needs to draw itself.
    draw() {
      const divPosition = this.getProjection().fromLatLngToDivPixel(
        this.position
      )!;
      const containerDivHeight = this.containerDiv!.offsetHeight;
      const adjustmentX = 48;
      const adjustmentY = -48;
      if (this.containerDiv) {
        const header = document.querySelector<HTMLElement>('header')?.offsetHeight ?? 0;
        const isMobile = device.size === DeviceSize.mobile;
        this.containerDiv.style.left = isMobile
          ? -(window.innerWidth / 2) - 0.5 + 'px'
          : divPosition.x + adjustmentX + 'px';
        this.containerDiv.style.top = isMobile
          ? -((window.innerHeight - header) / 2.18) + 'px'
          : (divPosition.y - containerDivHeight / 2 + adjustmentY) + 'px';

      }

    }
  }

  return Popup;
}

function defineBackgroundOverlayClass() {
  class BackgroundOverlay extends google.maps.OverlayView {    
    map: google.maps.Map | null;
    containerDiv: HTMLDivElement | null;    
    parentParent: HTMLElement | null | undefined;

    constructor(
      map: google.maps.Map, 
      containerElement: HTMLDivElement) {
      
      super();
      this.map = map;
      this.containerDiv = containerElement;

      // Optionally stop clicks, etc., from bubbling up to the map.
      //BackgroundOverlay.preventMapHitsAndGesturesFrom(this.containerDiv);
    }

    updateOverlaySizeAndPosition() {      
      if (this.containerDiv && this.parentParent) {
        const boundingClientRect = this.parentParent.getBoundingClientRect();
        this.containerDiv.style.left = -boundingClientRect.x + 'px';
        this.containerDiv.style.top = -boundingClientRect.y + 'px';
        this.containerDiv.style.width = '100%';
        this.containerDiv.style.height = '100vh';
      }
    }
    
    onAdd() {      
        this.getPanes()!.overlayLayer.appendChild(toRaw(this.containerDiv!));
        this.parentParent = this.containerDiv?.parentElement?.parentElement;
    }
    
    onRemove() {
      this.containerDiv = null;
      this.map = null;
    }
    
    draw() {
      return this.updateOverlaySizeAndPosition();      
    }
  }

  return BackgroundOverlay;
}

</script>

<template>
  <div>
    <div 
      id="map_overlay_with_dots" 
      class="map__overlay absolute" 
    />
    <div
      id="map"
      class="absolute z-10 w-full h-map"
    />
    <div
      id="infoWindow-map"
      class="hidden"
    />    
    <button class="z-50 absolute bottom-20 left-10 max-sm:hidden">
      <img
        :src="map_3D_controls"
        alt="3D controls for map"
      >
    </button>
    <div
      v-if="markerPopupOpen"
      ref="markerPopupRef"
      class="absolute bg-blue text-white w-fit h-fit"
    >
      <MarkerWindow
        :selected-location-store="selectedLocationStore"
        :set-alarm="setAlarm"
        :set-selected-date="setSelectedDate"
        @close-popup="closePopup"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.gmnoprint[role='menubar'] {
  top: 40px !important;
}

.h-map > :first-child {
  height: 110% !important;
}

.map__overlay {
  background: linear-gradient(90deg, #36393a 10px, transparent 1%) center,
    linear-gradient(#36393a 10px, transparent 1%) center, white;
  background-size: 11px 11px;
  opacity: 0.4;

  /*
  background: linear-gradient(0deg, #313435 0%, #464A4F 100%);
  background-image: radial-gradient(rgb(212, 212, 212) 1px, transparent 0);
  background-position: -18px -18px;
  background-size: 11px 11px;
  opacity: 0.35;
  */
  /*width: 100%;*/
  /*height: 100%;*/

}

</style>
