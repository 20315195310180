import { TaskId, type TaskStatusResponse, type ScheduledTaskResponse } from "@/api/dtos/TaskDto";
import { useTasksApi } from "@/api/tasksApi";



export interface ITaskService {
    startConnectedMode(locationId: string, time?: number): Promise<ScheduledTaskResponse>;
    stopConnectedMode(locationId: string, time?: number): Promise<ScheduledTaskResponse>;
    getStateForTask(locationId: string, taskId: TaskId): Promise<TaskStatusResponse>;
}

export const useTaskService = function(): ITaskService {
  const tasksApi = useTasksApi();
  

  async function startConnectedMode(locationId: string): Promise<ScheduledTaskResponse> {
    return await tasksApi.scheduleNewTask(locationId, TaskId.Start_reading_realtime_values/*, new Date().getTime() * 1000*/)
  }
  async function stopConnectedMode(locationId: string): Promise<ScheduledTaskResponse> {
    return await tasksApi.scheduleNewTask(locationId, TaskId.Stop_reading_realtime_values/*, new Date().getTime() * 1000*/)

  }
  async function getStateForTask(locationId: string, taskId: TaskId): Promise<TaskStatusResponse> {
    return await tasksApi.getStateForTask(locationId, taskId);
  }


  return {
    startConnectedMode,
    stopConnectedMode,
    getStateForTask
  }
}