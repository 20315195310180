<script setup lang="ts">
import { computed } from 'vue';
import ConnectedPlugsIcon from './icons/ConnectedPlugsIcon.vue';
import DisconnectedPlugsIcon from './icons/DisconnectedPlugsIcon.vue'
import formatDate from 'date-fns/format';

const props = defineProps({
  dataFromDate: {
    type: String
  },
  connectedMode: {
    type: Boolean
  }
})

const formatedDate = computed( () => (props.dataFromDate && props.dataFromDate != "" && formatDate(new Date(Number(props.dataFromDate) * 1000), "dd.MM.yyyy")) ?? "");
const formatedTime = computed( () => (props.dataFromDate && props.dataFromDate != "" && formatDate(new Date(Number(props.dataFromDate) * 1000), "H:mm")) ?? "");

</script>

<template>
  <div v-if="dataFromDate" class="flex flex-row gap-2 items-center">
    <component :is="connectedMode ? ConnectedPlugsIcon : DisconnectedPlugsIcon" opacity="0.5" class="mt-0.5"  />
    <div class="data-from-title">{{ connectedMode ? "Connected" :  `Data from: ${formatedDate} | ${formatedTime}` }}</div>
  </div>
</template>

<style scoped>

.data-from-title {
  display: flex;
  /*width: 183.048px;*/
  height: 16.763px;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;

  color: #FFF;

  text-align: right;
  font-family: Avenir Next LT Pro;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 150% */

  opacity: 0.5;

}

</style>