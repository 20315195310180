<script setup lang="ts">
import { type Component, type PropType } from 'vue';

defineProps({
  backgroundColor: {
    type: String,  
    default: '#2A2D2F'
  },
  backgroundActiveColor: {
    type: String,
    default: '#5a6165'
  },
  borderColor: {
    type: String,
    default: '#797979'
  },
  borderActiveColor: {
    type: String,
    default: '#797979'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  iconComponent: {
    type: Object as PropType<Component>,
    required: false      
  },
  iconPlacement: {
    type: String as PropType<'left' | 'right'>,
    default: 'left'    
  }
});

const emit = defineEmits<{
  (e: 'click'): void
}>()

function onClickHandler() {  
  emit('click');
}

</script>

<template>
  <div         
    class="button-container btn-cont-def-background cursor-pointer"
    :class="{'button-no-icon': !iconComponent, 'button-disabled': disabled}"
    @click="onClickHandler"
  >
    <component class="button-icon-max-size"  v-if="iconComponent && iconPlacement === 'left'" :is="iconComponent" />
    <span class="button-text select-none"><slot /></span>
    <component class="button-icon-max-size" v-if="iconComponent && iconPlacement === 'right'" :is="iconComponent" />
  </div>
</template>

<style scoped>
.button-container {
  display: inline-flex;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 5px;

  border-radius: 50px;
  border: 1px solid/* #797979*/;  
  border-color: v-bind(borderColor);
  height: max-content;
  /*transition: background-color 0.01s ease;*/
}

.button-container:active {
  /*background-color: #5a6165;  */
  background-color: v-bind(backgroundActiveColor);
  border-color: v-bind(borderActiveColor);
}

.btn-cont-def-background {
  /*background: #2A2D2F;*/
  background-color: v-bind(backgroundColor);
}

.button-no-icon {
  padding-left: 25px;
  padding-right: 25px;
}

.button-text {
  color: #FFF;
  text-align: center;
  font-family: 'Avenir Next LT Pro';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 136.364% */
}

.button-disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}

.button-icon-max-size {
  max-width: 15px;
  max-height: 15px;
}
</style>