<script setup lang="ts">
import ArchiveTab from '@/components/ArchiveTab.vue';
import { useHeaderStore } from '@/stores/header';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { onMounted, watch } from 'vue';

const headerStore = useHeaderStore();
const router = useRouter();
const { selectedLocation } = storeToRefs(headerStore);

onMounted(() => {
  if(!selectedLocation?.value?.id) {
    router.push('/');
  }
})

watch(selectedLocation, () => {
  if (!selectedLocation.value?.id) {
    router.push('/');
  }
})

</script>
<template>
  <div v-if="selectedLocation?.id" class="pt-[76px] pb-[67px] scroll-container hide-scroll" style="background-color: #1E1E1E; color: white; min-height: 100vh;">
    <ArchiveTab :location="selectedLocation"/>
  </div>
  <div v-else class="ps h-screen w-screen pt-[76px] pb-[67px] scroll-container" style="background-color: #1E1E1E; color: white;">
    Select Location
  </div>
</template>

<style scoped lang="scss">
.hide-scroll::-webkit-scrollbar {
	display: none;
  -ms-overflow-style: none;
	scrollbar-width: none;
}
.hide-scroll {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
</style>