<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.39867 10.9054L12.4896 2.81445L20.5806 10.9054L19.8067 11.6792L13.0399 4.91242L13.0399 20.3721L11.9393 20.3721L11.9393 4.91242L5.17251 11.6792L4.39867 10.9054Z"
      fill="#828282"
      stroke="#828282"
      stroke-width="0.5"
    />
  </svg>
</template>
