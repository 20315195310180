import { v4 as uuidv4 } from 'uuid';
import { type WidgetDescriptor,  type WidgetPack, type WidgetConfigContent } from '@/types/widgets-map-types'
const WIDGETS_MAP: Record<string, WidgetPack> = {
};

const HIDDEN_WIDGETS: Array<WidgetDescriptor['key']> = [

];


const DEFAULT_WIDGET_WIDTH = 2;
const DEFAULT_WIDGET_HEIGHT = 11;
const WIDGET_MIN_WIDTH = 1.5
const WIDGET_MIN_HEIGHT = 6;

function shouldShowConfigDialogFactory(shouldShow: boolean) {
  return function() {
    return shouldShow;
  }
}


export function getWidgetComponent(widgetKey: string): any | null {  
  const widgetPack = WIDGETS_MAP[widgetKey];
  const widgetDesc = widgetPack?.descriptor;
  return widgetDesc?.component ?? null;
}

export function getWidgetDescriptor(widgetKey: string): WidgetDescriptor {
  const widgetPack = WIDGETS_MAP[widgetKey];
  if(widgetPack && widgetPack.descriptor) {
    return {
      ...widgetPack.descriptor
    };

  }else {
    return {
      ...WIDGETS_MAP['WidgetNotFound'].descriptor,
      key: widgetKey
    };    

  }  
}

export function getWidgetConfigContent(widgetKey: string): WidgetConfigContent | null {
  const widgetPack = WIDGETS_MAP[widgetKey];
  if(widgetPack && widgetPack.configContent){
    return widgetPack.configContent;
  }

  return null;
}

export function registerWidget(
  widgetKey: string, 
  widgetDescriptor: Omit<WidgetDescriptor, 'key' | 'w' | 'h'> & Partial<Pick<WidgetDescriptor, 'w' | 'h'>>,
  widgetConfigContent?: WidgetConfigContent,
  isHidden: boolean = false
) {
  if(widgetKey == null) {
    throw new Error("Widget key must not be nullable")
  }
  if(!widgetDescriptor?.component) {
    throw new Error(`Component missing for widget with key ${widgetKey}`)
  }

  if(widgetKey in WIDGETS_MAP) {
    throw new Error(`Widget with key ${widgetKey} already exists`);
  }

  const tempWidgetDesc: WidgetDescriptor = {
    ...widgetDescriptor,    
    key: widgetKey,
    h: widgetDescriptor.h ?? DEFAULT_WIDGET_HEIGHT,
    w: widgetDescriptor.w ?? DEFAULT_WIDGET_WIDTH,
    mW: widgetDescriptor.mW ?? WIDGET_MIN_WIDTH,
    mH: widgetDescriptor.mH ?? WIDGET_MIN_HEIGHT,
    
  };

  let configContent = undefined;
  if(widgetConfigContent) {
    configContent = {
      shouldShowConfigDialog: shouldShowConfigDialogFactory(widgetConfigContent?.configContentComponent != null),
      ...(widgetConfigContent ?? {})
    }
  }
  
  WIDGETS_MAP[widgetKey] = {
    descriptor: tempWidgetDesc,
    configContent
  }

  if(isHidden) {
    HIDDEN_WIDGETS.push(widgetKey)
  }
}

export function getWidgetsList(): Array<WidgetPack> {
  return Object.values(WIDGETS_MAP)
    .filter( widget => !HIDDEN_WIDGETS.includes(widget?.descriptor?.key))
    .sort( compareWidetFactory('componentName'))
}

export function compareWidetFactory(fieldName: keyof WidgetDescriptor){ 
  return function (a: WidgetPack, b: WidgetPack){  
    const aDescriptor = a.descriptor;
    const bDescriptor = b.descriptor;
    if (aDescriptor[fieldName] < bDescriptor[fieldName]) {
      return -1;
    }
    if (aDescriptor[fieldName] > bDescriptor[fieldName]) {
      return 1;
    }

    return 0;
  }
}

export function generateWidgetId() {
  return uuidv4();
}