<script setup lang="ts">
import { ref, watchEffect, watch } from 'vue';
import MarkerWindowSlot from '../components/MarkerWindowSlot.vue';
import SettingsIcon from '../icons/SettingsIcon.vue';
import InfoIcon from '../icons/InfoIcon.vue';
import HardHatIcon from '../icons/HardHatIcon.vue';
import LeftArrowIcon from '../icons/LeftArrowIcon.vue';
import TriangleIcon from '../icons/TriangleIcon.vue';
import CirclePercentageIcon from '../icons/CirclePercentageIcon.vue';
import SpecifiedScopeIcon from '../icons/SpecifiedScopeIcon.vue';
import NotesIcon from '../icons/NotesIcon.vue';
import ValueCircle from '../components/ValueCircle.vue';
import EventsSection from '../components/EventsSection.vue';
import NotesSection from '../components/NotesSection.vue';
import AlarmInfo from '../components/AlaramInfo.vue';
import Alarms from '../components/Alarms.vue';
import AlarmLogs from '../components/AlarmLogs.vue';
import { useAlarmsApi } from '../../../../packages/smarthub/src/api/alarmsApi';
import { AlarmDto } from '../../../smarthub/src/api/dtos/AlarmDto';
import { computed } from 'vue';

const props = defineProps({
  selectedLocationStore: {
    type: Object,
    required: true,
  },
  setAlarm: {
    type: Function,
    required: true,
  },
  setSelectedDate: {
    type: Function,
    required: true,
  },
});

const alarmsApi = useAlarmsApi();
const selectedNavItem = ref('alarms');
const prevSeletedItem = ref();
const alarmsForLocation = ref([]);
const location = ref();
const dataLoading = ref(false);
const singleAlarmLogs = ref([]);
const selectedAlarm = ref();
const range = ref({
  start: new Date(),
  end: new Date(),
});
const date = ref(new Date());
const isDayPicker = ref(true);
const alarmDatesForlocation = ref([]);
const attributes = ref<any>();
const shouldSetWeeklyView = ref(false);
const lastScrollPosition = ref(0);
const calendar = ref();
const clickHistory = ref<string[]>([]);
const lastElement = ref<string>('');

const emit = defineEmits(['closePopup']);

watchEffect(() => {
  alarmsForLocation.value = props.selectedLocationStore.alarms || [];
  dataLoading.value = props.selectedLocationStore.loading;
  location.value = props.selectedLocationStore.locationId;
  singleAlarmLogs.value = props.selectedLocationStore.alarmLogs || [];
  alarmDatesForlocation.value = props.selectedLocationStore.alarmDatesForLocation || [];
  const redAlarmDates =
    alarmDatesForlocation.value.filter(item => item.priorityId == 10).map(item => (new Date(item.up)));
  const yellowAlarmDates =
    alarmDatesForlocation.value.filter(item => item.priorityId == 1).map(item => (new Date(item.up)));

  const redDateStrings = redAlarmDates.map(date => date.toLocaleDateString('en-US', { timeZone: 'UTC' }));
  const yellowDateStrings = yellowAlarmDates.map(date => date.toLocaleDateString('en-US', { timeZone: 'UTC' }));
  const filteredYellowDates = yellowDateStrings.filter(date => !redDateStrings.includes(date));

  attributes.value = [
    {
      highlight: {
        color: 'yellow',
        fillMode: 'outline',
        style: {
          zIndex: '1'
        }
      },
      dates: filteredYellowDates,
    },
    {
      highlight: {
        color: 'red',
        fillMode: 'outline',
        style: {
          zIndex: '1'
        },
      },
      dates: redAlarmDates,
    },
  ];
});

watch(alarmDatesForlocation, () => {
  const lastAlarm = new Date(alarmDatesForlocation.value[0].up);
  const start = lastAlarm.setHours(0, 0, 0) / 1000;
  const end = lastAlarm.setHours(23, 59, 59) / 1000;
  date.value = lastAlarm;
  range.value = {
    start: lastAlarm,
    end: lastAlarm
  };
  props.setSelectedDate({
    s: start,
    e: end
  });
});

const selectNavItem = (navItem: string) => {
  clickHistory.value.push(navItem);
  shouldSetWeeklyView.value = false;
  prevSeletedItem.value = selectedNavItem.value;
  selectedNavItem.value = navItem;
};

const INFO_ICON_COLORS = {
  ACTIVE: '#06E3FF',
  INACTIVE: '#FFFFFF',
};

const handleSelectAlarm = (alarm: AlarmDto) => {
  selectedAlarm.value = alarm;
  selectNavItem('singleAlarm');
  props.setAlarm(alarm);
  shouldSetWeeklyView.value = false;
};

const handleAcceptAlarm = async (alarm: AlarmDto) => {
  dataLoading.value = true;
  try {
    await alarmsApi.acceptAlarm(alarm.id);
    const selectedDate = new Date(date.value);
    if (isDayPicker.value) {
      props.setSelectedDate({
        s: selectedDate.setHours(0, 0, 1) / 1000,
        e: selectedDate.setHours(23, 59, 58) / 1000
      });
    } else {
      props.setSelectedDate({
        s: range.value.start.setHours(0, 0, 1) / 1000,
        e: range.value.end.setHours(23, 59, 58) / 1000
      });
    }
    dataLoading.value = false;
    selectNavItem('alarms');
    selectedAlarm.value = alarm;
  } catch (err) {
    console.log('Something went wrong!', err);
  } finally {
    dataLoading.value = false;
  }
};

const handleDayClick = (e) => {
  shouldSetWeeklyView.value = false;
  if (!e) return;
  let startDate;
  let endDate;
  const selectedDate = new Date(e);
  if (isDayPicker.value) {
    startDate = selectedDate.setHours(0, 0, 0);
    endDate = selectedDate.setHours(23, 59, 59);
    props.setSelectedDate({
      s: startDate / 1000,
      e: endDate / 1000
    });
    date.value = selectedDate;
  } else {
    const startDate = e.start;
    const endDate = e.end;
    startDate.setHours(0, 0, 0);
    endDate.setHours(23, 59, 59);
    props.setSelectedDate({
      s: (startDate.getTime() / 1000),
      e: (endDate.getTime() / 1000)
    });
    range.value = {
      start: startDate,
      end: endDate
    };
  }

};

const toggleCheckbox = () => {
  isDayPicker.value = !isDayPicker.value;
  shouldSetWeeklyView.value = false;
};

const handleScroll = () => {
  const scrollContainer = document.querySelector('.scroll-container');
  if (scrollContainer) {
    const currentScrollPosition = scrollContainer.scrollTop;
    if (currentScrollPosition > lastScrollPosition.value && currentScrollPosition !== 0) {
      shouldSetWeeklyView.value = true;
      if (isDayPicker.value) {
        calendar.value.move(date.value);
      } else {
        calendar.value.move(range.value.start);
      }
    } else if (currentScrollPosition === 0) {
      shouldSetWeeklyView.value = false;
    }
    lastScrollPosition.value = currentScrollPosition;
  }
};

const isNavItemActive = (navItem: string) => selectedNavItem.value === navItem;

const isMobile = computed(() => {
  return window.innerWidth <= 430;
});

const handleClose = () => {
  emit('closePopup');
};

const goBack = () => {
  lastElement.value = clickHistory.value.pop() ?? '';
  const lastItem = clickHistory.value[clickHistory.value.length - 1];
  selectedNavItem.value = lastItem ?? 'alarms'; 
};

</script>

<template>
  <MarkerWindowSlot :tab-active="selectedNavItem">
    <template #header>
      <LeftArrowIcon
        v-if="isNavItemActive('info') || isNavItemActive('singleAlarm')"
        class="cursor-pointer"
        @click="goBack()"
      />
      <span v-else>
        <span 
          v-if="!isNavItemActive('info') && !isNavItemActive('singleAlarm') && isMobile"
          class="cursor-pointer"
          @click="handleClose"
        >
          X
        </span>
      </span>
      <h3 class="uppercase text-[#d8d8d8] ml-[10%] text-[11px] font-['Avenir_Next_LT_Pro'] font-[400]">
        {{ location.name }}
      </h3>
      <div class="flex gap-[19px] items-center">
        <InfoIcon
          class="cursor-pointer"
          style="width: 18px;"
          :color="isNavItemActive('info') ? INFO_ICON_COLORS['ACTIVE'] : INFO_ICON_COLORS['INACTIVE']"
          @click="selectNavItem('info')"
        />
        <!-- Settings icon and functionality for it will be added in future release -->
        <!-- <SettingsIcon style="width: 18px;" /> -->
      </div>
    </template>

    <template v-if="selectedNavItem === 'alarms'">
      <main
        v-if="dataLoading"
        class="flex items-center"
        style="height: 380px; justify-content: center"
      >
        <div class="loader" />
      </main>
      <main
        v-else
        style="flex-direction: column"
        class="flex items-center hide-scroll alarms-list"
      >
        <div
          style="width: 100%;"
          class="custom-calendar"
        >
          <div
            class="toogler"
            @click="toggleCheckbox"
          >
            <div :class="{ 'date-toggle': isDayPicker, 'date-off': !isDayPicker }">
              <p>Date</p>
            </div>
            <div :class="{ 'range-off': isDayPicker, 'range-toggle': !isDayPicker }">
              <p>Range</p>
            </div>
          </div>
          <div
            v-if="!isDayPicker"
            class="my-calendar"
          >
            <VDatePicker
              ref="calendar"
              v-model.range="range"
              style="width: 100%; color: white"
              borderless
              transparent
              color="blue"
              trim-weeks
              :locale="{ firstDayOfWeek: 2, masks: { weekdays: 'WWW' } }"
              :max-date="new Date()"
              :attributes="attributes"
              :view="shouldSetWeeklyView ? 'weekly' : 'monthly'"
              @update:model-value="handleDayClick"
            />
          </div>
          <div
            v-else
            class="my-calendar"
          >
            <VDatePicker
              ref="calendar"
              v-model="date"
              style="width: 100%; color: white"
              borderless
              transparent
              color="blue"
              trim-weeks
              is-required
              :locale="{ firstDayOfWeek: 2, masks: { weekdays: 'WWW' } }"
              :max-date="new Date()"
              :attributes="attributes"
              :view="shouldSetWeeklyView ? 'weekly' : 'monthly'"
              @update:model-value="handleDayClick"
            />
          </div>
        </div>
        <div
          ref="scrollContainer"
          style="width: 100%; overflow-y: auto; height: 100%;"
          :style="[dataLoading ? { display: `flex`, justifyContent: 'center' } : '']"
          class="scroll-container hide-scroll"
          @scroll="handleScroll"
        >
          <Alarms
            :alarms-for-location="alarmsForLocation"
            :data-loading="dataLoading"
            :is-day-picker="isDayPicker"
            @select-alarm="handleSelectAlarm"
          />
        </div>
      </main>
    </template>

    <template v-if="selectedNavItem === 'info'">
      <main
        class="flex-1 flex items-center justify-center text-center min-h-[380px] flex-col"
      >
        <AlarmInfo :location="location" />
      </main>
    </template>

    <template v-if="selectedNavItem === 'singleAlarm'">
      <main
        style="overflow-y: auto"
        :class="[{ 'justify-center': dataLoading }, 'flex items-center hide-scroll mobile-widget']"
      >
        <AlarmLogs
          :data-loading="dataLoading"
          :single-alarm-logs="singleAlarmLogs"
          :selected-alarm="selectedAlarm"
          @accept-alarm="handleAcceptAlarm"
        />
      </main>
    </template>

    <template v-if="selectedNavItem === 'control'">
      <main class="flex items-center justify-center text-center min-h-[380px]">
        <div class="flex flex-col items-center">
          <HardHatIcon />
          <h1 class="text-[1.4rem] font-['Avenir_Next_LT_Pro']">
            Under consturction
          </h1>
        </div>
      </main>
    </template>

    <template v-if="selectedNavItem === 'events'">
      <main
        style="overflow-y: auto; justify-content: center;"
        class="hide-scroll flex items-center mobile-widget"
      >
        <EventsSection
          :location="location"
          :alarms-for-location="alarmsForLocation"
          :set-selected-date="props.setSelectedDate"
          :loading="dataLoading"
        />
      </main>
    </template>

    <template v-if="selectedNavItem === 'notes'">
      <main class="notes-section hide-scroll">
        <NotesSection :location="location" />
      </main>
    </template>
    <template #footer>
      <nav>
        <ul class="flex justify-center gap-5 bottom-nav-mobile">
          <li
            :class="['nav__item flex flex-col items-center self-center cursor-pointer',
                     { active: isNavItemActive('alarms') }]"
            @click="selectNavItem('alarms')"
          >
            <TriangleIcon
              class="w-[24px] h-[26.94px]"
              alt="Alarms icon"
            />
            <span class="mt-2 text-white text-[10.8px]">Alarms</span>
          </li>
          <li
            :class="['nav__item flex flex-col items-center self-center cursor-pointer',
                     { active: isNavItemActive('control') }]"
            @click="selectNavItem('control')"
          >
            <CirclePercentageIcon
              class="w-[24px] h-[26.94px]"
              alt="Control icon"
            />
            <span class="mt-2 text-white text-[10.8px]">Control</span>
          </li>
          <li
            :class="['nav__item flex flex-col items-center self-center cursor-pointer',
                     { active: isNavItemActive('events') }]"
            @click="selectNavItem('events')"
          >
            <SpecifiedScopeIcon
              class="w-[24px] h-[26.94px]"
              alt="Events icon"
            />
            <span class="mt-2 text-white text-[10.8px]">Events</span>
          </li>
          <li
            :class="['nav__item flex flex-col items-center self-center cursor-pointer',
                     { active: isNavItemActive('notes') }]"
            @click="selectNavItem('notes')"
          >
            <NotesIcon
              class="w-[24px] h-[26.94px]"
              alt="Notes icon"
            />
            <span class="mt-2 text-white text-[10.8px]">Archive</span>
          </li>
        </ul>
      </nav>
    </template>
  </MarkerWindowSlot>
</template>

<style lang="scss" scoped>
.notes-section {
  height: 380px;
  overflow-y: auto;
  justify-content: center;
  background-color: #2C2E31;
  position: relative;
  @media screen and (max-width: 430px) {
    height: 100%;
  }
}

.date-toggle {
  font-size: 11px;
  padding: 4px 10px;
  border-radius: 20px;
  background: rgb(21, 177, 202);
  font-family: 'Avenir Next LT Pro';
  font-weight: 400;
}

.alarms-list {
  height: 380px;
  @media (max-width: 430px) {
    height: 100%;
  }
}

.date-off {
  font-size: 11px;
  padding: 4px 10px;
  border-radius: 20px;
  font-family: 'Avenir Next LT Pro';
  font-weight: 400;
}

.range-off {
  font-size: 11px;
  width: 40px;
  padding: 4px 10px;
  background: gray;
  font-family: 'Avenir Next LT Pro';
  font-weight: 400;
}

.range-toggle {
  font-size: 11px;
  width: 56px;
  padding: 4px 10px;
  background: rgb(21, 177, 202);
  border-radius: 20px;
  font-family: 'Avenir Next LT Pro';
  font-weight: 400;
}

.toogler {
  display: flex;
  background: gray;
  width: 100px;
  border-radius: 20px;
  top: 13%;
  right: 6px;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  font-family: 'Helvetica Neue';
  @media screen and (max-width: 430px) {
    top: 63px;  
  }
}

.mobile-widget {
  height: 380px;
  @media screen and (max-width: 430px) {
    height: 100%;  
  }
}

.bottom-nav-mobile {
  @media screen and (max-width: 430px) {
    justify-content: space-evenly;
  }
}

.dot {
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

.loader {
  border: 4px solid #888;
  border-top: 4px solid #333;
  border-radius: 50%;
  width: 50px;
  height: 50px !important;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.my-calendar :deep(.vc-title-wrapper) {
  button {
    color: white;
    text-transform: uppercase;
    font-size: 16px;
  }
}

.my-calendar :deep(.vc-weekday) {
  color: white;
  text-transform: uppercase;
  font-weight: 300;
}

.my-calendar :deep(.vc-yellow) {
  --vc-highlight-outline-bg: transparent;
  --vc-accent-700: white;
}

.my-calendar :deep(.vc-red) {
  --vc-highlight-outline-bg: transparent;
  --vc-accent-700: white;
}

.my-calendar :deep(.vc-blue) {
  --vc-highlight-solid-bg: rgb(21, 177, 202) !important
}

.my-calendar :deep(.vc-arrow) {
  color: white
}

.my-calendar :deep(.vc-prev) {
  position: absolute;
  left: 0;
}

.my-calendar :deep(.vc-next) {
  position: absolute;
  left: 165px;
  & {
    @media screen and (max-width: 430px) {
      left: 180px;
    }
  }
}

.my-calendar :deep(.vc-header) {
  padding-left: 90px;
  padding-right: 45px;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 430px) {
    padding-left: 60px;  
  }
}

.nav__item {
  opacity: 54%;
  position: relative;

  &::before {
    position: absolute;
    top: -10px;
    width: 100%;
    height: 2px;
    background-color: #15B1CA;
  }
}

.nav__item.active {
  color: #fff;
  opacity: 100%;

  &::before {
    content: '';
  }
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
