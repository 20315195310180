<script lang="ts" setup>
import type { Component, PropType } from 'vue';
import AddIcon from './icons/AddIcon.vue';

defineProps({
  iconComponent: {
    type: Object as PropType<Component>,
    default: () => AddIcon
  },
  backgroundColor: {
    type: String as PropType<string | null>,
    default: '#15B1CA'
  },
  backgroundActiveColor: {
    type: String,     
    //default: 'rgb(17, 142, 162)'
    default: 'rgb(17, 147, 168)'
  }
});

const emit = defineEmits<{
  (e: 'click'): void
}>();

function clickHanlder() {
  emit('click');
}
</script>

<template>
  <div 
    class="inline-flex icon-button-container"
    @click="clickHanlder"
    >
    <div class="inline-flex">
      <component :is="iconComponent" />
    </div>
  </div>
</template>

<style scoped>
.icon-button-container {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;

  border-radius: 50px;
  background: v-bind(backgroundColor);
}

.icon-button-container:active {
  background-color: v-bind(backgroundActiveColor);
}

.icon-button-icon {
  width: 7.867px;
  height: 7.867px;
  transform: rotate(45deg);
  flex-shrink: 0;
}
</style>