import { useApi, type ApiLoadingType } from "@/composables/api";
import type { FilesReponse } from "./dtos/FilesDto";
import { useAuthStore } from "../../src/stores/auth";
import { BASE_URL } from '@/config/environment';

export interface IEvents {
  getFilesForLocation(locationId: string): Promise<FilesReponse>;
  getFileFromSubFolder(locationId: string, folderId: string): Promise<FilesReponse>;
  getSingleImage(locationId: string, fileId: string): Promise<any>;
  createFolder(locationId: string, folderName: string, subfolderId?: string): Promise<any>;
  deleteFile(locationId: string, fileId: string, isFolder: boolean): Promise<any>;
  renameFile(locationId: string, fileId: string, newName: string): Promise<any>;
  uploadFileToLocation(locationId: string, files: FormData, subFolder: string): Promise<any>;
}


const API_VERSIONING = 'api/v1';

export function useFilesApi() : IEvents & ApiLoadingType {
  const api = useApi();
  const auth = useAuthStore();
  const { loading,  errors, clearErrors } = api;

  async function getFilesForLocation(locationId: string): Promise<FilesReponse> {
    return api.get(`${API_VERSIONING}/locations/${locationId}/files`);
  }

  async function getFileFromSubFolder(locationId:string, folderId:string): Promise<FilesReponse> {
    return api.get(`${API_VERSIONING}/locations/${locationId}/files/${folderId}`)
  }

  async function createFolder(locationId:string, folderName:string, subfolderId?: string): Promise<FilesReponse> {
    return api.post(`${API_VERSIONING}/locations/${locationId}/files/${subfolderId ? subfolderId : ''}` , {
      "name": folderName
    })
  }

  async function renameFile(locationId:string, fileId:string, newName: string): Promise<any> {
    return api.patch(`${API_VERSIONING}/locations/${locationId}/files/${fileId}`, {
      "name": newName
    })
  }

  async function getSingleImage(locationId:string, fileId:string): Promise<any> {
    const token = await auth.getAccessToken().catch(auth.handleRefreshTokenExpirationFactory(api.refreshTokenExpiredAction));
    const data = fetch(`${BASE_URL}/${API_VERSIONING}/locations/${locationId}/files/${fileId}?contents=1`, {
      method: 'GET',
      headers: {
        Authorization: token
      }
    }).then((response) => response.blob());
    return data;
  }
  
  async function uploadFileToLocation(locationId: string, files: FormData, subFolder: string): Promise<any> {
    const token = await auth.getAccessToken().catch(auth.handleRefreshTokenExpirationFactory(api.refreshTokenExpiredAction));
    const response = fetch(`${BASE_URL}/${API_VERSIONING}/locations/${locationId}/files/${subFolder ? subFolder : ''}`, {
        method: 'POST',
        headers: {
            Authorization: token,
        },
        body: files
    });
    return response;
  }

  async function deleteFile(locationId: string, fileId: string, isFolder: boolean): Promise<any> {
    return api.deleteRequest(`${API_VERSIONING}/locations/${locationId}/files/${fileId}${isFolder ? '?r=1' : '' }`);
  }

  return {
    loading,
    errors,
    clearErrors,
    getFilesForLocation,
    getFileFromSubFolder,
    getSingleImage,
    deleteFile,
    renameFile,
    uploadFileToLocation,
    createFolder
  }

}