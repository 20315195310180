<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5803 12.2811L12.4894 20.3721L4.39844 12.2811L5.17228 11.5073L11.9391 18.2741L11.9391 2.81445L13.0397 2.81445L13.0397 18.2741L19.8065 11.5073L20.5803 12.2811Z"
      fill="#828282"
      stroke="#828282"
      stroke-width="0.5"
    />
  </svg>
</template>
