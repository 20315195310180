<script setup lang="ts">
import { PropType, onMounted, ref, onUnmounted, toRaw } from 'vue';
import DownArrorIcon from '../icons/DownArrowIcon.vue';

type Option = {
  label: string;
  value: unknown;
};

const props = defineProps({
  placeHolder: {
    type: String,
    required: true,
  },
  options: {
    type: Array as PropType<Option[]>,
    required: true,
  },
  useBackground: {
    type: Boolean,
  },
  modelValue: {
    type: undefined,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);
const dropdownOpen = ref(false);
const root = ref();

const detectClickOutside = async (event: MouseEvent) => {
  if (!root.value?.contains(event.target)) {
    dropdownOpen.value = false;
  }
};

const handleOnClickOption = (option: Option | null) => {
  emit('update:modelValue', option?.value);
  dropdownOpen.value = false;
};

onMounted(() => {
  document.addEventListener('click', detectClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', detectClickOutside);
});

const getSelectedOptionLabel = (modelValue: unknown) =>
  props.options.find((option) => toRaw(option.value) == toRaw(modelValue))
    ?.label;
</script>

<template>
  <div ref="root">
    <!-- The select is only there to make the div width correct -->
    <div class="h-0 px-5">
      <select class="h-0">
        <option>{{ props.placeHolder }}</option>
        <option v-for="option in props.options" :key="option.label">
          {{ option.label }}
        </option>
      </select>
    </div>
    <!-- Dropdown -->
    <div class="relative text-left">
      <div
        :class="useBackground ? 'bg-gray-700' : 'border border-gray-600'"
        class="px-4 py-2 rounded-md cursor-pointer relative select"
        @click="dropdownOpen = !dropdownOpen"
      >
        <div
          class="w-full h-full bg-transparent absolute top-0 left-0 z-2 select-none"
        />
        <div v-if="!props.modelValue" class="text-gray-400">
          {{ placeHolder }}
        </div>
        <div v-else class="text-white">
          {{ getSelectedOptionLabel(props.modelValue) }}
        </div>
        <div class="absolute right-4 bottom-0 top-0 flex items-center w-2">
          <down-arror-icon />
        </div>
      </div>
      <transition
        enter-active-class="transition-all ease-out origin-top transform"
        leave-active-class="transition-all ease-in origin-top transform"
        enter-from-class="scale-75 opacity-0"
        enter-to-class="scale-100 opacity-100"
        leave-class="scale-100 opacity-100"
        leave-to-class="scale-75 opacity-0"
      >
        <div
          v-if="dropdownOpen"
          class="absolute text-white bg-gray-700 w-full mt-2 rounded-md z-20 overflow-auto scroll-bar-thin max-h-60"
        >
          <div
            v-for="option in props.options"
            :key="option.label"
            class="w-full overflow-hidden truncate p-2 hover:bg-gray-600 cursor-pointer select-none rounded-md"
            @click="() => handleOnClickOption(option)"
          >
            {{ option.label }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<style scoped>
th {
  background-color: #1e1e1e;
  position: sticky;
  top: 0;
}
</style>
