<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.6849 25.6041H34.2662V21.8071H41.6849V25.6041ZM41.6849 27.7737H34.2662V31.5707H41.6849V27.7737ZM41.6849 9.874H34.2662V13.6709H41.6849V9.874ZM41.6849 15.8406H34.2662V19.6374H41.6849V15.8406ZM41.6849 33.7402H34.2662V37.5372H41.6849V33.7402V33.7402ZM46.809 41.2256C46.5971 42.3539 45.2723 42.3809 44.3874 42.419H27.9073V47.3007H24.6166L0.362793 42.9613V4.99773L24.7597 0.652954H27.9073V4.976H43.8204C44.716 5.01396 45.7016 4.94886 46.4805 5.49667C47.0263 6.29945 46.9734 7.31924 47.0105 8.2413L46.9893 36.4795C46.9628 38.0579 47.1323 39.6689 46.809 41.2256ZM19.7944 32.7151C18.3319 29.6777 16.8429 26.6617 15.3856 23.6242C16.827 20.668 18.2472 17.7011 19.662 14.734C18.4591 14.7937 17.2562 14.8696 16.0586 14.9565C15.163 17.1857 14.1191 19.3554 13.4197 21.6607C12.7679 19.4856 11.9042 17.3918 11.1146 15.271C9.9488 15.3361 8.78297 15.4066 7.61724 15.4771C8.84657 18.2544 10.1555 20.9934 11.3478 23.7869C9.94352 26.499 8.62937 29.2491 7.26747 31.9775C8.42792 32.0262 9.58847 32.0751 10.7489 32.0913C11.5756 29.9326 12.6036 27.8551 13.3243 25.6529C13.9708 28.0178 15.0677 30.2038 15.9685 32.4656C17.2456 32.5579 18.5173 32.6392 19.7944 32.7151ZM44.4831 7.55211H27.9073V9.874H32.1466V13.6709H27.9073V15.8406H32.1466V19.6374H27.9073V21.8071H32.1466V25.6041H27.9073V27.7737H32.1466V31.5707H27.9073V33.7402H32.1466V37.5372H27.9073V40.0456H44.4831V7.55211Z"
      fill="#7C7F81"
    />
  </svg>
</template>
