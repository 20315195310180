<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.1025 5.04907C15.8135 4.33813 16.7777 3.93872 17.7831 3.93872C18.7886 3.93872 19.7528 4.33813 20.4638 5.04907C21.1747 5.76002 21.5741 6.72426 21.5741 7.72969C21.5741 8.73512 21.1747 9.69937 20.4638 10.4103L12.7838 18.0903C12.345 18.5291 12.0875 18.7865 11.7993 19.0108C11.4609 19.2755 11.0949 19.5022 10.7063 19.6868C10.3776 19.8431 10.0311 19.9589 9.44334 20.1548L6.74571 21.0535L6.09641 21.2704C5.83816 21.3566 5.56101 21.3692 5.29603 21.3067C5.03104 21.2442 4.78871 21.1091 4.5962 20.9166C4.40368 20.7241 4.26861 20.4818 4.20612 20.2168C4.14363 19.9518 4.15619 19.6747 4.2424 19.4164L5.35804 16.0703C5.55397 15.4817 5.66974 15.1352 5.826 14.8057C6.0114 14.4179 6.23728 14.0519 6.50202 13.7127C6.72547 13.4261 6.98374 13.1678 7.42255 12.729L15.1025 5.04907ZM6.71738 19.784L9.01748 19.0165C9.65788 18.8027 9.92991 18.7112 10.1833 18.5906C10.491 18.4433 10.7824 18.2635 11.052 18.0538C11.2731 17.8806 11.4771 17.679 11.9547 17.2013L18.0835 11.0726C17.2432 10.7748 16.4804 10.2923 15.8514 9.66061C15.2202 9.03144 14.7383 8.26868 14.4411 7.42852L8.31231 13.5573C7.83464 14.0341 7.63224 14.2373 7.45979 14.4592C7.25014 14.7286 7.07017 15.0198 6.92302 15.3279C6.80239 15.5813 6.7109 15.8533 6.49716 16.4937L5.73046 18.7938L6.71738 19.784ZM15.4247 6.44322C15.4531 6.5849 15.4992 6.77759 15.5778 7.00185C15.8145 7.67957 16.2021 8.29468 16.7112 8.80081C17.2171 9.30982 17.8319 9.69738 18.5094 9.93426C18.7344 10.0128 18.9271 10.0589 19.0688 10.0873L19.6048 9.55131C20.0851 9.06761 20.3541 8.41324 20.3529 7.73156C20.3517 7.04988 20.0804 6.39645 19.5984 5.91443C19.1164 5.43241 18.463 5.16108 17.7813 5.15989C17.0996 5.1587 16.4452 5.42773 15.9615 5.90807L15.4247 6.44322Z"
      fill="#828282"
    />
  </svg>
</template>
