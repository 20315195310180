import { type LocationsResponse, type LocationResponse ,type LocationMetadataResponse } from "./dtos/LocationDto";
import { useApi, type ApiLoadingType } from "@/composables/api";

export interface ILocations {
  getLocations(queryParams?: LocationQueryParams): Promise<LocationsResponse>;
  getLocation(locationId: string, queryParams?: LocationQueryParams):  Promise<LocationResponse>;
  getLocationMetadata(locationId: string, queryParams?: LocationQueryParams): Promise<LocationMetadataResponse>;
}

export interface LocationQueryParams {
  code: string | string[]
  dept: string | string[]
  zone: string | string[]
}

const API_VERSIONING = 'api/v1'

export function useLocationsApi() : ILocations & ApiLoadingType {
  const api = useApi();
  const { loading,  errors, clearErrors } = api;

  async function getLocations(queryParams?: LocationQueryParams): Promise<LocationsResponse> {
    return api.get<LocationsResponse>(`${API_VERSIONING}/locations`, {}, {
      params: queryParams
    });
  }
  async function getLocation(locationId: string, queryParams?: LocationQueryParams):  Promise<LocationResponse> {
    return api.get<LocationResponse>(`${API_VERSIONING}/locations/${locationId}`, {}, {
      params: queryParams
    })
  }
  async function getLocationMetadata(locationId: string, queryParams?: LocationQueryParams): Promise<LocationMetadataResponse> {
    return api.get<LocationMetadataResponse>(`${API_VERSIONING}/locations/${locationId}/metadata`, {}, {
      params: queryParams
    });
  }


  return {
    getLocations,
    getLocation, 
    getLocationMetadata, 
    loading,
    errors,
    clearErrors
  }

}