
<script setup lang="ts">
import { onMounted, defineProps, ref, watch, computed, PropType } from 'vue';
import FolderSettingsIcon from '../icons/FolderSettingsIcon.vue';
import DeleteFileModal from '../components/DeleteFileModal.vue';
import RenameFileModal from '../components/RenameFileModal.vue';
import SingleFolder from '../components/SingleFolder.vue';
import FileOptions from '../components/FileOptions.vue';
import FileExtentionAction from './FileExtentionAction.vue';
import LeftArrowIcon from '../icons/LeftArrowIcon.vue';
import UploadImageIcon from '../icons/UploadImageIcon.vue';
import CreateFolderIcon from '../icons/CreateFolderIcon.vue';
import { useFilesApi } from '../../../../packages/smarthub/src/api/filesApi';
import { useFilesService } from '../../../../packages/smarthub/src/services/filesService';
import { File, FolderHistoryItem } from '../../../../packages/smarthub/src/api/dtos/FilesDto';
import FolderIcon from '../icons/FolderIcon.vue';
import CreateFolderModal from './CreateFolderModal.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { formatTimeAgo } from '@vueuse/core';
import { useHeaderStore } from '@wisionmonorepo/smarthub/src/stores/header';
import { LocationDto } from '@wisionmonorepo/smarthub/src/api/dtos/LocationDto';
import { useFooterStore } from '@wisionmonorepo/smarthub/src/stores/footer';
import { onClickOutside } from '@vueuse/core';

const props = defineProps({
  location: {
    type: Object as PropType<LocationDto>,
    required: true
  },
});

const locationIdRef = computed(() => props.location);

onMounted(() => {
  onClickOutside(optionsRef, handleClose);
});

const handleClose = () => {
  submenuFilesOpened.value = {};
};

watch(locationIdRef, () => {
  folderHistory.value = [];
  activeFolder.value = null;
  fetchDirectory();
});

const filesApi = useFilesApi();
const fileService = useFilesService();
const directoryStructure = ref<File[]>([]);
const selectedFiles = ref<File[]>([]);
const folderHistory = ref<FolderHistoryItem[]>([]);
const dataLoading = ref(false);
const activeFolder = ref();
const activeHistory = ref<File[]>([]);
const imageUrls = ref<Record<string, { base64: string; url: string }>>({});
const imagesLoading = ref(false);
const showDeleteModal = ref(false);
const showRenameModal = ref(false);
const showCreateModal = ref(false);
const submenuFilesOpened = ref<Record<string, boolean>>({});
const selectedFile = ref();
const currentPage = ref(1);
const pageSize = 10;
const isFolderDelete = ref(false);
const imageCache = ref(new Map());
const headerStore = useHeaderStore();
const scrollToDiv = ref();
const positionDiv = ref();
const optionsRef = ref();

const footerStore = useFooterStore();
const { toogleFooter } = footerStore;

onMounted(() => {
  fetchDirectory();
});

const errors = filesApi.errors;

watch(errors, () => {
  if (errors?.value?.response?.data.error) {
    toast.error(errors.value.response.data.error, {
      autoClose: 2000,
      theme: 'dark'
    });
  }
});

const fetchDirectory = async (isUpadte = false, isSubfolder = false) => {
  dataLoading.value = true;
  let response;
  if (!isSubfolder) {
    response = await fileService.getFilesForLocation(locationIdRef.value.id);
  } else {
    response = await fileService.getFileFromSubFolder(locationIdRef.value.id, activeFolder.value.id);
  }

  const { folders, files } = await fileService.processDirectoryData(response);
  
  directoryStructure.value = folders;
  selectedFiles.value = files;
  dataLoading.value = false;
  await fetchImageUrls();

  if (!isUpadte) {
    folderHistory.value.push({
      folders: folders,
      files: files,
    });
  } else {
    folderHistory.value[folderHistory.value.length - 1].files = files;
    folderHistory.value[folderHistory.value.length - 1].folders = folders;
  }
};

const getSingleFile = async (file: File) => {
  const data = await filesApi.getSingleImage(locationIdRef.value.id, file.id);
  return data;
};

const fetchImageUrls = async () => {
  imagesLoading.value = true;
  const startIndex = (currentPage.value - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, selectedFiles.value.length);
  for (let i = startIndex; i < endIndex; i++) {
    const file = selectedFiles.value[i];
    if (imageCache.value.has(file.id)) {
      imageUrls.value[file.id] = imageCache.value.get(file.id);
    } else {
      const response = await getSingleFile(file);

      if (response) {
        const reader = new FileReader();
        const url = URL.createObjectURL(response);
        reader.readAsDataURL(response);
        reader.onload = function () {
          const base64 = reader.result as string;
          imageUrls.value[file.id] = {
            base64,
            url: url,
          };
          imageCache.value.set(file.id, {
            base64: reader.result,
            url: url,
          });
        };
      }
    }
  }

  imagesLoading.value = false;
};

const loadMoreImages = async () => {
  currentPage.value++;
  await fetchDirectory(true, !!activeFolder?.value?.id);

  setTimeout(() => {
    positionDiv.value.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  }, 100);
};

const selectFolder = async (folder: File) => {
  currentPage.value = 1;
  const content = await filesApi.getFileFromSubFolder(locationIdRef.value.id, folder.id);

  activeFolder.value = folder;
  const folderImages: File[] = [];
  const folderFolders: File[] = [];

  content.forEach((item: File) => {
    if (item.isDir) {
      folderFolders.push(item);
    } else {
      folderImages.push(item);
    }
  });

  selectedFiles.value = folderImages;
  await fetchImageUrls();
  directoryStructure.value = folderFolders;
  folderHistory.value.push({
    folders: folderFolders,
    files: folderImages,
    active: folder,
  });
  
  activeHistory.value.push(folder);
};

const goBack = () => {
  activeHistory.value.pop();
  const lastActive = activeHistory.value[activeHistory.value.length - 1];
  const historyFolders = [];
  const historyImages = [];

  folderHistory.value.pop();
  const lastHistory = folderHistory.value.slice(-1);
  if (folderHistory.value.length == 1) {
    activeFolder.value = null;
  } else {
    activeFolder.value = lastActive;
  }

  const oldFolders = lastHistory[0]['folders'];
  const oldImages = lastHistory[0]['files'];

  oldFolders.forEach((item) => {
    historyFolders.push(item);
  });

  oldImages.forEach((item) => {
    historyImages.push(item);
  });

  directoryStructure.value = oldFolders;
  selectedFiles.value = oldImages;

};

const downloadImage = (image: File) => {
  const imageSrc = imageUrls.value[image.id]?.url;
  if (imageSrc) {
    const anchor = document.createElement('a');
    anchor.href = imageSrc;
    anchor.download = image.name;
    anchor.style.display = 'none';

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    toggleFilesSubmenu(image.id);
  }
};

const toggleOptionModal = (file: File, type: string, isFolder = false) => {
  isFolderDelete.value = isFolder;
  toggleFilesSubmenu(file.id);
  selectedFile.value = file;
  if (type === 'rename') {
    showRenameModal.value = true;
  } else {
    showDeleteModal.value = true;
  }
};

const handleFileDelete = async () => {
  const folderDelete = isFolderDelete.value;
  await filesApi.deleteFile(locationIdRef.value.id, selectedFile.value.id, folderDelete);
  await fetchDirectory(true, !!activeFolder?.value?.id);
  showDeleteModal.value = false;
};

const handleFileRename = async (newName: string) => {
  await filesApi.renameFile(locationIdRef.value.id, selectedFile.value.id, newName);
  await fetchDirectory(true, !!activeFolder?.value?.id);

  showRenameModal.value = false;
};

const handleCreateFolder = async (folderName: string) => {
  await filesApi.createFolder(locationIdRef.value.id, folderName, !!activeFolder?.value?.id && activeFolder?.value?.id);
  showCreateModal.value = false;
  await fetchDirectory(true, !!activeFolder?.value?.id);
};

const uploadFiles = async (event: Event) => {
  const input = event.target as HTMLInputElement;
  if (input.files) {
    const formData = new FormData();
    for (let i = 0; i < input.files.length; i++) {
      formData.append('filename' + `${i}`, input.files[i]);
    }

    try {
      let subFolder;
      if (activeFolder?.value?.id) {
        subFolder = activeFolder.value.id;
      }
      await filesApi.uploadFileToLocation(locationIdRef.value.id, formData, subFolder);
      await fetchDirectory(true, !!subFolder);
    } catch (error) {
      console.error('File upload error:', error);
    } finally {
      imagesLoading.value = false;
    }
  }
};

const currentActiveMenu = ref('');

const toggleFilesSubmenu = (imageId: string) => {
  if (currentActiveMenu.value === imageId) {
    submenuFilesOpened.value = {};
    currentActiveMenu.value = '';
  } else {
    for (const id in submenuFilesOpened.value) {
      if (id !== imageId) {
        submenuFilesOpened.value[id] = false;
      }
    }
    submenuFilesOpened.value[imageId] = !submenuFilesOpened.value[imageId];
    currentActiveMenu.value = imageId;
  }
};

const openInBig = (router) => {
  headerStore.setSelectedItem(locationIdRef.value);
  !footerStore.isFooterVisible && toogleFooter();
  router.push?.('/archive');
};

</script>

<template>
  <DeleteFileModal
    v-if="showDeleteModal"
    :file="selectedFile"
    :is-folder="isFolderDelete"
    @handle-file-delete="handleFileDelete"
    @close-modal="showDeleteModal = false"
  />
  <RenameFileModal
    v-if="showRenameModal"
    :file="selectedFile"
    :is-folder="isFolderDelete"
    @handle-file-rename="handleFileRename"
    @close-modal="showRenameModal = false"
  />
  <CreateFolderModal
    v-if="showCreateModal"
    :is-folder="isFolderDelete"
    @handle-create-folder="handleCreateFolder"
    @close-modal="showCreateModal = false"
  />
  <div
    v-if="dataLoading || imagesLoading"
    class="flex items-center"
    style="justify-content: center; height: 100%;"
  >
    <div class="loader" />
  </div>
  <div
    ref="scrollToDiv"
    v-else
  >
    <div
      class="flex items-center pt-2"
    >
      <div style="width: 20%; padding-left: 10px;">
        <span v-if="folderHistory.length > 1">
          <LeftArrowIcon
            style="cursor: pointer;"
            @click="goBack"
          />
        </span>
      </div>
      <div
        class="text-center flex justify-center items-center"
        style="width: 50%;"
      >
        <span v-if="activeFolder?.name">
          <FolderIcon
            :width="15"
            :height="11"
          /></span>
        <span class="pl-3 folder-name">{{ activeFolder?.name }}</span>
      </div>
      <div
        class="flex"
        style="width: 30%; justify-content: space-evenly;"
      >
        <CreateFolderIcon
          class="cursor-pointer"
          @click="showCreateModal = true"
        />
        <label
          for="fileInput"
        >
          <UploadImageIcon 
            style="cursor: pointer;"
          />
          <input
            id="fileInput"
            type="file"
            style="display: none;"
            multiple
            @change="uploadFiles"
          >
        </label>
      </div>
    </div>
    <div
      v-if="folderHistory.length <= 1"
      class="flex items-center justify-center pt-2"
    >
      <span
        class="open-in-button"
        @click="openInBig($router)"
      >OPEN IN BIG VIEW</span>
    </div>
    <div v-if="directoryStructure.length < 1 && selectedFiles.length < 1">
      <div class="pt-5">
        <h3 class="text-center">
          No files in this folder
        </h3>
      </div>
    </div>
    <div 
      v-else 
      class="files-list"
      style="height: 100%;"
    >
      <div class="pt-3">
        <div 
          v-for="image, index in directoryStructure"
          :key="image.id"
          class="flex"
          style="align-items: center; padding: 7px 10px 7px 10px; word-break: break-all; position: relative;"
        >
          <SingleFolder
            :item="image"
            @select-folder="selectFolder"
            @toggle-files-submenu="toggleFilesSubmenu"
          />
          <div style="padding-left: 8px; position: relative;">
            <FileOptions
              ref="optionsRef"
              style="right: 10px !important; top: -50px;"
              :item="image"
              :index="index"
              :is-folder="true"
              :is-active="submenuFilesOpened[image.id]"
              @click.stop
              @toggle-option-modal="toggleOptionModal"
            />
          </div>
          <div />
        </div>
        <div v-if="selectedFiles.length > 0 && !imagesLoading">
          <div 
            v-for="image in selectedFiles.slice(0, currentPage * pageSize)"
            :key="image.id"
            class="flex"
            style="align-items: center; padding: 5px 5px 5px 10px; word-break: break-all;"
          >
            <FileExtentionAction 
              :image="image"
              :image-urls="imageUrls"
            />
            <div style="padding-left: 0px; width: 80%; position: relative;">
              <p class="file-name-style">
                {{ image.name }}
              </p>
              <p style="font-size: 10px; font-weight: 400px; opacity: 0.5;">
                Modified {{ formatTimeAgo(new Date(image.lastmodified)) }}
              </p>
              <FileOptions
                ref="optionsRef"
                style="top: -85px;"
                :item="image"
                :is-folder="false"
                :is-active="submenuFilesOpened[image.id]"
                :image-urls="imageUrls"
                @toggle-option-modal="toggleOptionModal"
                @click.stop
                @download-image="downloadImage(image)"
              />
            </div>
            <div @click="toggleFilesSubmenu(image.id)">
              <FolderSettingsIcon style="cursor: pointer;" />
            </div>
            <div />
          </div>
        </div>
      </div>
      <div
        v-if="selectedFiles.length > currentPage * pageSize && !imagesLoading"
        class="flex justify-center p-3"
      >
        <button
          class="load-more-button"
          @click="loadMoreImages"
        >
          Load More
        </button>
      </div>
      <div ref="positionDiv"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.labels {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.open-in-button {
  border: 1px solid #FFFFFF;
  border-radius: 50px;
  padding: 8px 30px;
  font-family: 'Avenir Next LT Pro';
  cursor: pointer;
}

.file-name-style {
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #FFFFFF;
}

.folder-style {
  width: 27%;
  margin: 5px 8px;
  height: 91px;
  background: #404448;
  border-radius: 5px;
  justify-content: center;
  flex-direction: column;
  word-wrap: break-word;
}

.files-list {
  background: #404448;
  margin: 0px 10px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.loader {
  border: 4px solid #888;
  border-top: 4px solid #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.load-more-button {
  background: #404448;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  padding: 2px 5px;
  cursor: pointer;
}

.folder-name {
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #FFFFFF;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
