<script setup lang="ts">
import { PropType } from 'vue';
import { File } from '../../../smarthub/src/api/dtos/FilesDto';
import { IMAGE_EXTENTIONS }  from '@wisionmonorepo/smarthub/src/helpers/archive/index';
import { checkFileExtention } from '@wisionmonorepo/smarthub/src/helpers/archive/index';
import { getIcon } from '@wisionmonorepo/smarthub/src/helpers/archive/index';

const props = defineProps({
  image: {
    type: Object as PropType<File>,
    required: true,
  },
  imageUrls: {
    type: Object,
    required: true
  },
  isArchiveTab: {
    type: Boolean,
    default: false,
  }
});

const openImageInNewTab = (imageSrc) => {
  const win = window.open();
  win.document.write('<iframe src="' + imageSrc.url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
};

</script>
<template>
  <div class="w-[20%]">
    <div
      :class="{'container archive-padding': isArchiveTab, 'marker-padding': !isArchiveTab}"
      @click="openImageInNewTab(props.imageUrls[image.id])"
    >
      <img
        v-if="IMAGE_EXTENTIONS.includes(checkFileExtention(image.name.toLowerCase()))"
        class="tile-image cursor-pointer"
        :class="{'image-div': isArchiveTab}"
        style="height: 25px; object-fit: cover;"
        :style="`padding: ${isArchiveTab ? '0' : '0px 0px'}`"
        width="40"
        :src="`${imageUrls[image.id]?.base64}`"
      >
      <div
        v-else
        style="width: 40px;"
        class="flex justify-center"
      >
        <component
          :is="getIcon(checkFileExtention(image.name))"
          :width="26"
          :height="24"
        />
      </div>
    </div>
  </div>
</template>

<style setup lang="scss" scoped>
  .container {
    cursor: pointer;
    width: 40px;
    height: 25px;
  }

  .archive-padding {
    padding: 0px;
  }

  .marker-padding {
    @media screen and (max-width: 430px) {
      padding-right: 18px;
    }
  }

  @media screen and (max-width: 768px) {
  .image-div {
    display: none;
  }
  .container {
    display: none;
  }
}
</style>
