<script lang="ts" setup>
import type { DashboardView, DashboardViewSimple } from '@/models/DashboardModel';
import { ref, type PropType, watch, nextTick, computed, watchEffect, type Component } from 'vue';
import Button from '@/components/Button.vue';
import PencilIcon from '@/components/icons/PencilIcon.vue';
import DeleteIcon from '@/components/icons/DeleteIcon.vue';
import CheckmarkIcon from '@/components/icons/CheckmarkIcon.vue';
import CopyIcon from '@/components/icons/CopyIcon.vue'
import WidgetSelect from '@/components/WidgetSelect.vue';
import DashboardViewSelect from '@/components/DashboardViewSelect.vue';
import Input from '@/components/Input.vue';
import ConnectedDataFrom from '@/components/ConnectedDataFrom.vue';
import type { WidgetPack } from '@/types/widgets-map-types';
import type { LocationModel } from '@/models/LocationModel';
import { ConsumedAction, type CommandTarget, type CommandActions } from '@/utils/ConsumedAction';
import DashboardMoreItemsDropdown, { type MoreItemsDropdownItem} from './DashboardMoreItemsDropdown.vue';
import { useSmarthubBreakpoints } from '@/composables/smarthubBreakpoints';
import DisconnectedPlugsIcon from './icons/DisconnectedPlugsIcon.vue';
import ConnectedPlugsIcon from './icons/ConnectedPlugsIcon.vue';

const props = defineProps({
  editMode: {
    type: Boolean,    
    required: true,
  },
  connectedMode: {
    type: Boolean,
    required: true,
  },
  connectedModeLoading: {
    type: Boolean,
    required: true,
  },
  dashboardViewsSimpleList: {
    type: Array as PropType<DashboardViewSimple[] | undefined>,
    required: true,
  },    
  selectedDashboardViewSimple: {
    type: Object as PropType<DashboardViewSimple | null>,
    required: true,
  },
  selectedDashboardView: {
    type: Object as PropType<DashboardView | null>,
    required: true,
  },
  widgetItems: {
    type: Array as PropType<Array<WidgetPack>>,
    required: true,
  },
  selectedLocation: {
    type: Object as PropType<LocationModel | null>,
    required: true,
  },
  dataFromDate:{
    type: String,
    required: true
  },
  postCommands: {
    type: Object as PropType<ConsumedAction<CommandTarget, CommandActions<CommandTarget>>>,
    required: true,
  }
})

const emit = defineEmits<{
  (e: 'enter-edit-mode'): void;
  (e: 'exit-edit-mode' ): void;
  (e: 'create-view'): Promise<void>;
  (e: 'copy-view'): Promise<void>;
  (e: 'save-view'): Promise<void>;
  (e: 'delete-view'): Promise<void>;
  (e: 'rename-view'): Promise<void>;
  (e: 'cancel-view-changes'): void
  (e: 'add-widget', widgetPack: WidgetPack): void;
  (e: 'view-selected', view: DashboardViewSimple | null): void;
  (e: 'enter-connected-mode'): void;
  (e: 'exit-connected-mode'): void;  
  (e: 'update:updated-view-name', viewName: string): void;
}>();

const viewNameRef = ref();
const editViewNameMode = ref<boolean>(false);

const updatedViewName = ref<string | null>(null);

watch(updatedViewName, (newUpdatedViewName) => {
  emit('update:updated-view-name', newUpdatedViewName as string);  
}, { immediate: true})

async function cancelViewChanges(){
  clearChanges();  
  emit('cancel-view-changes');
}

function clearChanges() {
  updatedViewName.value = null;  
}


async function toggleViewNameEdit() {  
  if(!props.editMode) {    
    if(editViewNameMode.value) {      
      saveViewNameToState();     
      await nextTick();       
      await emit('save-view');      
    }
    editViewNameMode.value = !editViewNameMode.value;    
  }  
}
  

function saveViewNameToState() {  
  const updatedName = viewNameRef.value.textContent;  
  updatedViewName.value = updatedName;  
}

watch(() => props.editMode, (newEditModeValue, oldEditModeValue) => {
    if(newEditModeValue == false && editViewNameMode.value == false) {    
      editViewNameMode.value = false;
    }

    if(oldEditModeValue == false && newEditModeValue == true) {      
      if(editViewNameMode.value) {
         editViewNameMode.value = false;
      }

      if(props.selectedDashboardView) {
        updatedViewName.value = props.selectedDashboardView.viewName;
      }

    }

    if(oldEditModeValue == true && newEditModeValue == false) {      
      clearChanges();
    }
  });

watch( editViewNameMode, (newEditViewNameVal) => newEditViewNameVal && (updatedViewName.value = null))

const inputRef = ref();

watch( [() => props.editMode, () => inputRef.value], ([newEditMode, newInputRef]) => {  
  if(newEditMode && newInputRef && props.postCommands.has('name-input-field')) {    
    const cmd = props.postCommands.consume('name-input-field');
    if(cmd == 'focus') {      
      newInputRef.inputRef.focus();
    }
  }
  
}, { flush: 'post'});


const { isMobile, isTabletOrLaptop, isDesktop } = useSmarthubBreakpoints();

const moreItems = computed(() => {
  const resultItems : Array<MoreItemsDropdownItem> = [];

  if(props.selectedDashboardView != null) {
    if(!props.editMode) {
      
        if(isMobile.value) {
          resultItems.push(
            { title: "Edit view", eventName: 'enter-edit-mode', iconComponent: PencilIcon}
          );      
        }

        if(props.selectedLocation != null) {
          if(!props.connectedMode) {
            resultItems.push(
              { title: "Enter connected Mode", eventName: 'enter-connected-mode', iconComponent: ConnectedPlugsIcon}
            );
          }else {
            resultItems.push(
              { title: "Exit connected mode", eventName: "exit-connected-mode", iconComponent: DisconnectedPlugsIcon}
            )
          }//end if !connectedMode
      }

    } else {    
      resultItems.push(        
        { title: "Delete view", eventName: 'delete-view', iconComponent: DeleteIcon},
      );      

      if(isMobile.value) {
        resultItems.push(
        ...[            
            { title: "Cancel view changes", eventName: 'cancel-view-changes', iconComponent: false},
            { title: "Save changes", eventName: 'save-view', iconComponent: CheckmarkIcon }
          ] as Array<MoreItemsDropdownItem>
      );      

      }

    }//end if !editMode

    if(isMobile.value && !props.editMode) {      
      resultItems.push( { title: "Rename view", eventName: 'rename-view', iconComponent: false as unknown as Component} );
    }

    if(isMobile.value || (isTabletOrLaptop.value && props.editMode)) {
      resultItems.push(        
        { title: "Copy view", eventName: 'copy-view', iconComponent: CopyIcon, disabled: true},        
      );
    }
  }

  return resultItems;
});

</script>

<template>
  <div class="w-full flex justify-between px-[10px] z-[1]">
    <div 
      v-if="!(isMobile && editMode)"      
      class="z-10 shrink-[4] basis-[240px] flex flex-row gap-2.5"
      :class="{
        'order-2': isTabletOrLaptop,
        'justify-end': isTabletOrLaptop
        }"
    > 
      <Transition name="buttons-edit-mode" mode="out-in" >
        <div class="inline-flex gap-2" v-if="!editMode" >
          <DashboardViewSelect                       
            :items="dashboardViewsSimpleList"
            :selected-item="selectedDashboardViewSimple"
            :drop-down-position="(isTabletOrLaptop && selectedDashboardView == null) ? 'top-center' : 'top-left'"
            @view-selected="$emit('view-selected', $event)"
            @create-view="$emit('create-view')"
          /> 
          <div v-if="!isMobile" class="inline-flex gap-2 items-baseline self-stretch pt-2.5">
            <Button                    
              v-if="!editMode && selectedDashboardView"                  
              :icon-component="PencilIcon"
              @click="$emit('enter-edit-mode')"
            >
              Edit
            </Button>
            <Button                     
              v-if="!editMode && selectedDashboardView"
              :icon-component="CopyIcon"
              disabled
              @click="$emit('copy-view')"
            >
              Copy
            </Button>         
          </div>
          <div v-if="isTabletOrLaptop" class="inline-flex gap-2 items-baseline pt-[11px]">        
            <!-- Transition fix copy - (non edit mode) - currently not showing on isTabletOrLaptop because of moreItems.length == 0 -->    
            <DashboardMoreItemsDropdown          
              v-if="isTabletOrLaptop && moreItems.length > 0"
              :items="moreItems"
              @enter-edit-mode="$emit('enter-edit-mode')"
              @exit-edit-mode="$emit('exit-edit-mode')"
              @enter-connected-mode="$emit('enter-connected-mode')"
              @exit-connected-mode="$emit('exit-connected-mode')"
              @delete-view="$emit('delete-view')"
              @cancel-view-changes="$emit('cancel-view-changes')"
              @save-view="$emit('save-view')"
              @rename-view="$emit('rename-view')"
            />     
          </div>    
        </div>
        <div v-else-if="(isTabletOrLaptop || isDesktop) && editMode" 
          class="inline-flex flex-row gap-2.5 items-center" >          
          <WidgetSelect             
            class="overflow-ellipsis whitespace-nowrap"                
            :items="widgetItems"
            :drop-down-position="isTabletOrLaptop ? 'top-center' : 'top-left'"
            @widget-selected="$emit('add-widget', $event)"                                    
          />          
          <div v-if="isTabletOrLaptop && editMode" class="inline-flex gap-2 items-baseline self-stretch pt-[9px]">
            <Button                                  
              v-if="selectedDashboardView"                  
              :icon-component="PencilIcon"
              @click="$emit('cancel-view-changes')"
            >
              Cancel
            </Button>
            <Button                     
              v-if="selectedDashboardView"
              :icon-component="CheckmarkIcon"              
              :background-color="'#15B1CA'"
              :background-active-color="'#66dcef'"
              @click="$emit('save-view')"
            >
              Save
            </Button>                   
          </div>       
          <div v-if="isTabletOrLaptop" class="inline-flex gap-2 items-baseline ">
            <DashboardMoreItemsDropdown          
              v-if="isTabletOrLaptop && moreItems.length > 0"
              :items="moreItems"
              @enter-edit-mode="$emit('enter-edit-mode')"
              @exit-edit-mode="$emit('exit-edit-mode')"
              @enter-connected-mode="$emit('enter-connected-mode')"
              @exit-connected-mode="$emit('exit-connected-mode')"
              @delete-view="$emit('delete-view')"
              @cancel-view-changes="$emit('cancel-view-changes')"
              @save-view="$emit('save-view')"
              @rename-view="$emit('rename-view')"
            />     
          </div>
        </div>        
      </Transition>              
    </div>
    
    <div 
      class="shrink-[3] flex basis-[300px]"
      :class="{
        'order-1': isTabletOrLaptop,
        'justify-start': isTabletOrLaptop || isMobile,
        'justify-center': !isTabletOrLaptop && !isMobile
      }"      
      >
      <div 
        class="whitespace-nowrap "        
        v-if="!isMobile && selectedDashboardView || isMobile && selectedDashboardView && editMode" 
      >                
        <Transition name="buttons-edit-mode" mode="out-in" >
        <span v-if="!editMode" :class="{'pl-2': isTabletOrLaptop}">
          <span                     
            class="whitespace-nowrap editable text-ellipsis" 
            ref="viewNameRef"
            :contenteditable="!editMode && editViewNameMode"
            v-on:keydown.enter.prevent.stop="() => { }"
            >{{ selectedDashboardView.viewName }}</span>
            <button 
              v-if="!editMode"
              @click="toggleViewNameEdit"
              >
                <div class="ml-1.5 inline-block">                    
                  <PencilIcon v-if="!editViewNameMode" />
                  <CheckmarkIcon v-else-if="editViewNameMode" />                    
              </div>
            </button>                  
          </span>          
          <Input
            ref="inputRef"
            class="dashboard-view-name"
            v-else-if="editMode"
            placeholder="Type view name ..."
            v-model="updatedViewName"            
          />            
        </Transition>   
      </div>             
    </div>
  
    <div 
      v-if="!isTabletOrLaptop"
      class="flex justify-end pt-2 gap-2.5 btn-container basis-[335px]"      
      >                  
      <Transition name="buttons-edit-mode" mode="out-in" >
      <div class="inline-flex" v-if="!isMobile && !editMode && selectedDashboardView && selectedLocation">                          
        <ConnectedDataFrom 
          class="-mt-2.5 mr-2.5"                    
          :connected-mode="connectedMode" 
          :data-from-date="(dataFromDate as string)" 
        />                                              
        <Button                                                    
          v-if="!connectedMode"
          :disabled="connectedModeLoading"
          class="whitespace-nowrap"
          background-color="#15B1CA"
          border-color="#15B1CA"
          background-active-color="rgb(47, 208, 233)"
          border-active-color="rgb(47, 208, 233)"
          
          @click="$emit('enter-connected-mode')"
        >
          Click to connect
        </Button>
        <Button                                  
          v-else-if="connectedMode"
          :disabled="connectedModeLoading"
          class="whitespace-nowrap"
          background-color="#FC5F58"
          border-color="#FC5F58"
          background-active-color="rgb(253, 127, 121)"
          border-active-color="rgb(253, 127, 121)"                    
          @click="$emit('exit-connected-mode')"
        >
          Click to disconnect
        </Button>         
      </div>
      <div class="flex justify-center gap-2.5 shrink-0" v-else-if="!isMobile && editMode">
        <Button
          :icon-component="DeleteIcon"
          @click="$emit('delete-view')"                    
        >
          Delete
        </Button>
        <Button
          @click="cancelViewChanges"
        >Cancel</Button>
        <Button
          :icon-component="CheckmarkIcon"
          :background-color="'#15B1CA'"
          :background-active-color="'#66dcef'"
          @click="$emit('save-view')"
          >
          Save changes
        </Button>                
      </div>
      <div 
        v-else-if="isMobile && moreItems.length > 0" 
        class="inline-flex gap-2 items-baseline pt-[1px]" >
        <DashboardMoreItemsDropdown          
          :items="moreItems"
          @enter-edit-mode="$emit('enter-edit-mode')"
          @exit-edit-mode="$emit('exit-edit-mode')"
          @enter-connected-mode="$emit('enter-connected-mode')"
          @exit-connected-mode="$emit('exit-connected-mode')"
          @delete-view="$emit('delete-view')"
          @cancel-view-changes="$emit('cancel-view-changes')"
          @save-view="$emit('save-view')"
          @rename-view="$emit('rename-view')"
      />
      </div>
    </Transition>  
    </div>                         
  </div>
</template>

<style>
.buttons-edit-mode-enter-active {  
  transition: opacity 0.2s ease;  
}
.buttons-edit-mode-leave-active {  
  transition: opacity 0.2s ease;  
}

.buttons-edit-mode-enter-from,
.buttons-edit-mode-leave-to {
  opacity: 0;
}

.dashboard-view-name.smarthub-input-container {
  width: 190px;
}

</style>