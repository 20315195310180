export interface UserInfoData {
  audience: string;
  owner_id: string;
  domain: string;
  usercode: string;
  user: string;
  userid: number;
  user_id: number;
  user_email: string;
}

const USER_INFO_DATA_ENTRIES = {
  audience: "audience",
  owner_id: "owner_id",
  domain: "domain",
  usercode: "usercode",
  user: "user",
  userid: "userid",
  user_id: "user_id",
  user_email: "user_email",
}

const USER_INFO_DATA_KEYS = new Set(Object.keys(USER_INFO_DATA_ENTRIES));

export class UserInfoService {
  constructor(private storage: typeof window.localStorage | typeof window.sessionStorage) {}

  setUserInfoData(tokenData: UserInfoData) {
    const that = this;
    Object.entries(tokenData)
      .forEach( ([key,value]) => {
        if(USER_INFO_DATA_KEYS.has(key)) {
          that.storage.setItem(key, value)
        }
      });
  }

  getUserInfoData(): UserInfoData | null {
    const that = this;
    const tokenEntries: Array<[string, string | number]> = [];

    USER_INFO_DATA_KEYS.forEach( key => {
      let tokenEntryData: string | number | null = that.storage.getItem(key);
      if(tokenEntryData != null) {
        tokenEntryData = (key && (key === USER_INFO_DATA_ENTRIES.userid || key == USER_INFO_DATA_ENTRIES.user_id))
          ? parseInt(tokenEntryData)
          : tokenEntryData;

        tokenEntries.push([key, tokenEntryData])
      }
    });

    if(tokenEntries.length == 0) {
      return null;
    }else {
      return Object.fromEntries(tokenEntries) as unknown as UserInfoData;
    }
    
  }

  setUserInfoDataEntry(key: string, value: string | number) {
    if(USER_INFO_DATA_KEYS.has(key)) {
      this.storage.setItem(key, value.toString())
    }
  }

  getUserInfoDataEntry(key: string) : string | number | null {
    const value = this.storage.getItem(key);
    return key.startsWith("user") 
      ? Number(value)
      : value;      
  }

  clearUserInfoData() {
    const that = this;
    USER_INFO_DATA_KEYS.forEach( key => that.storage.removeItem(key))
  }

}

export const userInfoService = new UserInfoService(window.sessionStorage);