import { ref, type UnwrapRef } from "vue";

export function useLoading<E_T>() {
  const loading = ref<boolean>(false);
  const errors = ref<E_T | null>(null);

  function setLoading(isLoading: boolean) {
    loading.value = isLoading;
  }

  function toggleLoading() {
    loading.value = !loading.value;
  }

  function setErrors(errorValue: E_T | null) {
    errors.value = errorValue as UnwrapRef<E_T> | null; 
  }

  function clearErrors(){
    if(errors.value != null) {
      errors.value = null;
    }
  }


  return {
    loading,
    setLoading,
    errors,
    setErrors,
    clearErrors    
  }
}