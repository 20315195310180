
import { defineStore } from 'pinia'
import { useInitialDashboardDataStore } from './initialDashboardData';
import { useUserInfoApi } from '@/api/userInfo';
import { useLocationAlarmsStore } from './locationAlarms';

export const useEagerStoreLoader = defineStore('eager-store-loader', () => {
  /*  Please don't put every store here ... */
  useUserInfoApi();
  useLocationAlarmsStore();
  useInitialDashboardDataStore();  
})
