import { GetGISDataResponse } from '@wisionmonorepo/api-client-v1/src/responses';

export enum GisType {
  AlarmWire = 'AlarmWire',
  BoilerCentral = 'BoilerCentral',
  DistributionLine = 'DistributionLine',
  MainLine = 'MainLine',
  MeasuringPoint = 'MeasuringPoint',
  MonitoringStation = 'MonitoringStation',
  MonitoringStationArea = 'MonitoringStationArea',
  ServiceLine = 'ServiceLine',
  ValveChamber = 'ValveChamber',
  ValveWell = 'ValveWell',
}

const typeMap: Record<number, GisType> = {
  6: GisType.ValveChamber,
  8: GisType.BoilerCentral,
  10: GisType.MainLine,
  11: GisType.DistributionLine,
  12: GisType.ServiceLine,
  23: GisType.ValveWell,
  41: GisType.MonitoringStationArea,
  43: GisType.MonitoringStation,
  44: GisType.MeasuringPoint,
  190: GisType.AlarmWire,
  191: GisType.AlarmWire,
  192: GisType.AlarmWire,
  193: GisType.AlarmWire,
  194: GisType.AlarmWire,
  195: GisType.AlarmWire,
  196: GisType.AlarmWire,
  197: GisType.AlarmWire,
};

// https://developers.google.com/maps/documentation/javascript/examples/overlay-symbol-dashed
const dashedLine: google.maps.Data.StyleOptions = {
  strokeOpacity: 0,
  icons: [
    {
      icon: {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 2
      },
      offset: '0',
      repeat: '10px'
    },
  ],
};

const styleOptions: Record<GisType, google.maps.Data.StyleOptions> = {
  AlarmWire: { strokeColor: '#ff0000', ...dashedLine },
  BoilerCentral: { icon: 'http://maps.google.com/mapfiles/ms/micons/green.png' },
  DistributionLine: { strokeColor: '#333333' },
  MainLine: { strokeColor: '#000000' },
  MeasuringPoint: { icon: 'http://maps.gstatic.com/intl/en_us/mapfiles/marker_whiteM.png' },
  MonitoringStation: { icon: 'http://maps.google.com/mapfiles/ms/micons/purple.png' },
  MonitoringStationArea: { icon: 'http://maps.google.com/mapfiles/ms/micons/grey.png' },
  ServiceLine: { strokeColor: '#666666' },
  ValveChamber: { icon: 'http://maps.google.com/mapfiles/ms/micons/blue.png' },
  ValveWell: { icon: 'http://maps.google.com/mapfiles/ms/micons/red.png' },
};

export const featureStyle = 
  (feature: google.maps.Data.Feature): google.maps.Data.StyleOptions => {
    const id: number = feature.getProperty('typ_id');
    const gisType = typeMap[id];

    return styleOptions[gisType] || {};
  };

export type GisFilter = Record<GisType, boolean>;

// Filter GeoJson features on a Google Map
export const filterGis = (map: google.maps.Map, geoJson: GetGISDataResponse, selectedTypes: GisFilter): void => {
  const features = geoJson.features
    .filter((feature) => selectedTypes[typeMap[feature.properties.typ_id]]);

  const filteredGeoJson = {
    ...geoJson,
    features,
  };

  map.data.forEach((feature) => map.data.remove(feature));
  map.data.addGeoJson(filteredGeoJson);
};
