<script setup lang="ts">
import { onMounted, watchEffect, ref } from 'vue';
import { useEventsStore } from '../../../smarthub/src/stores/events';
import SingleAlarmEvent from './SingleAlarmEvent.vue';

const props = defineProps({
  location: {
    type: Object,
    required: true,
  },
  alarmsForLocation: {
    type: Array,
    required: true,
  },
  setSelectedDate: {
    type: Function,
    required: true,
  },
  loading: {
    type: Boolean,
  }
});

const actions = ref<any[]>([]);
const eventsStore = useEventsStore();

onMounted(() => {
  eventsStore.setEventsDate(new Date(), props.location.id);
});

watchEffect(() => {
  const combinedActions = [...eventsStore.alarmEvents, ...eventsStore.actionEvents, ...eventsStore.notesEvents];
  combinedActions.sort((a, b) => {
    const getTimestamp = (item) => {
      if (item.timestamp) return item.timestamp;
      if (item.action && item.action.timestamp) return item.action.timestamp;
      if (item.note && item.note.created) return item.note.created;
      return '';
    };

    const timestampA = getTimestamp(a);
    const timestampB = getTimestamp(b);

    return new Date(timestampB) - new Date(timestampA);
  });
  actions.value = combinedActions;
});

</script>

<template>
  <div
    v-if="eventsStore.loading || loading"
    class="loader"
  />
  <div
    v-else
    style="width: 100%; height: 100%; align-items: center; justify-content: center;"
    class="flex"
  >
    <div
      v-if="actions.length > 0 && !eventsStore.loading"
      style="width: 100%; height: 100%; "
      class="events-list"
    >
      <div
        v-for="(item, index) in actions"
        :key="index"
        style="position: relative;"
      >
        <div v-if="!item.action && !item.note">
          <div v-if="item.status === 'Accept'">
            <SingleAlarmEvent
              alarm-status="Accept"
              :alarm-time="item.timestamp"
              :alarm-text="item.text"
              :alarm-priority-id="item.priorityid"
              :alarm-priority-text="item.priority"
            />
          </div>
          <div v-if="item.status === 'Down'">
            <SingleAlarmEvent
              alarm-status="Down"
              :alarm-time="item.timestamp"
              :alarm-text="item.text"
              :alarm-priority-id="item.priorityid"
              :alarm-priority-text="item.priority"
            />
          </div>
          <div v-if="item.status === 'Up'">
            <SingleAlarmEvent
              alarm-status="Up"
              :alarm-time="item.timestamp"
              :alarm-text="item.text"
              :alarm-priority-id="item.priorityid"
              :alarm-priority-text="item.priority"
            />
          </div>
        </div>
        <div v-else-if="item.action">
          <SingleAlarmEvent
            alarm-status="Edit"
            :alarm-time="item.action.timestamp"
            :alarm-text="item.action.reference"  
            :alarm-priority-text="item.action.user"
            :is-action="true"
            :prev-value="item.action.previousvalue"
            :new-value="item.action.value"
            :unit="item?.action?.unit"
          />
        </div>
        <div v-else>
          <SingleAlarmEvent
            alarm-status="Note"
            :alarm-time="item.note.created"
            :alarm-text="item.note.user"  
            :alarm-priority-text="item.note.text"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <p>No events</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.time-stamp {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #C3C4C5;
}
.loader {
    border: 4px solid #888;
    border-top: 4px solid #333;
    border-radius: 50%;
    width: 50px;
    height: 50px !important;
    animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
