import { type DashboardView2 } from "@/models/DashboardModel";
import { createTestViewId, shuffleLayout } from "@/utils/InitialDashboardDataUtils";

export const TEST_LAYOUT = [  
  { x: 0, y: 0, w: 2, h: 11, mW: 1.5, mH: 6, i: 'status1', key: 'PercentageWidget', componentName: 'Percentage Widget', componentProps: { unitName:'mNAP', percent:'20' }},
  { x: 2, y: 0, w: 2, h: 11, mW: 1.5, mH: 6, i: 'status2', key: 'ProgressBar', componentName: 'Progress Bar', componentProps: { unitName: 'Level', percent: '78'} },  
  { x: 4, y: 0, w: 2, h: 11, mW: 1.5, mH: 6, i: 'status3', key: 'WidgetNotFound', componentName: 'Widget Not Found', componentProps: { unitName: 'Level', percent: '78'} },  
  { x: 6, y: 0, w: 2, h: 11, mW: 1.5, mH: 6, i: 'status4', key: 'PercentageWidgetLabel', componentName: 'Percentage Widget Label', componentProps: { unitName: 'Level', percent: '78'} },  
  
  { x: 8, y: 11, w: 4, h: 11, mW: 3, mH: 6, i: 'status5', key: 'PumpingStation', componentName: 'Pumping Station', componentProps: { unitName:'mNAP', percent:'20' }},
];




export const globalDashboardViews: DashboardView2[] = [
  {
    viewId: createTestViewId('global-view'),
    viewName: "View 1",
    locationId: null,
    layout: [
        {
            "componentName": "Radar Level Widget",
            "componentDesc": "Disaply levels",
            "key": "RadarLevelWidget",
            "h": 11,
            "w": 2,
            "mW": 1.5,
            "mH": 6,
            "x": 0,
            "y": 0,
            "componentProps": {
                "config": {
                    "locationsObjectsTags": [
                        {
                            "locationId": "50533036",
                            "objectId": "2f505330-362f-5650-2f4e-2f31",
                            "tagId": "2f2f5053-3036-2f4e-315f-6d7766",
                            "descTag": "value"
                        },
                        {
                            "locationId": "50533036",
                            "objectId": "2f505330-362f-5650-2f58-2f31",
                            "tagId": "2f2f5053-3036-2f58-315f-7569746c",
                            "descTag": "upper_pump_level"
                        },
                        {
                            "locationId": "50533036",
                            "objectId": "2f505330-362f-5650-2f58-2f31",
                            "tagId": "2f2f5053-3036-2f58-315f-696e6c",
                            "descTag": "lower_pump_level"
                        },
                        {
                            "locationId": "50533036",
                            "objectId": "2f505330-362f-5650-2f58-2f31",
                            "tagId": "2f2f5053-3036-2f58-315f-68",
                            "descTag": "upper_alarm_level"
                        },
                        {
                            "locationId": "50533036",
                            "objectId": "2f505330-362f-5650-2f58-2f31",
                            "tagId": "2f2f5053-3036-2f58-315f-6c",
                            "descTag": "lower_alarm_level"
                        }
                    ]
                }
            },
            "i": "0b38bc99-0e6b-4486-b4d6-caeeda7b86d8"
        },
        {
            "componentName": "Toggle Widget",
            "componentDesc": "Display toggle",
            "key": "ToggleWidget",
            "h": 11,
            "w": 2,
            "mW": 1.5,
            "mH": 6,
            "x": 2,
            "y": 0,
            "componentProps": {
                "config": {
                    "locationsObjectsTags": [
                        {
                            "locationId": "32303233-2d31-3536-34",
                            "objectId": "2f323032-332d-3135-3634-2f574c2f4e2f-31",
                            "tagId": "2f2f3230-3233-2d31-3536-342f4e315f68"
                        }
                    ]
                }
            },
            "i": "f5fa28d1-aac6-4901-b2dd-1ec1f76d5c70"
        },
        {
            "componentName": "Pumping Station",
            "componentDesc": "Pumping station 3d model",
            "w": 4,
            "h": 11,
            "mW": 3,
            "mH": 6,
            "key": "PumpingStation",
            "x": 4,
            "y": 0,
            "componentProps": {
                "config": []
            },
            "i": "5cb1c8dd-dd32-426b-80ed-75b8f77cf1ed"
        },
        {
            "componentName": "Consumption Widget",
            "componentDesc": "Display consumption level",
            "key": "ConsumptionWidget",
            "h": 11,
            "w": 2,
            "mW": 1.5,
            "mH": 6,
            "x": 8,
            "y": 0,
            "componentProps": {
                "config": {
                    "locationsObjectsTags": [
                        {
                            "locationId": "50533036",
                            "objectId": "2f505330-362f-5650-2f4e-2f31",
                            "tagId": "2f2f5053-3036-2f4e-315f-6d7766"
                        }
                    ]
                }
            },
            "i": "ef397fea-f450-4fb5-956c-cbfd684b50ca"
        },
        {
            "componentName": "Flow Widget",
            "componentDesc": "Display flow",
            "key": "WaterWidget",
            "h": 11,
            "w": 2,
            "mW": 1.5,
            "mH": 6,
            "x": 4,
            "y": 11,
            "componentProps": {
                "config": {
                    "locationsObjectsTags": [
                        {
                            "locationId": "32303233-2d31-3536-32",
                            "objectId": "2f323032-332d-3135-3632-2f56502f444f-2f31",
                            "tagId": "2f2f3230-3233-2d31-3536-322f444f315f-696e-76"
                        }
                    ]
                }
            },
            "i": "d5fd6e77-8e76-40d9-b3a7-eee1386d0d69"
        }
    ]    
  },
  {
    viewId: createTestViewId('global-view'),
    viewName: "View 2",
    locationId: null,
    layout: [
        {
            "componentName": "Graph Widget",
            "componentDesc": "Display graph",
            "componentIcon": {
                "hmrId": "98b2acc9",
                "file": "D:\/Projects\/Wision\/TeleControlNet\/packages\/smarthub\/src\/components\/icons\/GraphWidgetIcon.vue"
            },
            "key": "GraphWidget",
            "h": 11,
            "w": 2,
            "mW": 1.5,
            "mH": 6,
            "x": 0,
            "y": 0,
            "componentProps": {
                "config": {
                    "locationsObjectsTags": [
                        {
                            "locationId": "50533036",
                            "objectId": "2f505330-362f-5650-2f4e-2f31",
                            "tagId": "2f2f5053-3036-2f4e-315f-6d7766"
                        }
                    ]
                }
            },
            "i": "2d60da7c-3a91-4689-8b0b-cedd4b478964"
        },
        {
            "componentName": "Flow Widget",
            "componentDesc": "Display flow",
            "componentIcon": {
                "hmrId": "87ee8f22",
                "file": "D:\/Projects\/Wision\/TeleControlNet\/packages\/smarthub\/src\/components\/icons\/FlowWidgetIcon.vue"
            },
            "key": "WaterWidget",
            "h": 11,
            "w": 2,
            "mW": 1.5,
            "mH": 6,
            "x": 3,
            "y": 0,
            "componentProps": {
                "config": {
                    "locationsObjectsTags": [
                        {
                            "locationId": "32303233-2d31-3536-32",
                            "objectId": "2f323032-332d-3135-3632-2f56502f542f-31",
                            "tagId": "2f2f3230-3233-2d31-3536-322f54315f6d-7766"
                        }
                    ]
                }
            },
            "i": "71991bc4-5b8d-441f-bdb6-68d79ac315f3"
        },
        {
            "componentName": "Weather Widget",
            "componentDesc": "Display Weather For Location",
            "componentIcon": {
                "hmrId": "aaf6da0f",
                "file": "D:\/Projects\/Wision\/TeleControlNet\/packages\/smarthub\/src\/components\/icons\/WeatherWidgetIcon.vue"
            },
            "mW": 3,
            "mH": 11,
            "w": 3,
            "h": 11,
            "key": "Weather",
            "x": 6,
            "y": 0,
            "componentProps": {
                "config": {
                    "locationCoordinates": {
                        "name": "Meteo station Deelen",
                        "x": "51.5318131",
                        "y": "4.5519395"
                    }
                }
            },
            "i": "915984f3-e77a-4051-94df-ae14744c6084"
        }
    ]
  },
  {
    viewId: createTestViewId('global-view'),
    viewName: "View 3",
    locationId: null,
    layout: []
  },
  {
    viewId: createTestViewId('global-view'),
    viewName: "View 4",
    locationId: null,
    layout: [...TEST_LAYOUT]
  },
];


export const localDashboardViewsL1: DashboardView2[] = [
  {
    viewId: createTestViewId('local-view-l1'),
    viewName: "View 1",
    locationId: null,
    layout: []    
  },
  {
    viewId: createTestViewId('local-view-l1'),
    viewName: "View 2",
    locationId: null,
    layout: []    
  },
  {
    viewId: createTestViewId('local-view-l1'),
    viewName: "View 3",
    locationId: null,
    layout: []    
  },
];

export const localDashboardViewsL2: DashboardView2[] = [
  {
    viewId: createTestViewId('local-view-l2'),
    viewName: "View 1",
    locationId: null,
    layout: []    
  },
  {
    viewId: createTestViewId('local-view-l2'),
    viewName: "View 2",
    locationId: null,
    layout: []    
  },
]


export const localDashboardViewsOnlineDemo: DashboardView2[] = [
  {
    viewId: createTestViewId('local-view-online-demo'),
    viewName: "View 1",
    locationId: null,
    layout: []    
  },
  {
    "viewId": "37fceaa5-dd97-4d05-bf18-2096189d513d",
    "viewName": "Online demo layout",
    "layout": [
        {
            "componentName": "Consumption Widget",
            "componentDesc": "Display consumption level",
            "key": "ConsumptionWidget",
            "h": 11,
            "w": 2,
            "mW": 1.5,
            "mH": 6,
            "x": 0,
            "y": 0,
            "componentProps": {
                "config": {
                    "locationsObjectsTags": [
                        {
                            "locationId": "50533036",
                            "objectId": "2f505330-362f-5650-2f4e-2f31",
                            "tagId": "2f2f5053-3036-2f4e-315f-6d617870"
                        }
                    ]
                }
            },
            "i": "3df5cc48-232d-4467-83da-a4dd433e36b1"
        },
        {
            "componentName": "Consumption Widget",
            "componentDesc": "Display consumption level",
            "key": "ConsumptionWidget",
            "h": 11,
            "w": 2,
            "mW": 1.5,
            "mH": 6,
            "x": 2,
            "y": 0,
            "componentProps": {
                "config": {
                    "locationsObjectsTags": [
                        {
                            "locationId": "50533036",
                            "objectId": "2f505330-362f-5650-2f4e-2f31",
                            "tagId": "2f2f5053-3036-2f4e-315f-6d6178"
                        }
                    ]
                }
            },
            "i": "61439d3d-5439-414d-978f-c05e397141d6"
        },
        {
            "componentName": "Consumption Widget",
            "componentDesc": "Display consumption level",
            "key": "ConsumptionWidget",
            "h": 11,
            "w": 2,
            "mW": 1.5,
            "mH": 6,
            "x": 4,
            "y": 0,
            "componentProps": {
                "config": {
                    "locationsObjectsTags": [
                        {
                            "locationId": "50533036",
                            "objectId": "2f505330-362f-5650-2f4e-2f31",
                            "tagId": "2f2f5053-3036-2f4e-315f-6d7766"
                        }
                    ]
                }
            },
            "i": "8e7d1885-e104-4a58-9b3d-f2da11f39f73"
        },
        {
            "componentName": "Consumption Widget",
            "componentDesc": "Display consumption level",
            "key": "ConsumptionWidget",
            "h": 11,
            "w": 2,
            "mW": 1.5,
            "mH": 6,
            "x": 10,
            "y": 0,
            "componentProps": {
                "config": {
                    "locationsObjectsTags": [
                        {
                            "locationId": "50533036",
                            "objectId": "2f505330-362f-5650-2f4e-2f31",
                            "tagId": "2f2f5053-3036-2f4e-315f-6861"
                        }
                    ]
                }
            },
            "i": "1c9f7395-94c2-4ed1-9259-d0e2de5bdba5"
        },
        {
            "componentName": "Consumption Widget",
            "componentDesc": "Display consumption level",
            "key": "ConsumptionWidget",
            "h": 11,
            "w": 2,
            "mW": 1.5,
            "mH": 6,
            "x": 6,
            "y": 0,
            "componentProps": {
                "config": {
                    "locationsObjectsTags": [
                        {
                            "locationId": "50533036",
                            "objectId": "2f505330-362f-5650-2f4e-2f31",
                            "tagId": "2f2f5053-3036-2f4e-315f-6d696e"
                        }
                    ]
                }
            },
            "i": "a1d80820-004b-4b57-ab7f-2f7fd69246b0"
        },
        {
            "componentName": "Consumption Widget",
            "componentDesc": "Display consumption level",
            "key": "ConsumptionWidget",
            "h": 11,
            "w": 2,
            "mW": 1.5,
            "mH": 6,
            "x": 8,
            "y": 0,
            "componentProps": {
                "config": {
                    "locationsObjectsTags": [
                        {
                            "locationId": "50533036",
                            "objectId": "2f505330-362f-5650-2f4e-2f31",
                            "tagId": "2f2f5053-3036-2f4e-315f-6d696e70"
                        }
                    ]
                }
            },
            "i": "e196538c-1274-41ea-a963-d098ec81f6b8"
        },
        {
            "componentName": "Camera Widget",
            "componentDesc": "Display camera",
            "key": "CameraWidget",
            "h": 11,
            "w": 2,
            "mW": 1.5,
            "mH": 6,
            "x": 0,
            "y": 11,
            "componentProps": {
                "config": []
            },
            "i": "4e200acb-c51c-4d05-af67-9080a0df869a"
        }
    ],
    "locationId": "50533036"
    },
    {
        "viewId": "591afe76-0391-4065-888d-2f7425c0870e",
        "viewName": "Demo Widgets",
        "locationId": "50533036",
        "layout": [
            {
                "componentName": "Pumping Station",
                "componentDesc": "Pumping station 3d model",
                "w": 4,
                "h": 11,
                "mW": 3,
                "mH": 6,
                "key": "PumpingStation",
                "x": 4,
                "y": 0,
                "componentProps": {
                    "config": []
                },
                "i": "f0f40a15-0ee4-4688-a2c7-a293b8cca6b9"
            },
            {
                "componentName": "Radar Level Widget",
                "componentDesc": "Disaply levels",
                "key": "RadarLevelWidget",
                "h": 11,
                "w": 2,
                "mW": 1.5,
                "mH": 6,
                "x": 0,
                "y": 11,
                "componentProps": {
                    "config": {
                        "locationsObjectsTags": [
                            {
                                "locationId": "50533036",
                                "objectId": "2f505330-362f-5650-2f44-2f31",
                                "tagId": "2f2f5053-3036-2f44-315f-666c63757272",
                                "descTag": "value"
                            }
                        ]
                    }
                },
                "i": "69663ae8-7cbf-44e0-bbe6-3b81e47e9b6f"
            },
            {
                "componentName": "Camera Widget",
                "componentDesc": "Display camera",
                "key": "CameraWidget",
                "h": 11,
                "w": 2,
                "mW": 1.5,
                "mH": 6,
                "x": 8,
                "y": 0,
                "componentProps": {
                    "config": []
                },
                "i": "41ee4ace-6cf5-4c59-b5c6-f34708e0df02"
            },
            {
                "componentName": "Graph Widget",
                "componentDesc": "Display graph",
                "key": "GraphWidget",
                "h": 11,
                "w": 2,
                "mW": 1.5,
                "mH": 6,
                "x": 10,
                "y": 0,
                "componentProps": {
                    "config": {
                        "locationsObjectsTags": [
                            {
                                "locationId": "50533036",
                                "objectId": "2f505330-362f-5650-2f4e-2f31",
                                "tagId": "2f2f5053-3036-2f4e-315f-6d7766"
                            }
                        ]
                    }
                },
                "i": "3dbe2156-c524-4590-bb27-6f4dbf61d0a6"
            },
            {
                "componentName": "Consumption Widget",
                "componentDesc": "Display consumption level",
                "key": "ConsumptionWidget",
                "h": 11,
                "w": 2,
                "mW": 1.5,
                "mH": 6,
                "x": 0,
                "y": 0,
                "componentProps": {
                    "config": {
                        "locationsObjectsTags": [
                            {
                                "locationId": "50533036",
                                "objectId": "2f505330-362f-5650-2f4e-2f31",
                                "tagId": "2f2f5053-3036-2f4e-315f-6d7766"
                            }
                        ]
                    }
                },
                "i": "072df61e-a460-4af9-94ae-7b86320a755f"
            },
            {
                "componentName": "Flow Widget",
                "componentDesc": "Display flow",
                "key": "WaterWidget",
                "h": 11,
                "w": 2,
                "mW": 1.5,
                "mH": 6,
                "x": 2,
                "y": 0,
                "componentProps": {
                    "config": {
                        "locationsObjectsTags": [
                            {
                                "locationId": "50533036",
                                "objectId": "2f505330-362f-5650-2f4e-2f31",
                                "tagId": "2f2f5053-3036-2f4e-315f-6d617870"
                            }
                        ]
                    }
                },
                "i": "9ea68db9-18be-4e7f-be3f-8ade277b1166"
            },
            {
                "componentName": "Toggle Widget",
                "componentDesc": "Display toggle",
                "key": "ToggleWidget",
                "h": 11,
                "w": 2,
                "mW": 1.5,
                "mH": 6,
                "x": 3,
                "y": 11,
                "componentProps": {
                    "config": {
                        "locationsObjectsTags": [
                            {
                                "locationId": "50533036",
                                "objectId": "2f505330-362f-5650-2f4e-2f31",
                                "tagId": "2f2f5053-3036-2f4e-315f-6d7766"
                            }
                        ]
                    }
                },
                "i": "e890ceef-38b0-4e36-83be-b8d4853761b2"
            },
            {
                "componentName": "Alarms Widget",
                "componentDesc": "Alarms Description",
                "w": 5,
                "h": 12,
                "mW": 5,
                "mH": 5,
                "key": "Alarms",
                "x": 6,
                "y": 11,
                "componentProps": {
                    "config": []
                },
                "i": "ee532fd1-80da-4d60-8990-6e1e45b6a5ba"
            }
        ]
    }      
];

