<script lang="ts" setup >
import { type PropType, ref, onMounted, onBeforeUnmount } from 'vue';
import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { IS_DEVELOPMENT } from '@/config/environment';
import {createLogger } from '@/utils/logger';
const logger = createLogger();
if(!IS_DEVELOPMENT) {
  logger.setLoggerEnabled(false);
}
logger.setLoggerEnabled(false);


const props = defineProps({
  modelUrl: {
    type: String,
    required: true,
  },
  cameraPosition: {
    type: Object as PropType<{x: number, y: number, z: number}>,
    default: () => ({
        x: 0,
        y: 0,
        z: 16
    })
  },
  cameraZoom: {
    type: Number,
    default: 1
  },
  backgroundColor: {
    type: String,
    required: false
  },
  renderingLoop: {
    type: Boolean,
    default: false,
  }
})

const pumpingStationModelElement = ref<HTMLDivElement | null>(null);
let model : Partial<{ 
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
  renderer: THREE.WebGLRenderer;
  light: THREE.AmbientLight;
  controls: OrbitControls;
  handleWindowResize: (ev?: UIEvent, htmlElementBox?: {width: number, height: number}) => any;
  resizeObserver: ResizeObserver
}> = {};

onMounted(() => {
  const modelViewportWidth: number = pumpingStationModelElement.value!.offsetWidth;
  const modelViewportHeight: number = pumpingStationModelElement.value!.offsetHeight;
  

  const scene = new THREE.Scene();
  if(props.backgroundColor)  {
    scene.background = new THREE.Color(props.backgroundColor)
  }

  function createCamera() {
    const tmpCamera = new THREE.PerspectiveCamera( 75, modelViewportWidth / modelViewportHeight, 0.1, 1000 );
    //camera.position.z = 5;
    //tmpCamera.position.z = 16;
    tmpCamera.position.x = props?.cameraPosition?.x;
    tmpCamera.position.y = props?.cameraPosition?.y;
    tmpCamera.position.z = props?.cameraPosition?.z;
    tmpCamera.zoom = props.cameraZoom;
            
    tmpCamera.updateProjectionMatrix();    
    return tmpCamera;
  }

  const camera = createCamera();

  function createRenderer() {
    const tmpRenderer = new THREE.WebGLRenderer();

    tmpRenderer.outputColorSpace = THREE.SRGBColorSpace;

    tmpRenderer.setSize( modelViewportWidth, modelViewportHeight );
    tmpRenderer.setPixelRatio( window.devicePixelRatio );          
    tmpRenderer.toneMapping = THREE.ACESFilmicToneMapping;
    tmpRenderer.toneMappingExposure = 1;

    return tmpRenderer;
  }

  const renderer = createRenderer();


  pumpingStationModelElement.value!.appendChild( renderer.domElement );

  function createTestObject() {
    const geometry = new THREE.BoxGeometry( 1, 1, 1 );
    const material = new THREE.MeshBasicMaterial( { color: 0x00ff00, wireframe: false } );
    const cube = new THREE.Mesh( geometry, material );
    
    return cube;
  }
  //let pumpingStation = {};
  //scene.add( createTestObject() );


  const light = new THREE.AmbientLight(0xffffff, 100);
  scene.add(light);
  
  logger.log("Scene", scene);  

  function createControl() {
    const tmpControls = new OrbitControls( camera, renderer.domElement );
    if(!props.renderingLoop) {
      tmpControls.addEventListener( 'change', renderInAnimationFrame ); // use if there is no animation loop      
    }
    tmpControls.update()
    return tmpControls;
  }

  const controls = createControl();

  load(props.modelUrl, scene);


  //window.addEventListener('resize', handleWindowResize, false);

  function handleWindowResize(event?: Event, htmlElementBox?: {width: number, height: number}) {  
    const newModelViewportWidth = !htmlElementBox 
      ? pumpingStationModelElement.value!.offsetWidth
      : htmlElementBox.width;
    const newModelViewportHeight = !htmlElementBox 
      ? pumpingStationModelElement.value!.offsetHeight
      : htmlElementBox.height;

    camera.aspect = newModelViewportWidth / newModelViewportHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(newModelViewportWidth, newModelViewportHeight);  
  }
  
  //exporting to outside function variable
  model = {
    ...model,
    scene,
    camera,
    light,
    renderer,
    controls,
    handleWindowResize,

  };
    
  //////start load model
  function load(path, scene) {
    const loader = new GLTFLoader();
    return loader.load( path, function ( gltf ) {
      
      logger.log("Scene children: ", gltf.scene.children)
      for( let child of gltf.scene.children) {
        if(child.isMesh) {
          child.material.wireframe = true;
        }
      }

      
      let pumpingStation = gltf.scene.children[1]
      logger.log("Group", pumpingStation)

      scene.add( gltf.scene );      

      logger.log("Loaded element", gltf)
      logger.log("Current scene", scene)    
      render();

    }, undefined, function ( error ) {
      logger.error( error );    
    } );
  }
  /////end load model
  render();
  if(props.renderingLoop) {
    startAnimate();
  }

  const resizeObserver = new ResizeObserver(function(entries: ResizeObserverEntry[]) {
    const containerEntry = entries.find( (entry: ResizeObserverEntry) => entry.target === pumpingStationModelElement.value );    
    const { contentRect } = containerEntry as ResizeObserverEntry;
      
    model.handleWindowResize && model?.handleWindowResize(undefined, {height: contentRect.height, width: contentRect.width});    
  })

resizeObserver.observe(pumpingStationModelElement.value!)
model.resizeObserver = resizeObserver;

})

onBeforeUnmount(() => {
  stopAnimate()
  //window.removeEventListener('resize', model.handleWindowResize);
  disposeThreeJsResources();  
  model.resizeObserver && model.resizeObserver.unobserve(pumpingStationModelElement.value!)
})

function btnClickHandler() {
  logger.log("Camera", model.camera?.position, model.camera?.rotation, model.camera?.quaternion);  
} 

let animationRunning = false;

function animate() {  
  if(animationRunning) {
    requestAnimationFrame( animate );
  }

  if(!(animationRunning && (model?.renderer && model?.scene && model?.camera))) {
    return;
  }  
  //cube.rotation.x += 0.01;
  //cube.rotation.y += 0.01;
/*
  if(pumpingStation) {
    pumpingStation.rotation.x += 0.01;      
    pumpingStation.rotation.y += 0.01;      
  }
*/
  render();
}

function render() {
  const { renderer, scene, camera } = model;
  //@ts-ignore
  renderer.render( scene, camera );
}

function renderInAnimationFrame() {
  if(model?.renderer && model?.scene && model?.camera) {
    requestAnimationFrame(render);
  }
}

function stopAnimate() {
  animationRunning && logger.log("Animation loop stopped")
  animationRunning = false;

}

function startAnimate() {
  if(!animationRunning) {
    animationRunning = true;
    animate();  
    logger.log("Animation loop started")
  }
}

function disposeThreeJsObject(threeJsObj: any) {      
    if(threeJsObj && threeJsObj.dispose && typeof threeJsObj.dispose === 'function') {
      logger.log("Disposing...")
      threeJsObj.dispose();
    }
}

function disposeThreeJsResources() {
  Object.values(model).forEach( function(modelValue){
    if(modelValue.isScene) {
      modelValue.children
        .filter( (sceneSubObj: any) => sceneSubObj.isGroup)
        .forEach( (groupObj: any) => {          
          return groupObj.children
            .filter( (groupSubObj: any) => groupSubObj?.geometry)            
            .forEach( (groupSubObj: any) => disposeThreeJsObject(groupSubObj.geometry));                    
        });
    }

    disposeThreeJsObject(modelValue)
  })
}

const DEBUG_MODE = false;

//startAnimate();
</script>

<template>  
  <button     
    v-if="DEBUG_MODE && IS_DEVELOPMENT"
    @click="btnClickHandler"
    class="absolute mt-10 ml-4 bg-blue-500 px-3 py-1 rounded-25 text-white active:bg-blue-700">
    Print
  </button>
  <div class="h-full w-full" ref="pumpingStationModelElement"></div>
</template>
