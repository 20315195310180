<script setup lang="ts">
import { PropType } from 'vue';
import { File } from './NotesSection.vue';
import DeleteIcon from '../icons/DeleteIcon.vue';
import RenameIcon from '../icons/RenameIcon.vue';
import DownloadIcon from '../icons/DownloadIcon.vue';
import OpenNewTabIcon from '../icons/OpenNewTabIcon.vue';

const emit = defineEmits(['toggleOptionModal', 'downloadImage']);

const props = defineProps({
  item: {
    type: Object as PropType<File>,
    required: true,
  },
  isActive: Boolean,
  isFolder: Boolean,
  index: Number,
  imageUrls: Object,
});

const openImageInNewTab = (imageSrc) => {
  const win = window.open();
  win.document.write('<iframe src="' + imageSrc.url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
};

</script>

<template>
  <div
    class="sub-menu text-style"
    :class="{ active: props.isActive, subposition: props.index % 3 === 0, subFile: !isFolder}"
    style="width: 110px;"
  >
    <p
      class="option-hover flex items-center py-2"
      @click="emit('toggleOptionModal', item, 'delete', isFolder ? true : false)"
    >
      <DeleteIcon class="mx-3" /> Delete
    </p>
    <p 
      class="option-hover flex items-center py-2"
      @click="emit('toggleOptionModal', item, 'rename', isFolder ? true : false)"
    >
      <RenameIcon class="mx-3" /> Rename
    </p>
    <p
      v-if="!isFolder"
      class="option-hover flex items-center py-2"
      @click="openImageInNewTab(props.imageUrls[item.id])"
    >
      <OpenNewTabIcon class="mx-3" /> Open in new window
    </p>
    <p
      v-if="!isFolder"
      class="option-hover flex items-center py-2"
      @click="emit('downloadImage', item)"
    >
      <DownloadIcon class="mx-3" /> Download
    </p>
  </div>
</template>

<style lang="scss" scoped>
.sub-menu {
  display: none;
  position: absolute;
  top: -55px;
  right: -10px !important;
  border: 1px solid gray;
  border-radius: 5px;
  background: #404448;
  width: 90px;
  margin: 0px 5px;
  z-index: 10;
  text-align: left;
}

.text-style {
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #FFFFFF;
}

.option-hover {
  // padding: 3px;
  &:hover {
    opacity: 0.4;
    // color: #404448;
    border-radius: 5px;
    cursor: pointer;
  }
}

.active {
  display: block;
}

.subposition {
  right: -35px !important;
}

.subFile {
  width: 185px !important;
}

</style>
