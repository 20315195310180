<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import WidgetsWindow from '@/components/WidgetsWindow.vue';
import DashboardCanvas from '@/components/DashboardCanvas.vue';
import { type LayoutItem } from '@/types/widget-layout-types';
import Button from '@/components/Button.vue';
import DeleteIcon from '@/components/icons/DeleteIcon.vue';
import type { DashboardView, DashboardViewSimple, DashboardLayout, DashboardLayoutItem } from '@/models/DashboardModel';
import { useDashboardService } from '@/services/dashboardService';
import { getWidgetsList, compareWidetFactory, generateWidgetId } from '@/utils/widgets-map';
import type { WidgetDescriptor, WidgetPack } from '@/types/widgets-map-types';
import SimpleDialog from '@/components/SimpleDialog.vue';
import WidgetConfigDialog from '@/components/WidgetConfigDialog.vue';
import { useDashboardConnectedMode } from '@/composables/dashboardConnectedMode';
import { useHeaderStore } from '@/stores/header';
import { storeToRefs } from 'pinia';
import DashboardHeaderBar from '@/components/DashboardHeaderBar.vue';
import { ConsumedAction, type CommandActions, type CommandTarget } from '@/utils/ConsumedAction';
import WidgetSelect from '../WidgetSelect.vue';
import IconButton from '../IconButton.vue';
import { createLogger } from "@/utils/logger";
import { IS_DEVELOPMENT } from '@/config/environment';
import CheckmarkIcon from '../icons/CheckmarkIcon.vue';
import { onBeforeRouteLeave, useRouter } from 'vue-router';
import { useSmarthubBreakpoints } from '@/composables/smarthubBreakpoints';
import Input from '@/components/Input.vue';
const logger = createLogger("Dashboard");
if(!IS_DEVELOPMENT) {
  logger.setLoggerEnabled(false);
}
logger.setLoggerEnabled(false);



const headerStore = useHeaderStore();
const { selectedLocation } = storeToRefs(headerStore);

const dashboardService = useDashboardService();

const editMode = ref<boolean>(false);

const updatedViewName = ref<string | null>(null);

function setEditMode(active: boolean) {  
  editMode.value = active;
}

function enterEditMode() {  
  editMode.value = true;
}

function exitEditMode() {    
  editMode.value = false;  
}

const LAYOUT_COL = 12;

const updatedLayout = ref<DashboardLayout | null>(null);


function updateLayoutHandler(newLayout: DashboardLayout){  
  updatedLayout.value = newLayout;  
}

function cancelViewChanges(){
  clearViewChanges();
  exitEditMode();
}

function clearViewChanges() {
  clearChanges();
  clearLastSelectedThings();
}

function clearChanges() {
  updatedViewName.value = null;
  updatedLayout.value = null;
}

////////////////// View Layout Select
watch(editMode, (newEditModeValue, oldEditModeValue) => {
  if(oldEditModeValue == false && newEditModeValue == true) {
    editDashboardViewLayout.value = [...dashboardViewLayout.value];

    if(!updatedViewName.value && selectedDashboardView.value) {
      updatedViewName.value = selectedDashboardView.value.viewName;
    }

  }else if(oldEditModeValue == true && newEditModeValue == false) {
    editDashboardViewLayout.value = [];
  }
  });

const dashboardViewsSimpleList = ref<DashboardViewSimple[]>();
const selectedDashboardViewSimple = ref<DashboardViewSimple | null>(null);
const selectedDashboardView = ref<DashboardView | null>(null);
const dashboardViewLayout = computed(() => selectedDashboardView.value?.layout ?? []);
const editDashboardViewLayout = ref<(DashboardLayoutItem & LayoutItem)[]>([]);


async function fetchDashboardViews() {
  const result = dashboardViewsSimpleList.value = await dashboardService.getDashboardViews(selectedLocation.value);
  if(selectedDashboardViewSimple.value) {
    const selectedViewId = selectedDashboardViewSimple.value?.viewId;
    const simpleDashboardViewInList = dashboardViewsSimpleList.value!.find( viewListItem => viewListItem.viewId == selectedViewId)
    if(simpleDashboardViewInList) {
      selectedDashboardViewSimple.value.viewName = simpleDashboardViewInList?.viewName;
    } else {
      selectedDashboardViewSimple.value = null;
    }
  }
  return result;
}

async function fetchSelectedDashboardView(viewId: string) {
  selectedDashboardView.value = await dashboardService.getDashboardView(selectedLocation.value, viewId)    
}

onMounted(fetchDashboardViews)
watch(() => selectedLocation.value, async () => {  
  exitEditMode();
  await nextTick();
  viewLayoutSelectedHandler(null);     
  await nextTick();
  return await fetchDashboardViews();
})

const toClickedPath = ref<string | null>(null);
const router = useRouter();
onBeforeRouteLeave((to, from, next) => {
	if (editMode.value && !toClickedPath.value) {
		leaveDialogOpen.value = true;
		next(false);
		toClickedPath.value = to.fullPath;
	} else {
		next();
	}
});

watch( () => selectedDashboardViewSimple.value, async (newDashboardSimple, oldDashboardSimple) => {
  logger.log("Selected View changed - (current, previous)", newDashboardSimple?.viewId, oldDashboardSimple?.viewId )
  if(newDashboardSimple != null) {    
    logger.log("Selected View changed - fetching Dashboard View");
    await fetchSelectedDashboardView(newDashboardSimple.viewId);
  } else {
    selectedDashboardView.value = null;
  }
}, {
  immediate: true
});

function viewLayoutSelectedHandler(layoutViewItem: DashboardViewSimple | null){
  selectedDashboardViewSimple.value = layoutViewItem;
}
  


const preCommands = new ConsumedAction<CommandTarget, CommandActions<CommandTarget>>();
const postCommands = new ConsumedAction<CommandTarget, CommandActions<CommandTarget>>();

async function createViewHandler() {
  const createdDashboardView = await dashboardService.createDashboardView(selectedLocation.value, null);
  clearViewChanges();
  await fetchDashboardViews();
  
  viewLayoutSelectedHandler(dashboardViewsSimpleList.value!.find( viewListItem => viewListItem.viewId == createdDashboardView.viewId) as DashboardViewSimple)  
  await nextTick();

  preCommands.set('dashboard', 'enter-edit-mode')  
}

watch([() => editMode.value, () => selectedDashboardView.value], ([newEditMode, newSelectedDashboardView]) => {  
  if(newEditMode == false && newSelectedDashboardView != null && preCommands.has('dashboard')) {
    const cmd = preCommands.consume('dashboard');
    if(cmd == 'enter-edit-mode') {
      enterEditMode();      
      postCommands.set('name-input-field', 'focus' );
    }
  }

}, { flush: 'pre'});

async function deleteViewHandler() {
  if(selectedDashboardView.value){
    await deleteView();  
    clearViewChanges()
    exitEditMode();
    await nextTick();
    viewLayoutSelectedHandler(null);
    await fetchDashboardViews();    

    closeDeleteDialog();
  }
}


async function deleteView() {
  if(selectedDashboardView.value) {
    return await dashboardService.deleteDashboardView(selectedLocation.value, selectedDashboardView.value);
  }
}

async function saveViewHandler() { 
  if(selectedDashboardView.value) { 
    await saveView();
    clearViewChanges();

    await fetchDashboardViews();
    if(selectedDashboardViewSimple.value) {      
      await fetchSelectedDashboardView(selectedDashboardViewSimple.value.viewId);
    }

    exitEditMode();
  }
}

async function saveView(){
  if(selectedDashboardView.value) {
    let viewToSave = { 
      ...selectedDashboardView.value
    };

    if(updatedLayout.value) {
      viewToSave.layout = updatedLayout.value;
    }

    if(updatedViewName.value && updatedViewName.value != selectedDashboardView.value.viewName) {
      viewToSave.viewName = updatedViewName.value;
    }
    
    await dashboardService.updateDashboardView(selectedLocation.value, viewToSave);    
  }
}


function copyViewHandler() {

}
////////////
//////////// Widget Select
const lastSelectedWidgetPack = ref<WidgetPack | null>(null);
const lastWidgetConfigObject = ref<WidgetDescriptor['componentProps'] | null>(null);

function clearLastSelectedThings() {
  lastWidgetConfigObject.value = null;
  lastSelectedWidgetPack.value = null;
}

function configChangeHandler(configObj: object) {
  logger.log("Config change", configObj);
  lastWidgetConfigObject.value = configObj;
}


const widgetItems = computed(() => [...getWidgetsList()]);

function widgetConfigDialogShouldBeShown(): boolean {
  return !!(lastSelectedWidgetPack.value?.configContent && lastSelectedWidgetPack.value?.configContent?.shouldShowConfigDialog?.(selectedLocation.value))
}

function addWidgetHandler(widgetPack: WidgetPack) {  
  if(editMode.value && selectedDashboardView.value) {
    lastSelectedWidgetPack.value = widgetPack;
    if(!widgetConfigDialogShouldBeShown()) {
      addWidget(widgetPack);
      clearLastSelectedThings();
    }else {
      openWidgetConfigDialog();
    }    
  }
}

function calculateNumOfColumns() {
  const windowWidth = window.innerWidth;
  if(windowWidth < 480) {
    return 2;
  }else if(windowWidth < 768) {
    return 4;    
  }else if(windowWidth < 996) {
    return 6;
  }else if(windowWidth < 1200) {
    return 8;
  }else {
    return 12;
  }
}

function addWidget(widgetPack: WidgetPack) {
  if(editMode.value && selectedDashboardView.value) {    
    const { descriptor: widgetDescriptor } = widgetPack;

    const layoutCol = calculateNumOfColumns();
    const editLayout = editDashboardViewLayout.value;
    const tempWidgetDescriptor = {
      ...widgetDescriptor,
      x: (editLayout.length * 2) % (layoutCol || 12),
      y: (editLayout.length + (layoutCol || 12)),
      w: widgetDescriptor.w,
      h: widgetDescriptor.h,
      componentProps: {        
        ...(widgetDescriptor?.componentProps ?? {}),        
        ...(lastWidgetConfigObject.value ?? {}),
        config: {
          ...(widgetDescriptor?.componentProps?.config ?? {}),
          ...(lastWidgetConfigObject.value?.config ?? {})
        }
      },
      i: generateWidgetId()
    } 
    editDashboardViewLayout.value.push(tempWidgetDescriptor as (WidgetDescriptor & LayoutItem))
  }

}

function addWidgetWidgetConfigDialogHanlder(){
  if(editMode.value && selectedDashboardView.value && lastSelectedWidgetPack.value) {    
    addWidget(lastSelectedWidgetPack.value);
    closeWidgetConfigDialog();
  }  
}

function removeWidgetHandler(widgetDescriptor: LayoutItem) {  
  if(editMode.value) {
    const widgetInstanceId = widgetDescriptor.i;
    const index = editDashboardViewLayout.value.findIndex(item => item.i == widgetInstanceId);
    logger.log("Deleting widget with index", index);
    editDashboardViewLayout.value.splice(index,1);
  }
}
///////
/// Dialogs
const deleteDialogOpen = ref<boolean>(false);
const leaveDialogOpen = ref<boolean>(false);

function openDeleteDialog() {
  deleteDialogOpen.value = true;
}

function closeDeleteDialog() {
  updatedViewName.value = null;
  deleteDialogOpen.value = false;
}

const widgetConfigDialogOpen = ref<boolean>(false);

function openWidgetConfigDialog() {  
  headerStore.disableSelectLocation();  
  widgetConfigDialogOpen.value = true;
}

function closeWidgetConfigDialog() {
  widgetConfigDialogOpen.value = false;
  clearLastSelectedThings();
  headerStore.enableSelectLocation();
}

function confirmLeave() {
  leaveDialogOpen.value = false;
  router.push(`${toClickedPath.value}`);
}

function cancelLeave() {
  leaveDialogOpen.value = false;
}


const renameDialogOpen = ref<boolean>(false);
function openRenameDialog() {
  renameDialogOpen.value = true;
}

function closeRenameDialog() {
  renameDialogOpen.value = false;
}

function openRenameDialogHandler() {
  openRenameDialog();
  updatedViewName.value = selectedDashboardView.value?.viewName ?? "";
}

function renameViewHandler() {
  saveViewHandler();
  closeRenameDialog();  
}

/////////////////// Connected mode //////////////////////
async function enterConnectedMode() {  
  return await startConnectedMode();  
}

async function exitConnectedMode() {  
  return await stopConnectedMode();
}

const { startConnectedMode, stopConnectedMode, connectedMode, connectedModeLoading, dataFromDate } = useDashboardConnectedMode();
////////////////// End Connected mode //////////////////
const isGlobalDashboardView = computed(() => !!selectedLocation.value)

////////////////////// Responsive /////////////////////
const { isMobile } = useSmarthubBreakpoints();
///////////////////// End Responsive //////////////////

</script>
<template>  
  <WidgetsWindow        
    name="center control"
    title="Weather dash"
    :edit-mode="editMode"
    @edit-mode-changed="setEditMode"
    >
    <template #window-bar>
      <DashboardHeaderBar 
        :edit-mode="editMode"
        :connected-mode="connectedMode"
        :connected-mode-loading="connectedModeLoading"
        :dashboard-views-simple-list="dashboardViewsSimpleList"              
        :selected-dashboard-view-simple="selectedDashboardViewSimple"
        :selected-dashboard-view="selectedDashboardView"
        :selected-location="selectedLocation"
        @enter-edit-mode="enterEditMode"
        @exit-edit-mode="exitEditMode"
        @enter-connected-mode="enterConnectedMode"
        @exit-connected-mode="exitConnectedMode"
        @create-view="createViewHandler"
        @delete-view="openDeleteDialog"
        @copy-view="copyViewHandler"
        @save-view="saveViewHandler"
        @rename-view="openRenameDialogHandler"
        @cancel-view-changes="cancelViewChanges"
        @view-selected="viewLayoutSelectedHandler"
        @add-widget="addWidgetHandler"
        :widget-items="widgetItems"              
        @update:updated-view-name="updatedViewName = $event"
        :data-from-date="(dataFromDate as string)"
        :postCommands="postCommands"
      />
    </template>
    <template #content> 
      <DashboardCanvas 
        :layouts="!editMode ? dashboardViewLayout : editDashboardViewLayout" 
        :is-global-dashboard-view="isGlobalDashboardView"
        :edit-mode="editMode" 
        :connected-mode="connectedMode"
        @layout-updated="updateLayoutHandler" 
        @widget-deleted="removeWidgetHandler"
      />
    </template>
    <template  #default>
    <div v-if="isMobile && editMode"  class="fixed right-3.5 bottom-20">
      <WidgetSelect 
        class="fab-drop-down"
        :items="widgetItems"
        :header-component="IconButton"
        :drop-down-position="'bottom-right'"
        :drop-down-shadow="'0px 5px 10px 2px rgba(0, 0, 0, 0.3)'"
        @widget-selected="addWidgetHandler"
      />
      </div>
    </template>
  </WidgetsWindow>
  <SimpleDialog
    v-if="deleteDialogOpen"    
    class="z-10"              
    title="Are you sure you want to delete this view?">
    <template #buttons>
      <Button @click="closeDeleteDialog">Cancel</Button>
      <Button 
        :background-color="'#FC5F58'" 
        :border-color="'#FC5F58'" 
        :background-active-color="'rgb(253, 127, 121)'" 
        :border-active-color="'rgb(253, 127, 121)'"
        :icon-component="DeleteIcon"
        @click="deleteViewHandler"
      >Delete</Button>
    </template>
  </SimpleDialog>
  <WidgetConfigDialog
    class="z-10"
    v-if="widgetConfigDialogOpen && widgetConfigDialogShouldBeShown()"    
    v-bind="lastSelectedWidgetPack?.configContent?.configDialogProps"
    @close="closeWidgetConfigDialog"
    @cancel="closeWidgetConfigDialog"
    @add="addWidgetWidgetConfigDialogHanlder"    
  >
    <template #content="{onAddAllowed}">
      <component 
        :is="lastSelectedWidgetPack?.configContent?.configContentComponent" 
        v-bind="lastSelectedWidgetPack?.configContent?.configContentProps ?? {}" 
        @config-change="configChangeHandler"
        :onAddAllowed="onAddAllowed"
      />
    </template>
  </WidgetConfigDialog> 
  <SimpleDialog v-if="leaveDialogOpen" title="Are you sure you want to leave this view?">
    <template #buttons>
      <Button @click="cancelLeave">Cancel</Button>
      <Button 
        @click="confirmLeave" 
        :icon-component="CheckmarkIcon"
        :background-color="'#15b1ca'"
        :background-active-color="'#66dcef'">Confirm</Button>
    </template>
  </SimpleDialog>
  <SimpleDialog
    v-if="renameDialogOpen"    
    class="z-10 rename-dialog"              
    title="Rename view"
    dialog-height="140px"
    >
    <template #content>
      <div class="my-3 w-50">
        <Input             
          class="rename-input"  
          v-model="updatedViewName"
        />
      </div>
    </template>
    <template #buttons>
      <Button                
        @click="closeRenameDialog">Cancel</Button>
      <Button                 
        :background-color="'#FC5F58'" 
        :border-color="'#FC5F58'" 
        :background-active-color="'rgb(253, 127, 121)'" 
        :border-active-color="'rgb(253, 127, 121)'"        
        @click="renameViewHandler"
      >Rename</Button>
    </template>
  </SimpleDialog>
</template>

<style scoped lang="scss">
.editable::selection {
  background-color: rgb(21,177, 202, 0.75);
}

.fab-drop-down:deep(.select-header-container) {
  width: 46px;
  height: 46px;
}

.fab-drop-down:deep(.select-header-container svg) {
  height: 14px;
  width: 14px;
}

.rename-input.smarthub-input-container {
  @apply w-full;      
}

/** For some reason :deep didn't worked here ... */
:global(.rename-dialog .dialog-container){
  @apply py-1.5;
}

</style>