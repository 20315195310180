import { defineStore } from "pinia";
import { ref, computed, watch, toRaw } from 'vue';
import { httpClient } from '../utils/httpClient';
import { AUTH_URL, CLIENT_ID, CLIENT_SECRET, IS_DEVELOPMENT } from "@/config/environment";
import type { TokenData } from "@/services/TokenService";
import { tokenService, isTokenExpired as isTokenExpiredUtil } from "@/services/TokenService";
import { useLoading } from "@/composables/loading";
import { createLogger } from "@/utils/logger";
import type { AxiosError } from "axios";
import { LastValueEmitter } from "@/utils/LastValueEmitter";
import { nextTick } from 'vue';
import EventEmitter from "events";
import { callbackFactory } from "@/utils/callback-factory";
const logger = createLogger("Auth");
if(!IS_DEVELOPMENT) {
  logger.setLoggerEnabled(false);
}
logger.setLoggerEnabled(false);
type SmarthubAxiosAuthError = AxiosError<{error: string}>;


export const authEvents = new LastValueEmitter();

export const BEFORE_LOGIN_EVENT = 'before-login';
export const LOGGED_IN_EVENT = 'logged-in';
export const BEFORE_LOG_OUT_EVENT = 'before-log-out';
export const LOGGED_OUT_EVENT = 'logged-out';

export const useAuthStore = defineStore('auth', () => {  
  const isInitialized = ref(false);    

  const isLogedinEventSent = ref(false);
  const isBeforeLoginEventSent = ref(false);
  const isLogoutEventSent = ref<boolean | null>(null);
  const isBeforeLogoutEventSent = ref<boolean | null>(null);
  
  const authData = ref<TokenData | null>(null);
  const isAuthenticated  = computed( () => {
    return authData.value != null
  });
  
  const refreshTokenSubscribers: EventEmitter = new EventEmitter();
  type OpState = 'pending' | 'settled';
      
  const refreshTokenStateObj : { state: OpState} = {
    state: 'settled'
  }  


  watch( () => isLogedinEventSent.value, (newIsLoggedInValue) => {
    if(newIsLoggedInValue == true) {
      isBeforeLogoutEventSent.value = false;
      isLogoutEventSent.value = false;
    }
  })

  const { loading, setLoading, errors, setErrors, clearErrors } = useLoading<any>();  


  async function isLoggedIn(checkTokenExpired: boolean = false): Promise<boolean> {
    if(!isInitialized.value) {
      regenerateStore();

    }

    if(authData.value && checkTokenExpired) {
      if( isTokenExpiredUtil(authData.value?.expires as number)) {
        await refreshToken();
      }
    }

    if(isInitialized.value && authData.value != null && !isLogedinEventSent.value) {      
      await nextTick();
      if(!isBeforeLoginEventSent.value) {
        setTimeout( () => authEvents.emit(BEFORE_LOGIN_EVENT), 0);
        isBeforeLoginEventSent.value = true;
      }
      if(!isLogedinEventSent.value) {
        setTimeout( () => authEvents.emit(LOGGED_IN_EVENT), 0 )      
        isLogedinEventSent.value = true;
      }
    }

    return isAuthenticated.value;
  }

  function setTokenData(tokenData: TokenData) {
    logger.log("Token data", tokenData);
    authData.value = {
        ...toRaw(authData.value),
        ...tokenData
    };
    tokenService.setTokenData(tokenData);
    if(!isInitialized.value) {
      isInitialized.value = true;
    }
  }

  function handleError<T extends Error>(error: T | string) {
    function isAxiosError(err: any): err is SmarthubAxiosAuthError {      
      return !!(err?.response?.data?.error) ;
    }

    let errorMessage ;
    if(typeof error == 'string') {
      errorMessage = error;
    }else if(typeof error == 'object') {
      if(isAxiosError(error)) {
        if(isRefreshTokenExpirationError(error)) {
          errorMessage = "Refresh token expired";
        } else {
          errorMessage = error?.response?.data.error
        }
      }else if(error instanceof Error) {
        errorMessage = error.message;
      }else {
        errorMessage = error;
      }
    }
    
    logger.error("Auth", errorMessage);
    setErrors(errorMessage);    
  }

  async function login(username: string, password: string): Promise<boolean> {
    logger.log("Login started")
    clearErrors(); 
    setLoading(true);

    if(await isLoggedIn()) {
      logger.log("User already logged in");
      return false;
    } 

    try {
      if(!isBeforeLoginEventSent.value) {
        setTimeout(() => authEvents.emit(BEFORE_LOGIN_EVENT), 0);
        isBeforeLoginEventSent.value = true;
      }

      const tokenData = await httpClient.post(AUTH_URL, {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: 'password',
        //username: encodeURIComponent(username),
        username: username,
        password: password
        
      }, {
        headers: {
          "Content-Type": 'application/x-www-form-urlencoded'
        }
      })

      setTokenData(tokenData.data);
      logger.log("Login finished")      
      
      if(!isLogedinEventSent.value) {
        setTimeout( () => authEvents.emit(LOGGED_IN_EVENT), 0);
        isLogedinEventSent.value = true;
      }
      return true;
    }catch(error) {
      logger.log("Login error");
      handleError(error as Error);          
      throw error;    
    }finally {
      setLoading(false);
    }

  }

  function logout(rediretToLoginScreen: boolean = false) {
    setLoading(true);        

    if(isBeforeLogoutEventSent.value !== true) {
      setTimeout(() => authEvents.emit(BEFORE_LOG_OUT_EVENT), 0);
      isBeforeLogoutEventSent.value = true;
    }

    logger.log("Loging out started")
    clearErrors();    
    clearAuthData();
    setLoading(false);
    
    logger.log("Loging out finished")
  }

  async function isTokenExpired(): Promise<boolean> {
    if(await isLoggedIn()) {
      return isTokenExpiredUtil(authData.value?.expires as number)
    }

    const err = "User not logged in: isTokenExpired()"    
    handleError(err);
    setLoading(false);
    throw new Error(err)
  }

  function regenerateStore() {
    logger.log("Regenerate store started")
    if(tokenService.hasToken()){
      const tokenData = tokenService.getTokenData();
      if(tokenData != null) {
        authData.value = tokenData;
      }

    }

    if(!isInitialized.value) {
      isInitialized.value = true;
    }
  }
  const refreshTokenExpirationHandler = handleRefreshTokenExpirationFactory((err) => {    
    clearAuthData();
    handleError(err);    
  }, false);

  async function refreshToken(forceRefresh: boolean = false): Promise<boolean> {
    logger.log("Refresh token started")
    clearErrors();
    setLoading(true);

    if(! (await isLoggedIn())) {
      const err = "Refresh token - user must be logged in";
      handleError(err);    
      setLoading(false);
      throw new Error(err);
    }
    
    if(await isTokenExpired() || forceRefresh) {          
      return new Promise(function(resolve, reject) {
        if(refreshTokenStateObj.state === 'pending') {
          refreshTokenSubscribers.once('result', callbackFactory(resolve, reject))
          return;
        } else {
          refreshTokenStateObj.state = 'pending';

          return httpClient.post(AUTH_URL, {
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            grant_type: 'refresh_token',
            refresh_token: authData.value?.refresh_token        
          }, {
            headers: {
              "Content-Type": 'application/x-www-form-urlencoded'
            }
          })
          .then( tokenData => {
            setTokenData(tokenData.data)            
            logger.log("Refresh token finished");

            resolve(true);
            refreshTokenSubscribers.emit('result', null, true)
          }).catch(error => {
            if(isRefreshTokenExpirationError(error)) {
              refreshTokenExpirationHandler(error);          
            } else {
              logger.log("Refresh token error");          
              handleError(error as Error);          
            }
            
            reject(error);      
            refreshTokenSubscribers.emit('result', error, null);
          })
          .finally(() => {
            refreshTokenStateObj.state = 'settled';
            setLoading(false);
          })
        }
      });      
    } else {
      logger.log("Refresh token still not expired")
      setLoading(false);
      return false;
    }

  }

  function isRefreshTokenExpirationError(error: any | AxiosError): error is AxiosError {
    return error.response && 
      error.response.data && 
      error.response.status && 
      error.response.status == 403 &&
      error.response.data?.error == "Access token is not valid";
  }

  function handleRefreshTokenExpirationFactory(callbackAction: (((error: any) => void) | null), throwError: boolean = true){
    return function (error: any | AxiosError): never | void {
      if(error?.response?.status == 403 && error?.response?.data?.error == "The refresh token is invalid.") {
        clearAuthData();        
        callbackAction && callbackAction(error);
      }

      if(throwError) {
        throw error;
      }
    }
  }


  async function getAccessToken(): Promise<string | null> {
    if(! (await isLoggedIn())){      
      return null;
    }

    if(await isTokenExpired()) {
      await refreshToken();
    }

    return `${authData.value?.token_type} ${authData.value?.access_token}` as string | null;     
  }

  function getAuthData() {
    if(authData.value == null) {
      return null;
    }

    return {
      ...authData.value
    }
  }

  function clearAuthData(){
    tokenService.clearTokenData();
    authData.value = null;
    isInitialized.value = false;
    isLogedinEventSent.value = false;
    isBeforeLoginEventSent.value = false;
    
    if(isLogoutEventSent.value !== true) {
      setTimeout( () => authEvents.emit(LOGGED_OUT_EVENT), 0);
      isLogoutEventSent.value = true;
    }
  }


  return {
    loading,    
    isLoggedIn,    
    isAuthenticated,
    getAccessToken,
    refreshToken,
    login,
    logout,    
    errors,
    clearErrors,

    getAuthData,
    clearAuthData,
    isRefreshTokenExpirationError,
    handleRefreshTokenExpirationFactory
  };

});
