import { usePreferencesApi, type IPreferencesApi } from "@/api/preferencesApi";
import { type IKeyValueStorage } from "./KeyValueBrowserStorage";
import type { APP_KEYS, DashboardPreferenceResponse, PREF_KEYS, PreferenceResponse } from "@/api/dtos/PreferenceDto";
import { DASHBOARD_APP_KEY } from "@/api/dtos/PreferenceDto";



export class KeyValuePreferenceStorage<
    APPLICATION_KEY extends APP_KEYS, 
    PREFERENCE_KEYS extends PREF_KEYS<APPLICATION_KEY>
  > implements IKeyValueStorage {

  private preferenceApi: IPreferencesApi;
  readonly PREFERENCE_APPLICATION_KEY: APPLICATION_KEY;
  constructor(applicationKey: APPLICATION_KEY){        
    this.PREFERENCE_APPLICATION_KEY = applicationKey
    this.preferenceApi = usePreferencesApi();
  }

  async setItem(key: string, value: any): Promise<void> {    
    const result = await this.preferenceApi.createOrUpdateApplicationPreference(
      this.PREFERENCE_APPLICATION_KEY,
      encodeURIComponent(key),
      value
    ) as PreferenceResponse<APPLICATION_KEY, PREFERENCE_KEYS>;
  }
  async getItem(key: string): Promise<any | null> {    
    const preferenceItem = await this.preferenceApi.getPreferenceForApplicationPreferenceKey(
      this.PREFERENCE_APPLICATION_KEY,
      encodeURIComponent(key)
    ) as DashboardPreferenceResponse;
    return preferenceItem.preference?.[key];
  }
  async removeItem(key: string): Promise<boolean> {
    await this.preferenceApi.removeApplicationPreference(
      this.PREFERENCE_APPLICATION_KEY,
      encodeURIComponent(key)
    );
    
    return true;
  }

}

export function createDashboardPreferenceStorage() {
  return new KeyValuePreferenceStorage(DASHBOARD_APP_KEY);
}