<script setup lang="ts">
import { PropType, computed, onMounted, ref } from 'vue';
import important_msg from '../../public/assets/overlay/important_msg.svg';
import { onClickOutside } from '@vueuse/core';
import { AlarmsInfoModel } from '../../../smarthub/src/models/AlarmsInfoModel';

interface Notification {
	NotificationType: string;
}

const props = defineProps({
  isWelcomeOverlayOpen: {
    type: Boolean,
    required: true,
  },
  userName: {
    type: String,
    required: true,
  },
  alarmsInfo: {
    type: Object as PropType<AlarmsInfoModel>,    
    default: () => ({
      urgentAlarms: [],
      nonurgentAlarms: []
    })
  },  
  loading: {
    type: Boolean,
    default: false    
  }
});

const emit = defineEmits(['close', 'openUrgentAlarms', 'openNonUrgentAlarms']);
const handleClose = () => emit('close');
const handleOpenUrgentAlarms = () => {
  emit('openUrgentAlarms');
  emit('close');
};
const handleOpenNonUrgentAlarms = () => {
  emit('openNonUrgentAlarms');
  emit('close');
};
const welcomeOverlayTitle = ref(null);

onMounted(() => {
  onClickOutside(welcomeOverlayTitle, handleClose);
});

const nonurgentAlarmsCount = computed(() => props.alarmsInfo.nonurgentAlarms.length ?? 0);
const urgentAlarmsCount = computed( () => props.alarmsInfo.urgentAlarms.length ?? 0);

type PartOfTheDay = 'morning' | 'afternoon' | 'evening';

function calculatePartOfTheDay(): PartOfTheDay {
  const currentDate = new Date();

  const currentHours = currentDate.getHours();
  const isMorning = currentHours < 12;
  const isAfterNoon = currentHours >= 12 && currentHours < 17;
  const isEvening = currentHours >= 17;

  if (isMorning) {
    return 'morning';
  } else if (isAfterNoon) {
    return 'afternoon';
  } else {  
    return 'evening';
  }
}

function getPartOfDayGreeting() {
  const partOfTheDay = calculatePartOfTheDay();
  //localized strings should be returned here
  switch (partOfTheDay) {
  case 'morning': return 'Good morning';
  case 'afternoon': return 'Good afternoon';
  case 'evening': return 'Good evening';
  }
}

</script>

<template>
  <div
    v-if="isWelcomeOverlayOpen" class="fixed top-0 left-0 z-[100] w-full h-full">
    <div
      class="flex flex-col z-50 w-full h-full mx-auto border border-gray-600 text-white welcome-overlay-bg"
    >
      <div class="flex justify-center flex-col flex-1 p-4 text-gray-100">
        <div class="flex-row text-center px-3 mt-8 mb-4 m-auto">
          <div v-if="!props.loading">
            <img :src="important_msg" class="m-auto" alt="important message icon"/>
            <div class="text-[21.8px] mt-[0.5rem] mb-[1rem] max-sm:mb-[4rem]">
              Important Message
            </div>
          </div>
          <div ref="welcomeOverlayTitle" class="welcome-overlay-title">
            <h1 v-if="!props.loading">
              {{ getPartOfDayGreeting() }} {{ userName }}, you have
              <span
                v-if="urgentAlarmsCount > 0"
                @click="handleOpenUrgentAlarms"
                class="inline-block relative cursor-pointer alarms-underline"
              >
                {{ urgentAlarmsCount }} urgent alarm{{ urgentAlarmsCount > 1 ? "s" : "" }}
              </span>
              <span v-if="nonurgentAlarmsCount > 0">
                <span v-if="urgentAlarmsCount > 0 && nonurgentAlarmsCount > 0">
                  and
                </span>
                <span
                  @click="handleOpenNonUrgentAlarms"
                  class="inline-block relative cursor-pointer communication-error-underline"
                >
                  {{ nonurgentAlarmsCount }} non-urgent alarm{{ nonurgentAlarmsCount > 1 ? "s" : "" }}
                </span>
              </span>
              to attend to
            </h1>
            <h1 v-else-if="!loading && (nonurgentAlarmsCount + urgentAlarmsCount == 0)">
              {{ getPartOfDayGreeting() }} {{ userName }}, everything is fine in all 500 pumpstations.
            </h1>
            <h2 v-else>
              Loading of notification data in progress. <br /> Please wait ...
            </h2>            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.welcome-overlay-bg {
	background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.welcome-overlay-title {
	width: 1068px;

  @media (max-width: 1200px) {
    width: auto;
  }

	h1 {
		color: #ffffff;
		font-size: 44.8px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 61px;
		text-align: center;

    @media (max-width: 564px) {
        font-size: 29.8px;
        line-height: 41px;
    }
	}

  h2 {
		color: #ffffff;
		font-size: 30.8px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 50px;
		text-align: center;

    @media (max-width: 564px) {
        font-size: 17.8px;
        line-height: 29x;
    }
	}

}

.pop-enter-active,
.pop-leave-active {
	transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter-from,
.pop-leave-to {
	opacity: 0;
	transform: scale(0.3) translateY(-50%);
}

.alarms-underline {
	&::after {
		position: absolute;
		content: '';
		width: 100%;
		height: 4px;
		left: 0;
		bottom: 0;
		background-color: #fe1717;
	}
}

.communication-error-underline {
	&::after {
		position: absolute;
		content: '';
		width: 100%;
		height: 4px;
		left: 0;
		bottom: 0;
		background-color: #c67f38;
	}
}
</style>
