<template>
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_918_5745)">
            <path
                d="M5.30558 7.27935L8.03753 4.5474L8.67383 5.1837L4.85558 9.00195L4.53743 8.6838L1.03733 5.1837L1.67363 4.5474L4.40558 7.27935L4.40558 0.00195312H5.30558L5.30558 7.27935Z"
                fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_918_5745">
                <rect width="9" height="9" fill="white" transform="matrix(-1 0 0 -1 9.35547 9.00195)" />
            </clipPath>
        </defs>
    </svg>
</template>