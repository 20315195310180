import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useLocationAlarmsService } from '@/services/locationAlarmsService'
import type { LocationAlarmsModel } from '@/models/AlarmMode';
import { useStoreAuthEvents } from '@/composables/storeAuthEvents';
import { AlarmPriority, type AlarmDto } from '@/api/dtos/AlarmDto';
import { type AlarmsInfoModel } from '@/models/AlarmsInfoModel';

export const useLocationAlarmsStore = defineStore('locationAlarms', () => {
  const locationAlarmsService = useLocationAlarmsService();
  const locationAlarms = ref<LocationAlarmsModel[]>([]);
  const locationAlarmsInitalized = ref(false);  

  const loading = computed(() => locationAlarmsService.loading.value)  
  const errors = computed(() => locationAlarmsService.errors.value)


  const alarmsInfo = computed<AlarmsInfoModel>( () => {
    const nonurgentAlarms: AlarmDto[] = [];
    const urgentAlarms: AlarmDto[] = [];
    
    locationAlarms.value
      .map( la => la.alarms)
      .flat()
      .forEach(a => {
        if(a.priorityid == AlarmPriority.NON_URGENT_ALARM) {
          nonurgentAlarms.push(a);
        } else if(a.priorityid == AlarmPriority.URGENT_ALARM) {
          urgentAlarms.push(a);
        }
      });

      return {
        nonurgentAlarms,
        urgentAlarms
      }
  });



  function clearErros() {
    locationAlarmsService.clearErrors();
  }

  async function fetchLocationsAlarmsIfNotAlready() {
    if(!locationAlarmsInitalized.value) {
      return fetchLocationAlarms();
    }
  }

  function fetchLocationAlarms() {
    locationAlarmsInitalized.value = true;
    return locationAlarmsService.getAlarms()
      .then( response => {
        locationAlarms.value = response
        
      })
      .catch( error => {
        console.error(error);
        locationAlarmsInitalized.value = false;
      });
  }

  function refetchLocationAlarms() {
    clearErros();
    fetchLocationAlarms();    
  }

  useStoreAuthEvents({
    loggedIn() {
      return fetchLocationsAlarmsIfNotAlready()
    },
    loggedOut() {
      locationAlarmsInitalized.value = false;
      locationAlarms.value = [];

    }
  });


  return {
    locationAlarms,
    fetchLocationsAlarmsIfNotAlready,
    refetchLocationAlarms,
    loading,
    errors,
    clearErros,
    alarmsInfo
  }
})
