import { useAuthStore } from "@/stores/auth";
import { httpClient } from "@/utils/httpClient";
import { useRouter } from 'vue-router';
import { ROUTE_PATHS } from "@/constants/routes-paths";
import { AxiosError, type AxiosRequestConfig, type AxiosResponse } from "axios";
import { useLoading } from "./loading";

export function useApi() {
  const auth = useAuthStore();
  const router = useRouter();
  const { loading, setLoading, errors,  setErrors, clearErrors } = useLoading();


  function refreshTokenExpiredAction() {
    auth.clearAuthData();
    router.push(ROUTE_PATHS.LOGIN);      
  }

  function resolveRequestDataPaylod<RES_T = any, REQ_T = any>(data: REQ_T, config: AxiosRequestConfig<REQ_T> = {}) {
    if(data) {
      const { data: configObjData = {} } = data;
      return typeof data == 'object'
        ? ({
          ...data,
          ...configObjData
        })
        : data;
    }else if(config?.data) {
      return typeof config.data == 'object'
        ? ({
          ...config.data
        })
        : config.data

    }else {
      return null;
    }    
  }
  
  
  async function performFetch<RES_T = any, REQ_T = any>(    
    method: string,
    url: string, 
    data: REQ_T,
    config: AxiosRequestConfig<REQ_T> = {}): Promise<AxiosResponse<RES_T, REQ_T>> {
      const token = await auth.getAccessToken().catch(auth.handleRefreshTokenExpirationFactory(refreshTokenExpiredAction));
      if(!token) {
        router.push(ROUTE_PATHS.LOGIN);
      }
  
      const { 
        headers: configObjHeaders = {}, 
        data: configObjData = {},        
        ...restConfigObj
      } = config;
      
      return httpClient({
          method,
          url,
          data: resolveRequestDataPaylod(data, config),
          headers: {
            Authorization: token,
            ...configObjHeaders          
          },
          ...restConfigObj
        })
  }

  async function fetch<RES_T = any, REQ_T = any>(
    method: string,
    url: string, 
    data: REQ_T,
    config: AxiosRequestConfig<REQ_T> = {}): Promise<RES_T> {
      setLoading(true);
      clearErrors();
    
      return performFetch<RES_T, REQ_T>(method, url, data, config)
        .then( response => response.data)              
        .catch(async function (error) {                    
          if(auth.isRefreshTokenExpirationError(error)) {              
              await auth.refreshToken(true).catch(auth.handleRefreshTokenExpirationFactory(refreshTokenExpiredAction));
              return performFetch(method, url, data, config)
                .then( response => response.data)
                .catch(err2 => {
                  setErrors(err2);
                  throw err2
                });
          }else {
            setErrors(error);
            throw error;
          }
      }).finally( function() {
        setLoading(false);
      })
      
  }

  async function get<RES_T = any, REQ_T extends object = any>(
    url: string, 
    //@ts-ignore
    data: REQ_T = {},
    config: AxiosRequestConfig<REQ_T> = {}) {
      return fetch<RES_T, REQ_T>('get', url, data, config);
  }

  async function post<RES_T = any, REQ_T = any>(
    url: string, 
    //@ts-ignore
    data: REQ_T = {},
    config: AxiosRequestConfig<REQ_T> = {}) {
      return fetch<RES_T, REQ_T>('post', url, data, config);      
  }

  async function patch<RES_T = any, REQ_T = any>(
    url: string,
    //@ts-ignore
    data: REQ_T = {},
    config: AxiosRequestConfig<REQ_T> = {}) {
      return fetch<RES_T, REQ_T>('patch', url, data, config);      
  }

  async function put<RES_T = any, REQ_T = any>(
    url: string, 
    //@ts-ignore
    data: REQ_T = {},
    config: AxiosRequestConfig<REQ_T> = {}) {
      return fetch<RES_T, REQ_T>('put', url, data, config);      
  }

  async function deleteRequest<RES_T = any, REQ_T = any>(
    url: string, 
    //@ts-ignore
    data: REQ_T = {},
    config: AxiosRequestConfig<REQ_T> = {}) {
      return fetch<RES_T, REQ_T>('delete', url, data, config);      
  }


  return {
    loading,
    errors,
    clearErrors,
    fetch,
    get,
    post,
    patch,
    put,
    deleteRequest,
    refreshTokenExpiredAction
  }
}

export type ApiReturnType = ReturnType<typeof useApi>;
export type ApiLoadingType = Pick<ApiReturnType, 'loading' | 'errors' | 'clearErrors'>;