<script lang="ts">
import { WidgetControllerError } from '@/types/widget-error-types';
import type {  RadarLeveWidgetConfig, RadarLevelDescTags } from './radar-level-widget-types';
import { createLogger } from "@/utils/logger";
import { IS_DEVELOPMENT } from '@/config/environment';
const logger = createLogger("Dashboard");
if(!IS_DEVELOPMENT) {
  logger.setLoggerEnabled(false);
}
logger.setLoggerEnabled(false);

export interface RadarLevelControllerErrorPayload {

}


</script>

<script lang="ts" setup>
import { onMounted, type PropType, ref, computed, type Ref, watch } from 'vue';
import RadarLevelWidgetTabsContainer from './RadarLevelWidgetTabsContainer.vue';
import { useTagService } from '@/services/tagService';
import { type TagModel } from '@/models/TagModel';
import ProgressBar from '@/components/ProgressBar.vue';
import { useWidgetConnectedMode } from '@/composables/widgetConnectedMode';
import type { ConnectedValueRefType } from '@/stores/connectedMode';

const props = defineProps({
  ///// Default props
  editMode: {
    type: Boolean,
    default: false
  },
  connectedMode: {
    type: Boolean,
    default: false
  },
  config: {
    type: Object as PropType<RadarLeveWidgetConfig>,
  },
  isGlobalDashboardView: {
    type: Boolean,
  }, 
  onFooterTitleChange: {
    type: Function as PropType<(newTitle: string | null) => void>    
  },
  onHeaderTitleChange: {
    type: Function as PropType<(newTitle: string | null) => void>,   
  },
  onErrorChange: {
    type: Function as PropType<(newError: WidgetControllerError<RadarLevelControllerErrorPayload> | null) => void>,   
  },
  ///// End of Default props

})

const tagService = useTagService();
const { getTagsForLocationLoading } = tagService;

const valueTag = ref<TagModel>();
const upperAlarmBoundTag = ref<TagModel>();
const lowerAlarmBoundTag = ref<TagModel>();
const upperPumpBoundTag = ref<TagModel>();
const lowerPumpBoundTag = ref<TagModel>();
const totalUpperBoundTag = ref<TagModel>();
const totalLowerBoundTag = ref<TagModel>();``

const tagIdsToTagDescMap = ref<Record<string, RadarLevelDescTags>>({});
const tagDescToIdsMap = computed<Record<RadarLevelDescTags, string>>( () => Object.fromEntries(
   Object.entries(tagIdsToTagDescMap.value).map( entry => entry.reverse())
))

async function getTags() {  
  if(props.config && props.config.locationsObjectsTags && props.config.locationsObjectsTags.length > 0) {
    const { locationsObjectsTags } = props.config;

    const locationId = locationsObjectsTags.find( lot => lot.locationId != null)?.locationId ?? null;
    if(!locationId) {
      throw new Error("Radar Level Widget - Location id not found in widget conig")
    }

    const tagIdsAndTagDesc = locationsObjectsTags.map( lot => [lot.tagId, lot.descTag]);
    const tagsIds = tagIdsAndTagDesc.map( lot => lot[0]);

    tagIdsToTagDescMap.value = Object.fromEntries(tagIdsAndTagDesc);
    
    if(tagsIds.length > 0) {
      try {
        const result = await tagService.getTagsForLocation( locationId, {
          id: tagsIds,
          fields: ['id', 'value', 'referencecode', 'unit', 'location', 'displaycode', 'objectdisplaycode', 'updated', 'objectid', 'locationid', 'setting']
        });        

        result.forEach( resultTag => {
          if(resultTag.id in tagIdsToTagDescMap.value!) {
            const tagDescription = tagIdsToTagDescMap.value![resultTag.id];

              switch(tagDescription as TagModel['referencecode']) {
              case 'value': valueTag.value = resultTag; break;
              case 'upper_alarm_level': upperAlarmBoundTag.value = resultTag; break;
              case 'lower_alarm_level': lowerAlarmBoundTag.value = resultTag; break;
              case 'upper_pump_level': upperPumpBoundTag.value = resultTag; break;
              case 'lower_pump_level': lowerPumpBoundTag.value = resultTag; break;
              case 'max_presentation_level': totalUpperBoundTag.value = resultTag; break;
              case 'min_presentation_level': totalLowerBoundTag.value = resultTag; break;
              default:  logger.log("Radar Level Widget - no state found for refernce code"); break;
            }

          } else {
            logger.log("Radar Level Widget - tag description not known");
          }
          
        })
      }catch(error) {
        props?.onErrorChange?.(
          new WidgetControllerError<RadarLevelControllerErrorPayload>(
            error?.message ?? "Error fetching tags - Radar Level Widget", 
            error));

      }
    }

  }

  return null;
}
watch( () => getTagsForLocationLoading.value, ( newLoading) => logger.log("Loading", newLoading ), { immediate: true })
onMounted(getTags)

function logLevel( boundType: string,updateValue: number) {
  logger.log(`Update value - ${boundType}`, updateValue)
}

function useWidgetConnectedModeForDescTag(descTag: RadarLevelDescTags, stateRef: Ref<TagModel | undefined>){
  return useWidgetConnectedMode(
    () => props.config?.locationsObjectsTags?.find( locationObjTag => locationObjTag.descTag == descTag)?.locationId!, 
    () => props.config?.locationsObjectsTags?.find( locationObjTag => locationObjTag.descTag == descTag)?.tagId!, 
    () => stateRef as ConnectedValueRefType);
}

useWidgetConnectedModeForDescTag('value', valueTag);
useWidgetConnectedModeForDescTag('upper_alarm_level', upperAlarmBoundTag);
useWidgetConnectedModeForDescTag('lower_alarm_level', lowerAlarmBoundTag);
useWidgetConnectedModeForDescTag('upper_pump_level', upperPumpBoundTag);
useWidgetConnectedModeForDescTag('lower_pump_level', lowerPumpBoundTag);


function getTagStateForDescTag(descTag: RadarLevelDescTags): Ref<TagModel | undefined> | null{  
  switch(descTag) {
    case 'value': return valueTag;
    case 'upper_alarm_level': return upperAlarmBoundTag;
    case 'lower_alarm_level': return lowerAlarmBoundTag;
    case 'upper_pump_level': return upperPumpBoundTag;
    case 'lower_pump_level': return lowerPumpBoundTag;
    case 'max_presentation_level': return totalUpperBoundTag;
    case 'min_presentation_level': return totalLowerBoundTag;
    default:  
      logger.log("Radar Level Widget - no state found for refernce code");      
      return null;       
  }

}

async function updateBoundHandler(descTag: RadarLevelDescTags, value: number){
  logLevel(descTag, value);
  const tagId = tagDescToIdsMap.value[descTag]
  const tagState = getTagStateForDescTag(descTag);
  const maxLevelState = getTagStateForDescTag('max_presentation_level');
  const minLevelState = getTagStateForDescTag('min_presentation_level');
  if(tagId && tagState && !isNaN(value)) {
    if(maxLevelState?.value?.value >= value && minLevelState?.value?.value <= value) {
      const result = await tagService.modifyTagValue( tagId, String(value))
      tagState.value!.setting!.value = String(value);
      logger.log("Tag Update result", result)
    }
  }
}

/// Header and footer title changing handlers/examples
/*
function changeFooterHandler() {    
  props.onFooterTitleChange && props.onFooterTitleChange("New footer title");  
}
*/
/*
function changeHeaderHandler() {
  props.onHeaderTitleChange && props.onHeaderTitleChange("New header title");
}
*/
/// End Header and footer title changing handlers/examples


</script>


<template>  
  <ProgressBar 
    v-if="getTagsForLocationLoading"
  />  
  <RadarLevelWidgetTabsContainer
    v-else
    :edit-mode="editMode"
    :connected-mode="connectedMode"
    
    :unit-symbol="valueTag?.unit"
    :value-description="valueTag?.description"  

    :value-tag="valueTag"

    :upper-alarm-bound-tag="upperAlarmBoundTag"
    :lower-alarm-bound-tag="lowerAlarmBoundTag"
    
    :upper-pump-bound-tag="upperPumpBoundTag"
    :lower-pump-bound-tag="lowerPumpBoundTag"

    :total-upper-boundary-tag="totalUpperBoundTag"
    :total-lower-boundary-tag="totalLowerBoundTag"

    @upper-alarm-bound-update="updateBoundHandler( 'upper_alarm_level', $event)"
    @lower-alarm-bound-update="updateBoundHandler( 'lower_alarm_level', $event)"
    @upper-pump-bound-update="updateBoundHandler('upper_pump_level', $event)"
    @lower-pump-bound-update="updateBoundHandler('lower_pump_level', $event)"


  /> 
</template>