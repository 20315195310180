import type { LayoutItem } from "@/types/widget-layout-types";
import type { WidgetDescriptor } from "@/types/widgets-map-types";

export class WidgetControllerError<ERROR_PAYLOAD_T extends object> extends Error {
  constructor(message: string, public payload?: ERROR_PAYLOAD_T | null) {
    super(message);    
  }
}

export type SimpleLayoutItem = Pick<WidgetDescriptor, 'key' | 'componentName'> & Pick<LayoutItem, 'i'>;

export class WidgetLayoutItemError<ERROR_PAYLOAD_T extends object> extends WidgetControllerError<ERROR_PAYLOAD_T> implements SimpleLayoutItem {  
  public i: string;
  public key: string;  
  public componentName: string;
  constructor(
      layoutItem: SimpleLayoutItem, 
      message: string, 
      payload?: ERROR_PAYLOAD_T | null) {
    super(message, payload)
    this.i = layoutItem.i;
    this.key = layoutItem.key;
    this.componentName = layoutItem.componentName;
  }
}