import { BEFORE_LOGIN_EVENT, BEFORE_LOG_OUT_EVENT, LOGGED_IN_EVENT, LOGGED_OUT_EVENT, authEvents } from "@/stores/auth";
import type { AuthEventsHandlers } from "./authEvents";

export function useStoreAuthEvents(authEventsHandler: AuthEventsHandlers) {
  const {
    loggedIn,
    loggedOut,
    beforeLogin , 
    beforeLogout
  } = authEventsHandler;

  if(loggedIn) {
    authEvents.addListener(LOGGED_IN_EVENT, loggedIn)
  }
  if(loggedOut) {
    authEvents.addListener(LOGGED_OUT_EVENT, loggedOut);
  }    

  if(beforeLogin) {
    authEvents.addListener(BEFORE_LOGIN_EVENT, beforeLogin);
  }

  if(beforeLogout) {
    authEvents.addListener(BEFORE_LOG_OUT_EVENT, beforeLogout);
  }        



  function removeAllListeners(){
    if(loggedIn) {
      authEvents.removeListener(LOGGED_IN_EVENT, loggedIn)
    }
    if(loggedOut) {
      authEvents.removeListener(LOGGED_OUT_EVENT, loggedOut);
    }    

    if(beforeLogin) {
      authEvents.removeListener(BEFORE_LOGIN_EVENT, beforeLogin);
    }

    if(beforeLogout) {
      authEvents.removeListener(BEFORE_LOG_OUT_EVENT, beforeLogout);
    }        
  }

  return {
    removeAllListeners
  }
}