<script setup lang="ts">
import EventAcceptIcon from '../icons/EventAcceptIcon.vue';
import EventDownIcon from '../icons/EventDownIcon.vue';
import EventUpIcon from '../icons/EvenUpIcon.vue';
import UrgentCircleIcon from '../icons/UrgentCircleIcon.vue';
import NotUrgentCircleIcon from '../icons/NotUrgentCircleIcon.vue';
import LowCircleIcon from '../icons/LowCircleIcon.vue';
import EditEventIcon from '../icons/EditEventIcon.vue';
import NoteEventIcon from '../icons/NoteEventIcon.vue';
import ValueChangeIcon from '../icons/ValueChangeIcon.vue';
import { DefineComponent } from 'vue';
import { formatTimeAgo } from '@vueuse/core';

defineProps<{
  alarmStatus: string
  alarmTime: string
  alarmText: string
  alarmPriorityId?: number
  alarmPriorityText: string
  isAction?: boolean
  prevValue?: any
  newValue?: any,
  unit?: string
}>();

const ALARM_EVENT_ICONS: Record<string, DefineComponent> = {
  'Up': EventUpIcon as DefineComponent,
  'Down': EventDownIcon as DefineComponent,
  'Accept': EventAcceptIcon as DefineComponent,
  'Edit': EditEventIcon as DefineComponent,
  'Note': NoteEventIcon as DefineComponent,
};

const statusToIcon = (status: string) => {
  return ALARM_EVENT_ICONS[status];
};

const ALARM_PIRORITY_ICON: Record<string, DefineComponent> = {
  '10': UrgentCircleIcon as DefineComponent,
  '1': NotUrgentCircleIcon as DefineComponent,
  '0': LowCircleIcon as DefineComponent
};

const priorityToIcon = (priority: number) => {
  return ALARM_PIRORITY_ICON[priority];
};

</script>

<template>
  <div
    class="flex"
    style="min-height: 61px; padding-right: 10px; padding-top: 10px;"
  >
    <div
      class="flex items-center"
      style="width: 25%; justify-content: center;"
    >
      <div
        class="flex"
        style="flex-direction: column; align-items: center;"
      >
        <component :is="statusToIcon(alarmStatus)" />
      </div>
    </div>
    <div
      class="flex alarm-event"
      style="border-bottom: 1px solid #67696A; padding-bottom: 10px;"
    >
      <p class="main-text">
        {{ alarmText }}
      </p>
      <div class="flex items-center">
        <component :is="priorityToIcon(alarmPriorityId)" />
        <p
          class="sub-text"
          :style="[alarmPriorityId || alarmPriorityId === 0 ? {marginLeft: `5px`} : '']"
        >
          {{ alarmPriorityText }}
        </p>
      </div>
      <p
        v-if="isAction && prevValue || prevValue === 0 && newValue"
        class="sub-text flex items-center"
      >
        {{ prevValue }} <span style="margin: 0px 5px;"><ValueChangeIcon /></span> {{ newValue }} {{ unit }}
      </p>
      <p
        v-else-if="isAction && newValue"
        class="sub-text"
      >
        {{ newValue }}
      </p>
    </div>
    <div
      class="flex items-center"
      style="width: 30%; justify-content: center; border-bottom: 1px solid #67696A; padding-bottom: 10px;"
    >
      <p class="sub-text text-center">
        {{ formatTimeAgo(new Date(alarmTime)) }}
      </p>
    </div>
  </div>
</template>

<style>
.time-stamp {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #C3C4C5;
  font-family: "Avenir Next";
}
.sub-text {
  color: #C3C4C5;
  font-family: "Avenir Next LT Pro";
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
}

.alarm-event {
  width: 70%;
  padding-left: 5px;
  justify-content: center;
  flex-direction: column;
}

.main-text {
  color: #FFFFFF;
  font-family: "Avenir Next LT Pro";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}
</style>
