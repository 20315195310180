<script lang="ts" setup>
import DialogCloseIcon from '@/components/icons/DialogCloseIcon.vue';
import Button from '@/components/Button.vue';
import { ref, type PropType } from 'vue';
import { IS_DEVELOPMENT } from '@/config/environment';
import type { WidgetConfigDialogProps } from '@/types/widgets-map-types';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';

defineProps({
  teleportTarget: {
    type: String,
    default: 'body'
  },
  title: {
    type: String,
    default: "Widget Config"
  },
  scrollingBehaviour: {
    type: String as PropType<WidgetConfigDialogProps['scrollingBehaviour']>,
    default: 'expanding-scroll'
  }
})

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'add'): void;
  (e: 'cancel'): void
}>();

function closeHandler() {
  emit('close');
}

function addHandler() {
  emit('add');
}

function cancelHandler() {
  emit('cancel');
}

const IsAddConfigObjAllowed = ref<boolean>(!IS_DEVELOPMENT ? false: true); 
function onAddAllowedHandler(isAllowed: boolean) {  
  if(!IS_DEVELOPMENT) {
    IsAddConfigObjAllowed.value = isAllowed;
  }
}

</script>

<template>
  <Teleport :to="teleportTarget">
    <div class="w-full h-full flex justify-center items-center absolute left-0 top-0 config-dialog-screen-container">
      <div class="absolute w-full h-full bg-black opacity-40 z-[1000]"></div>
      <div class="flex flex-col grow-0 items-center config-dialog-container z-[1000]">
        <div class="flex flex-row justify-between items-center config-dialog-bar">
          <div class="inline-flex select-none config-dialog-bar-title">{{ title }}</div>
          <button 
            class="inline-flex active:opacity-50 config-dialog-bar-close"
            @click="closeHandler"
          >
            <DialogCloseIcon />
          </button>
        </div>
        <component 
          :is="scrollingBehaviour === 'expanding-overflow' ? 'div' : PerfectScrollbar" 
          class="flex flex-col self-stretch grow config-dialog-content-wrapper">
          <div class="flex flex-col self-stretch grow config-dialog-content-wrapper">
          <div class="grow self-stretch config-dialog-content"> 
            <slot name="content" :onAddAllowed="onAddAllowedHandler"/>          
          </div>
          <div class="flex flex-row items-center grow-0 config-dialog-buttons justify-end self-end">  
            <Button 
              class="active:opacity-50 cursor-pointer"
              background-color="#34383B" 
              border-color="#34383B"
              background-active-color="#34383B" 
              border-active-color="#34383B"          
              @click="cancelHandler">Cancel</Button>
            <Button 
              class="cursor-pointer"
              :disabled="!IsAddConfigObjAllowed"
              background-color="#15B1CA" 
              border-color="#15B1CA"
              background-active-color="rgb(73, 214, 236)" 
              border-active-color="rgb(73, 214, 236)"
              @click="addHandler">Add</Button>
          </div>
          </div>
        </component>
      </div>      
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
$inital_height: 264.498px;
.config-dialog-screen-container {

}
.config-dialog-container {
  min-width: 340.666px;
  max-width: 80vw;  
  /*height: 264.498px;*/
  min-height: $inital_height;
  max-height: 80vh;
  flex-shrink: 0;

  border-radius: 15px;
  background: #34383B;
  
  // box-shadow: -1px 18px 15px 18px rgba(0,0,0,0.53);

  @media screen and (max-width: 580px) {   
    min-width: clamp(min(460px, 90vw), 460px, 80vw);
    max-width: 90vw;
  }

}


.config-dialog-content-wrapper {

}

.config-dialog-content {  
  min-height: calc($inital_height - (36.644px + 45px));
}
.config-dialog-bar {
  /*width: 338.633px;*/
  width: 100%;
  height: 36.644px;
  flex-shrink: 0;

  border-radius: 15px 15px 0px 0px;
  background: linear-gradient(180deg, #35393B 0%, #2F3335 100%);

  padding-left: 15px;
  padding-right: 15px;
}

.config-dialog-bar-title {
  display: flex;
  /*width: 128.811px;*/
  height: 16.763px;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;

  color: #FFF;

  font-family: 'Avenir Next LT Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 125% */
}

.config-dialog-bar-close {
  width: 10.28px;
  height: 10.28px;
  flex-shrink: 0;

  stroke-width: 1px;
  stroke: #FFF;
  /*opacity: 0.5;*/  
}

.config-dialog-buttons {
  height: 45px;  
  justify-content: flex-end;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 8px;
}

</style>