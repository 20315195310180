import type { DashboardView, DashboardViewSimple } from "@/models/DashboardModel";
import {  type IKeyValueStorage } from "./KeyValueBrowserStorage";
import { v4 as uuidv4 } from 'uuid';
/*
KEYS STRUCTURE:
  view/type/(subtype | locationId)? / dashboardViewId

  view/template/85c5cced-3ffc-444b-bfcc-e9ec4309a03c
  view/global/85c5cced-3ffc-444b-bfcc-e9ec4309a03c
  view/global/list
  view/local/32303233-2d31-3536-32/85c5cced-3ffc-444b-bfcc-e9ec4309a03c
  view/local/list
  view/local/totallist
*/

export interface IDashboardKeyValueService {
  upsertDashboardView(locationId: string | null, dashboardView: DashboardView): Promise<DashboardView>;
  upsertDashboardViewInViewList(locationId: string | null, dashboardView: DashboardView): Promise<DashboardViewSimple>;    
  createDashboardView(locationId: string | null, viewName: string): Promise<DashboardView>;    
  
  updateDashboardView(locationId: string | null, dashboardView: DashboardView): Promise<DashboardView>
  
  deleteDashboardViewInViewList(locationId: string | null, dashboardView: DashboardView): Promise<boolean>;
  deleteDashboardView(locationId: string | null, dashboardView: DashboardView): Promise<boolean>;
  
  getDashboardViews(locationId?: string | null, viewId?: string, listType?: 'list' | 'totallist'): Promise<DashboardViewSimple[]>;
  getDashboardView(locationId: string | null, viewId: string): Promise<DashboardView | null>
}

export class DashboardKeyValueService implements IDashboardKeyValueService {
  private readonly SEPARATOR = '/';
  constructor(private keyValueStorage: IKeyValueStorage){}  

  generateKey(locationId: string | null, viewId: string, type: 'layout' | 'list' | 'totallist' = 'layout'): string {        
    if(!locationId && type != 'totallist') {
      return type == 'layout'
        ? `view${this.SEPARATOR}global${this.SEPARATOR}${viewId}`
        : `view${this.SEPARATOR}global${this.SEPARATOR}list`
    } else {
      return type ==  'layout'
        ? `view${this.SEPARATOR}local${this.SEPARATOR}${locationId}${this.SEPARATOR}${viewId}`
        : (type == 'list' 
          ? `view${this.SEPARATOR}local${this.SEPARATOR}${locationId}${this.SEPARATOR}list`
          : `view${this.SEPARATOR}local${this.SEPARATOR}totallist`)
    }
  }

  async upsertDashboardViewInViewList(locationId: string | null, dashboardView: DashboardView): Promise<DashboardViewSimple> {
    const newDashboardViewSimple = await this.upsertDashboardViewInViewListByListType(locationId, dashboardView, 'list');
    if(locationId != null) {
      await this.upsertDashboardViewInViewListByListType(locationId, dashboardView, 'totallist');
    }
    
    return newDashboardViewSimple;
  }

  protected async upsertDashboardViewInViewListByListType(locationId: string | null, dashboardView: DashboardView, listType: 'list' | 'totallist'): Promise<DashboardViewSimple> {
    const { viewId, viewName } = dashboardView;
    const newDashboardViewSimple = {
      viewId,
      viewName,
      locationId: locationId ?? null  
    };

    const currentDashboardList = await this.getDashboardViews(locationId, viewId, listType)      

    const indexOfView = currentDashboardList.findIndex( viewItem => viewItem.viewId == viewId);
    if(indexOfView != -1) {
      currentDashboardList[indexOfView] = newDashboardViewSimple
    } else {
      currentDashboardList.push(newDashboardViewSimple)
    }

    await this.keyValueStorage.setItem( 
      this.generateKey(locationId, viewId, listType), 
      currentDashboardList);

    return newDashboardViewSimple;
  }

  async createDashboardView(locationId: string | null, viewName: string): Promise<DashboardView> {
    const viewId = uuidv4()
    const newDashboardview: DashboardView = {
      viewId,
      viewName,
      locationId: locationId ?? null,
      layout: []
    };
    
    await this.keyValueStorage.setItem( this.generateKey(locationId, viewId), newDashboardview)
    return newDashboardview;
  }
  
  async upsertDashboardView(locationId: string | null, dashboardView: DashboardView): Promise<DashboardView> {
    return this.updateDashboardView(locationId, dashboardView);
  }

  async updateDashboardView(locationId: string | null, dashboardView: DashboardView): Promise<DashboardView> {
    const { viewId } = dashboardView;
    const key = this.generateKey(locationId, viewId, 'layout');
    await this.keyValueStorage.setItem( key, { ...dashboardView, locationId: locationId ?? null })
    return dashboardView;
  }

  async deleteDashboardViewInViewList(locationId: string | null, dashboardView: DashboardView): Promise<boolean> {
    let result = await this.deleteDashboardViewInViewListByListType(locationId, dashboardView, 'list');
    if(locationId != null) {
      result = result && await this.deleteDashboardViewInViewListByListType(locationId, dashboardView, 'totallist');
    }

    return result;
  }

  protected async deleteDashboardViewInViewListByListType(locationId: string | null, dashboardView: DashboardView, listType: 'list' | 'totallist'): Promise<boolean> {
    const { viewId } = dashboardView;
    const currentDashboardList = await this.getDashboardViews(locationId, viewId, listType)      

    let changed = false;
    const indexOfView = currentDashboardList.findIndex( viewItem => viewItem.viewId == viewId);
    let updatedDashboardList = currentDashboardList;
    if(indexOfView != -1) {
      updatedDashboardList = updatedDashboardList.filter( viewSimple => viewSimple.viewId != viewId)
      changed = true;
    }

    if(changed){
      await this.keyValueStorage.setItem(this.generateKey(locationId, viewId, listType), updatedDashboardList);
      return true;
    }

    return false;
  }


  async deleteDashboardView(locationId: string | null, dashboardView: DashboardView): Promise<boolean> {
    const currentGlobalDashboardView = await this.getDashboardView(locationId, dashboardView.viewId);
    if(currentGlobalDashboardView) {
      this.keyValueStorage.removeItem(this.generateKey(locationId, dashboardView.viewId, 'layout'))
      return true;
    }
    
    return false;
  }
  async getDashboardViews(locationId: string | null, viewId: string = "", listType: 'list' | 'totallist' = 'list'): Promise<DashboardViewSimple[]> {
    const result = await this.keyValueStorage.getItem( this.generateKey(locationId, viewId, listType))
    return result ?? [];      
  }

  async getDashboardView(locationId: string | null, viewId: string): Promise<DashboardView | null> {
    const key = this.generateKey(locationId, viewId);
    const dashboardView = await this.keyValueStorage.getItem(key);
    return dashboardView ?? null;
  }
}
