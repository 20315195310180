import type { DashboardLayout, DashboardLayoutItem, DashboardView, DashboardViewSimple } from "@/models/DashboardModel";
import { DashboardKeyValueService, type IDashboardKeyValueService } from "./DashboardKeyValueService";
import { toRaw } from "vue";
import omit from 'lodash.omit';
import type { LocationModel } from "@/models/LocationModel";
import { IS_DEVELOPMENT } from "@/config/environment";
import { BrowserKeyValueStorage, type IKeyValueStorage } from "./KeyValueBrowserStorage";
import { createDashboardPreferenceStorage as createDashboardPrefStorageFactory  } from "./KeyValuePreferenceStorage";

export interface IDashboardService {
  createDashboardView(selectedLocation: LocationModel | null, viewName: string | null): Promise<DashboardView>;    
  upsertDashboardView(selectedLocation: LocationModel | null, dashboardView: DashboardView): Promise<DashboardView>;    
  updateDashboardView(selectedLocation: LocationModel | null, dashboardView: DashboardView): Promise<DashboardView>
  deleteDashboardView(selectedLocation: LocationModel | null, dashboardView: DashboardView): Promise<boolean>;
  getDashboardViews(selectedLocation: LocationModel | null, listType?: 'list' | 'totallist'): Promise<DashboardViewSimple[]>;
  getDashboardView(selectedLocation: LocationModel | null, viewId: string): Promise<DashboardView | null>  
}

const DEBUG_LOCAL_STORAGE = false;

const DEFAULT_NEW_VIEW_NAME = "New widgets layout view"

export const useDashboardService = function(createDashboardPreferenceStorage: () => IKeyValueStorage = createDashboardPrefStorageFactory): IDashboardService {
  const decoratedService: IDashboardService = new DashboardServiceObjectSanitizer(
    new DashboardService(
      new DashboardKeyValueService(
        DEBUG_LOCAL_STORAGE && IS_DEVELOPMENT
          ? new BrowserKeyValueStorage()
          : createDashboardPreferenceStorage()
      )
    )
  );

  async function createDashboardView(selectedLocation: LocationModel | null, viewName: string | null): Promise<DashboardView> {
    return decoratedService.createDashboardView(selectedLocation, viewName);
  }

  async function upsertDashboardView(selectedLocation: LocationModel | null, dashboardView: DashboardView): Promise<DashboardView> {
    return decoratedService.updateDashboardView(selectedLocation, dashboardView);
  }

  async function updateDashboardView(selectedLocation: LocationModel | null, dashboardView: DashboardView): Promise<DashboardView> {
    return decoratedService.updateDashboardView(selectedLocation, dashboardView);
  }

  async function deleteDashboardView(selectedLocation: LocationModel | null, dashboardView: DashboardView): Promise<boolean> {
    return decoratedService.deleteDashboardView(selectedLocation, dashboardView)
  }
  async function getDashboardViews(selectedLocation: LocationModel | null, listType: 'list' | 'totallist' = 'list' ): Promise<DashboardViewSimple[]> {
    return decoratedService.getDashboardViews(selectedLocation, listType);
  }
  async function getDashboardView(selectedLocation: LocationModel | null, viewId: string): Promise<DashboardView | null> {
    return decoratedService.getDashboardView(selectedLocation, viewId);
  }  

  return {
    createDashboardView,
    upsertDashboardView,
    updateDashboardView,
    deleteDashboardView,
    getDashboardViews,
    getDashboardView
  }

}

class DashboardService implements IDashboardService {
  constructor(private keyValueService: IDashboardKeyValueService){}
  
  protected checkViewIdExists(dashboardView: DashboardView) {
    if(!dashboardView.viewId) {
      throw new Error("Dashboard view id is not defined");
    }
  }

  async createDashboardView(selectedLocation: LocationModel | null, viewName: string | null): Promise<DashboardView> {
    const locationId = selectedLocation?.id ?? null;
    const dashboardView = await this.keyValueService.createDashboardView( 
      locationId, 
      viewName ?? DEFAULT_NEW_VIEW_NAME);
    await this.keyValueService.upsertDashboardViewInViewList(locationId, dashboardView);
    return dashboardView;
  }

  async upsertDashboardView(selectedLocation: LocationModel | null, dashboardView: DashboardView): Promise<DashboardView> {
    this.checkViewIdExists(dashboardView);
    const locationId = selectedLocation?.id ?? null;
    const updatedDashboardView = await this.keyValueService.upsertDashboardView(locationId, dashboardView);
    await this.keyValueService.upsertDashboardViewInViewList(locationId,dashboardView);
    return updatedDashboardView;
  }

  async updateDashboardView(selectedLocation: LocationModel | null, dashboardView: DashboardView): Promise<DashboardView> {
    this.checkViewIdExists(dashboardView);
    const locationId = selectedLocation?.id ?? null;
    const updatedDashboardView = await this.keyValueService.updateDashboardView(locationId, dashboardView);
    await this.keyValueService.upsertDashboardViewInViewList(locationId,dashboardView);
    return updatedDashboardView;
  }

  async deleteDashboardView(selectedLocation: LocationModel | null, dashboardView: DashboardView): Promise<boolean> {
    this.checkViewIdExists(dashboardView);
    const locationId = selectedLocation?.id ?? null;
    const result1 = await this.keyValueService.deleteDashboardView(locationId, dashboardView);
    const result2 = await this.keyValueService.deleteDashboardViewInViewList(locationId, dashboardView);
    
    return result1 || result2;
  }
  async getDashboardViews(selectedLocation: LocationModel | null, listType: 'list' | 'totallist' = 'list' ): Promise<DashboardViewSimple[]> {
    const locationId = selectedLocation?.id ?? null;
    return await this.keyValueService.getDashboardViews(locationId, "", listType);

  }
  async getDashboardView(selectedLocation: LocationModel | null, viewId: string): Promise<DashboardView | null> {
    const locationId = selectedLocation?.id ?? null;
    return this.keyValueService.getDashboardView(locationId, viewId);
  }  
}

class DashboardServiceObjectSanitizer implements IDashboardService {
  constructor(private __dashboardService: IDashboardService) {}

  protected sanitizeDashboardView(dashboardView: DashboardView): DashboardView {
    const rawDashobardView = this.__sanitizeDashboardViewSimple(toRaw(dashboardView));
    rawDashobardView.layout = this.__sanitizeDashboardLayout(rawDashobardView.layout);
    
    return rawDashobardView;
  }
  
  protected __sanitizeDashboardViewSimple(dashboardView: DashboardView): DashboardView{
    return { ...omit(dashboardView, ['title', 'desc'] )} as DashboardView;
  }

  protected __sanitizeDashboardLayout(dashboardLayout: DashboardLayout | null) {
    return dashboardLayout 
      ? dashboardLayout.map( layoutItem => omit(layoutItem, [
        'component', 'title', 'desc', 'moved', 'componentIcon', 'componentName', 'componentDesc', 'componentHeaderTitle', 'componentFooterTitle'
      ]) as DashboardLayoutItem)
      : []
  }


  createDashboardView(selectedLocation: LocationModel | null, viewName: string | null): Promise<DashboardView> {
    return this.__dashboardService.createDashboardView(selectedLocation, viewName);
  }

  upsertDashboardView(selectedLocation: LocationModel | null, dashboardView: DashboardView): Promise<DashboardView> {
    const sanitizedView = this.sanitizeDashboardView(dashboardView);
    return this.__dashboardService.upsertDashboardView(selectedLocation, sanitizedView);
  }

  updateDashboardView(selectedLocation: LocationModel | null, dashboardView: DashboardView): Promise<DashboardView> {
    const sanitizedView = this.sanitizeDashboardView(dashboardView);
    return this.__dashboardService.updateDashboardView(selectedLocation, sanitizedView);
  }
  deleteDashboardView(selectedLocation: LocationModel | null, dashboardView: DashboardView): Promise<boolean> {
    const sanitizedView = this.sanitizeDashboardView(dashboardView);    
    return this.__dashboardService.deleteDashboardView(selectedLocation, sanitizedView);
  }
  getDashboardViews(selectedLocation: LocationModel | null, listType?: 'list' | 'totallist' ): Promise<DashboardViewSimple[]> {
    return this.__dashboardService.getDashboardViews(selectedLocation, listType);
  }
  getDashboardView(selectedLocation: LocationModel | null, viewId: string): Promise<DashboardView | null> {
    return this.__dashboardService.getDashboardView(selectedLocation, viewId);
  }

}
