export interface AlarmsResponse {
  alarms: AlarmResponse[]
}

export interface AlarmResponse {
  alarm: AlarmDto
}

export interface AlarmLogResponse {
  alarm: AlarmLogDto
}

export interface AlarmLogDto {
  eventid: string,
  text: string,
  time: string,
  "time@uts": number,
}
export interface AlarmDto {
  locationcode: string
  tagname: string
  bit: number
  up: string
  down?: string
  accept: any
  reset: any
  textid: string
  text: string
  priorityid: number
  priority: string
  departmentcode: string
  department: string
  location: string
  locationactive: number
  processcode: string
  process: string
  toolcode: string
  tool: string
  toolsequencenr: number
  objectcode: string
  object: string
  referencecode: string
  reference: string
  locationdisplaycode: string
  processdisplaycode: string
  objectdisplaycode: string
  tagdisplaycode: string
  tagid: string
  description: string
  id: string
  "up@uts": string
  "down@uts"?: string
  "accept@uts": any
  "reset@uts": any
  locationid: string
}

export enum AlarmPriority {
  REGISTRED = -1,
  NO_ALARMS = 0,
  NON_URGENT_ALARM = 1,
  URGENT_ALARM = 10
}