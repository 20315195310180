<script setup lang="ts">
import { ref, computed, onMounted, defineProps } from 'vue';
import { useLocationService } from '../../../smarthub/src/services/locationService';
import { onClickOutside } from '@vueuse/core';
import type { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { LocationsResponse, LocationDto } from '../../../smarthub/src/api/dtos/LocationDto';
import { useHeaderStore } from '../../../smarthub/src/stores/header';
import { storeToRefs } from 'pinia';
import MagnifyingGlassIcon from '../icons/MagnifyingGlassIcon.vue';
import { useSmarthubBreakpoints } from '../../../smarthub/src/composables/smarthubBreakpoints';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  inputFullWidth: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['toggleInputWidth', 'reduceInputWidth']);

const locationsData = ref<LocationsResponse>();
const searchQuery = ref('');
const isOpen = ref(false);
const dropdownSearchRef = ref();
const inputRef = ref();
const headerStore = useHeaderStore();
const { selectedLocation } = storeToRefs(headerStore);

const filteredLocations = computed(() => {
  if (!locationsData.value || !locationsData.value.locations) {
    return [];
  }
  const query = searchQuery.value.toLowerCase();
  if (query === '') return locationsData.value.locations;
  return locationsData.value.locations.filter((item) => {
    const nameMatch = item.location.name.toLowerCase().includes(query);
    const descriptionMatch = item.location.code.toLowerCase().includes(query);

    return nameMatch || descriptionMatch;
  });
});

function selectLocation(location: LocationDto) {
  headerStore.setSelectedItem(location);
  searchQuery.value = '';
  isOpen.value = false;
  emit('reduceInputWidth');
}

const placeholderText = computed(() => {
  if (selectedLocation.value) {
    return `${selectedLocation.value.name}`;
  } else {
    return 'Choose installation...';
  }
});

async function fetchLocations() {
  const { getLocations } = useLocationService();
  const result = await getLocations();
  locationsData.value = result;
}

const handleClose = () => isOpen.value = false;

onMounted(() => {
  fetchLocations();
  onClickOutside(dropdownSearchRef, handleClose, { ignore: [inputRef] });
});

</script>
<template>
  <div
    :class="[{'header__dropdown__wrapper-disabled': disabled}, 
             {'full-width-mobile': inputFullWidth}, 'header__dropdown__wrapper']">
    <div class="relative">
      <button @click="emit('toggleInputWidth')" class="absolute shub-md:inset-y-0 shub-md:left-0.5 shub-md:top-0.5   w-[32px] h-[32px] flex justify-center items-center">
        <MagnifyingGlassIcon/>
      </button>
      <input
        ref="inputRef"
        v-model="searchQuery"
        type="search"
        class="header__dropdown__input rounded-full text-white placeholder:text-white 
          bg-[#1a1d1f] text-sm pr-3
          max-shub-md:h-[32px] max-shub-md:text-[12px] shub-md:w-[200px]
          shub-md:py-2 shub-md:placeholder:opacity-60 shub-md:pl-9"
        :class="[inputFullWidth ? 'w-full placeholder:opacity-60 pl-9' : 'max-shub-md:w-[32px] placeholder:opacity-0']"
        placeholder="Choose installation..."
        :tabindex="disabled ? -1 : undefined"
        @click="isOpen = !isOpen"
      >
      <div
        ref="dropdownSearchRef"
        :class="isOpen ? 'visible' : 'invisible'"
        class="header__dropdown absolute top-10 left-0 right-0 min-w-[182px] py-5 pl-5 pr-1 
        rounded-[15px] border-2 border-[#797979] bg-[#34383b] overflow-y-hidden"
      >
        <ul>
          <PerfectScrollbar 
            :options="{ suppressScrollX: true}"      
            :style="{ height: filteredLocations.length === 0 ? '0' : '212px' }"      
          >
            <li
              v-for="item in filteredLocations"
              :key="item.location.id"
              class="text-[12px] cursor-pointer"
              @click="selectLocation(item.location)"
            >
              {{ item.location.name }}
              <span class="block text-[14px] opacity-50">
                {{ item.location.code }}
              </span>
            </li>
          </PerfectScrollbar>
        </ul>
        <span
          v-if="filteredLocations.length === 0"
          class="text-[12px]"
        >No location</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);

.header__dropdown {
	visibility: hidden;
	transition: all 0.1s ease-out;
	max-height: 0;

	&.visible {
		max-height: 262px;
		visibility: visible;
	}

	li + li {
		margin-top: 15px;
	}

	&__wrapper {
    transition: $transition;
		&-disabled {
			pointer-events: none;
			user-select: none;
			opacity: 0.4;
		}
	}
}

.full-width-mobile {
  @media only screen and (max-width: 768px) { 
    flex: 1;
    margin-right: 20px;
    transition: $transition;
  }
}
</style>
