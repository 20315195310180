<script lang="ts">
import { type LocationModel } from '@/models/LocationModel';
import { type ObjectModel } from '@/models/ObjectModel'
import { type TagModel } from '@/models/TagModel';

export interface Tag {
  location: LocationModel,
  object: ObjectModel,
  tag: TagModel
}

</script>
<script lang="ts" setup>
import TagDeleteIcon from '@/components/icons/TagDeleteIcon.vue';
import TagCircleIcon from '@/components/icons/TagCircleIcon.vue';
import type { PropType } from 'vue';

defineProps({
  tags: {
    type: Array as PropType<Array<Tag>>,        
  },
  stackingDirection: {
    type: String as PropType<'horizontal' | 'vertical'>,
    default: 'horizontal'
  },
  tagIdSelector: {
    type: Function as PropType<(tag: Tag) => string>,
    default: (tag: Tag) => `${tag.location.id}-${tag.object.id}-${tag.tag.id}`
  },  
  tagTitleSelector: {
    type: Function as PropType<(tag: Tag) => string>,
    default: (tag: Tag) => `${tag.location.name} | ${tag.object.name} | ${tag.tag.name}`
  },

});

const emit = defineEmits<{
  (e: 'tag-delete', tag: Tag): void
}>();



function deleteHandler(tag: Tag){
  emit('tag-delete', tag);
}

</script>

<template>
  
  <TransitionGroup name="list" tag='ul'
    class="flex flex-wrap gap-2 "
    :class="{'flex-row': stackingDirection == 'horizontal', 'flex-col': stackingDirection == 'vertical'}">
    <li 
      :key="tagIdSelector(t)"      
      v-for="t in tags"
      class="select-none whitespace-nowrap overflow-ellipsis flex flex-row justify-center items-center tag-item"  
    >
    <div class="flex flex-row justify-between items-center gap-3.5 tag-content-wrapper">
      <span class="tag-title inline-flex">{{ tagTitleSelector(t) }}</span>      
      <div 
        class="relative justify-center items-center active:opacity-50 tag-delete"
        @click.stop="deleteHandler(t)"
      >
        <TagCircleIcon class="tag-delete-circleicon"/> 
        <TagDeleteIcon class="absolute left-[4px] top-[4px] tag-delete-xicon"/>
      </div>
    </div>
  </li>
  </TransitionGroup>
</template>


<style scoped>
.tags-list-container {

}

.tag-item {
  /*width: 192.071px;*/
  width: max-content;
  height: 21.707px;
  flex-shrink: 0;

  border-radius: 50px;
  background: #797979;
}

.tag-content-wrapper {
  padding: 4.5px 8.5px;
}

.tag-title {
  color: #FFF;
  font-family: 'Avenir Next LT Pro';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 136.364% */
}

.tag-delete {  
  width: 13.528px;
  height: 13.528px;
  flex-shrink: 0;  
}

.tag-delete-xicon {
  width: 5.556px;
  height: 5.556px;
  flex-shrink: 0;
}

.tag-delete-circleicon {
  width: 13.528px;
  height: 13.528px;
  flex-shrink: 0;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}


</style>