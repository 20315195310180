<template>
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_918_5749)">
            <path
                d="M4.31649 1.72455L1.58454 4.4565L0.948242 3.8202L4.76649 0.00195312L5.08464 0.320103L8.58474 3.8202L7.94844 4.4565L5.21649 1.72455V9.00195H4.31649V1.72455Z"
                fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_918_5749">
                <rect width="9" height="9" fill="white" transform="translate(0.266602 0.00195312)" />
            </clipPath>
        </defs>
    </svg>
</template>