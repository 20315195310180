import type { DashboardView } from "@/models/DashboardModel";
import type { LocationModel } from "@/models/LocationModel";
import { useDashboardService } from "./dashboardService";
import { globalDashboardViews, localDashboardViewsL1, localDashboardViewsL2, localDashboardViewsOnlineDemo } from "@/data/InitialDashboardData";
import { type Ref } from 'vue';
import { useLoadingPercentProgress } from "@/composables/loadingPercentProgress";
import { createDashboardBatchPreferenceStorage, type IKeyValueBatchOperation } from "./KeyValueBatchPreferenceStorage";
import type { IKeyValueStorage } from "./KeyValueBrowserStorage";

interface IInitialDashboardDataService {
  createInitialDashboardViews(): Promise<void>;
  deleteInitialDashboardViews(): Promise<void>;
  hasInitializedData(): Promise<boolean>;  
  loading: Ref<boolean>;
  loadingPercentProgress: Ref<number>;
}

const locationL1Id = '32303233-2d31-3536-32';
const locationL2Id = '32303233-2d31-3536-33';
const locationOnlineDemoId = '50533036';

export function useInitialDashboardDataService() : IInitialDashboardDataService {
  const dashboardService = useDashboardService(createDashboardBatchPreferenceStorage);  
  const batchDashboardStorage: IKeyValueStorage & IKeyValueBatchOperation = createDashboardBatchPreferenceStorage();

  const { loading, startLoading, stopLoading, updateProgress, loadingPercentProgress } = useLoadingPercentProgress();

  const HAS_INITIALIZED_DATA_KEY = 'dashboard-data-initialized';  

  async function __createDashboardViewsForLocation(locationId: string | null, dashboardViews: DashboardView[]) {
    const createdDashboardViews = [];
    const location: LocationModel | null = locationId != null 
    ? { id: locationId } as LocationModel
    : null;

    for(const tmpDashboardView of dashboardViews) {
      createdDashboardViews.push(
        await dashboardService.upsertDashboardView(location, tmpDashboardView as DashboardView)
      )

      updateProgress(1);
    }
    return createdDashboardViews;    
  }

  async function __deleteDashboardViewsForLocation(locationId: string | null, dashboardViews: DashboardView[]) {
    const createdDashboardViews = [];
    const location: LocationModel | null = locationId != null 
    ? { id: locationId } as LocationModel
    : null;

    for(const tmpDashboardView of dashboardViews) {
      createdDashboardViews.push(
        await dashboardService.deleteDashboardView(location, tmpDashboardView as DashboardView)
      )

      updateProgress(1);
    }
    return createdDashboardViews;    
  }

  type LocalDashobardViewEntry = { locationId: string | null, dashboardViews: DashboardView[]};

  function calculateProgress(
    globalDashboardViews: DashboardView[], 
    localDashboardViewsEntries: LocalDashobardViewEntry[]) {
      return globalDashboardViews.length + localDashboardViewsEntries.flatMap( localEntry => localEntry.dashboardViews).length
    }

  async function __createInitalDashboardViews(
    globalDashboardViews: DashboardView[], 
    localDashboardViewsEntries: LocalDashobardViewEntry[] ): Promise<any> {
    
    startLoading( calculateProgress(globalDashboardViews, localDashboardViewsEntries));

    const createdGlobalDashboardViews = await __createDashboardViewsForLocation(null, globalDashboardViews as DashboardView[]);
    
    const createdLocalDashobardViews: LocalDashobardViewEntry[] = [];
    for(const localEntry of localDashboardViewsEntries) {
      const createdDashboardViews = await __createDashboardViewsForLocation(localEntry.locationId, localEntry.dashboardViews)
      createdLocalDashobardViews.push({ 
        locationId: localEntry.locationId, 
        dashboardViews: createdDashboardViews
      });         
    }
    
    stopLoading();

    return { globalDashboardViews: createdGlobalDashboardViews, localDashboardViews: createdLocalDashobardViews};
  }

  async function __deleteInitalDashboardViews(
    globalDashboardViews: DashboardView[], 
    localDashboardViewsEntries: LocalDashobardViewEntry[] ): Promise<any> {

    startLoading( calculateProgress(globalDashboardViews, localDashboardViewsEntries));
    
    const deletedGlobalDashboardViewsResult = await __deleteDashboardViewsForLocation(null, globalDashboardViews as DashboardView[]);
    
    const deletedLocalDashobardViewsResult = [];
    for(const localEntry of localDashboardViewsEntries) {
      const createdDashboardViews = await __deleteDashboardViewsForLocation(localEntry.locationId, localEntry.dashboardViews)
      deletedLocalDashobardViewsResult.push({ 
        locationId: localEntry.locationId, 
        dashboardViews: createdDashboardViews
      });         
    }
    
    stopLoading();

    return { globalDashboardViews: deletedGlobalDashboardViewsResult, localDashboardViews: deletedLocalDashobardViewsResult};
  }


  async function createInitialDashboardViews(): Promise<void> {
    try {
      await batchDashboardStorage.startBatch();
      const result = await __createInitalDashboardViews( 
        globalDashboardViews as DashboardView[],
        [
          { locationId: locationL1Id, dashboardViews: localDashboardViewsL1 as DashboardView[]},
          { locationId: locationL2Id, dashboardViews: localDashboardViewsL2 as DashboardView[]},
          { locationId: locationOnlineDemoId, dashboardViews: localDashboardViewsOnlineDemo as DashboardView[]},
        ]
      );            
      await setHasInitializedData(true);
      const batchResult = await batchDashboardStorage.commit();      

      return batchResult;      
    } catch(error) {
      await batchDashboardStorage.rollback();
      throw error;
    }
  }      

  async function deleteInitialDashboardViews(): Promise<void> {
    try {
      await batchDashboardStorage.startBatch();

      const deleteResult = await __deleteInitalDashboardViews( 
        globalDashboardViews as DashboardView[],
        [
          { locationId: locationL1Id, dashboardViews: localDashboardViewsL1 as DashboardView[]},
          { locationId: locationL2Id, dashboardViews: localDashboardViewsL2 as DashboardView[]},
          { locationId: locationOnlineDemoId, dashboardViews: localDashboardViewsOnlineDemo as DashboardView[]},
        ]
      );

      await setHasInitializedData(false);
      const batchResult = await batchDashboardStorage.commit();      
      return batchResult;
    } catch(error) {
      await batchDashboardStorage.rollback();
      throw error;
    }
  }      

  async function hasInitializedData() {
    return !!await batchDashboardStorage.executeWithoutBatch ( (keyValueStorage) => keyValueStorage.getItem(HAS_INITIALIZED_DATA_KEY) );
  }  

  async function setHasInitializedData(hasInitializedData: boolean) {
    return await batchDashboardStorage.setItem(HAS_INITIALIZED_DATA_KEY, hasInitializedData);      
  }


  async function setHasInitializedDataNonBatch(hasInitializedData: boolean) {
    return await batchDashboardStorage
      .executeWithoutBatch( (keyValueStorage) => keyValueStorage.setItem(HAS_INITIALIZED_DATA_KEY, hasInitializedData));
  }



  return {
    createInitialDashboardViews,
    deleteInitialDashboardViews,
    hasInitializedData,
    loading,
    loadingPercentProgress
  }

}