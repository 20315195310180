<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { MapGoogleSmarthub } from "@wisionmonorepo/ui";
import { units } from '@wisionmonorepo/ui/mocks/units';
import { useNotificationsStore } from '@/stores/notifications';
import { storeToRefs } from 'pinia';
import type { LocationAlarmsModel } from "@/models/AlarmMode";
import { useLocationAlarmsService } from "@/services/locationAlarmsService";
import { useLocationAlarmsStore } from '@/stores/locationAlarms';
import { useSelectedLocationStore } from '@/stores/selectedLocation';

const notificationsStore = useNotificationsStore();
const { isWelcomeOverlayOpen } = storeToRefs(notificationsStore);

const locationAlarmsStore  = useLocationAlarmsStore();
const { locationAlarms, loading } = storeToRefs(locationAlarmsStore);
const selectedLocationStore = useSelectedLocationStore();
const setLocation = selectedLocationStore.setLocation
const setAlarm = selectedLocationStore.setAlarm
const setSelectedDate = selectedLocationStore.setSelectedDate

// Map
const mapTypeSatelite = false;
const  gisOptions = {
  AlarmWire: false,
  BoilerCentral: false,
  DistributionLine: false,
  MainLine: false,
  MeasuringPoint: false,
  MonitoringStation: false,
  MonitoringStationArea: false,
  ServiceLine: false,
  ValveChamber: false,
  ValveWell: false,
};
//const devices = units;
const selectedUnit = {};

const selectedDeviceData = undefined;
const geoJson = undefined;
const type = undefined;

enum UnitStatusNames {
  Alarm = 'alarm',
  Inactive = 'inactive',
  Ok = 'ok',
  Problem = 'problem',
}

function getUnitStatusName(unit: LocationAlarmsModel): UnitStatusNames  {
  if(unit.alarms.length == 0){
    return UnitStatusNames.Inactive
  }else {
    const maxPriorityId = Math.max(...unit.alarms.map( unitAlarm => unitAlarm.priorityid))
    switch(maxPriorityId){
      case 0: return UnitStatusNames.Inactive;
      case -1: return UnitStatusNames.Ok;
      case 1: return UnitStatusNames.Problem;
      case 10: return UnitStatusNames.Alarm;
      default: throw new Error("Not supported priority id value");
    }
  }
}

</script>

<template>  
  <MapGoogleSmarthub    
    :devices="locationAlarms"
    :type="type"
    :mapTypeSatelite="mapTypeSatelite"
    :gisOptions="gisOptions"
    :selectedDeviceData="selectedDeviceData"
    :selectedUnit="selectedUnit"
    :geoJson="geoJson"
    :is-welcome-overlay-open="isWelcomeOverlayOpen"    
    :getUnitStatusName="getUnitStatusName"
    :setLocation="setLocation"
    :setAlarm="setAlarm"
    :setSelectedDate="setSelectedDate"
    :selectedLocationStore="selectedLocationStore"
  />
</template>
