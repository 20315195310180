import type { LocationDto } from '@/api/dtos/LocationDto';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useChartsStore = defineStore('chart', () => {
	const state = {
		selectedDate: ref<Date>(),
		selectedLocationForCharts: ref<LocationDto | null>(null),
		locationSelectEnabled: ref(true),
	};

	const setSelectedDate = (newDate: Date) => {
		state.selectedDate.value = newDate;
	};

	const setSelectedLocation = (item: LocationDto) => {
		state.selectedLocationForCharts.value = item;
	};

	const removeSelectedItem = () => {
		state.selectedLocationForCharts.value = null;
	};

	const disableLocationSelect = () => {
		state.locationSelectEnabled.value = false;
	};

	const enableLocationSelect = () => {
		state.locationSelectEnabled.value = false;
	};

	return { ...state, setSelectedDate, setSelectedLocation, disableLocationSelect, enableLocationSelect, removeSelectedItem };
});
