<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { TEST_USERNAME, TEST_PASSWORD, TEST_DOMAIN } from '../config/environment';
import { useAuthStore } from '@/stores/auth';
import EventsIcon from '@/components/icons/EventsIcon.vue';
import AccountCircleIcon from '@/components/icons/AccountCircleIcon.vue';
import LockIcon from '@/components/icons/LockIcon.vue';
import VisibillityLockIcon from '@/components/icons/VisibillityLockIcon.vue';
import VisibillityLockIconDisabled from '@/components/icons/VisibillityLockIconDisabled.vue';
import { storeToRefs } from 'pinia';
import { useNotificationsStore } from '@/stores/notifications';
import GlobeIcon from '@/components/icons/GlobeIcon.vue';

const auth = useAuthStore();
const { clearErrors : clearAuthErrors } = auth;
const router = useRouter();
const { errors: authErrors } = storeToRefs(auth);
const notificationStore = useNotificationsStore();

const domain = ref(TEST_DOMAIN);
const username = ref(TEST_USERNAME);
const password = ref(TEST_PASSWORD);
const showPassword = ref(false);
const formDisabled = ref(false);

async function onSubmitHandler(){   
  formDisabled.value = true;
  try {
    notificationStore.clearWelcomeOverlaySeen();    
    await auth.login(`${username.value}@${domain.value}`, password.value);
    notificationStore.fetchWelcomeOverlaySeen();
    router.push("/");
  } catch(err) {
    console.error("Error login: ", err);
  }
}

function setEnableForm(isEnabled: boolean) {
  formDisabled.value = !isEnabled;
}

function toggleShowPassword() {
  showPassword.value = !showPassword.value;
}

function tryAgain(){
  clearAuthErrors();
  setEnableForm(true);
}

function empty() {
  //empty
}

const fieldClasses = computed(() => {
  return `w-full py-2 pl-11 rounded-full bg-transparent border-2 border-[#979797] hover:border-white focus:outline-none focus:border-white placeholder:text-white placeholder:text-[15.8px] text-white transition-all lg:w-[381px] ${authErrors.value ? 'border-[#c61710]' : ''}`;
});

</script>

<template>
  <div class="map__overlay fixed" />
  <div class="login-container flex flex-col justify-center items-center h-screen opacity-80">
    <div class="flex flex-col gap-5 mt-auto pt-[10%]">
      <div class="smarthub-logo flex items-center justify-center gap-x-3 cursor-pointer">
        <EventsIcon style="width: 36.05px; height: 37.5px"/>
        <span class="text-white text-[34.2px]">smarthub</span>
      </div>
      <p v-if="authErrors" class="text-white text-[11.8px] text-center">Error logging in. Check your domain, username and password.</p>
      <p v-else class="text-white text-[11.8px] text-center">Welcome, please enter information below.</p>
      <form id="login-form" @submit.prevent="onSubmitHandler" class="flex flex-col gap-3">

        <div class="relative">
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <GlobeIcon />
          </span>
          <input :disabled="formDisabled" v-model="domain" :class="fieldClasses" placeholder="Enter your domain*"/>
        </div>

        <div class="relative">
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <AccountCircleIcon />
          </span>
          <input :disabled="formDisabled" v-model="username" :class="fieldClasses" placeholder="Enter your e-mail or user code*"/>
        </div>
        
        <div class="relative">
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <LockIcon style="width: 20px; height: 20px;" />
          </span>
          <input :disabled="formDisabled" :type="showPassword ? 'text' : 'password'" :class="['pr-20', fieldClasses]" v-model="password" placeholder="Your password*"/>
        
          <span class="absolute top-[10px] right-0 flex items-center pr-3">
            <button type="button" @click="toggleShowPassword">
              <VisibillityLockIcon v-if="showPassword" style="width: 24px; height: 24px;" />
              <VisibillityLockIconDisabled v-else style="width: 24px; height: 24px;" />
            </button>
          </span>
        </div>
      </form>
      <button 
        :disabled="formDisabled" 
        class="text-white uppercase" 
        type="submit" 
        form="login-form">
        Continue
      </button>    
      <a :style="{visibility: formDisabled && !auth.loading ? 'visible' : 'hidden'}" class="text-white cursor-pointer self-center select-none" @click.prevent="tryAgain">Try again</a>

    </div>
    <div class="flex flex-col items-center gap-3 mt-auto mb-[50px]">
      <small class="text-white">we are wienerberger</small>
      <small class="text-white text-[11.9px] opacity-[59%]">Smarthub version 1.0.0 - Beta Build 542565421</small>
    </div>
  </div>
</template>


<style scoped lang="scss">
$bgColorFrom: #1e1f22;
$bgColorTo: #0a0a0b;

.login-container {
  background: url('../../public/assets/login/background.png') no-repeat center/cover, radial-gradient(circle, $bgColorFrom 0%, $bgColorTo 100%);
}

.map__overlay {
  z-index: -1;
	background: linear-gradient(90deg, $bgColorTo 10px, transparent 1%) center,
    linear-gradient($bgColorTo 10px, transparent 1%) center;
  background-size: 11px 11px;
  width: 100%;
  height: 100%;
}
</style>