<script setup lang="ts">
import { ref, onMounted, watch, shallowRef, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { Header, WelcomeOverlay, BottomNavDynamic } from '@wisionmonorepo/ui';
import type { NavRoute } from '@wisionmonorepo/ui';
import { useHeaderStore } from '@/stores/header';
import { useNotificationsStore } from '@/stores/notifications';
import { storeToRefs } from 'pinia'
import { RouterLink } from 'vue-router'
import DashboardIcon from '../icons/DashboardIcon.vue';
import MapIcon from '../icons/MapIcon.vue';
import GraphIcon from '../icons/GraphIcon.vue';
import HistoryIcon from '../icons/HistoryIcon.vue';
import ArchiveIcon from '../icons/ArchiveIcon.vue';
import { useAuthStore } from '@/stores/auth';
import { ROUTE_PATHS } from '@/constants/routes-paths';
import { useUserInfoStore } from '@/stores/userInfo';

interface Notification {
	NotificationType: string;
}

const route = useRoute();
const router = useRouter();

const auth = useAuthStore();

const userInfoStore = useUserInfoStore();
const headerStore = useHeaderStore();
const { toggleDropdown } = headerStore;
const { isDropdownOpenState } = storeToRefs(headerStore);
const { selectedLocation } = storeToRefs(headerStore);
const { userName } = storeToRefs(userInfoStore);

const notificationsStore = useNotificationsStore();
const { isWelcomeOverlayOpen, alarmsInfo, locationAlarmsLoading } = storeToRefs(notificationsStore);

onMounted(async () => {    
    notificationsStore.fetchWelcomeOverlaySeen();    
  })

const navRoutes: Array<NavRoute> = [
  {
    title: "Dashboard",
    to: "/dashboard",
    icon: DashboardIcon
  },
  {
    title: "Map",    
    to: "/map",
    icon: MapIcon
  },
  {
    title: "Graph",    
    to: "/graph",
    icon: GraphIcon
  },
  {
    title: "History",    
    to: "/history",
    icon: HistoryIcon
  },
];
const computedNavRoutes = shallowRef<Array<NavRoute>>([]);
const selectedLocationId = ref<string | null>(null);

onMounted(() => {
  updateNavRoutes();
});

watch(() => selectedLocation?.value?.id, (newValue) => {
  selectedLocationId.value = newValue;
  updateNavRoutes();
});

function updateNavRoutes() {
  computedNavRoutes.value = selectedLocationId.value ? [...navRoutes, {
    title: "Archive",
    to: "/archive",
    icon: ArchiveIcon
  }] : [...navRoutes];
}


function handleLogout() {
  auth.logout();  
  router.push(ROUTE_PATHS.LOGIN);
  notificationsStore.clearWelcomeOverlaySeen();  
}

const totalNotificationCount = computed( () => alarmsInfo.value.urgentAlarms.length + alarmsInfo.value.nonurgentAlarms.length )

</script>

<template>
  <Header
    :user-name="userName"
    :title="title"
    :is-dropdown-open="isDropdownOpenState"
    :notification-number="totalNotificationCount"
    @logout="handleLogout"
    @toggle="toggleDropdown"
  />
  
  <main>
    <slot />
  </main>

  <transition name="pop" appear>
    <WelcomeOverlay
      :loading="locationAlarmsLoading"
      :is-welcome-overlay-open="isWelcomeOverlayOpen"      
      :user-name="userName"
      @close="notificationsStore.setWelcomeOverlaySeen()"
      :alarms-info="alarmsInfo"
    />
  </transition>

  <BottomNavDynamic
    :router-link-component="RouterLink"
    :routes="computedNavRoutes"
    :initialRoute="route.path"
    :route="route"
    :router="router"
    />
</template>