import { useApi } from '@/composables/api';
import type { TaskStatusResponse, TasksStatusesResponse, TaskId, ScheduledTaskResponse } from './dtos/TaskDto';

export interface ITagsApi {
	scheduleNewTask(locationId: string, action: TaskId, time?: number): Promise<ScheduledTaskResponse>;
	getStateForTask(locationId: string, taskId: TaskId): Promise<TaskStatusResponse>;
	getStates(locationId: string): Promise<TasksStatusesResponse>;
}

const API_VERSIONING = 'api/v1';

export function useTasksApi(): ITagsApi {
	const api = useApi();

  async function scheduleNewTask(locationId: string, action: TaskId, time?: number): Promise<ScheduledTaskResponse> {
    return api.post(`${API_VERSIONING}/locations/${locationId}/tasks`, {
      action,
      time
    });
  }

	async function getStateForTask(locationId: string, taskId: TaskId): Promise<TaskStatusResponse> {
		return api.get(`${API_VERSIONING}/locations/${locationId}/tasks/${taskId}`);
	}

	async function getStates(locationId: string): Promise<TasksStatusesResponse> {
		return api.get(`${API_VERSIONING}/locations/${locationId}/tasks`)
	}

	return {
		scheduleNewTask,
		getStateForTask,
		getStates
	};
}
