import { defineStore } from 'pinia';
import { useCachedServiceCall } from '@/composables/cachedServiceCall';
import { useLocationObjectTagsService, type ILocationObjectTagsService } from '@/services/locationObjectTagsService';
import type { LocationModel } from '@/models/LocationModel';
import type { ObjectModel } from '@/models/ObjectModel';
import type { TagModel } from '@/models/TagModel';
import { CachedData } from '@/utils/CachedData';
import { type TagParams } from '@/services/locationObjectTagsService';
import { useStoreAuthEvents } from '@/composables/storeAuthEvents';
import type { LocationQueryParams } from '@/api/locationsApi';

const CACHE_BACKETS = {
  LOCATIONS: 'LOCATIONS',
  OBJS_FOR_LOCATION: 'OBJS_FOR_LOCATION',
  TAGS_FOR_OBJECT: 'TAGS_FOR_OBJECT',
  TAGS_FOR_LOCATION: 'TAGS_FOR_LOCATION',
}

interface ToExtendExample extends ILocationObjectTagsService {
  /**
   * NOTE: !!! Read-only cached result of the service call. Please don't modify returned value.
   * @override
   */
  getLocations(queryParams?: LocationQueryParams): Promise<LocationModel[]>;

  /**
   * NOTE: !!! Read-only cached result of the service call. Please don't modify returned value.
   * @override
   */
  getObjectsForLocation(locationId: string): Promise<ObjectModel[]>;

  /**
   * NOTE: !!! Read-only cached result of the service call. Please don't modify returned value.
   * @override
   */
  getTagsForObject(objectId: string): Promise<TagModel[]>;
  

  /**
   * NOTE: !!! Read-only cached result of the service call. Please don't modify returned value.
   * @override
   */
  getTagsForLocation(locationId: string, params?: TagParams): Promise<TagModel[]>;
}

/**
 * This cached data store has methods that return cached result of the service calls and their response is readonly, 
 * suitable for filling select/dropdown lists. 
 * Please don't modify returned values.
 */
const cachedData = new CachedData();
export const useCachedDataStore = defineStore('cachedData', function(): ILocationObjectTagsService & ToExtendExample {

  const {
    getLocations: getLocationServiceCall,
    getLocationsLoading,
    getLocationsErrors,
    getLocationsClearErrors,

    getObjectsForLocation: getObjectsForLocationServiceCall,
    getObjectsForLocationLoading,
    getObjectsForLocationErrors,
    getObjectsForLocationClearErrors,

    getTagsForObject: getTagsForObjectServiceCall,
    getTagsForObjectLoading,
    getTagsForObjectErrors,
    getTagsForObjectClearErrors,

    getTagsForLocation: getTagsForLocationServiceCall,
    getTagsForLocationLoading,
    getTagsForLocationErrors,
    getTagsForLocationClearErrors
  } = useLocationObjectTagsService();

  useStoreAuthEvents({
    loggedOut() {
      cachedData.purgeAll();
    }
  });

  const {
    serviceCall: getLocations,
  } = useCachedServiceCall<Parameters<ILocationObjectTagsService['getLocations']> , LocationModel[]>(
    CACHE_BACKETS.LOCATIONS,
    () => 'all',
    cachedData,
    getLocationServiceCall,   
    1000 * 60 * 20
  );

  const {
    serviceCall: getObjectsForLocation,
  } = useCachedServiceCall<Parameters<ILocationObjectTagsService['getObjectsForLocation']> , ObjectModel[]>(
    CACHE_BACKETS.OBJS_FOR_LOCATION,
    ( ...args ) => args[0],
    cachedData,
    getObjectsForLocationServiceCall,
    1000 * 60 * 4
  );

  const {
    serviceCall: getTagsForObject,
  } = useCachedServiceCall<Parameters<ILocationObjectTagsService['getTagsForObject']> , TagModel[]>(
    CACHE_BACKETS.TAGS_FOR_OBJECT,
    (...args ) => args[0],
    cachedData,
    getTagsForObjectServiceCall,
    1000 * 10
    );
  
  const {
    serviceCall: getTagsForLocation,
  } = useCachedServiceCall<Parameters<ILocationObjectTagsService['getTagsForLocation']>,  TagModel[]>(
    CACHE_BACKETS.TAGS_FOR_LOCATION,
    (...args ) => (args[0] ?? "").concat('-', args[1]?.type ?? ""),
    cachedData,
    getTagsForLocationServiceCall,
  );
  
  return {
    getLocations,
    getLocationsLoading,
    getLocationsErrors,
    getLocationsClearErrors,

    getObjectsForLocation,
    getObjectsForLocationLoading,
    getObjectsForLocationErrors,
    getObjectsForLocationClearErrors,

    getTagsForObject,
    getTagsForObjectLoading,
    getTagsForObjectErrors,
    getTagsForObjectClearErrors,

    getTagsForLocation,
    getTagsForLocationLoading,
    getTagsForLocationErrors,
    getTagsForLocationClearErrors
  }
})


