import { useLoading } from "./loading"

export function useServiceCallWithLoading<   
  PARAMS_T extends Array<any>,  
  RESPONSE_DTO_T,
  MODEL_T
>(
  apiCallFunction: (...args: PARAMS_T) => Promise<RESPONSE_DTO_T>, 
  mappingFunction: (responseDto: RESPONSE_DTO_T, args: PARAMS_T) => Promise<MODEL_T>) {
  
  const { setLoading, loading, errors, setErrors, clearErrors} = useLoading<any | any[]>();

  
  const serviceCall = async function(...args: PARAMS_T): Promise<MODEL_T> {
    setLoading(true);
    try {
      const responseDto  = await apiCallFunction(...args);
      return await mappingFunction(responseDto, args);  
    }catch(error: any) {
      setErrors(error);
      throw(true);
    }
    finally {
      setLoading(false);
    }  
  }

  return {
    serviceCall,    
    loading,
    errors,    
    clearErrors,    
  }  
}