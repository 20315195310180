<script setup lang="ts" generic="MAPPED_TAG_T = DefaultWeatherLocation">
import type { LocationResponse } from '@/api/dtos/LocationDto';
import { IS_DEVELOPMENT } from '@/config/environment';
import { useLocationService } from '@/services/locationService';
import { Select } from '@wisionmonorepo/ui';
import { computed, onMounted, ref, watch, type PropType } from 'vue';

export interface DefaultWeatherLocation {
    locationCoordinates: {
        name: string;
        x: number;
        y: number;
        locationId: string;
    }
}

interface LocationSelect {
    label: string;
    value: {
        name: string;
        x: string;
        y: string;
        locationId: string;
    }
}

const props = defineProps({
  onAddAllowed: {
    type: Function as PropType<(isAllowed: boolean) => void>    
  },
});

onMounted(() => {
    fetchLocations();
});

const selectedOrg = ref();
const locationsData = ref();
const currentLocation = ref();
const { loading } = useLocationService();
const locationOptions = ref<LocationSelect[]>([]);

const emit = defineEmits<{
    (e: 'config-change', componentProps: { config: { locationCoordinates: MAPPED_TAG_T[] } }): void
}>();

watch(selectedOrg, () => {
    emit('config-change', { config: { locationCoordinates: selectedOrg.value } });
    props?.onAddAllowed && props.onAddAllowed(true);
    currentLocation.value = selectedOrg.value.name;
})

watch(locationsData, () => {
    let data: LocationSelect[] = [];
    filteredLocations.value.forEach((item: LocationResponse) => {
        data.push({
            label: item.location.name,
            value: { name: item.location.name, x: item.location.x, y: item.location.y, locationId: item.location.id }
        })
    })
    locationOptions.value = data;
})

async function fetchLocations() {
    const { getLocations } = useLocationService();
    const result = await getLocations();
    locationsData.value = result;
}

const filteredLocations = computed(() => {
    if (!locationsData.value || !locationsData.value.locations) {
        return [];
    }
    return locationsData.value.locations.filter((item: LocationResponse) => {
        return item.location.name.toLowerCase();
    });
});

</script>

<template>
    <div v-if="loading" class="flex items-center justify-center h-[150px]">
        <div class="loader"></div>
    </div>
    <div v-else class="px-5 py-2 h-100">
        <Select v-model="selectedOrg" :place-holder="`${currentLocation ? currentLocation : 'Select location'}`"
            :options="locationOptions" />
    </div>
</template>