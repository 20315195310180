<script setup lang="ts">
import SidebarAccordion from './SidebarAccordion.vue';
import SidebarSearch from '../SidebarSearch.vue';
import VisibillityLockIcon from '@/components/icons/VisibillityLockIcon.vue';
import VisibillityLockIconDisabled from '@/components/icons/VisibillityLockIconDisabled.vue';
import { ref, defineProps, type PropType, watch } from 'vue';
import { type ClickedItem } from '../../api/dtos/TrendDto';
import { type LocationObjectsTags } from '../../api/dtos/LocationDto';
import { useChartsStore } from '../../stores/chart';
import { useHeaderStore } from '@/stores/header';
import { storeToRefs } from 'pinia';
import { useWindowSize } from '@vueuse/core';
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue';

const props = defineProps({
	locationsData: {
		type: Array as PropType<LocationObjectsTags[]>,
		required: true,
	},
	clickedItems: {
		type: Array as PropType<ClickedItem[]>,
		required: true,
	},
	dataLoading: {
		type: Boolean,
	},
});
const emit = defineEmits(['toggleTag']);
const toggleClicked = ref(false);
const shouldSetWeeklyView = ref(false);
const showSidebar = ref(true);
const date = ref(new Date());
const headerStore = useHeaderStore();
const { selectedLocation } = storeToRefs(headerStore);
const chartStore = useChartsStore();
const { width, height } = useWindowSize();

function handleTagClick(tagId: string, tagName: string) {
	if (!toggleClicked.value) {
		emit('toggleTag', tagId, tagName);
		toggleClicked.value = true;
	}
	setTimeout(() => {
		toggleClicked.value = false;
	}, 500);
}

function getIcon(tagId: string) {
	const item = props?.clickedItems?.find((item) => item.id === tagId);
	return item && item.visible ? VisibillityLockIcon : VisibillityLockIconDisabled;
}

function handleDayClick(e: any) {
	shouldSetWeeklyView.value = false;
	if (!e) return;
	const selectedDate = new Date(e);
	chartStore.setSelectedDate(selectedDate);

	handleCloseSidebar();
}

const handleCloseSidebar = () => {
	if (width.value < 1024) {
		showSidebar.value = false;
	}
}

watch(selectedLocation, (newLocation, previousLocation) => {
	if (newLocation) {
		handleCloseSidebar();
	}
});

</script>
<template>
	<button v-if="!showSidebar" @click="showSidebar = true" class="fixed top-20 right-0">
		<div class="back-btn__wrapper w-[48px] h-[40px] rounded-s-md">
			<ArrowRightIcon class="relative top-1/2 left-1/2 rotate-180 -translate-x-2/4 -translate-y-2/4" />
		</div>
	</button>
	<Transition name="slide-fade">
		<div v-if="showSidebar" :class="['sidebar', 'z-[99]', 'pb-[50px]', 'overflow-auto']">
			<div class="flex items-center pt-24 mb-3">
				<button @click="showSidebar = false" class="h-[15px] w-[15px] pl-2 shub-lg:hidden">
					<svg
						fill="#757678"
						height="15px"
						width="15px"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						viewBox="0 0 460.775 460.775"
						xml:space="preserve"
					>
						<path
							d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
					c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
					c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
					c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
					l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
					c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"
						/>
					</svg>
				</button>
				<SidebarSearch :is-loading="dataLoading" :show-sidebar="showSidebar" @close-sidebar="handleCloseSidebar"/>
			</div>

			<div class="h-[100px] w-[99%] mx-auto border border-black flex items-center justify-center">
				<span class="text-white text-center">Add from map...</span>
			</div>

			<div v-if="!dataLoading && locationsData.length > 0">
				<div style="width: 100%" class="custom-calendar relative">
					<VDatePicker
						ref="calendar"
						v-model="date"
						style="width: 100%; color: white"
						borderless
						transparent
						color="blue"
						trim-weeks
						is-required
						:locale="{ firstDayOfWeek: 2, masks: { weekdays: 'WWW' } }"
						:max-date="new Date()"
						:view="shouldSetWeeklyView ? 'weekly' : 'monthly'"
						@update:model-value="handleDayClick"
					/>
				</div>

				<div class="mt-5 pb-5">
					<SidebarAccordion v-for="item in props.locationsData" :key="item.location.id" is-top-level show-chevron class="my-1">
						<template v-slot:title>
							<div>
								<span class="uppercase w-full ml-2 text-[13px]">{{ item.location.name }}</span>
							</div>
						</template>
						<template v-slot:content>
							<div class="text-[14px]">
								<SidebarAccordion v-for="object in item.location.objects" :key="object.id" :show-chevron="!!object.tags">
									<template v-slot:title>
										<div>
											<VisibillityLockIcon class="inline" />
											<span class="uppercase w-full ml-2 text-[11px]">{{ object.description }}</span>
										</div>
									</template>

									<template v-slot:content>
										<div
											v-for="tag in object.tags"
											:key="tag.id"
											class="flex items-center gap-2 pl-5 mt-1 text-white text-[11px]"
											:class="!tag.trend && 'opacity-50'"
										>
											<div class="cursor-pointer">
												<component :is="getIcon(tag.id)" @click="handleTagClick(tag.id, tag.name)" class="w-[18px]" />
											</div>
											{{ tag.description }} ({{ tag.name }})
											<div class="w-3 h-3 rounded-full ml-auto mr-5" :style="{ backgroundColor: tag.color }"></div>
										</div>
									</template>
								</SidebarAccordion>
							</div>
						</template>
					</SidebarAccordion>
				</div>
			</div>
		</div>
	</Transition>
</template>

<style scoped lang="scss">
.sidebar {
	background: linear-gradient(0deg, #313435 0%, #464a4f 100%);
	box-shadow: -1px 5px 20px 8px rgba(0, 0, 0, 0.53);
	min-width: 17%;
	margin-left: auto;

	@media only screen and (max-height: 540px) {
		height: 150%;
	}
}

.date-toggle {
	font-size: 11px;
	padding: 4px 10px;
	border-radius: 20px;
	background: rgb(21, 177, 202);
}

.date-off {
	font-size: 11px;
	padding: 4px 10px;
	border-radius: 20px;
}

.range-off {
	font-size: 11px;
	width: 40px;
	padding: 4px 10px;
	background: gray;
}

.range-toggle {
	font-size: 11px;
	width: 56px;
	padding: 4px 10px;
	background: rgb(21, 177, 202);
	border-radius: 20px;
}

.dot {
	height: 6px;
	width: 6px;
	background-color: #fff;
	border-radius: 50%;
	display: inline-block;
}

.loader {
	border: 4px solid #888;
	border-top: 4px solid #333;
	border-radius: 50%;
	width: 50px;
	height: 50px !important;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.custom-calendar :deep(.vc-title-wrapper) {
	button {
		color: white;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 400;
	}
}

.custom-calendar :deep(.vc-weekday) {
	color: white;
	text-transform: uppercase;
	font-weight: 300;
}

.custom-calendar :deep(.vc-yellow) {
	--vc-highlight-outline-bg: transparent;
	--vc-accent-700: white;
}
.custom-calendar :deep(.vc-red) {
	--vc-highlight-outline-bg: transparent;
	--vc-accent-700: white;
}
.custom-calendar :deep(.vc-blue) {
	--vc-highlight-solid-bg: transparent;
}

.custom-calendar :deep(.vc-highlight-content-solid) {
	border: 1px solid #15b1ca;
	background: none;
	font-weight: initial;
}

.custom-calendar :deep(.vc-arrow) {
	color: white;
}
.custom-calendar :deep(.vc-prev) {
	position: absolute;
	left: 25%;

	@media only screen and (max-width: 1800px) { 
		left: 18%;
	}

	@media only screen and (max-width: 992px) {
		left: 0;
	}
}

.custom-calendar :deep(.vc-next) {
	position: absolute;
	right: 25%;
	@media only screen and (max-width: 1800px) { 
		right: 18%;
	}

	@media only screen and (max-width: 992px) { 
		right: 0;
	}
}

.nav__item {
	opacity: 54%;
	position: relative;

	&::before {
		position: absolute;
		top: -10px;
		width: 100%;
		height: 2px;
		background-color: #15b1ca;
	}
}

.nav__item.active {
	color: #fff;
	opacity: 100%;

	&::before {
		content: '';
	}
}
.hide-scroll::-webkit-scrollbar {
	display: none;
}
.hide-scroll {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.trend-missing {
	opacity: 0.3;
}

.slide-fade-enter-active {
	transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateX(100%);
	opacity: 0;
}

@media only screen and (max-width: 992px) {
	:deep(.vc-day) {
		min-height: 20px;
	}
	
	:deep(.vc-pane) {
		min-width: 232px;
	}

	:deep(.vc-pane) {
		min-width: 212px;
	}

	:deep(.vc-weekday) {
		font-size: 11px;
	}

	:deep(.vc-weeks) {
		min-width: 0;
	}

	:deep(.custom-calendar .vc-title-wrapper button) {
		font-size: 12px;
	}
}

.back-btn__wrapper {
	background: linear-gradient(0deg, #313435 0%, #464a4f 100%);
}

</style>
