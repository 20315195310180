<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useFilesApi } from '@/api/filesApi';
import { useFilesService } from '@/services/filesService';
import type { FolderHistoryItem, File } from '@/api/dtos/FilesDto';
import FolderSettingsIcon from '@wisionmonorepo/ui/src/icons/FolderSettingsIcon.vue';
import FileOptions from '@wisionmonorepo/ui/src/components/FileOptions.vue';
import DeleteFileModal from '@wisionmonorepo/ui/src/components/DeleteFileModal.vue';
import RenameFileModal from '@wisionmonorepo/ui/src/components/RenameFileModal.vue';
import CreateFolderModal from '@wisionmonorepo/ui/src/components/CreateFolderModal.vue';
import FileExtentionAction from '@wisionmonorepo/ui/src/components/FileExtentionAction.vue';
import CreateFolderIcon from './../components/icons/CreateFolderIcon.vue';
import SortUpIcon from './../components/icons/SortUpIcon.vue';
import SortDownIcon from './../components/icons/SortDownIcon.vue';
import UploadFilesIcon from './../components/icons/UploadFilesIcon.vue';
import TileIconOption from './../components/icons/TileIconOption.vue'
import ListIconOption from './../components/icons/ListIconOption.vue'
import RightArrowIcon from '@wisionmonorepo/ui/src/icons/RightArrowIcon.vue';
import FolderIcon from '@wisionmonorepo/ui/src/icons/FolderIcon.vue';
import { onClickOutside } from '@vueuse/core';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { IMAGE_EXTENTIONS } from '../helpers/archive/index';
import { checkFileExtention } from '../helpers/archive/index';
import { getIcon } from '../helpers/archive/index';
import { formatFileSize } from '../helpers/archive/index';
import { sortSelectedFilesList } from '../helpers/archive/index';
import { formatTimeAgo } from '@vueuse/core';


const props = defineProps({
  location: {
    type: Object,
    required: true
  }
})

const locationIdRef = computed(() => props.location.id);
const locationNameRef = computed(() => props.location.name);

watch(locationIdRef, async () => {
  folderHistory.value = [];
  activeFolder.value = null;
  await fetchDirectory();
});

const filesApi = useFilesApi();
const fileService = useFilesService();
const directoryStructure = ref<File[]>([]);
const selectedFiles = ref<File[]>([]);
const selectedFilesList = ref<File[]>([]);
const folderHistory = ref<FolderHistoryItem[]>([]);
const dataLoading = ref(false);
const activeFolder = ref();
const activeHistory = ref<File[]>([]);
const imageUrls = ref<Record<string, { base64: string; url: string }>>({});
const imagesLoading = ref(false);
const showDeleteModal = ref(false);
const showRenameModal = ref(false);
const showCreateModal = ref(false);
const submenuFilesOpened = ref<Record<string, boolean>>({});
const selectedFile = ref();
const currentPage = ref(1);
const isFolderDelete = ref(false);
const sortOrder = ref('asc');
const sortedBy = ref('');
const displayType = ref('list');
const pageSize = 12;
const optionsRef = ref();
const sortRef = ref();
const showSortDropdown = ref(false);
const imageCache = ref(new Map());

const errors = filesApi.errors;

watch(errors, () => {
  if (errors?.value?.response?.data.error) {
    toast.error(errors.value.response.data.error, {
      autoClose: 2000,
      theme: 'dark'
    });
  }
})


onMounted(() => {
  fetchDirectory();
  onClickOutside(optionsRef, handleClose);
  onClickOutside(sortRef, toggleSortDropdown);
});

const handleClose = () => {
  submenuFilesOpened.value = {};
}

const fetchDirectory = async (isUpadte = false, isSubfolder = false, imageFetch = false) => {
  if (!imageFetch) {
    dataLoading.value = true;
  }
  let response;
  if (!isSubfolder) {
    try {
      response = await fileService.getFilesForLocation(locationIdRef.value);
    } catch (error) {
      selectedFiles.value = [];
      directoryStructure.value = [];
      selectedFilesList.value = [];
      return;
    } finally {
      dataLoading.value = false;
    }
    imageFetch && currentPage.value++;
  } else {
    response = await fileService.getFileFromSubFolder(locationIdRef.value, activeFolder.value.id);
    imageFetch && currentPage.value++;
  }

  const { folders, files } = await fileService.processDirectoryData(response);


  directoryStructure.value = folders;
  selectedFiles.value = files;
  selectedFilesList.value = selectedFiles.value.slice(0, currentPage.value * pageSize);
  sortSelectedFilesList(selectedFilesList.value, sortedBy.value, sortOrder.value);
  sortSelectedFilesList(directoryStructure.value, sortedBy.value, sortOrder.value);
  dataLoading.value = false;
  await fetchImageUrls();

  if (!isUpadte) {
    folderHistory.value.push({
      folders: folders,
      files: files,
    });
  } else {
    if(files.length) folderHistory.value[folderHistory.value.length - 1].files = files
    folderHistory.value[folderHistory.value.length - 1].folders = folders
  }

};

const getSingleFile = async (file: File) => {
  const data = await filesApi.getSingleImage(locationIdRef.value, file?.id);
  return data;
};


const fetchImageUrls = async () => {
  imagesLoading.value = true;
  const startIndex = (currentPage.value - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, selectedFiles.value.length);
  for (let i = startIndex; i < endIndex; i++) {
    const file = selectedFiles.value[i];
    if (imageCache.value.has(file?.id)) {
      imageUrls.value[file.id] = imageCache.value.get(file.id);
    } else {
      const response = await getSingleFile(file);

      if (response) {
        const reader = new FileReader();
        const url = URL.createObjectURL(response);
        reader.readAsDataURL(response);
        reader.onload = function () {
          const base64 = reader.result as string;
          imageUrls.value[file.id] = {
            base64,
            url: url,
          };
          imageCache.value.set(file.id, {
            base64: reader.result,
            url: url,
          });
        };
      }
    }
  }
  imagesLoading.value = false;
};

const loadMoreImages = async () => {
  await fetchDirectory(true, !!activeFolder?.value?.id, true);
  setTimeout(() => {
    window.scrollTo({
    top: document.body.scrollHeight,
    behavior: 'smooth'
  });
  }, 100)
};

const selectFolder = async (folder: File) => {
  imagesLoading.value = true;
  currentPage.value = 1;
  const content = await filesApi.getFileFromSubFolder(locationIdRef.value, folder.id);

  activeFolder.value = folder;
  const folderImages: File[] = [];
  const folderFolders: File[] = [];
  content.forEach((item: File) => {
    if (item.isDir) {
      folderFolders.push(item);
    } else {
      folderImages.push(item);
    }
  });

  selectedFiles.value = folderImages;
  selectedFilesList.value = selectedFiles.value.slice(0, currentPage.value * pageSize);
  sortSelectedFilesList(selectedFilesList.value, sortedBy.value, sortOrder.value);
  await fetchImageUrls();
  directoryStructure.value = folderFolders;
  folderHistory.value.push({
    folders: folderFolders,
    files: folderImages,
    active: folder,
  });

  activeHistory.value.push(folder);
};

const toggleSortOrder = (field: string) => {
  if (field === sortedBy.value) {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortedBy.value = field;
    sortOrder.value = 'asc';
  }
  sortSelectedFilesList(selectedFilesList.value, sortedBy.value, sortOrder.value);
  sortSelectedFilesList(directoryStructure.value, sortedBy.value, sortOrder.value);

  toggleSortDropdown();
};

const downloadImage = (image: File) => {
  const imageSrc = imageUrls.value[image.id]?.url;
  if (imageSrc) {
    const anchor = document.createElement('a');
    anchor.href = imageSrc;
    anchor.download = image.name;
    anchor.style.display = 'none';

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    toggleFilesSubmenu(image.id);
  }
};

const toggleOptionModal = (file: File, type: string, isFolder = false) => {
  isFolderDelete.value = isFolder;
  toggleFilesSubmenu(file.id);
  selectedFile.value = file;
  if (type === 'rename') {
    showRenameModal.value = true;
  } else {
    showDeleteModal.value = true;
  }
};

const handleFileDelete = async () => {
  showDeleteModal.value = false;
  const folderDelete = isFolderDelete.value;
  await filesApi.deleteFile(locationIdRef.value, selectedFile.value.id, folderDelete);
  await fetchDirectory(true, !!activeFolder?.value?.id);
};

const handleFileRename = async (newName: string) => {
  showRenameModal.value = false;
  await filesApi.renameFile(locationIdRef.value, selectedFile.value.id, newName);
  await fetchDirectory(true, !!activeFolder?.value?.id);
};

const handleCreateFolder = async (folderName: string) => {
  showCreateModal.value = false;
  await filesApi.createFolder(locationIdRef.value, folderName, !!activeFolder?.value?.id && activeFolder?.value?.id);
  await fetchDirectory(true, !!activeFolder?.value?.id);
}

const uploadFiles = async (event: Event) => {
  imagesLoading.value = true;
  const input = event.target as HTMLInputElement;
  if (input.files) {
    const formData = new FormData();
    for (let i = 0; i < input.files.length; i++) {
      formData.append('filename' + `${i}`, input.files[i]);
    }
    try {
      let subFolder;
      if (activeFolder?.value?.id) {
        subFolder = activeFolder.value.id;
      }
      await filesApi.uploadFileToLocation(locationIdRef.value, formData, subFolder);
      await fetchDirectory(true, !!subFolder);
    } catch (error) {
      console.error('File upload error:', error);
    } finally {
      imagesLoading.value = false;
    }
  }
};

const currentActiveMenu = ref('');

const toggleFilesSubmenu = (imageId: string) => {
  if (currentActiveMenu.value === imageId) {
    submenuFilesOpened.value = {};
    currentActiveMenu.value = '';
  } else {
    for (const id in submenuFilesOpened.value) {
      if (id !== imageId) {
        submenuFilesOpened.value[id] = false;
      }
    }
    submenuFilesOpened.value[imageId] = !submenuFilesOpened.value[imageId];
    currentActiveMenu.value = imageId;
  }
};

const toggleSortDropdown = () => {
  showSortDropdown.value = !showSortDropdown.value;
};

const breadcrumbTrail = computed(() => {
  const trail = [];
  for (const item of folderHistory.value) {
    if (item.active) {
      trail.push(item.active);
    }
  }
  return trail;
});

const navigateToFolder = async (folder: File) => {
  const index = folderHistory.value.findIndex((item) => item.active?.id === folder.id);
  if (index !== -1) {
    folderHistory.value = folderHistory.value.slice(0, index + 1);
    activeFolder.value = folder;
    await fetchDirectory(true, !!folder.id);
  }
};

const changeDisplayType = (type: string) => {
  submenuFilesOpened.value = {};
  displayType.value = type;
}

const goToRoot = async () => {
  folderHistory.value = [];
  activeFolder.value = null;
  await fetchDirectory(false);
}

const openImageInNewTab = (imageSrc) => {
  const win = window.open();
  win.document.write('<iframe src="' + imageSrc.url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
};

</script>
<template>
  <div style="padding: 2% 5% 0% 5%;">
    <DeleteFileModal
      v-if="showDeleteModal"
      :file="selectedFile"
      :is-folder="isFolderDelete"
      @handle-file-delete="handleFileDelete"
      @close-modal="showDeleteModal = false"
    />
    <RenameFileModal
      v-if="showRenameModal"
      :file="selectedFile"
      :is-folder="isFolderDelete"
      @handle-file-rename="handleFileRename"
      @close-modal="showRenameModal = false"
    />
    <CreateFolderModal
      v-if="showCreateModal"
      :is-folder="isFolderDelete"
      @handle-create-folder="handleCreateFolder"
      @close-modal="showCreateModal = false"
    />
    <div v-if="dataLoading" class="flex items-center" style="justify-content: center; height: 100%;">
      <div class="loader" />
    </div>
    <div v-else>
      <div class="flex items-center pt-5 pb-4 justify-between archive-nav" :class="{'disabled': dataLoading || imagesLoading, 'max-shub-md:flex-wrap': breadcrumbTrail.length >= 1}">
        <div class="flex items-center path w-[80%] pl-[10px]" :class="{'max-shub-md:pb-[15px]': breadcrumbTrail.length >= 1}">
          <span class="crumb cursor-pointer" @click="goToRoot">{{ locationNameRef }}</span>
          <span>
            <RightArrowIcon v-if="breadcrumbTrail.length > 0" style="cursor: pointer; margin-left: 10px;" />
          </span>
          <div v-if="activeFolder?.name" class="flex">
            <div v-for="(crumb, index) in breadcrumbTrail" :key="crumb.id" :class="{'cursor-pointer': index !== breadcrumbTrail.length - 1}"
              @click="index !== breadcrumbTrail.length - 1 && navigateToFolder(crumb)">
              <div class="flex">
                <span class="pl-3 crumb" :class="{ 'active-crumb': index == breadcrumbTrail.length - 1 }">{{ crumb.name
                }}</span>
                <span v-if="index !== breadcrumbTrail.length - 1" class="flex items-center" style="margin-left: 10px;">
                  <RightArrowIcon />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex archive-options">
          <div>
            <CreateFolderIcon class="cursor-pointer" @click="showCreateModal = true" style="margin-right: 10px;" />
          </div>
          <div>
            <label for="fileInput">
              <UploadFilesIcon style="cursor: pointer;" />
              <input id="fileInput" type="file" style="display: none;" multiple @change="uploadFiles">
            </label>
          </div>
          <div class="ml-1 order-2 flex items-center justify-center relative" :class="{'invisible': displayType !== 'tile'}">
            <div
            v-if="showSortDropdown"
            ref="sortRef"
            class="sub-menu text-style"
          >
            <p class="option-hover flex items-center py-2 justify-between" @click="toggleSortOrder('name')" >
              Name <component v-if="sortedBy === 'name'" :is="sortOrder === 'asc' ? SortUpIcon : SortDownIcon" />
            </p>
            <p class="option-hover flex items-center py-2 justify-between" @click="toggleSortOrder('updated')">
              Modified <component v-if="sortedBy === 'updated'" :is="sortOrder === 'asc' ? SortUpIcon : SortDownIcon" />
            </p>
            <p class="option-hover flex items-center py-2 justify-between" @click="toggleSortOrder('size')">
              Size <component v-if="sortedBy === 'size'" :is="sortOrder === 'asc' ? SortUpIcon : SortDownIcon" />
            </p>
          </div>
            <div @click="toggleSortDropdown" class="dropdown flex items-center w-[50px] justify-around cursor-pointer">
              <span class="sort-text cursor-pointer">
                Sort
              </span>
              <span :class="{'rotate-arrow' : showSortDropdown}"><RightArrowIcon color="#969798"/></span>
            </div>
          </div>
          <div class="toggle-display ml-2">
            <div style="padding: 8px 8px; cursor: pointer;" :class="{ 'active-icon': displayType === 'tile' }">
              <TileIconOption @click="changeDisplayType('tile')" />
            </div>
            <div style="padding: 8px 6px; cursor: pointer;" :class="{ 'active-icon': displayType === 'list' }">
              <ListIconOption @click="changeDisplayType('list')" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="displayType === 'list'" class="files-list" style="padding-bottom: 1%; padding-top: 1%;">
        <div v-if="imagesLoading" class="flex items-center justify-center">
          <div class="loader" />
        </div>
        <div v-else>
          <div>
            <div class="flex pt-3" style="width: 100%;">
              <div class="text-left heading-labels file-name-row">
                <span @click="toggleSortOrder('name')" style="cursor: pointer;">File name
                    <span v-if="sortedBy === 'name'"><component class="sort-position" :is="sortOrder === 'asc' ? SortUpIcon : SortDownIcon" /></span>
                </span>
              </div>
              <div class="text-center heading-labels" style="width: 20%;">File type</div>
              <div class="text-center heading-labels" style="width: 20%; cursor: pointer;"
                @click="toggleSortOrder('size')">
                <span>Size</span>
                <span v-if="sortedBy === 'size'"><component class="sort-position" :is="sortOrder === 'asc' ? SortUpIcon : SortDownIcon" /></span>
              </div>
              <div class="text-center heading-labels" style="width: 20%; cursor: pointer;"
                @click="toggleSortOrder('updated')">
                <span>Modified</span>
                <span v-if="sortedBy === 'updated'"><component class="sort-position" :is="sortOrder === 'asc' ? SortUpIcon : SortDownIcon" /></span>
              </div>
            </div>
            <div v-for="image in directoryStructure"
              class="flex items-center text-center folder-style folder-list-item"
              :class="{ 'overlay': imagesLoading }"
              :key="image.id"
            >
            <div class="flex items-center extention-div">
              <div @click="selectFolder(image)">
                <div>
                  <FolderIcon class="cursor-pointer hide ml-[5px]" :width="30" :height="24"/>
                </div>
                  <FolderSettingsIcon @click="toggleFilesSubmenu(image.id)" @click.stop
                  style="cursor: pointer; position: absolute; right: 15px; top: 7px;" />
              </div>
              <div style="padding-left: 20px; position: relative; max-width: 100%;">
                <p class="file-name-style text-truncate cursor-pointer" @click="selectFolder(image)">
                  {{ image.name }}
                </p>
              </div>
            </div>
            <div class="folder-info" style="width: 20%; text-align: center;">
              <div>-</div>
            </div>
            <div class="folder-info" style="width: 20%; text-align: center;">
              <div>-</div>
            </div>
            <div class="folder-info" style="width: 20%; text-align: center; word-break: break-word;">
              <span>{{ formatTimeAgo(new Date(image.lastmodified)) }}</span>
            </div>
            <div style="width: 15%;">
              <FileOptions style="top: -45px; right: 25px !important;" @click.stop ref="optionsRef" :item="image" :is-folder="true" :is-active="submenuFilesOpened[image.id]"
              :image-urls="imageUrls" @toggle-option-modal="toggleOptionModal"
              @download-image="downloadImage(image)" />
            </div>
          </div>
            <div v-if="selectedFilesList.length > 0 && !imagesLoading">
              <div v-for="image in selectedFilesList" :key="image.id" class="flex items-center text-center folder-style folder-list-item">
                <div class="flex items-center extention-div">
                    <div>
                      <FileExtentionAction :image="image" :image-urls="imageUrls" :is-archive-tab="true"/>
                    <FolderSettingsIcon @click="toggleFilesSubmenu(image.id)"
                      style="cursor: pointer; position: absolute; right: 15px; top: 7px;" />
                  </div>
                  <div style="padding-left: 15px; position: relative; max-width: 100%;">
                    <p class="file-name-style text-truncate cursor-pointer" @click="openImageInNewTab(imageUrls[image.id])">
                      {{ image.name }}
                    </p>
                  </div>
                </div>
                <div class="folder-info" style="width: 20%; text-align: center;">
                  <div>{{ image.name.split('.').pop() }} file</div>
                </div>
                <div class="folder-info" style="width: 20%; text-align: center;">
                  <div>{{ formatFileSize(image.size) }}</div>
                </div>
                <div class="folder-info" style="width: 20%; text-align: center; word-break: break-word;">
                  <span>{{ formatTimeAgo(new Date(image.lastmodified)) }}</span>
                </div>
                <div style="width: 15%;">
                  <FileOptions style="top: -110px; right: 30px !important;" ref="optionsRef" @click.stop :item="image" :is-folder="false" :is-active="submenuFilesOpened[image.id]"
                    :image-urls="imageUrls" @toggle-option-modal="toggleOptionModal"
                    @download-image="downloadImage(image)" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedFiles.length > currentPage * pageSize && !imagesLoading" class="flex justify-center p-3">
            <button class="load-more-button" @click="loadMoreImages">
              Load More
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="imagesLoading" class="flex items-center justify-center">
          <div class="loader" />
        </div>
        <div v-else>
          <div>
            <div>
              <div class="folder-wrapper">
                <div v-for="item in directoryStructure"
                  class="m-[1%] p-[1%] bg-[#b4b4b4] dir-wrapper"
                  :class="{ 'overlay': imagesLoading }"
                  :key="item.id"
                  style="margin: 1%; background-color: #404448; border-radius: 8px;"
                  >
                  <div class="folder-container">
                  <div class="flex justify-between">
                    <div>
                      <FolderIcon @click="selectFolder(item)" class="cursor-pointer" :width="30" :height="24"/>
                    </div>
                    <div style="position: relative;">
                      <FolderSettingsIcon class="cursor-pointer" @click="toggleFilesSubmenu(item.id)" @click.stop/>
                      <FileOptions style="top: -40px; right: 15px !important;" ref="optionsRef" :item="item" :is-folder="true" :is-active="submenuFilesOpened[item.id]"
                        :image-urls="imageUrls" @toggle-option-modal="toggleOptionModal"
                        @download-image="downloadImage(item)"
                      />
                    </div>
                  </div>
                  <div class="py-3">
                    <span class="file-name-style cursor-pointer" @click="selectFolder(item)">{{ item.name }}</span>
                  </div>
                  <div class="folder-info">
                    <span>Modified {{ formatTimeAgo(new Date(item.lastmodified)) }}</span>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <div v-if="selectedFilesList.length > 0 && !imagesLoading" class="flex flex-wrap w-full">
              <div v-for="image in selectedFilesList" class="m-[1%] image-wrapper bg-[#b4b4b4]" :key="image.id" style="margin: 1%; padding: 1%; background-color: #404448; border-radius: 8px;">
                <div class="flex items-center image-container">
                  <div>
                    <!-- </div> -->
                  </div>
                  <div class="flex items-center justify-center" style="width: 100%; height: 100%;">
                    <img @click="openImageInNewTab(imageUrls[image.id])" v-if="IMAGE_EXTENTIONS.includes(checkFileExtention(image.name.toLowerCase()))" class="tile-image cursor-pointer" :src="`${imageUrls[image.id]?.base64}`"/>
                    <div v-else>
                      <component :is="getIcon(checkFileExtention(image.name.toLowerCase()))" :width="46" :height="44" />
                    </div>
                    </div>
                  </div>
                  <div class="file-name-container justify-between flex items-center" style="display: flex">
                    <p @click="openImageInNewTab(imageUrls[image.id])" class="file-name-style cursor-pointer" style="padding: 5% 0% 2% 0%; word-break: break-all;">
                      {{ image.name }}
                    </p>
                    <div style="position: relative;">
                      <FolderSettingsIcon @click="toggleFilesSubmenu(image.id)"
                        style="cursor: pointer; position: relative;" />
                          <FileOptions
                            class="file-option-position"
                            ref="optionsRef"
                            @click.stop
                            :item="image"
                            :is-folder="false"
                            :is-active="submenuFilesOpened[image.id]"
                            :image-urls="imageUrls" @toggle-option-modal="toggleOptionModal"
                            @download-image="downloadImage(image)" />
                    </div>
                  </div>
                <div class="folder-info" style="text-align: left;">
                  <span>Modified {{ formatTimeAgo(new Date(image.lastmodified)) }}</span>
                </div>
                <div style="width: 20%; position: relative;">
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedFiles.length > currentPage * pageSize && !imagesLoading" class="flex justify-center p-3">
            <button class="load-more-button" @click="loadMoreImages">
              Load More
            </button>
          </div>
        </div>
      </div>
      <div v-if="!selectedFilesList.length && !directoryStructure.length && !imagesLoading">
        <h3 class="text-center">
          No files in this folder
        </h3>
      </div>
    </div>
  </div>
</template>
  
<style lang="scss" scoped>

.folder-list-position {
  width: 25%;
  padding-left: 40px;
  align-items:center;
}

.sort-position {
  display: inline;
  vertical-align: unset;
  margin-left: 0.25rem;
}

.folder-list-item {
  align-items: center;
  word-break:break-all;
  width: 100%;
  position: relative;
  padding: 10px 0px;
}

.file-name-row {
  width: 25%; padding-left: 85px;
}

.extention-div {
  width: 25%; padding-left: 30px
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.sort-text {
  cursor: pointer;
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 400;
  color: #969798;
}
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rotate-arrow {
  transform: rotate(90deg);
}


@media screen and (max-width: 768px) {
  .folder-list-position {
    padding-left: 0px;
  }
  .file-name-row {
    padding-left: 0;
    text-align: center;
  }
  .extention-div {
    padding-left: 0px;
  }
  .hide {
    display: none;
  }
}

.archive-nav {
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    .path {
      padding-bottom: 15px;
    }
  }
}

.sub-menu {
  position: absolute;
  top: 100%;
  right: 20%;
  border: 1px solid gray;
  border-radius: 5px;
  background: #404448;
  z-index: 10;
}

.text-style {
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  // line-height: 15px;
  color: #FFFFFF;
}

.option-hover {
  &:hover {
    opacity: 0.4;
    cursor: pointer;
  }
}

.sort-text {
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  // line-height: 15px;
  color: #969798;
}
.tile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.labels {
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

.folder-style {
  width: 100%;
  margin: 5px 0px;
  background: #404448;
  border-radius: 5px;
  justify-content: center;
  flex-direction: row;
  word-wrap: break-word;
  &:hover {
    outline: 1px solid gray;
  }
}

.files-list {
  background: #404448;
  margin: 0px 10px;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
  @media screen and (max-width: 400px) {
    margin: 1px 2px;
  }
}

.loader {
  border: 4px solid #888;
  border-top: 4px solid #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.load-more-button {
  background: #404448;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  padding: 2px 5px;
  cursor: pointer;
}

.folder-info {
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  opacity: 0.5;
}

.option-hover {
  padding: 3px;

  &:hover {
    // background: white;
    // color: #404448;
    border-radius: 5px;
    cursor: pointer;
  }
}

.folder-name {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.file-name-style {
  text-align: left;
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #FFFFFF;
}

.crumb {
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.active-crumb {
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.heading-labels {
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #FFFFFF;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.overlay {
  position: relative;
}

.overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  z-index: 1;
}

.active-icon {
  padding: 8px 6px;
  background: #404448;
  border-radius: 5px;
}

.toggle-display {
  width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.folder-container {
  width: 200px;
}
.folder-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.file-name-container {
  width: 200px;
}

.archive-options {
  justify-content: space-evenly;
  margin-right: 10px;
  margin-left: 5px;
  @media screen and (max-width: 370px) {
    width: 100%;
    justify-content: unset;
  }
}

.image-container {
  width: 200px;
  height: 200px;
  position: relative;
}

.file-option-position {
  right: 15px !important; top: -100px;
}

@media screen and (max-width: 487px) {
  .image-container {
    width: auto;
  }
  .file-name-container {
    width: 100%;
  }
  .dir-wrapper, .image-wrapper {
    width: 100% !important;
  }
  .folder-container {
    width: 100% !important;
  }
  .toggle-display {
    margin-right: 0px;
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .image-container {
    height: 100px !important;
  }
}

.disabled {
  pointer-events: none;
}
</style>
  