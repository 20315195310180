import { useApi } from '@/composables/api';
import type { ObjectsResponse } from './dtos/ObjectDto';

export interface IObjectsApi {
	getObjectsForLocation(locationId: string): Promise<ObjectsResponse>;
}

const API_VERSIONING = 'api/v1';

export function useObjectsApi(): IObjectsApi {
	const api = useApi();

  async function getObjectsForLocation(locationId: any): Promise<ObjectsResponse> {
    return api.get(`${API_VERSIONING}/locations/${locationId}/objects`)
  }

  return {
    getObjectsForLocation,
  }
}
