<script setup lang="ts">
import VueApexCharts, { type VueApexChartsComponent } from 'vue3-apexcharts';
import { ref, type Component, type ComponentOptions, watchEffect, watch, type PropType, computed } from 'vue';
import { type ISeries, type TrendResponse } from '../../api/dtos/TrendDto';
import { storeToRefs } from 'pinia';
import { useChartsStore } from '../../stores/chart';
import { type TagModel } from '@/models/TagModel';
import { useWindowSize } from '@vueuse/core';

const props = defineProps({
	secondChartData: {
		type: Array as PropType<TagModel[]>,
		required: true,
	},
	secondChartNames: {
		type: Array,
		required: true,
	},
	clickedTagName: {
		type: String,
		required: false,
	},
});

const mainChartRef = ref<Component & ComponentOptions<VueApexChartsComponent>>({});

const options1 = ref({
	chart: {
		id: 'chart2',
		type: 'area',
		height: 230,
		foreColor: '#ccc',
		toolbar: {
			autoSelected: 'pan',
			show: false,
		},
	},
	legend: {
		show: false,
	},
	colors: ['#8c9eff', '#84ffff', '#ffcea1', '#ff80ab', '#ffb0e8', '#3d5afe', '#00e5ff', '#ff6700', '#f50057', '#be007a'],
	stroke: {
		width: 3,
	},
	grid: {
		borderColor: '#555',
		clipMarkers: false,
		yaxis: {
			lines: {
				show: false,
			},
		},
	},
	dataLabels: {
		enabled: false,
	},
	fill: {
		gradient: {
			enabled: true,
			opacityFrom: 0.55,
			opacityTo: 0,
		},
	},
	markers: {
		size: 5,
		colors: ['#000524'],
		strokeColor: '#00BAEC',
		strokeWidth: 3,
	},
	tooltip: {
		theme: 'dark',
	},
	xaxis: {
		type: 'datetime',
		tooltip: {
			enabled: false,
		},
	},
	yaxis: {
		min: 0,
		tickAmount: 2,
	},
});

const options2 = ref({
	chart: {
		id: 'chart1',
		height: 130,
		type: 'bar',
		foreColor: '#ccc',
		brush: {
			target: 'chart2',
			enabled: true,
		},
		selection: {
			enabled: true,
			fill: {
				color: '#fff',
				opacity: 0.4,
			},
			xaxis: {
				min: new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0),
				max: new Date().setHours(23, 59, 59),
			},
		},
	},
	legend: {
		show: false,
	},
	colors: ['#8c9eff', '#84ffff', '#ffcea1', '#ff80ab', '#ffb0e8', '#3d5afe', '#00e5ff', '#ff6700', '#f50057', '#be007a'],
	stroke: {
		width: 2,
	},
	grid: {
		borderColor: '#444',
	},
	markers: {
		size: 0,
	},
	xaxis: {
		type: 'datetime',
		tooltip: {
			enabled: false,
		},
	},
	yaxis: {
		tickAmount: 2,
		min: 0,
	},
});

const series = ref<ISeries[]>([]);
const secondaryChartRef = ref<VueApexChartsComponent | null>(null);
const firstChartKey = ref(0);
const secondChartKey = ref(1);

const chartsStore = useChartsStore();
const { selectedDate } = storeToRefs(chartsStore);
const { width, height } = useWindowSize();

const firstChartHeight = computed(() => height.value < 500 ? '50%' : height.value / 4);
const secondChartHeight = computed(() => height.value < 500 ? '50%' : height.value / 6);

function handleBrush(xaxis: ApexXAxis, brushChartContext?: ApexChart) {
	mainChartRef.value.zoomX(xaxis.min, xaxis.max);
}

function createSeries(response: TrendResponse): [number, number][] {
	return response.map((el) => [new Date(`${el.logtime}`).getTime(), el.logvalue]);
}

function toggleSeries(clickedTagName: string) {
	if (props.secondChartNames.find((el) => el === clickedTagName)) {
		mainChartRef?.value?.toggleSeries(clickedTagName);
		secondaryChartRef?.value?.toggleSeries(clickedTagName);
	}
}

function transformDateToXAxis(selectedDate: Date) {
	const minDate = new Date(selectedDate);
	minDate.setDate(minDate.getDate() - 7);
	minDate.setHours(0, 0, 0, 0);

	const maxDate = new Date(selectedDate);
	maxDate.setHours(23, 59, 59, 999);

	return {
		min: minDate.getTime(),
		max: maxDate.getTime(),
	};
}

watchEffect(() => {
	if (props.secondChartData) {
		series.value = props.secondChartData
			.filter((tag) => tag.trend && tag.trend.length > 0)
			.map((tag) => {
				return {
					name: tag.name as string,
					data: createSeries(tag.trend as TrendResponse),
				};
			});
	}
});

watch(
	() => props.clickedTagName,
	(newValue) => {
		if (newValue) {
			toggleSeries(newValue);
		}
	}
);

watch(selectedDate, (newSelectedDate) => {
	if (newSelectedDate) {
		options2.value.chart.selection.xaxis = transformDateToXAxis(newSelectedDate);
		const result = transformDateToXAxis(newSelectedDate);
		handleBrush(result);
	}
});

watch(firstChartHeight, (newValue) => {
	if (newValue) {
		firstChartKey.value++;
		secondChartKey.value++;
	}
});
</script>

<template>
	<div id="wrapper">
		<VueApexCharts
			width="100%"
			:height="firstChartHeight"
			type="bar"
			:options="options1"
			:series="series"
			:key="firstChartKey"
			ref="mainChartRef"
		/>
		<VueApexCharts
			ref="secondaryChartRef"
			width="100%"
			type="area"
			:height="secondChartHeight"
			:options="options2"
			:series="series"
			:key="secondChartKey"
			@selection="(brushChartContext: ApexChart, { xaxis }: any) => handleBrush(xaxis, brushChartContext)"
		/>
	</div>
</template>

<style lang="scss" scoped>
:deep(.svg_select_points) {
	fill: #e64e19;
}
</style>
