<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.24479 10.698V3.65578L3.9982 5.90236L2.7885 4.64946L7.10886 0.329102L11.4292 4.64946L10.2195 5.90236L7.97293 3.65578V10.698H6.24479ZM1.92443 14.1542C1.44919 14.1542 1.04222 13.9849 0.7035 13.6462C0.364784 13.3074 0.195714 12.9008 0.196291 12.4261V9.83388H1.92443V12.4261H12.2933V9.83388H14.0214V12.4261C14.0214 12.9013 13.8521 13.3083 13.5134 13.647C13.1746 13.9857 12.7679 14.1548 12.2933 14.1542H1.92443Z"
      fill="white"
    />
  </svg>
</template>
