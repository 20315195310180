import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useEventsApi } from '@/api/eventsApi';
import { useAlarmsApi } from '@/api/alarmsApi';
import { useNotesApi } from '@/api/notesApi';

interface DateDto {
  s: number,
  e: number,
}

export const useEventsStore = defineStore('events', () => {
  const eventsApi = useEventsApi();
  const alarmsApi = useAlarmsApi();
  const notesApi = useNotesApi();
  const loading = ref(false);
  const selectedDate = ref();
  const location = ref();
  const alarmEvents = ref([]);
  const actionEvents = ref([]);
  const notesEvents = ref([]);

  function setSelectedDate(newDate: DateDto) {
    selectedDate.value = newDate;
  }

  function setEventsDate(todaysDate: Date, locationId: string) {
    location.value = locationId;
    const currentDate = todaysDate;
    const pastDate = new Date(currentDate);
    pastDate.setFullYear(currentDate.getFullYear() - 5);
    const pastDateTimeStamp = pastDate.getTime() / 1000;
    const currentDateTimeStamp = currentDate.getTime() / 1000;
    setSelectedDate({
        s: pastDateTimeStamp,
        e: currentDateTimeStamp
    })
  }

  watch(
    [selectedDate, location],
    async (date) => {
        if (date && location.value) {
            try {
                  loading.value = true;
                  const queryParams = selectedDate.value;
                  const alarmsResponse = await alarmsApi.getAlarmsForLocation(location.value, queryParams);
                  const eventsResponse = await eventsApi.getEventsForLocation(location.value, queryParams);
                  const notesResponse = await notesApi.getNotesForLoaction(location.value);
                  const events = eventsResponse.actions.slice(0, 20);
                  const alarms = alarmsResponse.alarms.slice(0,20);
                  const notes = notesResponse.notes.slice(0, 20);
                  const outputList = [];
                  for (const item of alarms) {
                    const statusMap = {
                      accept: 'Accept',
                      down: 'Down',
                      up: 'Up',
                    };
                    for (const key of Object.keys(statusMap)) {
                      const status = statusMap[key];
                      const timestamp = item.alarm[key];
                  
                      if (timestamp !== null) {
                        const newItem = {
                          text: item.alarm.text,
                          tagname: item.alarm.tagname,
                          priorityid: item.alarm.priorityid,
                          priority: item.alarm.priority,
                          timestamp: timestamp,
                          status: status,
                        };
                        outputList.push(newItem);
                      }
                    }
                  }

                  actionEvents.value = events;
                  alarmEvents.value = outputList;
                  notesEvents.value = notes;
    
            } catch (error) {
                loading.value = false;
                console.error('Something went wrong: ', error);
            } finally {
                loading.value = false;
            }
        }
    },
  );

  return {
    setSelectedDate,
    setEventsDate,
    loading,
    alarmEvents,
    actionEvents,
    notesEvents
  };
});
