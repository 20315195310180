import { useApi, type ApiLoadingType } from "@/composables/api";
import type { AlarmResponse, AlarmsResponse, AlarmLogResponse } from "./dtos/AlarmDto";

export interface AlarmsQueryParams {
  s?: number
  e?: number
  priority?: number
  groupid?: number
  serviceid?: number
  textid?: string
  filter?: "all" | "down" | "notdown" | "accepted" | "notaccepted" | "active" | "closed";
}


export interface IAlarms {
  getAlarms(queryParams?: AlarmsQueryParams): Promise<AlarmsResponse>;
  getAlarmsForLocation(locationId: string, queryParams?: AlarmsQueryParams):  Promise<AlarmResponse>;
  getAlarmLog(alarmId: string): Promise<AlarmLogResponse>;
  acceptAlarm(alarmId: string): Promise<any>; 
}


const API_VERSIONING = 'api/v1';

export function useAlarmsApi() : IAlarms & ApiLoadingType {
  const api = useApi();
  const { loading,  errors, clearErrors } = api;

  async function getAlarms(queryParams?: AlarmsQueryParams): Promise<AlarmsResponse> {
    return api.get(`${API_VERSIONING}/alarms`, {}, {
      params: queryParams
    });
  }
  async function getAlarmsForLocation(locationId: string, queryParams?: AlarmsQueryParams):  Promise<AlarmResponse> {
    return api.get(`${API_VERSIONING}/locations/${locationId}/alarms`, {}, {
      params: queryParams
    })
  }  
  async function getAlarmLog(alarmId: string):  Promise<AlarmLogResponse> {
    return api.get(`${API_VERSIONING}/alarms/${alarmId}/log`)
  }
  async function acceptAlarm(alarmId: string):  Promise<any> {
    return api.put(`${API_VERSIONING}/alarms/${alarmId}`, {
      "accept": true
    })
  }


  return {
    getAlarms,
    getAlarmsForLocation,
    getAlarmLog,
    loading,
    errors,
    clearErrors,
    acceptAlarm
  }

}