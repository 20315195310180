<script setup lang="ts">
import { ref, watch } from 'vue';
import Dashboard from '@/components/composites/Dashboard.vue'
import DashboardDataInitialization from '@/components/composites/DashboardDataInitialization.vue'
import { useInitialDashboardDataStore } from '@/stores/initialDashboardData';
import { storeToRefs } from 'pinia';

const initialDashboardDataStore = useInitialDashboardDataStore();
const { isDashboardInitialized } = storeToRefs(initialDashboardDataStore);
const initializationState = ref<'not-started' | 'pending' | 'settled'>('not-started');

watch( [() => isDashboardInitialized.value, () => initializationState.value], async ([newIsDashboardInitialized, newInitializationState]) => {
  if( newIsDashboardInitialized === false && newInitializationState == 'not-started') {    
    initializationState.value = 'pending';
    
    try {
      await initialDashboardDataStore.createInitialDashboardViews();      
    } finally {
      initializationState.value = 'settled';
    }
  }else if(isDashboardInitialized.value === true) {
    initializationState.value = 'settled'
  }  
}, { immediate: true })


</script>

<template>  
  <PerfectScrollbar class="h-screen w-screen scroll-container">  
    <div class="scroll-content min-w-screen flex flex-row justify-end">
      <div class="min-h-full min-w-full">      
        <Dashboard v-if="isDashboardInitialized && initializationState === 'settled'"/> 
        <DashboardDataInitialization v-else-if="initializationState !== 'settled'"/>
      </div>
    </div>
  </PerfectScrollbar>
</template>

<style scoped lang="scss">
/* header and footer height with padding and borders */
$header-height: 76px;
$footer-height: 67px;
.scroll-content {
  min-height: calc(100vh - ($header-height + $footer-height));  
}
.scroll-container {
  padding-top: $header-height;
  padding-bottom: $footer-height;
  
  background: linear-gradient(180deg, #2F3336 0%, #1A1B1E 100%);  
}

</style>
