<script lang="ts" setup>
import { computed, ref, type PropType, type Ref } from 'vue';
import RadarLevelWidget from './RadarLevelWidget.vue';
import type { SelectedRadarLeveWidgetTab } from './radar-level-widget-types';
import EyeWhite from '@/components/icons/EyeWhite.vue';
import EyeBlack from '@/components/icons/EyeBlack.vue';
import PowerOnWhite from '@/components/icons/PowerOnWhite.vue';
import PowerOnBlack from '@/components/icons/PowerOnBlack.vue';
import AlarmBellWhite from '@/components/icons/AlarmBellWhite.vue';
import AlarmBellBlack from '@/components/icons/AlarmBellBlack.vue';

import type { TagModel } from '@/models/TagModel';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  connectedMode: {
    type: Boolean,
    default: false
  },

  unitSymbol: {
    type: String,
    default: "N/A"
  },
  valueDescription: {
    type: String, 
    default: "Config object not valid"
  },

  valueTag: {
    type: Object as PropType<TagModel>,    
  },

  upperAlarmBoundTag: {
    type: Object as PropType<TagModel>,    
  },
  lowerAlarmBoundTag: {
    type: Object as PropType<TagModel>,    
  },
  upperPumpBoundTag: {
    type: Object as PropType<TagModel>,    
  },
  lowerPumpBoundTag: {
    type: Object as PropType<TagModel>,    
  },
  totalUpperBoundaryTag: {
    type: Object as PropType<TagModel>,    
  },
  totalLowerBoundaryTag: {
    type: Object as PropType<TagModel>,    
  }
});

const emit = defineEmits<{
  (e: 'upper-alarm-bound-update', newValue: number): void;  
  (e: 'lower-alarm-bound-update', newValue: number): void;  
  (e: 'upper-pump-bound-update', newValue: number): void;  
  (e: 'lower-pump-bound-update', newValue: number): void;  
  (e: 'selected-tab-changed', newSelectedTag: SelectedRadarLeveWidgetTab): void
}>();


type TagPropKeys = keyof Pick<typeof props, 'valueTag' | 'upperAlarmBoundTag' | 'lowerAlarmBoundTag' | 
  'upperPumpBoundTag' | 'lowerPumpBoundTag' | 'totalUpperBoundaryTag' | 'totalLowerBoundaryTag'>;


function convertToNumber(value: string | undefined) {
  const result = Number(value);
  return result
}

function useConvertedValue(propKey: TagPropKeys): Ref<number> {
  return computed( () => convertToNumber((props[propKey])?.value));

}

function useSetConvertedValue(propKey: TagPropKeys): Ref<number> {
  return computed( () => convertToNumber(props[propKey]?.setting?.value))
}

const valueValue = useConvertedValue('valueTag');

const upperAlarmBoundValue = useConvertedValue('upperAlarmBoundTag');
const setUpperAlarmBoundValue = useSetConvertedValue('upperAlarmBoundTag');
const lowerAlarmBoundValue = useConvertedValue('lowerAlarmBoundTag');
const setLowerAlarmBoundValue = useSetConvertedValue('lowerAlarmBoundTag')

const upperPumpBoundValue = useConvertedValue('upperPumpBoundTag');
const setUpperPumpBoundValue = useSetConvertedValue('upperPumpBoundTag');
const lowerPumpBoundValue = useConvertedValue('lowerPumpBoundTag');
const setLowerPumpBoundValue = useSetConvertedValue('lowerPumpBoundTag');

const totalUpperBoundValue = useConvertedValue('totalUpperBoundaryTag');
const totalLowerBoundValue = useConvertedValue('totalLowerBoundaryTag');

const selectedTab = ref<SelectedRadarLeveWidgetTab>('view');

function selectedTabChangeHandler(newSelectedTab: SelectedRadarLeveWidgetTab) {
  selectedTab.value = newSelectedTab;
}

function upperBoundUpdateHandler(newValue: number) {
  if(selectedTab.value == 'alarm_levels') {
    emit('upper-alarm-bound-update', newValue);
  }else if(selectedTab.value == 'power_on_off_levels') {
    emit('upper-pump-bound-update', newValue);
  }
}

function lowerBoundUpdateHandler(newValue: number) {
  if(selectedTab.value == 'alarm_levels') {
    emit('lower-alarm-bound-update', newValue);
  }else if( selectedTab.value == 'power_on_off_levels') {
    emit('lower-pump-bound-update', newValue);
  }
}

</script>

<template>
  <RadarLevelWidget  
    :edit-mode="editMode"
    :connected-mode="connectedMode"

    :value="valueValue"
    :unit-symbol="valueTag?.unit"
    :value-description="valueTag?.description"    

    :upper-bound-value="selectedTab == 'alarm_levels' ? upperAlarmBoundValue : upperPumpBoundValue"
    :lower-bound-value="selectedTab == 'alarm_levels' ? lowerAlarmBoundValue : lowerPumpBoundValue"  

    :set-upper-bound-value="selectedTab == 'alarm_levels' ? setUpperAlarmBoundValue : setUpperPumpBoundValue"
    :set-lower-bound-value="selectedTab == 'alarm_levels' ? setLowerAlarmBoundValue : setLowerPumpBoundValue"

    :total-upper-boundary-value="totalUpperBoundValue"
    :total-lower-boundary-value="totalLowerBoundValue"
      
    :type="selectedTab == 'view' ? 'value' : 'boundries'"
    :switch-upper-and-lower-levels="selectedTab == 'power_on_off_levels'"
    :upper-bound-title="selectedTab == 'alarm_levels' ? 'ALARM' : 'PUMP'"
    :lower-bound-title="selectedTab == 'alarm_levels' ? 'ALARM' : 'PUMP'"
    @upper-bound-update="upperBoundUpdateHandler"
    @lower-bound-update="lowerBoundUpdateHandler"
  > 
    <template #tabs-container>
      <div 
        class="absolute left-0 bottom-10 flex flex-row justify-center w-full gap-2"
        :class="{'pointer-events-none': editMode}"
      >
        <div 
          class="radar-level-tab-item" 
          :class="{'radar-level-tab-item-selected': selectedTab == 'view'}"
          @click="selectedTabChangeHandler('view')"
          >
            <component :is="selectedTab == 'view' ? EyeBlack : EyeWhite" />
        </div>
        <div 
          class="radar-level-tab-item" 
          :class="{'radar-level-tab-item-selected': selectedTab == 'power_on_off_levels'}"
          @click="selectedTabChangeHandler('power_on_off_levels')"
          >
            <component :is="selectedTab == 'power_on_off_levels' ? PowerOnBlack : PowerOnWhite" />
        </div>
        <div 
          class="radar-level-tab-item" 
          :class="{'radar-level-tab-item-selected': selectedTab == 'alarm_levels'}"
          @click="selectedTabChangeHandler('alarm_levels')"
          >
            <component :is="selectedTab == 'alarm_levels' ? AlarmBellBlack : AlarmBellWhite"/>
        </div>
      </div>
    </template>
  </RadarLevelWidget>
</template>

<style scoped>
.radar-level-tab-item {
  @apply inline-flex justify-center items-center;
  background-color: rgba(26, 40, 35, 1);
  border-radius: 6px;
  width: 48px;
  height: 30px;   
  transition: background-color 0.2s ease;
}

.radar-level-tab-item:active {
  opacity: 0.8;
}

.radar-level-tab-item svg {
  scale: 1.2;
}
.radar-level-tab-item-selected {
  background-color: white;
}
</style>