<template>
  <svg 
    width="12" 
    height="12" 
    viewBox="0 0 12 12" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      id="Vector" 
      d="M10.8857 6.9375H6.79395V11.0293C6.79395 11.2463 6.70773 11.4545 6.55425 11.608C6.40078 11.7614 6.19263 11.8477 5.97559 11.8477C5.75854 11.8477 5.55039 11.7614 5.39692 11.608C5.24345 11.4545 5.15723 11.2463 5.15723 11.0293V6.9375H1.06543C0.848387 6.9375 0.640235 6.85128 0.486762 6.69781C0.33329 6.54434 0.24707 6.33618 0.24707 6.11914C0.24707 5.9021 0.33329 5.69395 0.486762 5.54047C0.640235 5.387 0.848387 5.30078 1.06543 5.30078H5.15723V1.20898C5.15723 0.991942 5.24345 0.783789 5.39692 0.630317C5.55039 0.476845 5.75854 0.390625 5.97559 0.390625C6.19263 0.390625 6.40078 0.476845 6.55425 0.630317C6.70773 0.783789 6.79395 0.991942 6.79395 1.20898V5.30078H10.8857C11.1028 5.30078 11.3109 5.387 11.4644 5.54047C11.6179 5.69395 11.7041 5.9021 11.7041 6.11914C11.7041 6.33618 11.6179 6.54434 11.4644 6.69781C11.3109 6.85128 11.1028 6.9375 10.8857 6.9375Z" 
      fill="white"
    />
  </svg>  
</template>