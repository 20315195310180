<template>
  <svg
    width="27"
    height="20"
    viewBox="0 0 27 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.1027 0.739746H2.60266C2.10538 0.739746 1.62847 0.93729 1.27684 1.28892C0.925205 1.64055 0.727661 2.11747 0.727661 2.61475V5.42725C0.727661 5.92453 0.925205 6.40144 1.27684 6.75307C1.62847 7.1047 2.10538 7.30225 2.60266 7.30225V17.6147C2.60266 18.112 2.80021 18.5889 3.15184 18.9406C3.50347 19.2922 3.98038 19.4897 4.47766 19.4897H23.2277C23.7249 19.4897 24.2019 19.2922 24.5535 18.9406C24.9051 18.5889 25.1027 18.112 25.1027 17.6147V7.30225C25.5999 7.30225 26.0769 7.1047 26.4285 6.75307C26.7801 6.40144 26.9777 5.92453 26.9777 5.42725V2.61475C26.9777 2.11747 26.7801 1.64055 26.4285 1.28892C26.0769 0.93729 25.5999 0.739746 25.1027 0.739746ZM23.2277 17.6147H4.47766V7.30225H23.2277V17.6147ZM25.1027 5.42725H2.60266V2.61475H25.1027V5.42725ZM10.1027 11.0522C10.1027 10.8036 10.2014 10.5651 10.3772 10.3893C10.5531 10.2135 10.7915 10.1147 11.0402 10.1147H16.6652C16.9138 10.1147 17.1523 10.2135 17.3281 10.3893C17.5039 10.5651 17.6027 10.8036 17.6027 11.0522C17.6027 11.3009 17.5039 11.5393 17.3281 11.7152C17.1523 11.891 16.9138 11.9897 16.6652 11.9897H11.0402C10.7915 11.9897 10.5531 11.891 10.3772 11.7152C10.2014 11.5393 10.1027 11.3009 10.1027 11.0522Z"
      fill="#ABADAD"
    />
  </svg>
</template>
