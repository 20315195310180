import { registerWidget } from "./utils/widgets-map";
import { defineAsyncComponent } from 'vue'
import ProgressBar from './components/ProgressBar.vue';
import WidgetNotFound from './components/widgets/WidgetNotFound.vue';
import PumpingStation from './components/widgets/PumpingStationWidget.vue';
import WeatherWidgetConfigContent from "./components/widgets/weather-widget/WeatherWidgetConfigContent.vue";

import AlarmsWidgetIcon from "./components/icons/AlarmsWidgetIcon.vue";
import CameraWidgetIcon from "./components/icons/CameraWidgetIcon.vue";
import ConsumptionWidgetIcon from "./components/icons/ConsumptionWidgetIcon.vue";
import FlowWidgetIcon from "./components/icons/FlowWidgetIcon.vue";
import GraphWidgetIcon from "./components/icons/GraphWidgetIcon.vue";
import PercentageWidgetIcon from "./components/icons/PercentageWidgetIcon.vue";
import PercentageLabelWidgetIcon from "./components/icons/PercentageLabelWidgetIcon.vue";
import ProgressbarWidgetIcon from "./components/icons/ProgressbarWidgetIcon.vue";
import PumpingStationWidgetIcon from "./components/icons/PumpingStationWidgetIcon.vue";
import WeatherWidgetIcon from "./components/icons/WeatherWidgetIcon.vue";
import NotFoundWidgetIcon from "./components/icons/NotFoundWidgetIcon.vue";
import RadarLevelWidgetIcon from "./components/icons/RadarLevelWidgetIcon.vue";
import EnergyWidgetIcon from "./components/icons/EnergyWidgetIcon.vue";
import ToggleWidgetIcon from "./components/icons/ToggleWidgetIcon.vue";
import { RadarLevelWidgetConfigContent } from './components/widgets/radar-level-widget';
import { autoSelectDropdownsDescriptors } from './components/widgets/toggle-widget/ToggleWidgetConfigContentProps';

const PercentageWidget = defineAsyncComponent({
  loader: () => import('@wisionmonorepo/ui/src/widgets/PercentageWidget.vue'),
  loadingComponent: ProgressBar
});
const PercentageWidgetLabel = defineAsyncComponent({
  loader: () => import('@wisionmonorepo/ui/src/widgets/PercentageWidgetLabel.vue'),
  loadingComponent: ProgressBar
});

const ConsumptionWidget = defineAsyncComponent({
  loader: () => import('@wisionmonorepo/smarthub/src/components/widgets/consumption-widget/ConsumptionWidgetController.vue'),
  loadingComponent: ProgressBar
})

const ConsumptionWidgetConfigContent = defineAsyncComponent({
  loader: () => import('@wisionmonorepo/smarthub/src/components/widgets/consumption-widget/ConsumptionWidgetConfgContent.vue')
})

const DefaultWidgetConfigContent = ConsumptionWidgetConfigContent;
const LocationSelectConfigContent = WeatherWidgetConfigContent;

const GraphWidget = defineAsyncComponent({
  loader: () => import('@wisionmonorepo/smarthub/src/components/widgets/graph-widget/GraphWidgetController.vue'),
  loadingComponent: ProgressBar
});

const AlarmsWidget = defineAsyncComponent({
  loader: () => import('@wisionmonorepo/smarthub/src/components/widgets/alarms-widget/AlarmsWidgetController.vue'),
  loadingComponent: ProgressBar
})

const WeatherWidget = defineAsyncComponent({
  loader: () => import('@wisionmonorepo/smarthub/src/components/widgets/weather-widget/WeatherWidgetController.vue'),
  loadingComponent: ProgressBar
})

const CameraWidget = defineAsyncComponent({
  loader: () => import('@wisionmonorepo/smarthub/src/components/widgets/camera-widget/CameraWidgetController.vue'),
  loadingComponent: ProgressBar
});

const WaterWidget = defineAsyncComponent({
  loader: () => import ('@wisionmonorepo/smarthub/src/components/widgets/water-widget/WaterWidgetController.vue'),
  loadingComponent: ProgressBar
});

const RadarLevelWidget = defineAsyncComponent({
  loader: () => import ('@wisionmonorepo/smarthub/src/components/widgets/radar-level-widget/RadarLevelWidgetController.vue'),
  loadingComponent: ProgressBar
});

const RadarLevelWidgetConfgContent = defineAsyncComponent({
  loader: () => import('@wisionmonorepo/smarthub/src/components/widgets/radar-level-widget/RadarLevelWidgetConfigContent.vue')
})

const ToggleWidget = defineAsyncComponent({
  loader: () => import('@wisionmonorepo/smarthub/src/components/widgets/toggle-widget/ToggleWidgetController.vue'),
  loadingComponent: ProgressBar
});


////
registerWidget("WidgetNotFound", {  
  component: WidgetNotFound,
  componentName: "Widget Not Found",
  componentDesc: "Widget not found desc",
  componentIcon: NotFoundWidgetIcon
}, undefined, true);

registerWidget("Alarms", {  
  component: AlarmsWidget,
  componentName: "Alarms Widget",
  componentDesc: "Alarms Description",
  componentIcon: AlarmsWidgetIcon,
  w: 5,
  h: 15,
  mW: 2.5,
  mH: 5,
});

registerWidget("Weather", {  
  component: WeatherWidget,
  componentName: "Weather Widget",
  componentDesc: "Display Weather For Location",
  componentIcon: WeatherWidgetIcon,
  mW: 3,
  mH: 11,
  w: 3,
  h: 11,
}, {
  configContentComponent: WeatherWidgetConfigContent,
  shouldShowConfigDialog: selectedLocation => selectedLocation == null,
  configDialogProps: {
    scrollingBehaviour: 'expanding-overflow',
  }
});

registerWidget("ProgressBar", {  
  component: ProgressBar,
  componentName: "Progressbar",
  componentDesc: "Loading progressbar",
  componentIcon: ProgressbarWidgetIcon
}, undefined, true);

registerWidget("PumpingStation", {  
  component: PumpingStation,
  componentName: "Pumping Station",
  componentDesc: "Pumping station 3d model",
  componentIcon: PumpingStationWidgetIcon,
  w: 4,
  h: 11,
  mW: 3,
  mH: 6
});
////

registerWidget("PercentageWidget", {  
  component: PercentageWidget,
  componentName: "Percentage Widget",
  componentDesc: "Display values in form of percentage",
  componentIcon: PercentageWidgetIcon
});

registerWidget("PercentageWidgetLabel", {  
  component: PercentageWidgetLabel,
  componentName: "Percentage Widget Label",
  componentDesc: "Display percentage values with label",
  componentIcon: PercentageLabelWidgetIcon
});

registerWidget("ConsumptionWidget", {
  component: ConsumptionWidget,
  componentName: "Consumption Widget",
  componentDesc: "Display consumption level",
  componentIcon: ConsumptionWidgetIcon,
}, {
  configContentComponent: ConsumptionWidgetConfigContent,
  configDialogProps: {
    scrollingBehaviour: 'expanding-overflow'
  }  
}
)

registerWidget("GraphWidget", {
  component: GraphWidget,
  componentName: "Graph Widget",
  componentDesc: "Display graph",
  componentIcon: GraphWidgetIcon,
}, {
  configContentComponent: DefaultWidgetConfigContent,
  configContentProps: {
    multiselect: false,
  }
}
)

registerWidget("WaterWidget", {
  component: WaterWidget,
  componentName: "Flow Widget",
  componentDesc: "Display flow",
  componentIcon: FlowWidgetIcon
}, {
  configContentComponent: ConsumptionWidgetConfigContent,
  configContentProps: {
    multiselect: false,
  }
}
);

registerWidget("CameraWidget", {  
  component: CameraWidget,
  componentName: "Camera Widget",
  componentDesc: "Display camera",
  componentIcon: CameraWidgetIcon
}, {
  configContentComponent: LocationSelectConfigContent,
  shouldShowConfigDialog: selectedLocation => selectedLocation == null,
  configDialogProps: {
    scrollingBehaviour: 'expanding-overflow',
  },
});

registerWidget("RadarLevelWidget", {
  component: RadarLevelWidget,
  componentName: "Radar Level Widget",
  componentDesc: "Display levels",
  componentIcon: RadarLevelWidgetIcon,
}, {
  configContentComponent: RadarLevelWidgetConfgContent,
  configDialogProps: {
    //Temporary solution, should find some better approach
    scrollingBehaviour: window?.innerWidth > 480 ?  'expanding-overflow' : 'expanding-scroll'
  }
}
);

registerWidget(
	'ToggleWidget',
	{
		component: ToggleWidget,
		componentName: 'Toggle Widget',
		componentDesc: 'Display toggle',
    componentIcon: ToggleWidgetIcon
	},
	{
    configContentComponent: RadarLevelWidgetConfigContent,
    configContentProps: {
      autoSelectDropdownsDescriptors
    }
	}
);