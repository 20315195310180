<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5339 9.66833C1.26085 9.66833 1.02703 9.57103 0.832426 9.37643C0.637822 9.18182 0.540686 8.94817 0.541016 8.67546V1.7253C0.541016 1.45226 0.638319 1.21844 0.832923 1.02383C1.02753 0.829228 1.26118 0.732092 1.5339 0.732423H4.51253V1.7253H1.5339V8.67546H8.48405V5.69682H9.47693V8.67546C9.47693 8.9485 9.37963 9.18232 9.18502 9.37692C8.99042 9.57153 8.75676 9.66866 8.48405 9.66833H1.5339ZM6.99473 4.70394V3.21462H5.50541V2.22174H6.99473V0.732423H7.98761V2.22174H9.47693V3.21462H7.98761V4.70394H6.99473Z"
      fill="white"
    />
  </svg>
</template>
