import { useConnectedModeStore, type ConnectedValueRefType } from "@/stores/connectedMode"
import { onBeforeUnmount, onMounted, watch } from "vue";

export const useWidgetConnectedMode = function(locationIdProvider: () => string, tagIdProvider: () =>  string, stateValueRefProvider: () => ConnectedValueRefType) {

  let lastConfigState: {
    locationId: string,
    tagId: string,
    stateValueRef: ConnectedValueRefType
  } | null;

  const locationId = locationIdProvider();
  const tagId = tagIdProvider()
  const stateValueRef = stateValueRefProvider()

  watch([locationIdProvider, tagIdProvider, stateValueRefProvider] , (newValues, previousValues) => {
    const [newLocationId, newTagId, newStateValueRef] = newValues;
    const [previousLocationId, previousTagId, previousStateValueRef] = previousValues;
    if(previousLocationId && previousTagId && previousStateValueRef) {      
      connectedModeStore.unsubscribeToConnectedMode(previousLocationId, previousTagId, previousStateValueRef);
      lastConfigState = null;
    }

    if(newLocationId && newTagId && newStateValueRef) {
      connectedModeStore.subscribeToConnectedMode(newLocationId, newTagId, newStateValueRef);
      lastConfigState = {
        locationId: newLocationId,
        tagId: newTagId,
        stateValueRef: newStateValueRef
      }
    }
  });

  const connectedModeStore = useConnectedModeStore();  

  onMounted(() => {

    if(locationId && tagId && stateValueRef) {
      connectedModeStore.subscribeToConnectedMode(locationId, tagId, stateValueRef);
      lastConfigState = {
        locationId,
        tagId,
        stateValueRef,
      };  
    }

  });

  onBeforeUnmount(() => {
    if(lastConfigState) {
      connectedModeStore.unsubscribeToConnectedMode(lastConfigState.locationId, lastConfigState.tagId, lastConfigState.stateValueRef);
      lastConfigState = null;
    }
  })
}