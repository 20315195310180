<script lang="ts">
export interface NavRoute {
  to: string,
  icon: Component,
  title: string
}
</script>
<script setup lang="ts">
import type { Component } from 'vue';
import { onMounted, ref, watch } from 'vue';
import EventsIcon from '../icons/EventsIcon.vue';
import { useFooterStore } from '../../../smarthub/src/stores/footer';
import { storeToRefs } from 'pinia';

const props = defineProps<{
  routerLinkComponent: Component,
  routes: Array<NavRoute>,
  initialRoute: string,
  router: any,
  route: any
}
>();

const selectedIndex = ref<number>(props.routes.findIndex(route => route.to == props.initialRoute) ?? -1);
const listItemRef = ref();
const linkRef = ref();
const activePage = ref();
const footerStore = useFooterStore();

const { isFooterVisible } = storeToRefs(footerStore);

const onClickHandler = function (index: number, $event: MouseEvent) {
  if (selectedIndex.value != index) {
    selectedIndex.value = index;
    const listElement = listItemRef.value?.[index];
    const linkElement: HTMLElement = linkRef.value?.[index].$el;
    if (listElement && linkElement && listElement == $event.target) {
      linkElement.click();
    }
  }

};

const getUrlQueryParams = async () => {
  await props.router.isReady();
};

onMounted(() => {
  getUrlQueryParams();
  activePage.value = props.route?.fullPath?.substring(1);
});

watch(props.route, () => {
  activePage.value = props.route?.fullPath?.substring(1);
});

</script>

<template>
  <footer>
    <nav
      class="nav__bottom"
      :style="[{ display: !isFooterVisible ? 'none' : '' }]"
    >
      <div class="flex items-center justify-between nav_div">
        <div class="nav__item ml-2 max-sm:hidden">
          <div class="smarthub-logo flex items-center gap-x-3 cursor-pointer">
            <EventsIcon
              class="event-icon"
              style="width: 24px; height: 25px"
            />
            <span class="text-white logo-text">smarthub</span>
          </div>
        </div>
        <ul
          class="nav__item app-links"
          style="justify-content: space-evenly"
        >
          <li 
            v-for="(route, index) in routes" 
            :key="route.to"
            ref="listItemRef"
            :class="{ active: route.title.toLowerCase() === activePage }"
            @click="onClickHandler(index, $event)"
          >
            <component
              :is="routerLinkComponent"
              ref="linkRef"
              class="router-link"
              :to="route.to"
            >
              <component :is="route.icon" /> {{ route.title }}
            </component>
          </li>
        </ul>
        <div class="nav__item settings cursor-pointer text-right text-white pr-2 max-sm:hidden">
          we are wienerberger
        </div>
      </div>
    </nav>
  </footer>
</template>

<style lang="scss" scoped>
.nav__bottom {
  position: fixed;
  z-index: 100;
  bottom: 0;
  // display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #1a1b1e;
  left: 0;

  .logo-text {
    font-size: 22.8px;
  }

  .nav__item {
    // flex: 33.3333333%;

    @media (max-width: 768px) {
      .logo-text {
        // display: none;
      }
    }
  }

  @media (max-width: 992px) {
    justify-content: center;
  }
}

.app-links {
  display: flex;

  li {
    display: flex;
    flex: 20% 0 1;
    cursor: pointer;
    padding: 5px 35px 0;
    color: #fff;
    border-top: 5px solid transparent;
    text-align: center;
    font-size: 15px;
    display: flex;
    align-items: end;
    line-height: 22px;

    .router-link {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 1;
    }

    svg {
      margin-bottom: 2px;
      height: 30px;
      width: 28px;
    }

    &.active {
      border-top-color: #15B1CA;
      background: linear-gradient(0deg, #1c1c1c 0%, #282a2b 100%);
    }

    @media (max-width: 865px) {
      padding: 5px 25px 0;
    }
    @media (max-width: 768px) {
      font-size: 12.8px;
      padding: 5px 20px 0;
    }
    @media (max-width: 674px) {
      .logo-text {
        font-size: 16px;
    }
      padding: 5px 15px 0;
    }
    @media (max-width: 400px) {
      padding: 5px 18px 0;
    }
    @media (max-width: 370px) {
      padding: 5px 0px 0;
    }
  }
}
.nav_div {
  @media (max-width: 640px) {
    justify-content: center;
    ul {
      width: 100%;
    }
  }
}

@media (max-width: 674px) {
  .logo-text {
    font-size: 16px !important;
  }
}
</style>
