import { ref } from 'vue';
import { defineStore } from 'pinia'

export const useFooterStore = defineStore('footer', () => {
  const state = {
    isFooterVisible: ref<boolean>(true),
  }

  function toogleFooter() {
    if (window.innerWidth <= 430) {
      state.isFooterVisible.value = !state.isFooterVisible.value;
    }
  }

  return { ...state, toogleFooter }
});


