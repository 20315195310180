<script setup lang="ts">
import { PropType, computed, ref } from 'vue';
import { File } from '../../../smarthub/src/api/dtos/FilesDto';

const emit = defineEmits(['closeModal', 'handleFileRename']);

const props = defineProps({
  file: {
    type: Object as PropType<File>,
    required: true,
  },
  isFolder: Boolean,
});

const fileName = ref('');
const isInvalidName = computed(() => {
  return (
    fileName.value.length < 1 ||
    fileName.value.length >= 255 ||
    fileName.value.trim() === ''
  );
});

</script>

<template>
  <Teleport to="body">
    <div
      class="modal-overlay"
      @click="emit('closeModal')"
    >
      <div
        class="modal"
        @click.stop
      >
        <input
          v-model="fileName"
          :placeholder="`New ${props.isFolder ? 'folder' : 'file'} name`"
        >
        <p
          v-if="!isFolder"
          class="py-2 edit-warning pl-[30px] pr-[25px]"
        >
          *Chaning/removing file extension could cause file and readability issue.
        </p>
        <div class="flex items-center justify-end pt-2 pr-[25px]">
          <div class="pr-[25px] flex">
            <button @click="emit('closeModal')">
              <p class="action-text">Cancel</p>
            </button>
          </div>
          <div>
            <button
              class="danger"
              :style="`opacity: ${isInvalidName ? '0.4' : '1'}; cursor: ${isInvalidName ? 'not-allowed' : ''}`"
              :disabled="isInvalidName"
              @click="emit('handleFileRename', fileName)"
            >
              <p class="action-text">Rename</p>
            </button>
          </div>
        </div>
      </div>
      <div
        class="close"
      />
    </div>
  </Teleport>
</template>
  
<style lang="scss" scoped>

.edit-warning {
  font-style: italic;
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #FFFFFF;
  text-align: left;
}

.action-text {
  font-family: 'Avenir Next LT Pro';
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF;
}
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000da;
  z-index: 15;
  word-wrap: break-word;
}

.modal {
  text-align: center;
  background-color: #34383B;
  width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  font-family: 'Helvetica Neue';
  border: 1px solid #797979;
}
.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

p {
font-family: 'Helvetica Neue';
font-style: normal;
font-weight: 400;
font-size: 11px;
text-align: center;
color: #FFFFFF;
}

.danger {
  padding: 5px 10px;
  border-radius: 5px;
  background: #15B1CA;
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

input {
    color: black;
    padding-left: 10px;
    width: 246.97px;
    height: 30px;
    left: 15.97px;
    top: 16.31px;
    box-sizing: border-box;
    background: #34383B;
    border: 1px solid #797979;
    border-radius: 50px;
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
}
</style>
