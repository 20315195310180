import { IS_DEVELOPMENT } from "@/config/environment";

export function createLogger(title: string | null= null) {
  let loggerEnabled = true;
  function setLoggerEnabled(isLoggerEnabled: boolean) {
    loggerEnabled = isLoggerEnabled;
  }

  function fullMessage(msg: string) {
    return title ?
      `${title} : ${msg}`
      : msg;      
  }

  function log(msg: string, ...args: any[]){
    loggerEnabled && console.log(fullMessage(msg), ...args);
  }

  function error(msg: string, ...args: any[]) {
    loggerEnabled && console.error(fullMessage(msg), ...args);
  }

  function warn(msg: string, ...args: any[]) {
    loggerEnabled && console.warn(fullMessage(msg), ...args);
  }

  return {
    setLoggerEnabled,
    log,
    error,
    warn
  }  
}

export const defaultLogger = createLogger();
if(!IS_DEVELOPMENT) {
  defaultLogger.setLoggerEnabled(false);
}
