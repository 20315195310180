<script lang="ts" setup>
import { ref, type PropType } from 'vue';

defineProps({
  placeholder: {
    type: String,    
  },
  modelValue: {
    type: String as PropType<String | null>,
  },
  textAlign: {
    type: String as PropType<'left' | 'center' | 'right'>,
    default: 'left'
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: any): void
}>()

function onInputHandler(event: Event){
  emit('update:modelValue', (event?.target as HTMLInputElement).value)
}

const inputRef = ref();
defineExpose({
  inputRef
});
</script>

<template>  
  <input 
    ref="inputRef"
    class="smarthub-input-container smarthub-input-text px-3" 
    :value="modelValue"
    :placeholder="placeholder"
    @input="onInputHandler" />
</template>

<style scoped>

.smarthub-input-container {
  width: 147.421px;
  height: 30px;
  flex-shrink: 0;

  border-radius: 50px;
  border: 1px solid #797979;
  background: #2A2D2F;  
}
.smarthub-input-container::selection {  
  background-color: rgb(16, 133, 151);    
}


.smarthub-input-text {
  color: #FFF;

  text-align: v-bind(textAlign);
  font-family: 'Avenir Next LT Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */  
}

</style>