import './styles/tailwind-main.css';
import './styles/scss-main.scss';
import 'ol/ol.css';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import './widgets-registration';
import { useEagerStoreLoader } from './stores/eagerStoreLoader';

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(VCalendar, {})
app.use(PerfectScrollbar)

app.mount('#app')

useEagerStoreLoader();