import type { DashboardView } from '@/models/DashboardModel';
import shuffle from 'lodash.shuffle';

const ID_PREFIX = '365e37ec-2b7a-';
const counterMap: Record<string, number> = {};
export function createTestViewId(
  idPart: 'global-view' | 'local-view-l1' | 'local-view-l2' | 'local-view-online-demo' 
) {
  if(! (idPart in counterMap)) {
    counterMap[idPart] = 1;
  } else {
    counterMap[idPart] = counterMap[idPart] + 1
  }

  
  return `${ID_PREFIX}${idPart}-${counterMap[idPart]}`;
}

const GRID_COL = 12;

export function shuffleLayout(dashboardViewLayout: DashboardView['layout']) {
  return shuffle(dashboardViewLayout)
          .map( (layoutItem: any, layoutItemIndex: any, layoutItemsArray: any) => {
            const x = (layoutItemIndex > 0 ? (layoutItemsArray[layoutItemIndex - 1].x + layoutItemsArray[layoutItemIndex -1].w + layoutItemIndex * 2): (0)) % GRID_COL;
            const y = (layoutItem.x + layoutItemIndex * 2 + layoutItem.w) / GRID_COL > 1 ? 12 : 0;
            return {
            ...layoutItem,
            x,
            y
          }
      });
}
