import DefaultFileIconVue from "@wisionmonorepo/ui/src/icons/DefaultFileIcon.vue";
import ExcelIconVue from "@wisionmonorepo/ui/src/icons/ExcelIcon.vue";
import PdfIconVue from "@wisionmonorepo/ui/src/icons/PdfIcon.vue";
import PowerPointIconVue from "@wisionmonorepo/ui/src/icons/PowerPointIcon.vue";
import WordIconVue from "@wisionmonorepo/ui/src/icons/WordIcon.vue";
import SortUpIconVue from "@/components/icons/SortUpIcon.vue";
import SortDownIconVue from "@/components/icons/SortDownIcon.vue";
import type { Component, DefineComponent } from "vue";

export const checkFileExtention = (fileName: string) => {
  return fileName.split(".").pop();
};

type IconProps = {
  width: number;
  height: number;
};
type IconComponent = Component<IconProps>;
export const FILES_ICONS: Record<string, IconComponent> = {
  doc: WordIconVue as IconComponent,
  docx: WordIconVue as IconComponent,
  pptx: PowerPointIconVue as IconComponent,
  ppt: PowerPointIconVue as IconComponent,
  xlsx: ExcelIconVue as IconComponent,
  xls: ExcelIconVue as IconComponent,
  pdf: PdfIconVue as IconComponent,
};

export const SORT_ICONS: Record<string, DefineComponent> = {
  'asc': SortUpIconVue as DefineComponent,
  'desc': SortDownIconVue as DefineComponent,
};

export const getIcon = (extension: string): IconComponent => {
  return FILES_ICONS[extension] || DefaultFileIconVue;
};

export const FILE_EXTENTIONS = [
  "doc",
  "docx",
  "pptx",
  "ppt",
  "xlsx",
  "xls",
  "pdf",
];

export const IMAGE_EXTENTIONS = ["jpg", "jpeg", "png", "gif", "jif", "svg"];

export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)));

  return Math.round(100 * (bytes / Math.pow(k, i))) / 100 + " " + sizes[i];
};

export const formatDate = (inputDateStr: string): string => {
  const inputDate = new Date(inputDateStr);
  const currentDate = new Date();
  const timeDifferenceMs = currentDate.getTime() - inputDate.getTime();
  const units = ["year", "month", "day", "hour", "minute", "second"];
  const unitValues = [
    365.25 * 24 * 60 * 60 * 1000,
    30.44 * 24 * 60 * 60 * 1000,
    24 * 60 * 60 * 1000,
    60 * 60 * 1000,
    60 * 1000,
    1000,
  ];

  for (let i = 0; i < units.length; i++) {
    const unitValue = unitValues[i];
    if (timeDifferenceMs >= unitValue) {
      const value = Math.floor(timeDifferenceMs / unitValue);
      return `${value} ${value === 1 ? units[i] : units[i] + "s"} ago`;
    }
  }

  return "just now";
};

export const sortSelectedFilesList = (
  selectedFilesList,
  sortedBy,
  sortOrder
) => {
  selectedFilesList.sort((a, b) => {
    switch (sortedBy) {
      case "name":
        return sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      case "size":
        return sortOrder === "asc" ? a.size - b.size : b.size - a.size;
      case "updated":
        return sortOrder === "desc"
          ? new Date(a.lastmodified).valueOf() -
              new Date(b.lastmodified).valueOf()
          : new Date(b.lastmodified).valueOf() -
              new Date(a.lastmodified).valueOf();
      default:
        return 0;
    }
  });
};
