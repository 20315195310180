<template>
  <svg
    width="2"
    height="24"
    viewBox="0 0 2 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.028 2.04637C0.72 2.04637 0.434 1.80437 0.434 1.45237C0.434 1.10037 0.72 0.858371 1.028 0.858371C1.336 0.858371 1.622 1.10037 1.622 1.45237C1.622 1.80437 1.336 2.04637 1.028 2.04637ZM1.028 7.32079C0.72 7.32079 0.434 7.07878 0.434 6.72678C0.434 6.37478 0.72 6.13278 1.028 6.13278C1.336 6.13278 1.622 6.37478 1.622 6.72679C1.622 7.07878 1.336 7.32079 1.028 7.32079ZM1.028 12.5952C0.719999 12.5952 0.433999 12.3532 0.433999 12.0012C0.433999 11.6492 0.719999 11.4072 1.028 11.4072C1.336 11.4072 1.622 11.6492 1.622 12.0012C1.622 12.3532 1.336 12.5952 1.028 12.5952ZM1.028 17.8696C0.719999 17.8696 0.433999 17.6276 0.433999 17.2756C0.433999 16.9236 0.719999 16.6816 1.028 16.6816C1.336 16.6816 1.622 16.9236 1.622 17.2756C1.622 17.6276 1.336 17.8696 1.028 17.8696ZM1.028 23.144C0.719999 23.144 0.433999 22.902 0.433999 22.55C0.433999 22.198 0.719999 21.956 1.028 21.956C1.336 21.956 1.622 22.198 1.622 22.55C1.622 22.902 1.336 23.144 1.028 23.144Z"
      fill="#C3C4C5"
    />
  </svg>
</template>
