<script setup lang="ts">
import { type PropTypepType, onMounted, ref } from 'vue';
// OL
import Map from 'ol/Map';
import {OSM} from 'ol/source';
import XYZ from 'ol/source/XYZ';
import {Layer, Tile as TileLayer} from 'ol/layer';
import View from 'ol/View';
import {fromLonLat, toLonLat} from 'ol/proj';

// DECK
import {Deck} from '@deck.gl/core/typed';
import {GeoJsonLayer, ArcLayer, IconLayer, TextLayer, SolidPolygonLayer, PolygonLayer } from '@deck.gl/layers/typed';
import {ScenegraphLayer} from '@deck.gl/mesh-layers';

import type { ILocationAlarms } from './MapOpenLayers.vue';
import { filterEmptyCoordinatesLocations } from './MapOpenLayers.vue';

const props = defineProps({
  startPosition: {    
    type: Array as PropType<Array<number>>,
    default: () => fromLonLat([ 5.2913, 52.1326])     
  },
  startZoom: {
    type: Number,
    default: 9
  },
  locationsAlarms: {
    type: Array as PropType<ILocationAlarms[]>,
    default: () => []
  }
});

function getData() {
  return filterEmptyCoordinatesLocations(props.locationsAlarms);  
}

function getIconUrl(la: ILocation): string {    
  switch (la.maxPriorityId) {
    case -1: return NODES['white'];
    case 0: return NODES['green'];
    case 1: return NODES['orange'];
    case 10: return NODES['red'];
    default: throw new Error('Node marker type not supported')
  }  
}

function getPosition(la) {
  return [Number(la.y), Number(la.x)]
}


const map = ref<Deck>();
const mapRef = ref();

const openlayersMapElementId = "map";
const MAP_STYLE_URL = 'https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json';

// Datasource: Natural Earth http://www.naturalearthdata.com/ via geojson.xyz
const AIR_PORTS = 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_airports.geojson';

const NOT_CONVERTED_COORDS = [ 5.2913, 52.1326];
const CENTER_MAP = fromLonLat(NOT_CONVERTED_COORDS);

const DATA = [
  {
  icon: NODES['orange'],
  coordinate: NOT_CONVERTED_COORDS,
  }
];

function createIconLayer() {
  return new IconLayer({
    id: 'clusterm-marker-icon-layer',
    data: getData(),
    getPosition: getPosition,    
    getIcon: d => ({          
      url: getIconUrl(d),
      width: 128,
      height: 128,
      //anchorY: 128
    }),
    sizeScale: 12,
    getSize: d => 10,    
  })
}

function createScenegraphLayer() {
  return new ScenegraphLayer({
    id: 'scenegraph-layer',
    data: getData(),
    pickable: true,
    scenegraph: 'https://raw.githubusercontent.com/KhronosGroup/glTF-Sample-Models/master/2.0/BoxAnimated/glTF-Binary/BoxAnimated.glb',
    getPosition: la => [...getPosition(la), 10],
    getOrientation: d => [0, Math.random() * 180, 90],
    sizeMinPixels: 0.1,
    sizeMaxPixels: 1.5,

    _animations: {
      '*': {speed: 5}
    },    
    sizeScale: 500,
    _lighting: 'pbr'
  });
}

function createTextLayer() {
  return new TextLayer({
    data: DATA,
    getPosition: la => la.coordinate,
    getText: la => "Hello"    
  });
}

const POLYGON_DATA = [
  //{polygon: [[0, 0], [0, 1], [1, 1], [1, 0], [0, 0]]},   // Simple polygon (array of coords)
  {
    polygon: [                                            // Complex polygon with one hole
     [[0, 0], [0, 2], [2, 2], [2, 0], [0, 0]],            // (array of array of coords)
     [[0, 0], [0, 1], [1, 1], [1, 0], [0, 0]]
  ]
  }
];

const POLYGON_DATA3 = [
          [
            [
              5.1066997152835825,
              51.74304495337984
            ],
            [
              5.1066997152835825,
              51.48274024650556
            ],
            [
              5.676860877001786,
              51.48274024650556
            ],
            [
              5.676860877001786,
              51.74304495337984
            ],
            [
              5.1066997152835825,
              51.74304495337984
            ]
          ],
          [
            [
              5.1066997152835825,
              51.74304495337984,
              100
            ],
            [
              5.1066997152835825,
              51.48274024650556,
              100
            ],
            [
              5.676860877001786,
              51.48274024650556,
              100
            ],
            [
              5.676860877001786,
              51.74304495337984,
              100
            ],
            [
              5.1066997152835825,
              51.74304495337984,
              100
            ]
          ]
        ];

const landCover = [
  [
    [-123.0, 49.196],
    [-123.0, 49.324],
    [-123.306, 49.324],
    [-123.306, 49.196]
  ]
];

const POLIGON_DATA2 = [
      {
        // Simple polygon (array of coords)
        contour: [[-122.4, 37.7], [-122.4, 37.8], [-122.5, 37.8], [-122.5, 37.7], [-122.4, 37.7]],
        zipcode: 94107,
        population: 26599,
        area: 6.11
      },
      {
        // Complex polygon with holes (array of rings)
        contour: [
          [[-122.4, 37.7], [-122.4, 37.8], [-122.5, 37.8], [-122.5, 37.7], [-122.4, 37.7]],
          [[-122.45, 37.73], [-122.47, 37.76], [-122.47, 37.71], [-122.45, 37.73]]
        ],
        zipcode: 94107,
        population: 26599,
        area: 6.11
      },
      
    ];
function createSolidPoligonLayer() {
  /*
  return new PolygonLayer({
    id: 'polygon-layer',
    POLIGON_DATA2,
    pickable: true,
    stroked: true,
    filled: true,
    wireframe: true,
    lineWidthMinPixels: 1,
    getPolygon: d => d.contour,
    getElevation: d => d.population / d.area / 10,
    getFillColor: d => [d.population / d.area / 60, 140, 0],
    getLineColor: [80, 80, 80],
    getLineWidth: 1
  });
     
  return new PolygonLayer({
      id: 'ground',
      data: landCover,
      stroked: false,
      getPolygon: f => f,
      getFillColor: [0, 0, 0, 0]
    });
*/

  return new PolygonLayer({
    id: 'polygon-layer',
    data: POLYGON_DATA3,
    pickable: true,
    stroked: true,
    filled: true,
    wireframe: true,
    lineWidthMinPixels: 1,
    getPolygon: d => d,
    getElevation: d => 10000,//26123 / 6.11 / 10,
    getFillColor: d => [26123 / 6.11 / 60, 140, 0],
    getLineColor: [80, 80, 80],
    getLineWidth: 1,
  })

  return new SolidPolygonLayer({
    id: 'solid-polygon-layer',
    data: POLYGON_DATA3,
    getPolygon: d => d,
    getColor: [255, 0, 0],
    extruded: true
  });
}

function initMap() {
  const deck = new Deck({
    initialViewState: {longitude: 0, latitude: 0, zoom: 1},
    controller: false,
    parent: document.getElementById(openlayersMapElementId),
    style: {pointerEvents: 'none', 'z-index': 1},    
    layers: [
      /*
      new GeoJsonLayer({
        id: 'airports',
        data: AIR_PORTS,
        // Styles
        filled: true,
        pointRadiusMinPixels: 2,
        pointRadiusScale: 2000,
        getPointRadius: f => 11 - f.properties.scalerank,
        getFillColor: [200, 0, 80, 180],
        // Interactive props
        pickable: true,
        autoHighlight: true,
        onClick: info =>
          // eslint-disable-next-line
          info.object && alert(`${info.object.properties.name} (${info.object.properties.abbrev})`)
      }),
      new ArcLayer({
        id: 'arcs',
        data: AIR_PORTS,
        dataTransform: d => d.features.filter(f => f.properties.scalerank < 4),
        // Styles
        getSourcePosition: f => [-0.4531566, 51.4709959], // London
        getTargetPosition: f => f.geometry.coordinates,
        getSourceColor: [0, 128, 200],
        getTargetColor: [200, 0, 80],
        getWidth: 1
      })*/
      createIconLayer(),
      //createScenegraphLayer(),
      createSolidPoligonLayer()
    ]
  });

  // Sync deck view with OL view
  const deckLayer = new Layer({
    render({size, viewState}) {
      const [width, height] = size;
      const [longitude, latitude] = toLonLat(viewState.center);
      const zoom = viewState.zoom - 1;
      const bearing = (-viewState.rotation * 180) / Math.PI;
      const deckViewState = {bearing, longitude, latitude, zoom};
      deck.setProps({width, height, viewState: deckViewState});
      deck.redraw();
    }
  });

  // Create OL map with native OSM basemap and deck.gl overlay
  const view = new View({center: CENTER_MAP, zoom: 8, });
  //const view = new View({center: CENTER_MAP, zoom: 8, projection: 'EPSG:4326' });
  new Map({
    target: 'map',
    view,    
    layers: [
      new TileLayer({source: new OSM()}), 
      new TileLayer({
        source: new XYZ({
          crossOrigin: 'anonymous',
          url:'https://c.tile.jawg.io/jawg-dark/{z}/{x}/{y}.png?access-token=87PWIbRaZAGNmYDjlYsLkeTVJpQeCfl2Y61mcHopxXqSdxXExoTLEv7dwqBwSWuJ'
        })
      }),
      deckLayer
    ],    
  }); 
}

onMounted(() => {
  initMap();
})

</script>

<template>
  <div class="map-container">
    <div 
      :id="openlayersMapElementId" 
      class="map" 
    />
  </div>
</template>

<style scoped>
.map-container {
  margin: 0;
  height: 100vh;  
}
.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

body {
  height: 100vh;
  width: 100vw;

}
.deck-gl-map-container {
  height: 100vh;
  width: 100vw;
}

.deck-gl-map {
  height: 100vh;
  width: 100vw;
}
</style>

<script lang="ts">
  const NODES = Object.fromEntries( 
    Object.entries(
      getNodesSvg()).map( ([key, value]) => [
        key,
        svgToDataURL(value)
  ]));
  function svgToDataURL(svg: any) {
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
  }


  function getNodesSvg(){
    return {
      white: `<svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 184 184" xml:space="preserve">
    <path style="stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:4;fill:#fff;fill-rule:nonzero;opacity:1" vector-effect="non-scaling-stroke" transform="translate(72 72.215) scale(1.5)" d="M13.485 29.646 0 22.012V7.226L13.485 0l13.486 7.226v14.786l-13.486 7.634ZM9.215 11.19a7.339 7.339 0 0 1 1.967-1.39 5.206 5.206 0 0 1 4.607 0 7.339 7.339 0 0 1 1.967 1.39l5.731-3.335-10.002-5.262L3.484 7.856l5.731 3.335Zm3.147 15.268V20.27c-1.299-.346-2.373-1.013-3.222-2a5.127 5.127 0 0 1-1.274-3.447c0-.272.02-.55.057-.834a3.88 3.88 0 0 1 .206-.834L2.248 9.71v11.006l10.114 5.744Zm1.123-8.301c.95 0 1.748-.321 2.398-.963.649-.643.974-1.433.974-2.372 0-.939-.325-1.73-.974-2.372-.65-.642-1.449-.963-2.398-.963-.949 0-1.748.32-2.397.963-.65.643-.974 1.433-.974 2.372 0 .939.325 1.73.974 2.372.65.642 1.448.963 2.397.963Zm1.124 8.3 10.114-5.743V9.709l-5.881 3.446c.1.272.169.55.206.834.038.284.056.562.056.834 0 1.31-.424 2.458-1.273 3.446-.85.988-1.923 1.655-3.222 2.001v6.189Z"/>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#fff" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#fff" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.4s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.4s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#fff" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.8s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.8s" repeatCount="indefinite"/>
    </circle>
  </svg>
  `,
    green: `
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 184 184" xml:space="preserve">
      <path style="stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:4;fill:#fff;fill-rule:nonzero;opacity:1" vector-effect="non-scaling-stroke" transform="matrix(1.5 0 0 1.5 72 72.215)" d="M13.485 29.646 0 22.012V7.226L13.485 0l13.486 7.226v14.786l-13.486 7.634ZM9.215 11.19a7.339 7.339 0 0 1 1.967-1.39 5.206 5.206 0 0 1 4.607 0 7.339 7.339 0 0 1 1.967 1.39l5.731-3.335-10.002-5.262L3.484 7.856l5.731 3.335Zm3.147 15.268V20.27c-1.299-.346-2.373-1.013-3.222-2a5.127 5.127 0 0 1-1.274-3.447c0-.272.02-.55.057-.834a3.88 3.88 0 0 1 .206-.834L2.248 9.71v11.006l10.114 5.744Zm1.123-8.301c.95 0 1.748-.321 2.398-.963.649-.643.974-1.433.974-2.372 0-.939-.325-1.73-.974-2.372-.65-.642-1.449-.963-2.398-.963-.949 0-1.748.32-2.397.963-.65.643-.974 1.433-.974 2.372 0 .939.325 1.73.974 2.372.65.642 1.448.963 2.397.963Zm1.124 8.3 10.114-5.743V9.709l-5.881 3.446c.1.272.169.55.206.834.038.284.056.562.056.834 0 1.31-.424 2.458-1.273 3.446-.85.988-1.923 1.655-3.222 2.001v6.189Z"/>
      <circle cx="20" cy="20" fill="none" r="10" stroke="#ffffffe6" stroke-width=".5" transform="scale(4.6)"/>
      <!-- <circle cx="23" cy="23" fill="none" r="10" stroke="#44b39b" stroke-width=".5" transform="scale(4)"/>
      <circle cx="27" cy="27" fill="none" r="10" stroke="#44b39b" stroke-width=".5" transform="scale(3.4)"/> -->
    </svg>

    `,
    orange: `
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 184 184" xml:space="preserve">
    <path style="stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:4;fill:#fff;fill-rule:nonzero;opacity:1" vector-effect="non-scaling-stroke" transform="translate(72 72.215) scale(1.5)" d="M13.485 29.646 0 22.012V7.226L13.485 0l13.486 7.226v14.786l-13.486 7.634ZM9.215 11.19a7.339 7.339 0 0 1 1.967-1.39 5.206 5.206 0 0 1 4.607 0 7.339 7.339 0 0 1 1.967 1.39l5.731-3.335-10.002-5.262L3.484 7.856l5.731 3.335Zm3.147 15.268V20.27c-1.299-.346-2.373-1.013-3.222-2a5.127 5.127 0 0 1-1.274-3.447c0-.272.02-.55.057-.834a3.88 3.88 0 0 1 .206-.834L2.248 9.71v11.006l10.114 5.744Zm1.123-8.301c.95 0 1.748-.321 2.398-.963.649-.643.974-1.433.974-2.372 0-.939-.325-1.73-.974-2.372-.65-.642-1.449-.963-2.398-.963-.949 0-1.748.32-2.397.963-.65.643-.974 1.433-.974 2.372 0 .939.325 1.73.974 2.372.65.642 1.448.963 2.397.963Zm1.124 8.3 10.114-5.743V9.709l-5.881 3.446c.1.272.169.55.206.834.038.284.056.562.056.834 0 1.31-.424 2.458-1.273 3.446-.85.988-1.923 1.655-3.222 2.001v6.189Z"/>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#CD8136" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#CD8136" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.4s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.4s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#CD8136" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.8s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.8s" repeatCount="indefinite"/>
    </circle>
    </svg>
    `,
    red: `
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 184 184" xml:space="preserve">
    <path style="stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:4;fill:#fff;fill-rule:nonzero;opacity:1" vector-effect="non-scaling-stroke" transform="translate(72 72.215) scale(1.5)" d="M13.485 29.646 0 22.012V7.226L13.485 0l13.486 7.226v14.786l-13.486 7.634ZM9.215 11.19a7.339 7.339 0 0 1 1.967-1.39 5.206 5.206 0 0 1 4.607 0 7.339 7.339 0 0 1 1.967 1.39l5.731-3.335-10.002-5.262L3.484 7.856l5.731 3.335Zm3.147 15.268V20.27c-1.299-.346-2.373-1.013-3.222-2a5.127 5.127 0 0 1-1.274-3.447c0-.272.02-.55.057-.834a3.88 3.88 0 0 1 .206-.834L2.248 9.71v11.006l10.114 5.744Zm1.123-8.301c.95 0 1.748-.321 2.398-.963.649-.643.974-1.433.974-2.372 0-.939-.325-1.73-.974-2.372-.65-.642-1.449-.963-2.398-.963-.949 0-1.748.32-2.397.963-.65.643-.974 1.433-.974 2.372 0 .939.325 1.73.974 2.372.65.642 1.448.963 2.397.963Zm1.124 8.3 10.114-5.743V9.709l-5.881 3.446c.1.272.169.55.206.834.038.284.056.562.056.834 0 1.31-.424 2.458-1.273 3.446-.85.988-1.923 1.655-3.222 2.001v6.189Z"/>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#FE1717" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#FE1717" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.4s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.4s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#FE1717" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.8s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.8s" repeatCount="indefinite"/>
    </circle>
  </svg>
    `
    };
  }


</script>