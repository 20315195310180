<script setup lang="ts">
import LowPrioirtyIcon from '../icons/LowPriorityIcon.vue';
import HighPriorityIcon from '../icons/HighPriroityIcon.vue';
import NotificationAlarmIcon from '../icons/NotificationAlarmIcon.vue';
import AcceptAlarmIcon from '../icons/AcceptAlarmIcon.vue';
import DownAlarmIcon from '../icons/DownAlarmIcon.vue';
import RightArrowIcon from '../icons/RightArrowIcon.vue';
import { formatTimeAgo } from '@vueuse/core';

const props = defineProps({
  dataLoading: {
    type: Boolean,
    required: true,
  },
  alarmsForLocation: {
    type: Array,
    required: true,
  },
  isDayPicker: {
    type: Boolean
  }
});
const ALARM_ICONS = {
  '10': HighPriorityIcon,
  '1': LowPrioirtyIcon,
  '0': NotificationAlarmIcon,
};

const priorityIdToIcon = (priroityId: number) => {
  return ALARM_ICONS[priroityId];
};

const convertTimeToHHMM = (date: string): string => {
  const dateString: string = date;
  const dateObject: Date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'UTC',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit'
  };
  let time: string = dateObject.toLocaleTimeString('en-US', options);
  if (time.startsWith('24:')) {
    time = '00' + time.slice(2);
  }
  return time;
};

const timeAgo = (inputDateStr: string): string => {
  const inputDate = new Date(inputDateStr);
  const currentDate = new Date();
  const timeDifferenceMs = currentDate.getTime() - inputDate.getTime();

  const secondsPassed = Math.floor(timeDifferenceMs / 1000);
  const minutesPassed = Math.floor(secondsPassed / 60);
  const hoursPassed = Math.floor(minutesPassed / 60);
  const daysPassed = Math.floor(hoursPassed / 24);
  const monthsPassed = Math.floor(daysPassed / 30.44);
  const yearsPassed = Math.floor(daysPassed / 365.25);

  if (yearsPassed >= 1) {
    return `${yearsPassed} ${yearsPassed === 1 ? 'year' : 'years'} ago`;
  } else if (monthsPassed >= 1) {
    return `${monthsPassed} ${monthsPassed === 1 ? 'month' : 'months'} ago`;
  } else if (daysPassed >= 1) {
    return `${daysPassed} ${daysPassed === 1 ? 'day' : 'days'} ago`;
  } else if (hoursPassed >= 1) {
    return `${hoursPassed} ${hoursPassed === 1 ? 'hour' : 'hours'} ago`;
  } else if (minutesPassed >= 1) {
    return `${minutesPassed} ${minutesPassed === 1 ? 'minute' : 'minutes'} ago`;
  } else {
    return `${secondsPassed} ${secondsPassed === 1 ? 'second' : 'seconds'} ago`;
  }
};

const emit = defineEmits(['selectAlarm']);
</script>

<template>
  <div
    v-if="props.dataLoading"
    class="loader items-center"
  />
  <div
    v-else
    style="width: 100%; height: 0px;"
    :style="[props.alarmsForLocation.length >= 2 ? {minHeight: `0px`, paddingTop: '20px'} : '']"
  >
    <p 
      v-if="props.alarmsForLocation.length < 1"
      class="text-white text-center"
    >
      There are no alarms for this location!
    </p>
    <div
      v-for="(alarm, index) in alarmsForLocation"
      v-else
      :key="alarm.id"
      class="flex single-alarm"
      style="position: relative;"
      @click="emit('selectAlarm', alarm.alarm)"
    >
      <hr
        v-if="index !== 0"
        style="border-top: 1px solid gray; width: 77%; position: absolute; margin-left: 20%; top: 0;"
      >
      <div 
        style="padding-left: 15px;padding-right: 15px; display: flex; flex-direction: column; align-items: center"
      >
        <component :is="priorityIdToIcon(alarm.alarm.priorityid)" />
        <p class="alarm-time">
          {{ convertTimeToHHMM(alarm.alarm.up) }}
        </p>
        <p 
          v-if="!isDayPicker"
          class="alarm-time"
          style="font-size: 10px;"
        >
          {{ new Date(alarm?.alarm?.up).toLocaleString('en-US', { day: 'numeric', month: 'short' }) }}
        </p>
      </div>
      <div class="mobile-alarm-event">
        <div class="flex items-center">
          <p class="alarm-status">
            {{ alarm.alarm.text }}
          </p>
        </div>
        <p class="alarm-upper">
          {{ alarm.alarm.object }} {{ alarm.alarm.tagname }}
        </p>
        <div class="flex">
          <div 
            v-if="alarm.alarm.accept"
            class="flex items-center"
          >
            <AcceptAlarmIcon />
            <p 
              class="alarm-upper" 
              style="margin-left: 5px"
            >
              {{ formatTimeAgo(new Date(alarm?.alarm?.accept)) }}
            </p>
          </div>
          <div 
            v-if="alarm.alarm.down"
            class="flex items-center"
            :style="[!!alarm?.alarm?.accept ? 'margin-left: 10px' : '']"
          >
            <DownAlarmIcon />
            <p
              class="alarm-upper"
              style="margin-left: 5px"
            >
              {{ formatTimeAgo(new Date(alarm?.alarm?.down)) }}
            </p>
          </div>
        </div>
      </div>
      <div>
        <RightArrowIcon
          class="cursor-pointer"
          @click="emit('selectAlarm', alarm.alarm)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.single-alarm {
  min-height: 70px;
  align-items: center;
  padding: 15px 0px;
  cursor: pointer;
}

.mobile-alarm-event {
  width: 70%;
  @media screen and (max-width: 430px) {
    width: 75%;
  }
}
.alarm-upper {
  opacity: 0.7;
  color: #C3C4C5;
  font-family: "Avenir Next LT Pro";
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
}

.alarm-time {
  opacity: 0.72;
  color: #FFFFFF;
  font-family: "Avenir Next";
  font-size: 12.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}

.alarm-status {
  color: #FFFFFF;
  font-family: "Avenir Next";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: capitalize;
}

.loader {
    border: 4px solid #888;
    border-top: 4px solid #333;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
