<script setup lang="ts">
import SidebarRight from '@/components/composites/SidebarRight.vue';
import AreaChart from '@/components/graphs/AreaChart.vue';
import BrushChart from '@/components/graphs/BrushChart.vue';
import { ref, watch, onUnmounted } from 'vue';
import { useTrendApi, type TrendQueryParams } from '../api/trendApi';
import { type ClickedItem } from '../api/dtos/TrendDto';
import { useLocationObjectTagsService } from '@/services/locationObjectTagsService';
import { type LocationObjectsTags, type LocationDto } from '../api/dtos/LocationDto';
import { useChartsStore } from '../stores/chart';
import { storeToRefs } from 'pinia';
import { useHeaderStore } from '../stores/header';
import { type TagModel } from '../models/TagModel';

const measuredValueTagsNamesList = ref<string[]>([]);
const dailyTotalTagsNamesList = ref<string[]>([]);
const clickedTagName = ref<string>();
const clickedTagId = ref<string>();
const locationsList = ref<LocationDto[]>([]);
const locationsWithObjectsData = ref<LocationObjectsTags[]>([]);
const loadingData = ref(false);
const firstChartData = ref<TagModel[]>([]);
const secondChartData = ref<TagModel[]>([]);
const firstChartColorCounter = ref(0);
const secondChartColorCounter = ref(0);
const clickedItemsList = ref<ClickedItem[]>([]);
const firstChartRange = ref<TrendQueryParams>(getfirstChartDefaultRange());
const secondChartRange = ref<TrendQueryParams>(getSecondChartDefaultRange());
const firstChartColors = ref(['#80d8ff', '#f4956f', '#b9f6ca', '#b388ff', '#00b0ff', '#c61407', '#ff9100', '#00e676', '#651fff']);
const secondChartColors = ref([
	'#8c9eff',
	'#84ffff',
	'#ffcea1',
	'#ff80ab',
	'#ffb0e8',
	'#3d5afe',
	'#00e5ff',
	'#ff6700',
	'#f50057',
	'#be007a',
]);
const MILLISECONDS = 1000;

const headerStore = useHeaderStore();
const { selectedLocation } = storeToRefs(headerStore);

const chartStore = useChartsStore();
const { selectedDate, selectedLocationForCharts } = storeToRefs(chartStore);

const locationObjectTagsService = useLocationObjectTagsService();
const { getTrendForTag } = useTrendApi();

function getfirstChartDefaultRange() {
	const currentDate = new Date();
	currentDate.setDate(currentDate.getDate() - 1);
	currentDate.setHours(23, 59, 59);
	const endDate = Math.floor(currentDate.getTime());

	currentDate.setHours(0, 0, 0);
	const startDate = Math.floor(currentDate.getTime());

	const range = {
		s: startDate / MILLISECONDS,
		e: endDate / MILLISECONDS,
	};

	return range;
}

function getfirstChartRange(newSelectedDate: Date) {
	newSelectedDate.setHours(23, 59, 59);
	const endDate = Math.floor(newSelectedDate.getTime());

	newSelectedDate.setHours(0, 0, 0);
	const startDate = Math.floor(newSelectedDate.getTime());

	const range = {
		s: startDate / MILLISECONDS,
		e: endDate / MILLISECONDS,
	};

	return range;
}

function getSecondChartDefaultRange() {
	const currentDate = new Date();
	currentDate.setDate(currentDate.getDate() - 31);
	const startDate = Math.floor(currentDate.getTime());
	const endDate = Date.now();
	const range = {
		s: startDate / MILLISECONDS,
		e: endDate / MILLISECONDS,
		r: 10,
	};

	return range;
}

function getSecondChartRange(newSelectedDate: Date) {
	newSelectedDate.setHours(23, 59, 59);
	const endDate = Math.floor(newSelectedDate.getTime());
	newSelectedDate.setDate(newSelectedDate.getDate() - 31);
	const startDate = Math.floor(newSelectedDate.getTime());
	const range = {
		s: startDate / MILLISECONDS,
		e: endDate / MILLISECONDS,
		r: 10,
	};

	return range;
}

function handleTagToggle(tagId: string, tagName: string) {
	clickedTagName.value = tagName;
	clickedTagId.value = tagId;
	setTimeout(() => {
		clickedTagId.value = '';
		clickedTagName.value = '';
	}, 500);
	if (clickedItemsList.value) {
		clickedItemsList.value.map((el) => {
			if (el.id === tagId) {
				el.visible = !el.visible;
			}
		});
	}
}

// Names
async function fetchDailyTotalTags() {
	const { getDailyTotalTags } = useTrendApi();
	const response = getDailyTotalTags();
	return response;
}

async function fetchMeasuredValueTags() {
	const { getMeasuredValueTags } = useTrendApi();
	const response = getMeasuredValueTags();
	return response;
}

async function handleTagNamesFetch() {
	const measuredValueTagsResponse = await fetchMeasuredValueTags();
	const { tags: measuredValueTags } = measuredValueTagsResponse;
	const finalMeasuredValueTags = measuredValueTags.map((el) => el.tag);
	const measuredValueTagNames = finalMeasuredValueTags.map((tag) => tag.name);
	measuredValueTagsNamesList.value = measuredValueTagNames;

	const dailyTotalTagsResponse = await fetchDailyTotalTags();
	const { tags: dailyTotalTags } = dailyTotalTagsResponse;
	const finalDailyTotalTags = dailyTotalTags.map((el) => el.tag);
	const dailyTotalTagsNames = finalDailyTotalTags.map((tag) => tag.name);
	dailyTotalTagsNamesList.value = dailyTotalTagsNames;
}
// Names end

function handleAddLocation(location: LocationDto) {
	locationsList.value.push(location as LocationDto);
}

function isLocationAlreadyAdded(location: LocationDto) {
	const locationId = location.id;
	const isLocationAlreadyAdded = locationsList.value.some((existingLocation) => existingLocation.id === locationId);
	return isLocationAlreadyAdded;
}

async function fetchObjectsWithTags(locationId: string) {
	const locationObjects = await locationObjectTagsService.getObjectsForLocation(locationId);
	const tags = await locationObjectTagsService.getTagsForLocation(locationId, { type: '10,12,13' });

	const customTags = await Promise.all(
		tags.map((tag) => ({
			name: tag.name,
			id: tag.id,
			visible: true,
		}))
	);

	clickedItemsList.value = clickedItemsList.value.concat(customTags);

	const objectsWithTagsAndTrends = [];

	for (const obj of locationObjects) {
		const matchingTags = tags.filter((tag) => tag.objectid === obj.id);

		const tagsWithTrends = [];

		for (const tag of matchingTags) {
			let trend;
			if (tag.types.includes(12)) {
				trend = await getTrendForTag(tag.id, secondChartRange.value);
			} else {
				trend = await getTrendForTag(tag.id, firstChartRange.value);
			}

			let color;
			if (trend.length > 0) {
				if (tag.types.includes(12)) {
					color = secondChartColors.value[secondChartColorCounter.value % secondChartColors.value.length];
					secondChartColorCounter.value++;
				} else {
					color = firstChartColors.value[firstChartColorCounter.value % firstChartColors.value.length];
					firstChartColorCounter.value++;
				}
			}

			tagsWithTrends.push({
				...tag,
				trend: trend.length > 0 && trend,
				color: color,
			});
		}

		objectsWithTagsAndTrends.push({
			...obj,
			tags: tagsWithTrends.length > 0 ? tagsWithTrends : undefined,
		});
	}

	return objectsWithTagsAndTrends;
}

async function fetchLocationWithObjectsAndTags(loc: LocationDto) {
	try {
		loadingData.value = true;

		const objects = await fetchObjectsWithTags(loc.id);

		const obj = {
			location: {
				name: loc.name,
				desc: loc.description,
				id: loc.id,
				objects,
			},
		};

		locationsWithObjectsData.value.push(obj as LocationObjectsTags);

		return obj;
	} catch (error) {
		console.error('Something went wrong: ', error);
	}
}

function splitDataForCharts(result: LocationObjectsTags) {
	result.location.objects.forEach((object) => {
		if (object.tags) {
			object.tags.forEach((tag) => {
				if (tag.types) {
					if (tag.types.includes(12)) {
						secondChartData.value.push({
							...tag,
						});
					} else {
						firstChartData.value.push({
							...tag,
						});
					}
				}
			});
		}
	});
}

function emptyAllData() {
	locationsWithObjectsData.value.length = 0;
	firstChartColorCounter.value = 0;
	secondChartColorCounter.value = 0;
	firstChartData.value.length = 0;
	secondChartData.value.length = 0;
}

async function fetchDataForLocation(location: LocationDto) {
	await fetchLocationWithObjectsAndTags(location)
		.then((result) => splitDataForCharts(result as LocationObjectsTags))
		.then(() => (loadingData.value = false));
}

async function fetchLocation(location: LocationDto) {
	await fetchDataForLocation(location);
	await handleTagNamesFetch();
}

async function fetchAllLocations() {
	emptyAllData();

	for (let i = 0; i < locationsList.value.length; i++) {
		const location = locationsList.value[i];
		await fetchDataForLocation(location);
	}

	handleTagNamesFetch();
}

async function fetchAndAddLocation(newSelectedLocationData: (LocationDto | null)[], oldSelectedLocationData: (LocationDto | null)[]) {
	const [newSelectedLocation, newSelectedLocationForCharts] = newSelectedLocationData;
	const [oldSelectedLocation, oldSelectedLocationForCharts] = oldSelectedLocationData;

	if (newSelectedLocation && newSelectedLocation !== oldSelectedLocation) {
		chartStore.removeSelectedItem();
		if (!newSelectedLocationForCharts) {
			locationsList.value.length = 0;
			emptyAllData();
		}
		if (!isLocationAlreadyAdded(newSelectedLocation)) {
			handleAddLocation(newSelectedLocation);
			fetchLocation(newSelectedLocation);
		} else {
			console.log('Location is already added!');
		}
	}

	if (newSelectedLocationForCharts && newSelectedLocationForCharts !== oldSelectedLocationForCharts) {
		if (!isLocationAlreadyAdded(newSelectedLocationForCharts)) {
			handleAddLocation(newSelectedLocationForCharts);
			fetchLocation(newSelectedLocationForCharts);
		} else {
			console.log('Location is already added!');
		}
	}

	if (oldSelectedLocation && newSelectedLocation === null) {
		chartStore.removeSelectedItem();
		locationsList.value.length = 0;
		emptyAllData();
	}
}

watch(
	[selectedLocation, selectedLocationForCharts],
	(newSelectedLocationArray, oldSelectedLocationArray) =>
		fetchAndAddLocation(newSelectedLocationArray, oldSelectedLocationArray as (LocationDto | null)[]),
	{ immediate: true }
);

watch(selectedDate, (newSelectedDate) => {
	if (newSelectedDate) {
		let today = new Date();
		if (newSelectedDate.toDateString() === today.toDateString()) {
			firstChartRange.value = getfirstChartDefaultRange();
		} else {
			firstChartRange.value = getfirstChartRange(newSelectedDate);
		}
		secondChartRange.value = getSecondChartRange(newSelectedDate);

		fetchAllLocations();
	}
});

onUnmounted(() => {
	chartStore.removeSelectedItem();
	locationsList.value.length = 0;
});
</script>

<template>
	<PerfectScrollbar class="h-screen w-screen scroll-container">
		<div class="w-full h-full flex">
			<div class="dotted-bg h-full w-full absolute"></div>
			<div v-if="loadingData" class="absolute left-[45%] top-[100px]">
				<div class="loader" />
			</div>
			<div v-if="selectedLocation || selectedLocationForCharts" class="flex flex-col h-full grow min-w-0">
				<div class="pt-[80px] shub-md:pt-[6%] flex-[50%]">
					<AreaChart :first-chart-data="firstChartData" :first-chart-names="measuredValueTagsNamesList" :clickedTagName="clickedTagName" />
				</div>
				<div class="brush-chart__wrapper pb-5 flex-[50%]">
					<BrushChart :second-chart-data="secondChartData" :second-chart-names="dailyTotalTagsNamesList" :clickedTagName="clickedTagName" />
				</div>
			</div>
			<SidebarRight
				:locations-data="locationsWithObjectsData"
				:clicked-items="clickedItemsList"
				:data-loading="loadingData"
				@toggleTag="handleTagToggle"
			/>
		</div>
	</PerfectScrollbar>
</template>

<style lang="scss" scoped>
.dotted-bg {
	background: linear-gradient(0deg, #313435 0%, #464a4f 100%);
	background-image: radial-gradient(rgb(212, 212, 212) 1px, transparent 0);
	background-size: 12px 12px;
	background-position: -18px -18px;
	opacity: 0.1;
}

.scroll-container {
	background: linear-gradient(180deg, #2f3336 0%, #1a1b1e 100%);
}

.brush-chart__wrapper {
	@media only screen and (max-height: 540px) {
		padding-bottom: 15%;
	}
}
</style>
