<template>
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.1929 4.75977H25.562V0.268188H22.7984L0.227051 4.27079V39.1949L22.5304 43.1874H25.562V38.1968H40.1889C41.0223 38.1569 41.9484 38.2417 42.66 37.7127C43.2254 36.8194 43.0883 35.7015 43.1371 34.6934V6.71613C43.2492 5.5683 42.3139 4.64999 41.1929 4.75977ZM14.5224 23.3847C13.4161 23.9635 12.1488 23.8837 10.9449 23.8438L10.9401 29.6977L8.04002 29.4482L8.04488 13.4534C10.6915 13.5831 13.8986 12.3804 16.0919 14.4116C18.1926 17.0217 17.6369 21.8226 14.5224 23.3847ZM40.8081 35.8415H25.562V32.707H37.2596V30.7109H25.562V28.2156H37.2596V26.2194H25.5669C25.5669 26.2194 25.562 24.2631 25.5572 23.2849C27.4873 23.8987 29.6855 23.8838 31.4108 22.6961C33.2776 21.5632 34.2523 19.4023 34.4132 17.2463H27.999V10.7286L25.562 11.2327V7.11508H40.8081V35.8415ZM28.9734 9.68549C32.3609 9.84513 35.2126 12.7772 35.3929 16.2357H28.9734V9.68549ZM10.9401 16.1533C11.9002 16.1084 13.0895 15.9287 13.7377 16.8519C14.2934 17.8302 14.2641 19.1177 13.8011 20.1258C13.2455 21.1538 11.9879 21.0541 11.018 21.1738C10.9158 19.502 10.9254 17.8302 10.9401 16.1533Z"
      fill="#7C7F81"
    />
  </svg>
</template>
