import { ref, computed } from 'vue';
import { useLoading } from './loading';

export function useLoadingPercentProgress() {
  const { loading, setLoading } = useLoading();

  const __totalProgressItems = ref<number>(0);
  const __currentProgressItems = ref<number>(100);

  const loadingPercentProgress = computed( () => __currentProgressItems.value / __totalProgressItems.value);

  
  function restartLoading() {
    __totalProgressItems.value = 100;
    __currentProgressItems.value = 0;
  }

  function startLoading(totalProgressItems: number | undefined = 100){    
    restartLoading();
    totalProgressItems != 100 && (__totalProgressItems.value = totalProgressItems);
    setLoading(true);
  }

  function stopLoading() {
    setLoading(false);
    if(__totalProgressItems.value != 0) {
      updateProgress(__totalProgressItems.value, __totalProgressItems.value);
    }
  }

  function updateProgress(progressItemsToAdd: number, totalProgressItems?: number) {
    __currentProgressItems.value = __currentProgressItems.value + progressItemsToAdd;
    if(totalProgressItems) {
      __totalProgressItems.value = totalProgressItems;
    }
  }

  return {
    loading,
    loadingPercentProgress,
    startLoading,
    stopLoading,
    restartLoading,
    updateProgress
  }
}