<script setup lang="ts">
import { type PropType, onMounted, ref } from 'vue';
import {fromLonLat, toLonLat} from 'ol/proj';
// DECK
import {Deck} from '@deck.gl/core/typed';
import type { ILocationAlarms } from './MapOpenLayers.vue';
import { filterEmptyCoordinatesLocations } from './MapOpenLayers.vue';

////
import {GeoJsonLayer, PolygonLayer, BitmapLayer} from '@deck.gl/layers/typed';
import { TileLayer } from '@deck.gl/geo-layers/typed';
import {LightingEffect, AmbientLight, _SunLight as SunLight} from '@deck.gl/core/typed';
import {scaleThreshold} from 'd3-scale';
///

const props = defineProps({
  startPosition: {    
    type: Array as PropType<Array<number>>,
    default: () => fromLonLat([ 5.2913, 52.1326])     
  },
  startZoom: {
    type: Number,
    default: 9
  },
  locationsAlarms: {
    type: Array as PropType<ILocationAlarms[]>,
    default: () => []
  }
});


const map = ref<Deck>();
const mapRef = ref();

const mapElementId = "map";
const MAP_STYLE_URL = 'https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json';

// Datasource: Natural Earth http://www.naturalearthdata.com/ via geojson.xyz
const AIR_PORTS = 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_airports.geojson';

const NOT_CONVERTED_COORDS = [ 5.2913, 52.1326];
const CENTER_MAP = fromLonLat(NOT_CONVERTED_COORDS);

const DATA = [
  {
  icon: NODES['orange'],
  coordinate: NOT_CONVERTED_COORDS,
  }
];

function createTileLayer( options?: { 
  data?: any
  minZoom?: number,
  maxZoom?: number,
  tileSize?: number
} ) {
  return new TileLayer({
    data: options?.data ?? "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",

    minZoom: options?.minZoom ?? 0,
    maxZoom: options?.maxZoom ?? 19,
    tileSize: options?.tileSize ?? 256,

    renderSubLayers: (props) => {
      const {
        bbox: { west, south, east, north }
      } = props.tile;

      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north]
      });
    }
  });
}

const GEOJSON_DATA = {
  "type": "FeatureCollection",
  "features": [
      {
         "type":"Feature",
         "geometry":{
            "type":"Polygon",
            "coordinates":[
               [
                  [
                     -123.0249569,
                     49.2407190
                  ],
                  [
                     -123.0241582,
                     49.2407165
                  ],
                  [
                     -123.0240445,
                     49.2406847
                  ],
                  [
                     -123.0239311,
                     49.2407159
                  ],
                  [
                     -123.0238530,
                     49.2407157
                  ],
                  [
                     -123.0238536,
                     49.2404548
                  ],
                  [
                     -123.0249568,
                     49.2404582
                  ],
                  [
                     -123.0249569,
                     49.2407190
                  ]
               ]
            ]
         },
         "properties":{
            //"valuePerSqm":4563,
            "valuePerSqm": 50,
            "growth":0.3592            
         }
      }
    ]
  };
    

function initMap() {
  const DATA_URL =
  'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/geojson/vancouver-blocks.json'; // eslint-disable-line

const COLOR_SCALE = scaleThreshold()
  .domain([-0.6, -0.45, -0.3, -0.15, 0, 0.15, 0.3, 0.45, 0.6, 0.75, 0.9, 1.05, 1.2])
  .range([
    [65, 182, 196],
    [127, 205, 187],
    [199, 233, 180],
    [237, 248, 177],
    // zero
    [255, 255, 204],
    [255, 237, 160],
    [254, 217, 118],
    [254, 178, 76],
    [253, 141, 60],
    [252, 78, 42],
    [227, 26, 28],
    [189, 0, 38],
    [128, 0, 38]
  ]);

const INITIAL_VIEW_STATE = {
  latitude: 49.254,
  longitude: -123.13,
  zoom: 11,
  maxZoom: 16,
  pitch: 45,
  bearing: 0
};

const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json';

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0
});

const dirLight = new SunLight({
  timestamp: Date.UTC(2019, 7, 1, 22),
  color: [255, 255, 255],
  intensity: 1.0,
  _shadow: true
});

const landCover = [
  [
    [-123.0, 49.196],
    [-123.0, 49.324],
    [-123.306, 49.324],
    [-123.306, 49.196]
  ]
];

function getTooltip({object}) {
  return (
    object && {
      html: `\
  <div><b>Average Property Value</b></div>
  <div>${object.properties.valuePerParcel} / parcel</div>
  <div>${object.properties.valuePerSqm} / m<sup>2</sup></div>
  <div><b>Growth</b></div>
  <div>${Math.round(object.properties.growth * 100)}%</div>
  `
    }
  );
}

function createMap({data = DATA_URL, mapStyle = MAP_STYLE}) {
  const lightingEffect = new LightingEffect({ambientLight, dirLight});
    lightingEffect.shadowColor = [0, 0, 0, 0.5];

  const layers = [
    // only needed when using shadows - a plane for shadows to drop on
    new PolygonLayer({
      id: 'ground',
      data: landCover,
      stroked: false,
      getPolygon: f => f,
      getFillColor: [0, 0, 0, 0]
    }),
    new GeoJsonLayer({
      id: 'geojson',
      data,
      //data: GEOJSON_DATA,
      opacity: 0.8,
      stroked: false,
      filled: true,
      extruded: true,
      wireframe: true,
      getElevation: f => Math.sqrt(f.properties.valuePerSqm) * 10,      
      getFillColor: f => COLOR_SCALE(f.properties.growth),
      getLineColor: [255, 255, 255],
      pickable: true
    })
  ];

  return new Deck({
    parent: document.getElementById(mapElementId),
    layers: [      
      createTileLayer(),
      ...layers
    ],
    initialViewState: INITIAL_VIEW_STATE,
    controller: true,
    //@ts-ignore
    //getTooltip: getTooltip

  })  
/*
  return (
    <DeckGL
      layers={layers}
      effects={effects}
      initialViewState={INITIAL_VIEW_STATE}
      controller={true}
      getTooltip={getTooltip}
    >
      <Map reuseMaps mapLib={maplibregl} mapStyle={mapStyle} preventStyleDiffing={true} />
    </DeckGL>
  );*/
  }
  map.value = createMap({});
    
}

onMounted(() => {
  initMap();
})

</script>

<template>
  <div class="map-container">
    <div 
      :id="mapElementId" 
      class="map" 
    >
      <div className="attribution">
          <a
            href="http://www.openstreetmap.org/about/"
            target="_blank"
            rel="noopener noreferrer"
          >
          © OpenStreetMap
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.map-container {
  margin: 0;
  height: 100vh;  
}
.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

body {
  height: 100vh;
  width: 100vw;

}
.deck-gl-map-container {
  height: 100vh;
  width: 100vw;
}

.deck-gl-map {
  height: 100vh;
  width: 100vw;
}

.attribution {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  font: 12px Helvetica Neue, Arial, Helvetica, sans-serif;
  line-height: 12px;
  padding: 4px;
  z-index: 9;
}

</style>

<script lang="ts">
  const NODES = Object.fromEntries( 
    Object.entries(
      getNodesSvg()).map( ([key, value]) => [
        key,
        svgToDataURL(value)
  ]));
  function svgToDataURL(svg: any) {
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
  }


  function getNodesSvg(){
    return {
      white: `<svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 184 184" xml:space="preserve">
    <path style="stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:4;fill:#fff;fill-rule:nonzero;opacity:1" vector-effect="non-scaling-stroke" transform="translate(72 72.215) scale(1.5)" d="M13.485 29.646 0 22.012V7.226L13.485 0l13.486 7.226v14.786l-13.486 7.634ZM9.215 11.19a7.339 7.339 0 0 1 1.967-1.39 5.206 5.206 0 0 1 4.607 0 7.339 7.339 0 0 1 1.967 1.39l5.731-3.335-10.002-5.262L3.484 7.856l5.731 3.335Zm3.147 15.268V20.27c-1.299-.346-2.373-1.013-3.222-2a5.127 5.127 0 0 1-1.274-3.447c0-.272.02-.55.057-.834a3.88 3.88 0 0 1 .206-.834L2.248 9.71v11.006l10.114 5.744Zm1.123-8.301c.95 0 1.748-.321 2.398-.963.649-.643.974-1.433.974-2.372 0-.939-.325-1.73-.974-2.372-.65-.642-1.449-.963-2.398-.963-.949 0-1.748.32-2.397.963-.65.643-.974 1.433-.974 2.372 0 .939.325 1.73.974 2.372.65.642 1.448.963 2.397.963Zm1.124 8.3 10.114-5.743V9.709l-5.881 3.446c.1.272.169.55.206.834.038.284.056.562.056.834 0 1.31-.424 2.458-1.273 3.446-.85.988-1.923 1.655-3.222 2.001v6.189Z"/>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#fff" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#fff" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.4s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.4s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#fff" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.8s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.8s" repeatCount="indefinite"/>
    </circle>
  </svg>
  `,
    green: `
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 184 184" xml:space="preserve">
      <path style="stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:4;fill:#fff;fill-rule:nonzero;opacity:1" vector-effect="non-scaling-stroke" transform="matrix(1.5 0 0 1.5 72 72.215)" d="M13.485 29.646 0 22.012V7.226L13.485 0l13.486 7.226v14.786l-13.486 7.634ZM9.215 11.19a7.339 7.339 0 0 1 1.967-1.39 5.206 5.206 0 0 1 4.607 0 7.339 7.339 0 0 1 1.967 1.39l5.731-3.335-10.002-5.262L3.484 7.856l5.731 3.335Zm3.147 15.268V20.27c-1.299-.346-2.373-1.013-3.222-2a5.127 5.127 0 0 1-1.274-3.447c0-.272.02-.55.057-.834a3.88 3.88 0 0 1 .206-.834L2.248 9.71v11.006l10.114 5.744Zm1.123-8.301c.95 0 1.748-.321 2.398-.963.649-.643.974-1.433.974-2.372 0-.939-.325-1.73-.974-2.372-.65-.642-1.449-.963-2.398-.963-.949 0-1.748.32-2.397.963-.65.643-.974 1.433-.974 2.372 0 .939.325 1.73.974 2.372.65.642 1.448.963 2.397.963Zm1.124 8.3 10.114-5.743V9.709l-5.881 3.446c.1.272.169.55.206.834.038.284.056.562.056.834 0 1.31-.424 2.458-1.273 3.446-.85.988-1.923 1.655-3.222 2.001v6.189Z"/>
      <circle cx="20" cy="20" fill="none" r="10" stroke="#ffffffe6" stroke-width=".5" transform="scale(4.6)"/>
      <!-- <circle cx="23" cy="23" fill="none" r="10" stroke="#44b39b" stroke-width=".5" transform="scale(4)"/>
      <circle cx="27" cy="27" fill="none" r="10" stroke="#44b39b" stroke-width=".5" transform="scale(3.4)"/> -->
    </svg>

    `,
    orange: `
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 184 184" xml:space="preserve">
    <path style="stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:4;fill:#fff;fill-rule:nonzero;opacity:1" vector-effect="non-scaling-stroke" transform="translate(72 72.215) scale(1.5)" d="M13.485 29.646 0 22.012V7.226L13.485 0l13.486 7.226v14.786l-13.486 7.634ZM9.215 11.19a7.339 7.339 0 0 1 1.967-1.39 5.206 5.206 0 0 1 4.607 0 7.339 7.339 0 0 1 1.967 1.39l5.731-3.335-10.002-5.262L3.484 7.856l5.731 3.335Zm3.147 15.268V20.27c-1.299-.346-2.373-1.013-3.222-2a5.127 5.127 0 0 1-1.274-3.447c0-.272.02-.55.057-.834a3.88 3.88 0 0 1 .206-.834L2.248 9.71v11.006l10.114 5.744Zm1.123-8.301c.95 0 1.748-.321 2.398-.963.649-.643.974-1.433.974-2.372 0-.939-.325-1.73-.974-2.372-.65-.642-1.449-.963-2.398-.963-.949 0-1.748.32-2.397.963-.65.643-.974 1.433-.974 2.372 0 .939.325 1.73.974 2.372.65.642 1.448.963 2.397.963Zm1.124 8.3 10.114-5.743V9.709l-5.881 3.446c.1.272.169.55.206.834.038.284.056.562.056.834 0 1.31-.424 2.458-1.273 3.446-.85.988-1.923 1.655-3.222 2.001v6.189Z"/>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#CD8136" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#CD8136" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.4s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.4s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#CD8136" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.8s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.8s" repeatCount="indefinite"/>
    </circle>
    </svg>
    `,
    red: `
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 184 184" xml:space="preserve">
    <path style="stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:4;fill:#fff;fill-rule:nonzero;opacity:1" vector-effect="non-scaling-stroke" transform="translate(72 72.215) scale(1.5)" d="M13.485 29.646 0 22.012V7.226L13.485 0l13.486 7.226v14.786l-13.486 7.634ZM9.215 11.19a7.339 7.339 0 0 1 1.967-1.39 5.206 5.206 0 0 1 4.607 0 7.339 7.339 0 0 1 1.967 1.39l5.731-3.335-10.002-5.262L3.484 7.856l5.731 3.335Zm3.147 15.268V20.27c-1.299-.346-2.373-1.013-3.222-2a5.127 5.127 0 0 1-1.274-3.447c0-.272.02-.55.057-.834a3.88 3.88 0 0 1 .206-.834L2.248 9.71v11.006l10.114 5.744Zm1.123-8.301c.95 0 1.748-.321 2.398-.963.649-.643.974-1.433.974-2.372 0-.939-.325-1.73-.974-2.372-.65-.642-1.449-.963-2.398-.963-.949 0-1.748.32-2.397.963-.65.643-.974 1.433-.974 2.372 0 .939.325 1.73.974 2.372.65.642 1.448.963 2.397.963Zm1.124 8.3 10.114-5.743V9.709l-5.881 3.446c.1.272.169.55.206.834.038.284.056.562.056.834 0 1.31-.424 2.458-1.273 3.446-.85.988-1.923 1.655-3.222 2.001v6.189Z"/>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#FE1717" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#FE1717" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.4s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.4s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#FE1717" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.8s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.8s" repeatCount="indefinite"/>
    </circle>
  </svg>
    `
    };
  }


</script>