<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.56732 7.28958L1.7396 4.46186L2.53136 3.64182L4.00178 5.11224V0.503052H5.13287V5.11224L6.60328 3.64182L7.39504 4.46186L4.56732 7.28958ZM1.17406 9.55176C0.863009 9.55176 0.596637 9.44091 0.374944 9.21922C0.153251 8.99752 0.0425927 8.73134 0.0429697 8.42067V6.72404H1.17406V8.42067H7.96059V6.72404H9.09167V8.42067C9.09167 8.73172 8.98083 8.99809 8.75914 9.21978C8.53744 9.44148 8.27126 9.55213 7.96059 9.55176H1.17406Z"
      fill="white"
    />
  </svg>
</template>
