import { v4 as uuidv4 } from 'uuid';
import { createLogger } from "@/utils/logger";
import { IS_DEVELOPMENT } from '@/config/environment';
const logger = createLogger("Event emitter");
if(!IS_DEVELOPMENT) {
  logger.setLoggerEnabled(false);
}
logger.setLoggerEnabled(false);


export class LastValueEmitter {  
  private eventsListeners: Record<string, Map<Function, string>>;
  private lastEvents: Record<string, {eventId: string, eventArgs: any[], eventState: 'notifying' | 'settled'}>;

  constructor(protected parallelNotify: boolean = true) {
    this.eventsListeners = {};
    this.lastEvents = {};
  }


  public async addListener(eventType: string, listener: Function){
    if(!(eventType in this.eventsListeners) ) {
      this.eventsListeners[eventType] = new Map<Function, string>();
      logger.log("Added", eventType, listener, this.eventsListeners)
    }

    const listenersMap = this.eventsListeners[eventType];
    if(!listenersMap.has(listener) ) {
      listenersMap.set(listener, "");
    }

    const lastEventId = this.lastEvents[eventType]?.eventId ?? "";
    const lastEventArgs = this.lastEvents[eventType]?.eventArgs ?? [];

    if(lastEventId != listenersMap.get(listener) ) {
      let listenerResult = listener(eventType, ...lastEventArgs);
      if(listenerResult instanceof Promise) {
        listenerResult = await listenerResult;
      }
      
      listenersMap.set(listener, lastEventId)
    }
    
  }

  public removeListener(eventType: string, listener: Function) {
    if(eventType in this.eventsListeners) {
      const listenersMap = this.eventsListeners[eventType];
      if(listenersMap.has(listener) ) {
        listenersMap.delete(listener);
        logger.log("Removed", eventType, listener, this.eventsListeners)
      }
    }    
  }

  public async emit(eventType: string, ...args: any[]) {
    const currentEventType = eventType;
    const currentEventId = uuidv4();

    if(! (eventType in this.lastEvents)) {
      this.lastEvents[eventType] = { eventId: "", eventArgs: [], eventState: 'notifying'};
    }

    const lastEventEntry = this.lastEvents[currentEventType];

    lastEventEntry.eventId = currentEventId;
    lastEventEntry.eventArgs = [...args];

    logger.log("Notify - before function call ")
    try {
      lastEventEntry.eventState = 'notifying';
      await this.notify(eventType, lastEventEntry.eventId, ...lastEventEntry.eventArgs);
    }catch(error) {
      logger.error("Notify - error", error);
      throw error;
    }finally {
      lastEventEntry.eventState = 'settled';
    }
  }

  public isEventSent(eventType: string): boolean {
    if((eventType in this.lastEvents) && this.lastEvents[eventType].eventId != "") {
      return true;
    }

    return false;
  }

  public clearEventSent(eventType: string) {
    if(eventType in this.lastEvents) {
      delete this.lastEvents[eventType];
    }
  }

  protected async notify(eventType: string, eventId: string, ...eventArgs: any[]) {
    if(eventType in this.eventsListeners) {
      const listenersMap = this.eventsListeners[eventType];
      if(listenersMap.size > 0) {
        for( const listenerEntry of listenersMap.entries() ){
          const listnerF = listenerEntry[0];
          const listenerLastReceivedId = listenerEntry[1];
          if(listenerLastReceivedId != eventId) {            
            let result = listnerF(...eventArgs);
            if(!this.parallelNotify && (result instanceof Promise)) {
              result = await result;              
            }
            listenersMap.set(listnerF, eventId);
            logger.log("Notify - valid observer", eventType, eventId, listenersMap )
          }
        }
      }
    }
  }   

}