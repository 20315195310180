import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useUserInfoApi } from '@/api/userInfo';
import type { UserInfoData } from '@/services/UserInfoService';
import { userInfoService } from '@/services/UserInfoService';
import { useStoreAuthEvents } from '@/composables/storeAuthEvents';

export const useUserInfoStore = defineStore('userInfo', () => {
  const userInfoApi = useUserInfoApi();
  const userInfo = ref<UserInfoData | null>(null);  
  const initialized = ref(false);

  const userName = computed( () => {
    return userInfo.value 
      ? userInfo.value.user
      : null
  });

  function isUserInfoLoaded() {
    if(initialized.value) {          
      if(userInfo.value == null) {
        regenerateUserInfo();
      }
      
    }else {
      regenerateUserInfo();
    }

    return userInfo.value != null;
  }

  function regenerateUserInfo() {
    userInfo.value = userInfoService.getUserInfoData();
  }

  function setUserInfo(userInfoData: UserInfoData){
    userInfo.value = userInfoData;    
    userInfoService.setUserInfoData(userInfoData);
    initialized.value = true;    
  }

  async function fetchUserInfoIfNotAlreadyFetched() {
    if(isUserInfoLoaded()) {
      return userInfo.value;
    } else {
      return userInfoApi.getUserInfo()
        .then(setUserInfo);
    }
  }

  function clearUserInfo() {
    userInfo.value = null;
    initialized.value = false;
  }

  useStoreAuthEvents({
    loggedIn() {
      return fetchUserInfoIfNotAlreadyFetched();
    },
    loggedOut() {
      clearUserInfo();
      userInfoService.clearUserInfoData();
    }
  })

  return { 
    fetchUserInfoIfNotAlreadyFetched,
    clearUserInfo,

    userInfo,
    userName,    
  }
})
