import { useAlarmsApi } from "@/api/alarmsApi";
import type { LocationDto } from "@/api/dtos/LocationDto";
import { useLocationsApi } from "@/api/locationsApi";
import type { ApiLoadingType } from "@/composables/api";
import { useLoading } from "@/composables/loading";
import type { LocationAlarmsModel } from "@/models/AlarmMode";
import { transform as transformToCoordinate } from "ol/proj";

export interface ILocationAlarmFilter {
  showLocationsWithAlarmsOnly: boolean
}

export interface ILocationAlarmsService {
  getAlarms(options?: ILocationAlarmFilter): Promise<Array<LocationAlarmsModel>>
}

export function useLocationAlarmsService(): ILocationAlarmsService & ApiLoadingType {
  const locationsApi = useLocationsApi();
  const alarmsApi = useAlarmsApi();
  const {loading, setLoading, errors, clearErrors } = useLoading();

  function handleMultipleLocationWithSameId() {
    throw new Error("Location with same id already exsits")
  }

  function handleAlarmLocationIdNotFound() {
    throw new Error("Location with alarm locationid doesn't exists");
  }

  async function getAlarms(options: ILocationAlarmFilter = { showLocationsWithAlarmsOnly: false}): Promise<Array<LocationAlarmsModel>> {
    clearErrors();
    setLoading(true);
    try {
      const locationsPromise = locationsApi.getLocations();
      
      const alarmsPromise =  alarmsApi.getAlarms({
        filter: 'active'
      });

      const NON_INITED_PRIORITY_ID = -1000;

      const locations = await locationsPromise;
      const locationsAlarmsMap = new Map<LocationDto['id'], LocationAlarmsModel>();
      locations.locations.forEach(location => {
        const locationId = location.location.id
        if(!locationsAlarmsMap.has(locationId) ) {          
          const { x, y } = location.location;
          locationsAlarmsMap.set(locationId, {
            ...location.location,
            alarms: [],
            maxPriorityId: NON_INITED_PRIORITY_ID,
            coordinate: x ?
              transformToCoordinate([y, x] as number [], 'EPSG:4326','EPSG:3857')
              : [null, null],
            UnitID: locationId,
            UnitGPSLatitude: x ? Number(x) : 0,
            UnitGPSLongitude: y ? Number(y) : 0,
          });
        } else {
          handleMultipleLocationWithSameId();
        }
      })

      const alarms = await alarmsPromise;
      alarms.alarms.forEach( alarm => {
        const locationId  = alarm.alarm.locationid;
        const locationAlarm = locationsAlarmsMap.get(locationId);
        if(locationAlarm) {
          locationAlarm.alarms.push(alarm.alarm)
        }else {
          handleAlarmLocationIdNotFound();
        }      
      })

      locationsAlarmsMap.forEach( locationAlarm => {
        locationAlarm.maxPriorityId = locationAlarm.alarms.reduce((acc, next) => next.priorityid > acc ? next.priorityid : acc, locationAlarm.maxPriorityId);
        if(locationAlarm.maxPriorityId == NON_INITED_PRIORITY_ID){
          locationAlarm.maxPriorityId = 0;
        }
      })

    if(options.showLocationsWithAlarmsOnly){
        return Array.from(locationsAlarmsMap.values())
          .filter( location => location.alarms.length > 0);
      }else {
        let temp = Array.from(locationsAlarmsMap.values());        
        return temp;
      }

      
    }
    catch(error){
      errors.value = error
      throw error;
    }
    finally {
      setLoading(false);
    }
      
  }

  return {
    getAlarms,
    loading,
    errors,
    clearErrors
  } 
}