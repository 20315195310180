<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.71707 15.0614L5.66026 11.0046L4.27881 12.3763L9.71707 17.8145L21.3913 6.14028L20.0196 4.76855L9.71707 15.0614Z"
      fill="#828282"
    />
  </svg>
</template>
