import { objectSelectorFactoryByObjectPropertyAndValue, tagSelectorFactoryByTagPropertyAndValue, type LocationObjectTagSearchEntry } from '../radar-level-widget/RadarLevelWidgetConfigContent.vue';

export const autoSelectDropdownsDescriptors:
    Array<LocationObjectTagSearchEntry<DESC_TAGS_T, MAPPED_TAG_T>> = [
    {
        title: 'Select pump control parameters:',
        descTag: 'pump_control_params',
        autoParametersSelectors: {
            objectSelector: objectSelectorFactoryByObjectPropertyAndValue('displaycode', 'P1'),
            tagSelector: tagSelectorFactoryByTagPropertyAndValue('referencecode', 'bw'),
        }
    },
];
   