import { useApi } from '@/composables/api';
import { type PreferencesResponse, type PreferenceResponse, type APP_KEYS, type PREF_KEYS  } from './dtos/PreferenceDto';

export interface IPreferencesApi {
  getPreferenceForApplicationPreferenceKey<APPLICATION_KEY extends APP_KEYS, PREFERENCE_KEYS extends PREF_KEYS<APPLICATION_KEY>>(
    applicationKey: APPLICATION_KEY,
    preferenceKey: PREFERENCE_KEYS): Promise<PreferenceResponse<APPLICATION_KEY, PREFERENCE_KEYS>>;
  
  createOrUpdateApplicationPreference<APPLICATION_KEY extends APP_KEYS, PREFERENCE_KEYS extends PREF_KEYS<APPLICATION_KEY>>(
    applicationKey: APPLICATION_KEY,
    preferenceKey: PREFERENCE_KEYS,
    preferenceValue: any): Promise<PreferenceResponse<APPLICATION_KEY, PREFERENCE_KEYS>>;

  removeApplicationPreference<APPLICATION_KEY extends APP_KEYS, PREFERENCE_KEYS extends PREF_KEYS<APPLICATION_KEY>>(
    applicationKey: APPLICATION_KEY,
    preferenceKey: PREFERENCE_KEYS): Promise<void>;

  getAllPreferencesForApplicaionKey<APPLICATION_KEY extends APP_KEYS>(
    applicationKey: APPLICATION_KEY): Promise<PreferencesResponse<APP_KEYS, PREF_KEYS<APP_KEYS>>>
  
  /**
   * WARNING: Careful with this api endpoint. It will delete all previous preferences for mentioned application key and store only newly submitted
   * @param applicationKey Application key param
   */
  replaceAllPreferencesForApplicaionKey<APPLICATION_KEY extends APP_KEYS>(
    applicationKey: APPLICATION_KEY,
    preferenceValue: any): Promise<PreferencesResponse<APP_KEYS, PREF_KEYS<APP_KEYS>>>
}

const API_VERSIONING = 'api/v1';


export function usePreferencesApi(): IPreferencesApi {
	const api = useApi();

  async function getPreferenceForApplicationPreferenceKey<APPLICATION_KEY extends APP_KEYS, PREFERENCE_KEYS extends PREF_KEYS<APPLICATION_KEY>>(
    applicationKey: APPLICATION_KEY,
    preferenceKey: PREFERENCE_KEYS): Promise<PreferenceResponse<APPLICATION_KEY, PREFERENCE_KEYS>> {
    
      return api.get(`${API_VERSIONING}/preferences/apps/${applicationKey}/${preferenceKey as string}`, {} );
  }

  async function createOrUpdateApplicationPreference<APPLICATION_KEY extends APP_KEYS, PREFERENCE_KEYS extends PREF_KEYS<APPLICATION_KEY>>(
    applicationKey: APPLICATION_KEY,
    preferenceKey: PREFERENCE_KEYS,
    preferenceValue: any): Promise<PreferenceResponse<APPLICATION_KEY, PREFERENCE_KEYS>> {

      return api.put(`${API_VERSIONING}/preferences/apps/${applicationKey}/${preferenceKey as string}`, 
        preferenceValue,  
        { headers: {'Content-Type': 'application/json'}}
      );
  }

  async function removeApplicationPreference<APPLICATION_KEY extends APP_KEYS, PREFERENCE_KEYS extends PREF_KEYS<APPLICATION_KEY>>(
    applicationKey: APPLICATION_KEY,
    preferenceKey: PREFERENCE_KEYS): Promise<void> {

      return api.deleteRequest(`${API_VERSIONING}/preferences/apps/${applicationKey}/${preferenceKey as string}`);
  }

  async function getAllPreferencesForApplicaionKey<APPLICATION_KEY extends APP_KEYS>(
    applicationKey: APPLICATION_KEY): Promise<PreferencesResponse<APP_KEYS, PREF_KEYS<APP_KEYS>>> {
      return api.get(`${API_VERSIONING}/preferences/apps/${encodeURI(applicationKey)}`, {} );
    }
  
  /**
   * WARNING: Careful with this api endpoint. It will delete all previous preferences for mentioned application key and store only newly submitted
   * @param applicationKey Application key param
   */
  async function replaceAllPreferencesForApplicaionKey<APPLICATION_KEY extends APP_KEYS>(
    applicationKey: APPLICATION_KEY,
    preferenceValue: any): Promise<PreferencesResponse<APP_KEYS, PREF_KEYS<APP_KEYS>>> {
      return api.post(`${API_VERSIONING}/preferences/apps/${encodeURIComponent(applicationKey)}`, 
      preferenceValue,  
      { headers: {'Content-Type': 'application/json'}}
    );
  }



	return {
		getPreferenceForApplicationPreferenceKey,
		createOrUpdateApplicationPreference,
    removeApplicationPreference,
    getAllPreferencesForApplicaionKey,
    replaceAllPreferencesForApplicaionKey
	};
}
