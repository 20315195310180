<script lang="ts" generic="DESC_TAGS_T extends string = RadarLevelDescTags, MAPPED_TAG_T = DefaultMappedTagType<DESC_TAGS_T>, COMPONENT_PROPS_T extends ComponentPropsWithConfig<DESC_TAGS_T, MAPPED_TAG_T> = ComponentPropsWithConfig<DESC_TAGS_T, MAPPED_TAG_T>">
import { type AutoParametersSelectors } from '@/components/StationObjectParamSearch.vue';
import unionwith from 'lodash.unionwith';
import { type RadarLevelDescTags } from './radar-level-widget-types';
import { useSmarthubBreakpoints } from '@/composables/smarthubBreakpoints';

export type ComponentPropsWithConfig<DESC_TAGS_T extends string = RadarLevelDescTags, MAPPED_TAG_T = DefaultMappedTagType<DESC_TAGS_T>> = { config: { locationsObjectsTags: MAPPED_TAG_T[]} };

export function defaultMergeComponentProps<COMPONENT_PROPS_T extends ComponentPropsWithConfig = ComponentPropsWithConfig>(
  initalComponentProps: COMPONENT_PROPS_T, 
  selectedComponentProps: COMPONENT_PROPS_T) : COMPONENT_PROPS_T {  

  const initalComponentPropsRaw = toRaw(initalComponentProps);
  const selectedComponentPropsRaw = toRaw(selectedComponentProps);

  return {
    ...initalComponentPropsRaw,
    ...selectedComponentPropsRaw,
    config: {
      ...(initalComponentPropsRaw?.config ?? {}),
      ...(selectedComponentPropsRaw?.config ?? {}),
      locationsObjectsTags: 
        unionwith(
          (selectedComponentPropsRaw?.config?.locationsObjectsTags ?? []),
          (initalComponentPropsRaw?.config?.locationsObjectsTags ?? []),           
          (tag1, tag2) => tag1?.descTag == tag2?.descTag
        )      
    }
  }
}


export interface LocationObjectTagSearchEntry<
  DESC_TAGS_T extends string = RadarLevelDescTags,
  MAPPED_TAG_T = DefaultMappedTagType<DESC_TAGS_T>> {

  title: string;  
  descTag: DESC_TAGS_T;
  autoParametersSelectors: AutoParametersSelectors<DESC_TAGS_T>;
  tagMapper?: (tag: Tag) => MAPPED_TAG_T;
}

export function objectSelectorFactory() {
  return function objectSelector(objects: ObjectModel[], trigger: any): AutoSelectResult<ObjectModel> {    
      const mappedTag = (trigger as MAPPED_TAG_T[])?.[0];
      if(mappedTag) {
        const objectIndex = objects.findIndex((obj) => obj.id == mappedTag.objectId)
        if(objectIndex != -1) {          
          return { 
            isMatched: true,
            value: objects[objectIndex]
          }
        }
      }

      return { 
        isMatched: true,
        value: null
      };      
    }
}

export function objectSelectorFactoryByObjectPropertyAndValue(objectProperty: keyof ObjectModel, propertyValue: string) {
  return function objectSelector(objects: ObjectModel[], trigger: any): AutoSelectResult<ObjectModel> {          
      const propertyValueRefined = propertyValue.trim().toLowerCase();
      const mappedTag = (trigger as MAPPED_TAG_T[])?.[0];
      if(mappedTag) {
        const objectIndex = objects.findIndex((obj) => obj?.[objectProperty]?.trim()?.toLowerCase() == propertyValueRefined)
        if(objectIndex != -1) {          
          return { 
            isMatched: true,
            value: objects[objectIndex]
          }
        }
      }

      return { 
        isMatched: false,
        value: null
      };      
    }
}


export function tagSelectorFactory() {
  return function tagSelector(tags: TagModel[], trigger: any): AutoSelectResult<TagModel> {
      const mappedTag = (trigger as MAPPED_TAG_T[])?.[0];
      if(mappedTag) {      
        const tagIndex = tags.findIndex((tag) => tag.id == mappedTag.tagId)
        if(tagIndex != -1) {
          //emitParamsMatched(true);
          return {
            isMatched: true,
            value: tags[tagIndex]
          }
        }
      }

      return {
        isMatched: true,
        value: null
      };
    }          
}

export function tagSelectorFactoryByTagPropertyAndValue(tagProperty: keyof TagModel, propertyValue: string) {
  return function tagSelector(tags: TagModel[], trigger: any): AutoSelectResult<TagModel> {
      const propertyValueRefined = propertyValue.trim().toLowerCase();
      const mappedTag = (trigger as MAPPED_TAG_T[])?.[0];
      if(mappedTag) {      
        const tagIndex = tags.findIndex((tag) => tag?.[tagProperty]?.trim()?.toLowerCase() == propertyValueRefined)
        if(tagIndex != -1) {
          //emitParamsMatched(true);
          return {
            isMatched: true,
            value: tags[tagIndex]
          }
        }
      }

      return {
        isMatched: false,
        value: null
      };
    }          
}


</script>

<script lang="ts" setup generic="DESC_TAGS_T extends string = RadarLevelDescTags, MAPPED_TAG_T  = DefaultMappedTagType<DESC_TAGS_T>, COMPONENT_PROPS_T extends ComponentPropsWithConfig<DESC_TAGS_T, MAPPED_TAG_T> = ComponentPropsWithConfig<DESC_TAGS_T, MAPPED_TAG_T>">
import StationObjectParamSearch, { defaultTagMapper, type DefaultMappedTagType, type AutoSelectResult } from '@/components/StationObjectParamSearch.vue';
import StationObjectParamSearchResponsive from '@/components/StationObjectParamSearchResponsive.vue';
import { type Tag } from '@/components/TagsList.vue';
import { ref, type PropType, watch, computed, toRaw } from 'vue';
import { useHeaderStore } from '@/stores/header';
import { storeToRefs } from 'pinia';
import { IS_DEVELOPMENT } from '@/config/environment';

import { type ObjectModel } from '@/models/ObjectModel'
import { type TagModel } from '@/models/TagModel';

import cloneDeep from 'lodash.clonedeep';


const props = defineProps({
  tagMapper: { 
    type: Function as PropType<(tag: Tag) => MAPPED_TAG_T>,
    default: defaultTagMapper
  },
  onAddAllowed: {
    type: Function as PropType<(isAllowed: boolean) => void>    
  },
  currentComponentProps: {
    type: Object as PropType<ComponentPropsWithConfig>
  },
  mergeComponentProps: {
    type: Function as PropType<(initalComponentProps: COMPONENT_PROPS_T, selectedComponentProps: COMPONENT_PROPS_T ) => COMPONENT_PROPS_T>,
    default: defaultMergeComponentProps
  },
  autoSelectDropdownsDescriptors: {
    type: Array as PropType<Array<LocationObjectTagSearchEntry<DESC_TAGS_T, MAPPED_TAG_T>>>,
    default: (() => [
      {
        title: 'Select upper alarm level parameters:',    
        descTag: 'upper_alarm_level',
        autoParametersSelectors: {
          objectSelector: objectSelectorFactoryByObjectPropertyAndValue('displaycode', 'X1'),
          tagSelector: tagSelectorFactoryByTagPropertyAndValue('referencecode', 'h' ),
        }
      },
      {
        title: 'Select lower alarm level parameters:',    
        descTag: 'lower_alarm_level',
        autoParametersSelectors: {
          objectSelector: objectSelectorFactoryByObjectPropertyAndValue('displaycode', 'X1'),
          tagSelector: tagSelectorFactoryByTagPropertyAndValue('referencecode', 'l' ),
        }
      },
      {
        title: 'Select upper level parameters for turnning off the pump:',    
        descTag: 'upper_pump_level',
        autoParametersSelectors: {
          objectSelector: objectSelectorFactoryByObjectPropertyAndValue('displaycode', 'X1'),
          tagSelector: tagSelectorFactoryByTagPropertyAndValue('referencecode', 'uitl' ),
        }
      },
      {
        title: 'Select lower level parameters for turning on the pump:',    
        descTag: 'lower_pump_level',
        autoParametersSelectors: {
          objectSelector: objectSelectorFactoryByObjectPropertyAndValue('displaycode', 'X1'),
          tagSelector: tagSelectorFactoryByTagPropertyAndValue('referencecode', 'inl' ),
        }
      },
      {
        title: 'Select maximal presentation value parameters:',    
        descTag: 'max_presentation_level',
        autoParametersSelectors: {
          objectSelector: objectSelectorFactoryByObjectPropertyAndValue('displaycode', 'N1'),
          tagSelector: tagSelectorFactoryByTagPropertyAndValue('referencecode', 'maxp' ),
        }
      },
      {
        title: 'Select minimal presentation value parameters :',    
        descTag: 'min_presentation_level',
        autoParametersSelectors: {
          objectSelector: objectSelectorFactoryByObjectPropertyAndValue('displaycode', 'N1'),
          tagSelector: tagSelectorFactoryByTagPropertyAndValue('referencecode', 'minp' ),
        }
      } 

    ]) as () => Array<LocationObjectTagSearchEntry<DESC_TAGS_T, MAPPED_TAG_T>>
  }
});

const emit = defineEmits<{
  (e: 'config-change', componentProps: COMPONENT_PROPS_T): void  
}>();

const headerStore = useHeaderStore();
const { selectedLocation } = storeToRefs(headerStore);

const lastComponentProps = ref<COMPONENT_PROPS_T>(cloneDeep(props?.currentComponentProps ?? {}) as COMPONENT_PROPS_T )
const lastSelectedValueTag = ref<MAPPED_TAG_T[] | null>(null);
const lastSelectedLocationValuetag = ref<string | null>();

const selectedTags = ref<Map<string, MAPPED_TAG_T[]>>( new Map<string, MAPPED_TAG_T[]>());

const { isLessThen480 } = useSmarthubBreakpoints();

watch( selectedTags.value, (newSelectedTags) => {  
  const selectedLocationsObjectParams =  Array.from(toRaw(newSelectedTags).values()).flat();
  emit('config-change', { config: { locationsObjectsTags: selectedLocationsObjectParams} } );
  if(selectedLocationsObjectParams.length > 0) {
    props?.onAddAllowed && props.onAddAllowed(true);
  } else {
    props?.onAddAllowed && props.onAddAllowed(!IS_DEVELOPMENT ? false : true);
  }

})

function selectionChangeHandlerFactory(descTag: string) {  
  return function(tagsSelection: MAPPED_TAG_T[]){    
    selectedTags.value.set(descTag, tagsSelection)
  }
}

const selectionChangedValueHandler = selectionChangeHandlerFactory('value');
function selectionChangeValueHandlerWrapper(tagsSelection: MAPPED_TAG_T[]){
  selectionChangedValueHandler(tagsSelection);
  lastSelectedValueTag.value = tagsSelection;
}

const selectionChangeHandlers = computed( () => Object.fromEntries( 
  props.autoSelectDropdownsDescriptors
    .map( searchDropdown => [searchDropdown.descTag, selectionChangeHandlerFactory(searchDropdown.descTag)])
    )
  );

function locationSelectedValueHandler(locationId: string | null) {
  lastSelectedLocationValuetag.value = locationId;
}

</script>

<template>
  <div class="h-max min-h-[290px] radar-level-config-content-container">
    <StationObjectParamSearchResponsive
      :style="{zIndex: props?.autoSelectDropdownsDescriptors?.length > 0 ? (props?.autoSelectDropdownsDescriptors?.length + 1 * 100 ): undefined}"
      class="h-max relative"    
      :globally-selected-location-id="selectedLocation?.id ?? null"
      title="Select instalation parameters for widget value:"
      :multiselect="false"
      :tag-mapper="tagMapper"
      :tag-description="'value'"
      @selection-change="selectionChangeValueHandlerWrapper"
      @location-selected="locationSelectedValueHandler"
      />
    <div class="mt-10" v-if="autoSelectDropdownsDescriptors">
      <StationObjectParamSearch
        :style="{zIndex: (autoSelectDropdownsDescriptors.length - index) * 10}"
        class="h-max relative" 
        :key="index"
        v-for="(searchDropdown, index) in autoSelectDropdownsDescriptors"
        :title=" searchDropdown.title"
        :globally-selected-location-id="lastSelectedLocationValuetag"
        :multiselect="false"
        :tag-mapper="searchDropdown.tagMapper"
        :tag-description="searchDropdown.descTag"
        @selection-change="selectionChangeHandlers[searchDropdown.descTag]"
        :auto-parameters-selectors-trigger="lastSelectedValueTag"
        :auto-parameters-selectors="searchDropdown.autoParametersSelectors"        
        :select-dropdown-combination="!isLessThen480 ? 'location-object-tag' : 'object-tag'"
      />
    </div>
  </div>
</template>

<style scoped>
@media screen and (min-width: 580px) {
  .radar-level-config-content-container {
    @apply w-max;
  }
}

@media screen and (max-width: 580px) {
  .radar-level-config-content-container {
    @apply w-full;
  }
}
</style>