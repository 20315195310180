<script setup lang="ts">
import { useDevice, DeviceSize } from '../../../smarthub/src/utils/DeviceSize';

const props = defineProps({
  tabActive: {
    type: String,
    default: 'alarms'
  } 
});

const device = useDevice();

const calcuateWindowHeight = () => {
  let containerSize;
  const header = document.querySelector<HTMLElement>('header')?.offsetHeight ?? 0;
  if (device.size === DeviceSize.mobile) {
    const bodySpace = window.innerHeight - 35 - header;
    containerSize = bodySpace;
    return containerSize + 'px';
  } else {
    return '';
  }
};

const calcuateWindowWidth = () => {
  return device.size === DeviceSize.mobile ? '100vw' : '';
};

const getSpacing = () => {
  return device.size === DeviceSize.mobile ? 'space-between' : 'unset';
};

</script>
<template>
  <div
    class="widget flex flex-col cursor-default"
    :class="{'widget-archive': props.tabActive === 'notes'}"
    :style="[ {height: calcuateWindowHeight(), justifyContent: getSpacing(), width: calcuateWindowWidth()}]"
  >
    <header
      class="flex justify-between items-center px-4 pt-4 pb-2"
      style="border-bottom: 1px solid #1A1A1A;"
    >
      <slot name="header" />
    </header>
    <slot />
    <footer class="widget__footer pb-2 pt-2">
      <slot name="footer" />
    </footer>
  </div>
</template>

<style lang="scss" scoped>

.widget-archive {
    &::before {
      background-color: #2C2E31 !important;
    }
    @media (max-width: 576px) { 
      &::before {
        background-color: #464a4f !important;
      }
  }
}
.widget {
  position: relative;
  min-height: 396px;
  width: 300px;
  z-index: 10;
  border-radius: 25px;
  background: linear-gradient(0deg, #313435 0%, #464a4f 100%);
  box-shadow: -1px 18px 15px 19px rgb(0 0 0 / 53%);
  margin: 2rem auto;

  &::before {
    position: absolute;
    content: "";
    background-color: #3d4043;
    height: 30px;
    width: 30px;
    transform: rotate(45deg);
    top: 45%;
    left: -15px;
  }
  
  &__footer {
    border-top: 2px solid rgb(26 26 26 / 48%);
  }

  @media (max-width: 430px) {
    border-radius: 25px 25px 0 0;
    margin: 0;
    &::before {
      display: none;
    }
  }
}

</style>
