<script setup lang="ts">
import VueApexCharts from 'vue3-apexcharts';
import { ref, watchEffect, watch, type PropType, computed } from 'vue';
import { type TrendResponse, type ISeries } from '../../api/dtos/TrendDto';
import { type VueApexChartsComponent } from 'vue3-apexcharts';
import { type TagModel } from '../../models/TagModel';
import { useWindowSize } from '@vueuse/core';

const props = defineProps({
	firstChartData: {
		type: Array as PropType<TagModel[]>,
		required: true,
	},
	firstChartNames: {
		type: Array,
		required: true,
	},
	clickedTagName: {
		type: String,
		required: false,
	},
});

const series = ref<ISeries[]>([]);
const options = ref({
	chart: {
		type: 'area',
		height: '300px',
		foreColor: '#fff',
		dropShadow: {
			enabled: true,
			enabledSeries: [0],
			top: -2,
			left: 2,
			blur: 5,
			opacity: 0.06,
		},
		toolbar: {
			show: false,
		},
	},
	legend: {
		show: false,
	},
	plotOptions: {
		bar: {
			horizontal: true,
		},
	},
	colors: ['#80d8ff', '#f4956f', '#b9f6ca', '#b388ff', '#00b0ff', '#c61407', '#ff9100', '#00e676', '#651fff'],
	stroke: {
		curve: 'straight',
		width: 3,
	},
	dataLabels: {
		enabled: false,
	},
	markers: {
		size: 0,
		strokeColor: '#fff',
		strokeWidth: 3,
		strokeOpacity: 1,
		fillOpacity: 1,
		hover: {
			size: 6,
		},
	},
	xaxis: {
		type: 'datetime',
		axisBorder: {
			show: false,
		},
		axisTicks: {
			show: false,
		},
		labels: {
			datetimeUTC: false,
		},
	},
	yaxis: {
		labels: {
			offsetX: 14,
			offsetY: -5,
		},
		tooltip: {
			enabled: true,
		},
	},
	grid: {
		padding: {
			left: -5,
			right: 5,
		},
		yaxis: {
			lines: {
				show: false,
			},
		},
	},
	tooltip: {
		x: {
			format: 'dd MMM yyyy',
		},
	},
	fill: {
		type: 'solid',
		opacity: 0,
	},
	toolbar: {
		offsetX: 0,
		offsetY: 0,
	},
	annotations: {
		yaxis: [
			{
				y: 0.27,
				borderColor: '#ff0000',
				strokeDashArray: 7,
				label: {
					borderColor: '#00E396',
					style: {
						color: '#fff',
						background: '#00E396',
					},
				},
			},
			{
				y: 0.22,
				borderColor: '#956333',
				strokeDashArray: 7,
				label: {
					borderColor: '#00E396',
					style: {
						color: '#fff',
						background: '#00E396',
					},
				},
			},
		],
	},
});
const mainChartRef = ref<VueApexChartsComponent | null>(null);
const firstChartKey = ref(0);

function createSeries(response: TrendResponse): [number, number][] | undefined {
	return response.map((el) => [new Date(`${el.logtime}`).getTime(), el.logvalue]);
}

function toggleSeries(clickedTagName: string) {
	if (props.firstChartNames.find((el) => el === clickedTagName)) {
		mainChartRef?.value?.toggleSeries(clickedTagName);
	}
}

const { width, height } = useWindowSize();
const firstChartHeight = computed(() => height.value < 500 ? 150 : height.value / 2 - 130);

watchEffect(() => {
	if (props.firstChartData) {
		series.value = props.firstChartData
			.filter((tag) => tag.trend && tag.trend.length > 0)
			.map((tag) => {
				return {
					name: tag.name as string,
					data: createSeries(tag.trend as TrendResponse),
				};
			});
	}
});

watch(
	() => props.clickedTagName,
	(newValue) => {
		if (newValue) {
			toggleSeries(newValue);
		}
	}
);

watch(firstChartHeight, (newValue) => {
	if (newValue) firstChartKey.value++;
});
</script>

<template>
	<div class="relative">
		<VueApexCharts
			ref="mainChartRef"
			class="apexchart"
			width="100%"
			type="area"
			:height="firstChartHeight"
			:options="options"
			:series="series"
			:key="firstChartKey"
		/>
	</div>
</template>

<style lang="scss" scoped></style>
