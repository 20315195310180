import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import AlarmView from '../views/AlarmView.vue';
import DashboardView from '../views/DashboardView.vue';
import HistoryView from '../views/HistoryView.vue';
import GraphView from '../views/GraphView.vue';
import ArchiveView from '../views/ArchiveView.vue';

import AuthTest from "../test/auth-test/AuthTest.vue";

import MapOpenLayersTestView from "../test/map-test/MapOpenLayersTestView.vue";
import MapOpenLayersDeckGLTestView from "../test/map-test/MapOpenLayersDeckGLTestView.vue";
import MapDeckGLTestExampleView from "../test/map-test/MapDeckGLTestExampleView.vue";
import MapDeckGLTestView from '../test/map-test/MapDeckGLTestView.vue';
import ComponentsTestView from '@/test/ComponentsTestView.vue';

import { IS_DEVELOPMENT } from '../config/environment'
import { useAuthStore } from '@/stores/auth';
import { ROUTE_PATHS } from '@/constants/routes-paths';
import DashboarLayoutServiceTestVue from '@/test/DashboarLayoutServiceTest.vue';


const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: "",
        redirect: 'map'
      },
      {
        path: 'dashboard',
        component: DashboardView
      },
      {
        path: 'map',
        component: AlarmView
      },
      {
        path: "graph",          
        component: GraphView
      },
      {
        path: "archive",          
        component: ArchiveView
      },
      {
        path: "history",          
        component: HistoryView
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }    
];

if(IS_DEVELOPMENT){
  routes.push(
    {
      path: "/test",
      children: [
        {
          path: "auth",
          component: AuthTest
        },
        {
          path: "map",
          component: MapOpenLayersTestView
        },
        {
          path: "deckgl",
          component: MapDeckGLTestView
        },
        {
          path: "deckgl-example",
          component: MapDeckGLTestExampleView
        },
        {
          path: 'ol-deckgl',
          component: MapOpenLayersDeckGLTestView
        },
        {
          path: 'components',
          component: ComponentsTestView
        },
        {
          path: 'dashboard',
          component: DashboarLayoutServiceTestVue,
        }
      ]
    }  
  )
  
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});


router.beforeEach(async (from, to) => {
  const auth = useAuthStore();    
  
  const userLoggedIn = await auth.isLoggedIn()
  if(!userLoggedIn && from.name != 'login'){
    return ROUTE_PATHS.LOGIN;
  }
  
  if(userLoggedIn && from.name == 'login') {
    return ROUTE_PATHS.HOME;
  }
});

export default router
