<template>
  <svg
    width="32.3648208px"
    height="26.9506498px"
    viewBox="0 0 32.3648208 26.9506498"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>C770E725-081F-40C1-86F5-7D1B9EA43F15</title>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      :opacity="opacity"
    >
      <g
        id="InterAct-Dashboard-Pump-UX-Alarm-Notes-Mobile"
        transform="translate(-36.0619, -816.4104)"
        :stroke="color"
        stroke-width="2"
      >
        <g
          id="Group-29"
          transform="translate(1, 161)"
        >
          <g
            id="Group-3"
            transform="translate(0.2997, 638.5662)"
          >
            <g
              id="Group-8"
              transform="translate(34.7622, 16.8442)"
            >
              <path
                id="Triangle"
                d="M16.1824104,1.94258914 L30.5979549,25.9506498 L1.76686594,25.9506498 L16.1824104,1.94258914 Z"
              />
              <path
                id="Triangle"
                d="M16.1824104,11.3753166 L22.5067497,21.9080524 L9.85807115,21.9080524 L16.1824104,11.3753166 Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#FFFFFF'
    },
    opacity: {
      type: String,
      default: '0.480143229'
    }
  },
};
</script>

