<template>
  <svg
    width="122"
    height="30"
    viewBox="0 0 122 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.973145"
      y="0.5"
      width="120.054"
      height="28.1719"
      rx="7.5"
      stroke="white"
    />
    <path
      d="M41.8356 15.4981L41.0546 17.4121H39.8446L43.2216 9.62411H44.2116L47.5556 17.4121H46.3236L45.5316 15.4981H41.8356ZM43.6836 10.8891L42.2206 14.5411H45.1356L43.6836 10.8891ZM54.1706 15.6081L55.0396 16.2021C54.4346 17.0271 53.4336 17.6101 52.0696 17.6101C49.6826 17.6101 47.9666 15.9051 47.9666 13.5071C47.9666 11.0761 49.7486 9.42611 52.1136 9.42611C53.1806 9.42611 54.3356 9.84411 54.9076 10.6361L54.0496 11.2961C53.6756 10.7681 52.9166 10.3831 52.1136 10.3831C50.3096 10.3831 49.1546 11.8021 49.1546 13.5071C49.1546 15.2561 50.2876 16.6421 52.0806 16.6421C52.9496 16.6421 53.7086 16.2791 54.1706 15.6081ZM61.8298 15.6081L62.6988 16.2021C62.0938 17.0271 61.0928 17.6101 59.7288 17.6101C57.3418 17.6101 55.6258 15.9051 55.6258 13.5071C55.6258 11.0761 57.4078 9.42611 59.7728 9.42611C60.8398 9.42611 61.9948 9.84411 62.5668 10.6361L61.7088 11.2961C61.3348 10.7681 60.5758 10.3831 59.7728 10.3831C57.9688 10.3831 56.8138 11.8021 56.8138 13.5071C56.8138 15.2561 57.9468 16.6421 59.7398 16.6421C60.6088 16.6421 61.3678 16.2791 61.8298 15.6081ZM64.8989 13.8481V16.4441H68.9689V17.4121H63.8099V9.62411H68.8039V10.5811H64.8989V12.9131H68.5729V13.8481H64.8989ZM70.4163 17.4121V9.62411H72.7923C74.4753 9.62411 75.5753 10.2731 75.5753 11.7911C75.5753 13.3531 74.3543 13.9911 72.7373 13.9911H71.5053V17.4121H70.4163ZM71.5053 10.5481V13.0671H72.6603C73.7273 13.0671 74.4753 12.7151 74.4753 11.7911C74.4753 10.8561 73.7053 10.5481 72.6823 10.5481H71.5053ZM79.6218 10.5811V17.4121H78.5328V10.5811H76.0358V9.62411H82.1188V10.5811H79.6218Z"
      fill="white"
    />
  </svg>
</template>
