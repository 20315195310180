<script lang="ts" generic=" KEYS_MAP_T extends DEFAULT_KEYS_MAPPING_TYPE, ITEM_T extends SELECT_ITEM_TYPE<KEYS_MAP_T>">
export type SELECT_ITEM_TYPE<KEYS_MAP_T> = {
  [K in keyof KEYS_MAP_T]: K extends 'title' | 'desc' 
    ?  string | undefined | null 
    : K extends 'iconComponent' 
      ? Component
      : KEYS_MAP_T[K]
} /*& {
  [i: string | number]: any
};*/

export type DEFAULT_KEYS_MAPPING_TYPE = {
  iconComponent?: string | null | undefined;
  title: string;
  desc?: string | null | undefined;
  disabled?: boolean;
  customAttribute?: {
    attributeName: string,
    attributeValue: any
  }
}

const DEFAULT_KEYS_MAPPING = {
  title: 'title',
  desc: 'desc',
  iconComponent: 'iconComponent'
}

export type DropDownPosition = 'top-left' | 'bottom-left' | 'top-right' | 'bottom-right' | 'top-center' | 'bottom-center' | 'bottom-under';

</script>
<script lang="ts" setup generic="KEYS_MAP_T extends DEFAULT_KEYS_MAPPING_TYPE, ITEM_T extends SELECT_ITEM_TYPE<KEYS_MAP_T>">
import  { type PropType, type Component, ref, computed, onMounted, onBeforeUnmount, toRaw, watchEffect, watch } from 'vue';
import Button from '@/components/Button.vue';
import DefaultWidgetThumbnail from '@/components/icons/DefaultWidgetThumbnail.vue'
import type { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import type { EventEmitter } from 'events';
import { onClickOutside } from '@vueuse/core';
import { useResizeObserver } from '@vueuse/core';

const dropDownOpen = ref(false);


function toggleDropDown() {
  const previousOpen = toRaw(dropDownOpen.value);
  dropDownOpen.value = !dropDownOpen.value;
  if(previousOpen) {
    emit('drop-down-closed')
  }else {
    emit('drop-down-opened');
  }
}

function closeDropDown() {
  dropDownOpen.value = false;
  emit('drop-down-closed');
}

const props = defineProps({  
  buttonLabel: { // legacy prop
    type: String
  },
  headerLabel: {
    type: String
  },
  buttonBackgroundColor: {
    type: String,  
  },
  buttonIconComponent: {  //legacy prop
    type: Object as PropType<Component>,
    required: false,    
  },
  headerIconComponent: {
    type: Object as PropType<Component>,
    required: false,    
  },
  buttonIconPlacement: {  //legacy prop
    type: String as PropType<'left' | 'right'>,    
  },
  headerIconPlacement: {
    type: String as PropType<'left' | 'right'>,    
  },
  dropDownContentHeight: {
    type: String,
    default: '222.059px'
  },
  dropDownContentWidth: {
    type: String,
    default: '182.891px'
  },
  dropDownContentAction: {
    type: Object as PropType<{iconComponent: Component, label: string}>,
    default: null
  },  
  dropDownPosition: {
    type: String as PropType<DropDownPosition>
  },
  dropDownShadow: {
    type: String,
    default: '-1px 10px 7px 11px rgba(0,0,0,0.53)'
  },
  items: {
    type: Array as PropType<Array<ITEM_T>>,
    default: () => [],
  },
  titleOnlyItemWidth: {
    type: String,
    default: '135px'
  },
  titleOnlyItemContentAdditionalWidth: {
    type: String,
    default: '0px'
  },
  showSelectedInHeader: {
    type: Boolean,
    default: false    
  },
  showDesc: {
    type: Boolean,
    default: true
  },
  showIcon: {
    type: Boolean,
    default: true
  },
  iconPosition: {
    type: String as PropType<'left' | 'right'>,
    default: 'left'
  },
  keysMap: {
    type: Object as PropType<KEYS_MAP_T>,
    default: () => ({
      ...DEFAULT_KEYS_MAPPING
    }),
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false
  },
  externalControlEventEmitter: {
    type: Object as PropType<EventEmitter>,
    required: false
  },
  headerComponent: {
    type: Object as PropType<Component>,
    required: false
  },
  itemsGap: {
    type: String,
    default: '10px'
  },
  itemsGapTitleOnly: {
    type: String,
    default: '12px'
  }
});

const dropDownContainerRef = ref();
const actionBtnRef = ref();
const selectDropdownRef = ref();

const emit = defineEmits<{
  (e: 'item-selected', item: ITEM_T, index: number) : void;
  (e: 'action-click') : void;
  (e: 'drop-down-opened'): void;
  (e: 'drop-down-closed'): void;
}>()

function itemSelectedHandler(item: ITEM_T, index: number){
  emit('item-selected', item, index);
  closeDropDown();
}

function contentActionClickedHanlder() {
  emit('action-click');
  closeDropDown();
}

const headerHeight = ref();
const headerWidth = ref();

const titleKey = computed(() => (props.keysMap as KEYS_MAP_T)?.title ?? DEFAULT_KEYS_MAPPING.title);
const descKey = computed(() => (props.keysMap as KEYS_MAP_T)?.desc ?? DEFAULT_KEYS_MAPPING.desc);
const iconComponentKey = computed(() => (props.keysMap as KEYS_MAP_T).iconComponent ?? DEFAULT_KEYS_MAPPING.iconComponent);


function closeDropDownExternalAction(){  
  if(dropDownOpen.value) {
    dropDownOpen.value = false;
  }
}

onMounted(() => {
  if(props.externalControlEventEmitter){
    props.externalControlEventEmitter.addListener('close-dropdown', closeDropDownExternalAction)
  }

  onClickOutside(selectDropdownRef, closeDropDownExternalAction);
})

onBeforeUnmount(() => {
  if(props.externalControlEventEmitter) {
    props.externalControlEventEmitter.removeListener('close-dropdown', closeDropDownExternalAction)
  }
})



const glueActionButton = computed( () => {
  if(dropDownOpen.value && dropDownContainerRef.value && actionBtnRef.value) {    
    if(props.items.length == 0) {
      return true;
    }
    const dropDownContentElement = dropDownContainerRef.value.firstElementChild;
    const itemHeight = dropDownContentElement.firstElementChild.offsetHeight; //34.75        
    
    const contentHeight = dropDownContentElement.offsetHeight;
    const totalItemsHeight =  props.items.length * 12 + props.items.length * itemHeight + actionBtnRef.value.offsetHeight;    
    return totalItemsHeight < contentHeight;
  } else {        
    return false;    
  }  
});
/*
const glueActionButton = ref();

watch( [
  () => props.items, 
  () => dropDownOpen.value, 
  () => dropDownContainerRef.value, 
  () => actionBtnRef.value 
], ([items, open, dropDownContainer, actionBtn]) => {  
  if(open && dropDownContainer && actionBtn) {    
    if(items.length == 0) {
      return true;
    }
    const dropDownContentElement = dropDownContainer.firstElementChild;
    const itemHeight = dropDownContentElement.firstElementChild.offsetHeight; //34.75        
    
    const contentHeight = dropDownContentElement.offsetHeight;
    const totalItemsHeight =  props.items.length * 12 + props.items.length * itemHeight + actionBtnRef.value.offsetHeight;
    glueActionButton.value = totalItemsHeight < contentHeight
  } else {    
    glueActionButton.value = false
    return false;    
  }
});
*/

const headerRef = ref();
  useResizeObserver(headerRef, (entries) => {
  const entry = entries[0]
  
  const { width, height } = entry.target.getBoundingClientRect();

  headerWidth.value = `${width}px`;
  headerHeight.value = `${height}px`;  
})

</script>

<template>
  <div 
    class="relative overflow-visible"
    :class="{'select-disabled': disabled}"
    ref="selectDropdownRef"    
    @wheel.stop="() => {}"
    @touchstart.stop="() => {}"
    @touchend.stop="() => {}"
    @touchmove.stop="() => {}"
  >    
    <component
      ref="headerRef"
      :is="headerComponent ?? Button"
      :class="{
        'title-only-width': !showIcon && !showDesc, 
        'header-button-title-only-right-icon': !showIcon && !showDesc && buttonIconComponent && buttonIconPlacement == 'right'
        }"      
      :background-color="buttonBackgroundColor"
      :icon-placement="buttonIconPlacement ?? headerIconPlacement"
      :icon-component="buttonIconComponent ?? headerIconComponent"
      :disabled="disabled"
      class="select-header-container"
      @click="toggleDropDown"
    >    
      {{ buttonLabel ?? headerLabel }}
    </component>
    <Transition name="select-dropdown">
    <div 
      class="drop-down-content-wrapper absolute" v-if="dropDownOpen && !disabled"      
      :class="{
        'title-only-content-wrapper': !showDesc && !showIcon, 
        'title-and-icon-only-content-wrapper': !showDesc && showIcon, 
        'title-only-width': !showDesc && !showIcon,
        'drop-down-content-wrapper-top': dropDownPosition == 'top-left' || dropDownPosition == 'top-right' || dropDownPosition == 'top-center' || dropDownPosition == null,
        'drop-down-content-wrapper-bottom': dropDownPosition == 'bottom-left' || dropDownPosition == 'bottom-right' ||  dropDownPosition == 'bottom-center',
        'drop-down-content-wrapper-bottom-under': dropDownPosition == 'bottom-under',
        'drop-down-content-wrapper-right': dropDownPosition == 'top-right' || dropDownPosition == 'bottom-right',        
        'drop-down-content-wrapper-center': dropDownPosition == 'top-center' || dropDownPosition == 'bottom-center'
        }"     
      ref="dropDownContainerRef"
    >
    <PerfectScrollbar 
      :options="{ suppressScrollX: true}"      
      class="h-full w-full drop-down-content overflow-hidden"      
    >
      <div 
        v-for="(item, index) in items" 
        class="flex gap-2.5 drop-down-item select-none justify-between"
        :class="{
          'title-and-icon-only': showIcon && !showDesc,
          'drop-down-item-disabled': item?.disabled ?? false
          }"
        :key="index"
        @click="() => itemSelectedHandler(item, index)"        
      >
        <div class="grow-0 self-center drop-down-item-icon" v-if="showIcon && iconPosition == 'left'">
          <component :is="(item[iconComponentKey as keyof KEYS_MAP_T] && item[iconComponentKey as keyof KEYS_MAP_T]) ?? DefaultWidgetThumbnail" />
        </div>
        <div class="flex flex-col grow-3 self-center">
          <div class="whitespace-nowrap overflow-hidden drop-down-title">{{ item[titleKey as keyof KEYS_MAP_T] }}</div>
          <div v-if="showDesc" class="whitespace-nowrap overflow-hidden drop-down-desc">{{ (item[descKey as keyof KEYS_MAP_T] && item[descKey as keyof KEYS_MAP_T]) ?? "" }}</div>
        </div>
        <div class="grow-0 self-center drop-down-item-icon" v-if="showIcon && iconPosition == 'right'">
          <component :is="(item[iconComponentKey as keyof KEYS_MAP_T] && item[iconComponentKey as keyof KEYS_MAP_T]) ?? DefaultWidgetThumbnail" />
        </div>

      </div>      
      <div 
        ref="actionBtnRef"
        class="drop-down-action flex justify-center items-center"
        :class="{'drop-down-action-not-full-list': glueActionButton, 'drop-down-action-full-list': !glueActionButton}"
        @click="contentActionClickedHanlder"
        v-if="dropDownContentAction">
          <component v-if="dropDownContentAction.iconComponent" :is="dropDownContentAction.iconComponent" />
          <div>{{ dropDownContentAction.label }}</div>
      </div>          
    </PerfectScrollbar>
  </div>
  </Transition>    
  </div>
</template>

<style scoped lang="scss">
.select-disabled {
  user-select: none;  
}
.select-header-container.button-container::v-deep .button-text,
.select-header-container.button-container::v-deep .select-header-text {  
  text-wrap: nowrap;
  overflow: hidden;
}

.drop-down-content-wrapper::v-deep .ps__rail-y:hover {
  background-color: rgb(207, 209, 211);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.drop-down-content-wrapper-top {
  @apply mt-3;  
}

.drop-down-content-wrapper-right {
  left: calc(v-bind(headerWidth) - v-bind(dropDownContentWidth));
}

.drop-down-content-wrapper-right.title-only-content-wrapper {
  left: calc(v-bind(headerWidth) - (v-bind(titleOnlyItemWidth) + v-bind(titleOnlyItemContentAdditionalWidth)));
}

.drop-down-content-wrapper-right.title-and-icon-only-content-wrapper {
  left: calc(v-bind(headerWidth) - (v-bind(titleOnlyItemWidth) + v-bind(titleOnlyItemContentAdditionalWidth)));
}


.drop-down-content-wrapper-center {
  left: calc(0px - (v-bind(dropDownContentWidth) - v-bind(headerWidth)) / 2);
}

.drop-down-content-wrapper-center.title-only-content-wrapper {
  left: calc(0px - ((v-bind(titleOnlyItemWidth) + v-bind(titleOnlyItemContentAdditionalWidth)) - v-bind(headerWidth) ) / 2);
}


.drop-down-content-wrapper-bottom {
  bottom: 0;
  margin-bottom: calc(v-bind(headerHeight) + 20px);
  
}

.drop-down-content-wrapper-bottom-under {
  bottom: -80px;
  left: -130px;
  margin-bottom: calc(v-bind(headerHeight) + 20px);
}

.drop-down-content-wrapper-left {
  left: 0;  
}

.header-button-title-only-right-icon {
  justify-content: space-between !important;
  padding-right: 8px;
}

.drop-down-content-wrapper {
  width: v-bind(dropDownContentWidth); /*182.891px;*/
  height: v-bind(dropDownContentHeight);
  flex-shrink: 0;

  border-radius: 15px;
  border: 1px solid #797979;
  background: #34383B;
  
  display: flex;
  flex-direction: column;
  gap: 10px;

  /*box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.3);*/

  /*box-shadow: -1px 10px 7px 11px rgba(0,0,0,0.53);*/
  box-shadow: v-bind(dropDownShadow);
}
.drop-down-content {
  flex-shrink: 0;
  
  padding: 12px;
  padding-top: 10px;

  display: flex;
  flex-direction: column;
  gap: v-bind(itemsGap)
  
}

.title-only-width {
  width: v-bind(titleOnlyItemWidth);
}
.drop-down-content-wrapper.title-only-content-wrapper {    
  height: calc(v-bind(dropDownContentHeight) - 8.567px);
  flex-shrink: 0;  

  display: flex;
  flex-direction: column;
  gap: 12px;  
  
  width: calc(v-bind(titleOnlyItemWidth) + v-bind(titleOnlyItemContentAdditionalWidth));
}

.drop-down-content-wrapper.title-and-icon-only-content-wrapper {    
  height: calc(v-bind(dropDownContentHeight) - 8.567px);
  flex-shrink: 0;  

  display: flex;
  flex-direction: column;
  gap: 12px;  
  
  width: calc(v-bind(titleOnlyItemWidth) + v-bind(titleOnlyItemContentAdditionalWidth));
  min-width: fit-content;

}


.title-only-content-wrapper .drop-down-content,
.title-and-icon-only-content-wrapper .drop-down-content {
  flex-shrink: 0;  

  display: flex;
  flex-direction: column;
  gap: v-bind(itemsGapTitleOnly);

  padding-bottom: 0px !important;
}

.drop-down-title {
  display: flex;  
  /*width: 148.558px;*/
  height: 16.763px;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;

  color: #FFF;
  font-family: 'Avenir Next LT Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
}

.title-only-content-wrapper .drop-down-item,
.title-and-icon-only-content-wrapper .drop-down-item {  
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;

  /*max-height: 16.8px"*/ /*padding top and bottom 3px and 3px*/
}


.drop-down-item {
  box-sizing: content-box;
  /*max-height: 33.6px;*/
}

.drop-down-item:hover {
  box-sizing: content-box;
  background-color: #5a6165;  
  cursor: pointer;
  outline: solid 4px #5a6165;
  border-radius: 4px;
}

.drop-down-item-icon {
  /* used for targeting element outside of component*/
}

.drop-down-item-disabled {
  pointer-events: none;  
  user-select: none;
  opacity: 0.45;
}

.title-and-icon-only {
  min-height: 20px;
}

.title-only-content-wrapper .drop-down-item:hover,
.title-and-icon-only-content-wrapper .drop-down-item:hover {  
  outline: solid 5px #5a6165;
}


.drop-down-desc {
  display: flex;
  width: 148.558px;
  height: 16.763px;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;

  color: #FFF;
  font-family: Avenir Next LT Pro;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 150% */

  opacity: 0.5;

}

.drop-down-action {
  display: flex;
  /*width: 148.558px;*/
  /*width: 131.164px;*/
  /*height: 16.763px;*/
  /*height: 33.934px;*/
  flex-direction: row;
  justify-content: center;
  flex-shrink: 0;

  gap: 6px;

  color: #FFF;
  text-align: center;
  font-family: Avenir Next LT Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; 

  padding-top: 11px;
  padding-bottom: 11px;

  border-top: solid 1px #797979;  
}

.drop-down-action-not-full-list {
  @apply absolute bottom-0 left-0 right-0;
}
.drop-down-action-full-list {
  @apply mx-[-12px];
}
.drop-down-action:hover {
  /*@include select-item-hover;*/
  box-sizing: content-box;
  background-color: #5a6165;  
  cursor: pointer;
  /*outline: solid 4px #5a6165;*/
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top: solid 1px #797979; 

}

.drop-down-action-line {
  display: flex;
  /*width: 148.558px;*/
  /*width: 131.164px;*/
  height: 16.763px;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;

  color: #FFF;
  text-align: center;
  font-family: Avenir Next LT Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; 

  border-top: solid 1px #797979; 
}

.drop-down-action-label {
  background: #797979;
}


</style>

<style>
.select-dropdown-enter-active,
.select-dropdown-leave-active {
  transition: opacity 0.4s ease;
}

.select-dropdown-enter-from,
.select-dropdown-leave-to {
  opacity: 0;
}
</style>