import { ref } from 'vue';
import { defineStore } from 'pinia'
import { type LocationDto } from '../api/dtos/LocationDto';
import { useStoreAuthEvents } from '@/composables/storeAuthEvents';

const DEFAULT_TITLE = 'Pumping Stations - Central City Maps';

export const useHeaderStore = defineStore('header', () => {
  const state = {
    title: ref(DEFAULT_TITLE),
    isDropdownOpenState: ref(false),
    selectedLocation: ref<LocationDto | null>(null),
    selectLocationEnabled: ref<boolean>(true)
  }

  function toggleDropdown() {    
    state.isDropdownOpenState.value = !state.isDropdownOpenState.value;
  }

  const setSelectedItem = (item: LocationDto) => {
    state.selectedLocation.value = item;
  };

  const removeSelectedItem = () => {
    state.selectedLocation.value = null;
  }

  const disableSelectLocation = () => {
    state.selectLocationEnabled.value = false;
  }

  const enableSelectLocation = () => {
    state.selectLocationEnabled.value = true;
  }

  useStoreAuthEvents({
    loggedOut() {
      removeSelectedItem();
      enableSelectLocation();
    }
  })

  return { ...state, toggleDropdown, setSelectedItem, removeSelectedItem, disableSelectLocation, enableSelectLocation }
});


