import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useStoreAuthEvents } from '@/composables/storeAuthEvents';
import { useInitialDashboardDataService } from '@/services/InitialDashboardDataService';

export const useInitialDashboardDataStore = defineStore('dashboard-init-data', () => {
  const isDashboardInitialized = ref<boolean | null>(null);
  const isDashboardInitializedLoading = computed( () => isDashboardInitialized.value === null);

  const { hasInitializedData, createInitialDashboardViews: createInitialDashboardViewsService, deleteInitialDashboardViews } = useInitialDashboardDataService();

  useStoreAuthEvents({
    async loggedIn() {
      const result = await hasInitializedData();
      isDashboardInitialized.value = result;
    },
    async loggedOut() {
      isDashboardInitialized.value = null;
    },
  });

  async function createInitialDashboardViews() {    
    try {
      await createInitialDashboardViewsService();
      isDashboardInitialized.value = true;
    } catch(error){
      isDashboardInitialized.value = false;
      throw error;
    }
  }

  return {
    isDashboardInitialized,
    isDashboardInitializedLoading,
    
    createInitialDashboardViews,
    deleteInitialDashboardViews
  }
})
