import isBefore from 'date-fns/isBefore'

export class CachedData {
  private cacheBuckets: Map<string, CachedDataBucket>;
  private defaultTtlOffset: number;
  private defaultBucketSize: number;

  private cleanupService;

  constructor(
    cleanupServiceInterval: number = 4000, 
    defaultTtlOffset: number = 4000, 
    defaultBacketSize: number = 1) {

    this.cacheBuckets = new Map<string, CachedDataBucket>();
    this.defaultTtlOffset = defaultTtlOffset;
    this.defaultBucketSize = defaultBacketSize;

    const that = this;
    this.cleanupService = setInterval( () => that.runCleanup(), cleanupServiceInterval);
  }

  get<RESULT_T>(bucketKey: string, key: string ): RESULT_T | null {
    if(this.cacheBuckets.has(bucketKey)) {
      const bucket = this.cacheBuckets.get(bucketKey);
      return bucket?.get(key) ?? null;
    }

    return null;
  }

  set<RESULT_T>(
    bucketKey: string, 
    key: string, 
    value: RESULT_T, 
    ttlOffset: number = this.defaultTtlOffset,
    bucketSize: number = this.defaultBucketSize
  ): void {
    if(!(this.cacheBuckets.has(bucketKey))) {
      this.cacheBuckets.set(bucketKey, new CachedDataBucket(bucketSize, ttlOffset, ));
    }

    const bucket = this.cacheBuckets.get(bucketKey) as CachedDataBucket;
    bucket.set<RESULT_T>(key, value, ttlOffset);
    if(bucket.bucketSize != bucketSize) {
      bucket.bucketSize = bucketSize;
    }
  }

  protected getCurrentTimePlusOffset(ttlOffset: number) {
    return new Date().getTime() + ttlOffset;
  }

  runCleanup() {
    Array.from(this.cacheBuckets.values()).forEach( bucket => bucket.runCleanup() )
  }

  purgeAll() {
    Array.from(this.cacheBuckets.values()).forEach( bucket => bucket.purgeAll() )
    this.cacheBuckets.clear();
  }

  purgeBuckets(bucketsKeys: string[]) {
    const bucketKeysToPurge = Array
    .from(this.cacheBuckets.keys())
    .filter( (bucketKey) => (!bucketsKeys || (bucketsKeys && bucketsKeys.length == 0) ? false : bucketsKeys.includes(bucketKey)) )
    
    for( const purgeBuketKey of bucketKeysToPurge) {
      this.cacheBuckets.get(purgeBuketKey)?.purgeAll();
      this.cacheBuckets.delete(purgeBuketKey);
    }
  }


}

interface CacheDataBuckedDescriptor<RESULT_T> {
  value: RESULT_T | null;
  ttl: number;  
}


class CachedDataBucket {

  public bucketSize: number;
  protected defaultTtlOffset: number;
  protected cache: Map<string, CacheDataBuckedDescriptor<any>>;

  constructor(bucketSize: number = 1, defaultTtlOffset: number = 4000) {
    this.bucketSize = bucketSize;
    this.cache = new Map<string, any>();
    this.defaultTtlOffset = defaultTtlOffset;
  }
  
  static isExpiredByCurrentTime(currentMillis: number, ttlMillis: number): boolean {
    return isBefore(ttlMillis, currentMillis);    
  }
  static isExpired(ttlMillis: number): boolean {
   return isBefore(ttlMillis, new Date().getTime());   
  }

  get<RESULT_T>(key: string): RESULT_T | null {
    if(this.cache.has(key)) {
      const bucketDesc = this.cache.get(key) as CacheDataBuckedDescriptor<RESULT_T>;
      if(! CachedDataBucket.isExpired(bucketDesc?.ttl)) {
        return bucketDesc.value;
      } else {
        bucketDesc.value = null;
        this.delete(key);
      }
    }

    return null;
  }

  set<RESULT_T>(key: string, value: RESULT_T, ttlOffset: number = this.defaultTtlOffset): void {
    if(!(this.cache.has(key))) {
      if(this.cache.size + 1 > this.bucketSize) {
        this.runCleanup()
      }

      if(this.cache.size + 1 > this.bucketSize) {
        this.purgeOne();
      }      
    }

    this.cache.set(key, {
      ttl: new Date().getTime() + ttlOffset,
      value
    });      

    if(this.defaultTtlOffset != ttlOffset) {
      this.defaultTtlOffset = ttlOffset
    }
  }

  protected delete(key: string) {
    this.cache.delete(key);
  }

  protected clear(key: string) {
    if(this.cache.has(key)) {
      const bucketDesc = this.cache.get(key) as CacheDataBuckedDescriptor<any>;
      bucketDesc.value = null;
    }
  }

  runCleanup() {
    const currentTime = new Date().getTime();    

    const deleteKeys = Array.from(this.cache.entries())      
      .filter(([key, value]) => CachedDataBucket.isExpiredByCurrentTime(currentTime, value.ttl))
      .map(([key]) => key);
        
    for(const key of deleteKeys) {
      this.clear(key)
      this.delete(key);
    }
  }

  purgeOne() {
    const sortedKeys = Array.from(this.cache.entries())            
      .sort(([key1, value1] , [key2, value2]) => value2.ttl - value1.ttl )
      .map(([key]) => key)
    
    const deleteKey = sortedKeys.shift();
    
    if(deleteKey) {
      this.clear(deleteKey);
      this.delete(deleteKey);
    }
  }

  purgeAll() {
    const deleteKeys = Array.from(this.cache.entries())          
    .map(([key]) => key);
  
    for(const key of deleteKeys) {
      this.clear(key)
      this.delete(key);
    }
  }


}