<template>
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.49507 1.11963L8.04854 4.6731L4.49507 8.22656L4.15521 7.8867L7.12713 4.91478L0.337402 4.91478L0.337402 4.43141L7.12713 4.43141L4.15521 1.45949L4.49507 1.11963Z"
      fill="white"
      stroke="white"
      stroke-width="0.5"
    />
  </svg>
</template>
