<template>
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M20.2496 20.9996C20.1511 20.9998 20.0535 20.9804 19.9626 20.9427C19.8716 20.905 19.7889 20.8496 19.7195 20.7798L3.21945 4.27976C3.08474 4.13797 3.01074 3.94915 3.01325 3.75358C3.01575 3.55801 3.09455 3.37115 3.23285 3.23285C3.37116 3.09455 3.55801 3.01575 3.75358 3.01325C3.94915 3.01074 4.13797 3.08473 4.27976 3.21945L20.7798 19.7194C20.8846 19.8243 20.956 19.9579 20.9849 20.1034C21.0138 20.2488 20.9989 20.3996 20.9422 20.5366C20.8855 20.6736 20.7894 20.7907 20.6661 20.8731C20.5428 20.9555 20.3979 20.9995 20.2496 20.9996Z"
			fill="white"
		/>
		<path
			d="M11.9837 17.9996C10.0388 17.9996 8.16336 17.424 6.4093 16.2887C4.81226 15.2574 3.37461 13.7804 2.25148 12.023V12.0193C3.18617 10.6801 4.20992 9.54757 5.30961 8.63445C5.31956 8.62613 5.32767 8.61584 5.33344 8.60423C5.33921 8.59262 5.3425 8.57993 5.34312 8.56698C5.34374 8.55403 5.34166 8.54109 5.33702 8.52898C5.33238 8.51688 5.32528 8.50586 5.31617 8.49664L4.38242 7.56429C4.36583 7.54756 4.34354 7.5377 4.32 7.53665C4.29646 7.53561 4.2734 7.54347 4.25539 7.55867C3.08726 8.54304 2.00304 9.75054 1.01726 11.1643C0.847663 11.4077 0.754257 11.6961 0.748915 11.9927C0.743573 12.2893 0.826537 12.5809 0.987264 12.8302C2.22523 14.7676 3.81851 16.3993 5.59414 17.5482C7.59336 18.8434 9.74492 19.4996 11.9837 19.4996C13.1921 19.4959 14.3919 19.2967 15.5368 18.9099C15.5519 18.9048 15.5654 18.8959 15.5762 18.8841C15.5869 18.8723 15.5944 18.8579 15.598 18.8424C15.6016 18.8268 15.6012 18.8106 15.5968 18.7953C15.5924 18.78 15.5842 18.766 15.5729 18.7548L14.5613 17.7432C14.538 17.7205 14.5092 17.7042 14.4778 17.696C14.4463 17.6878 14.4132 17.6879 14.3818 17.6963C13.5984 17.8981 12.7926 18 11.9837 17.9996Z"
			fill="white"
		/>
		<path
			d="M23.0077 11.184C21.7674 9.26586 20.1582 7.63648 18.3545 6.47164C16.359 5.18164 14.1559 4.49961 11.9837 4.49961C10.7881 4.50173 9.60133 4.70503 8.4732 5.10101C8.45817 5.10624 8.44472 5.11521 8.43411 5.12708C8.42351 5.13895 8.4161 5.15332 8.41258 5.16884C8.40906 5.18436 8.40955 5.20052 8.41401 5.2158C8.41847 5.23108 8.42674 5.24497 8.43805 5.25617L9.4482 6.26632C9.47172 6.28944 9.50091 6.30595 9.53285 6.31418C9.56478 6.32241 9.59832 6.32207 9.63008 6.3132C10.3974 6.10563 11.1888 6.00019 11.9837 5.99961C13.891 5.99961 15.7609 6.58226 17.5407 7.73398C19.1677 8.78398 20.6223 10.2596 21.7482 11.9996C21.749 12.0007 21.7495 12.002 21.7495 12.0034C21.7495 12.0047 21.749 12.006 21.7482 12.0071C20.9309 13.2938 19.9167 14.4442 18.7426 15.4163C18.7325 15.4246 18.7243 15.4349 18.7185 15.4465C18.7126 15.4582 18.7092 15.4709 18.7086 15.4839C18.7079 15.4969 18.71 15.5099 18.7146 15.5221C18.7193 15.5343 18.7264 15.5453 18.7355 15.5546L19.6684 16.4869C19.6849 16.5036 19.707 16.5135 19.7305 16.5146C19.7539 16.5157 19.7769 16.508 19.7949 16.493C21.0484 15.4377 22.1334 14.1971 23.0124 12.8143C23.1678 12.5706 23.25 12.2874 23.2491 11.9984C23.2483 11.7094 23.1645 11.4268 23.0077 11.184Z"
			fill="white"
		/>
		<path
			d="M11.9996 7.49961C11.6625 7.49942 11.3265 7.53716 10.9979 7.61211C10.9813 7.61555 10.9659 7.62344 10.9535 7.63492C10.941 7.6464 10.9319 7.66104 10.9271 7.6773C10.9222 7.69356 10.9219 7.71081 10.9261 7.72723C10.9304 7.74365 10.939 7.75862 10.951 7.77054L16.2287 13.0468C16.2406 13.0588 16.2556 13.0674 16.272 13.0717C16.2884 13.0759 16.3057 13.0756 16.3219 13.0708C16.3382 13.0659 16.3528 13.0568 16.3643 13.0443C16.3758 13.0319 16.3837 13.0165 16.3871 12.9999C16.5374 12.3409 16.5372 11.6564 16.3867 10.9975C16.2362 10.3385 15.9391 9.72188 15.5176 9.19345C15.0961 8.66502 14.5609 8.23834 13.9519 7.9451C13.3428 7.65186 12.6756 7.49959 11.9996 7.49961Z"
			fill="white"
		/>
		<path
			d="M7.77055 10.9524C7.75862 10.9404 7.74365 10.9318 7.72723 10.9276C7.71082 10.9233 7.69356 10.9236 7.6773 10.9285C7.66105 10.9333 7.6464 10.9424 7.63492 10.9549C7.62344 10.9673 7.61555 10.9827 7.61211 10.9993C7.44211 11.742 7.46346 12.5156 7.67414 13.2478C7.88483 13.9799 8.27796 14.6466 8.81668 15.1853C9.3554 15.7241 10.0221 16.1172 10.7542 16.3279C11.4864 16.5386 12.2601 16.5599 13.0027 16.3899C13.0193 16.3865 13.0347 16.3786 13.0472 16.3671C13.0596 16.3556 13.0688 16.341 13.0736 16.3247C13.0784 16.3085 13.0787 16.2912 13.0745 16.2748C13.0703 16.2584 13.0617 16.2434 13.0496 16.2315L7.77055 10.9524Z"
			fill="white"
		/>
	</svg>
</template>
