<script lang="ts" generic="DESC_TAGS_T extends string = string, MAPPED_TAG_T = DefaultMappedTagType<DESC_TAGS_T>">
export type SelectDropdownCombination = 'location' | 'location-object' | 'location-object-tag' | 'object-tag';
  
export function defaultTagMapper<DESC_TAGS_T extends string = string>({ location, object, tag}: Tag, descTag?: DESC_TAGS_T): DefaultMappedTagType {
  return {
    locationId: location.id,
    objectId: object?.id ?? null,
    tagId: tag?.id ?? null,
    ...({ descTag})
  }
}

export interface DefaultMappedTagType<DESC_TAGS_T extends string = string> { 
  locationId: string; 
  objectId: string; 
  tagId: string;
  descTag?: DESC_TAGS_T;
}

export interface AutoSelectResult<SELECTED_MODEL> {
  isMatched: boolean;
  value: SELECTED_MODEL | null;
  stopMatchingAndEmit?: boolean;
}

export interface AutoParametersSelectors<DESC_TAGS_T extends string = string> {
  //locationSelector?: (locations: LocationModel[], trigger: any) => LocationModel
  objectSelector?: (objects: ObjectModel[], trigger: any) => AutoSelectResult<ObjectModel>,
  tagSelector?: (tags: TagModel[], trigger: any) => AutoSelectResult<TagModel>,
  descTag?: DESC_TAGS_T
}


 </script>

<script lang="ts" setup generic="DESC_TAGS_T extends string = string, MAPPED_TAG_T = DefaultMappedTagType<DESC_TAGS_T>">
import { ref, type PropType, watch, toRaw, computed, onMounted, type Ref } from 'vue';
import TagsList, { type Tag } from './TagsList.vue';
import Select, { type SELECT_ITEM_TYPE } from './Select.vue';
import IconButton from './IconButton.vue';
import EventEmitter from 'events';
import type { TagModel } from '@/models/TagModel';
import type { ObjectModel } from '@/models/ObjectModel';
import type { LocationModel } from '@/models/LocationModel';
import { useCachedDataStore } from '@/stores/cachedData';
import isEmpty from 'lodash.isempty';
import { nextTick } from 'process';

const props = defineProps({
  multiselect: {
    type: Boolean,
    default: true
  },
  initialSelection: {
    type: Array as PropType<Tag[]>,
    required: false,
  },
  tagMapper: {   //should return reduced data to be persited with layout
    type: Function as PropType<(tags: Tag, descTag?: DESC_TAGS_T) => MAPPED_TAG_T>,
    default: defaultTagMapper
  },
  tagDescription: {
    type: String,    
    requried: false
  },
  globallySelectedLocationId: {
    type: String as PropType<string | null>,
    default:  null,
  },
  title: {
    type: String,
    default: "Select instalation parameters:"
  },
  autoParametersSelectors: {
    type: Object as PropType<AutoParametersSelectors<DESC_TAGS_T>>,
    required: false
  },
  autoParametersSelectorsTrigger: {
    type: Object as PropType<any>,
    required: false
  },
  selectDropdownCombination: {
    type: String as PropType<SelectDropdownCombination>,    
    default: 'location-object-tag'
  },
})

const emit = defineEmits<{
  //main event that shoud be used (contains location id, object id and tag id when all three are selected)
  (e: 'selection-change', selected: Array<MAPPED_TAG_T>): void;

  (e: 'location-selected', selectedLocationId: string | null): void;
  (e: 'object-selected', selectedObjectId: string | null): void;
  (e: 'tag-selected', selectedTagId: string | null): void;
  (e: 'auto-select-params-matched', isMatched: boolean): void;
}>();

function mapTags(tags: Tag[]): MAPPED_TAG_T[] {
  return tags.map((value) => props.tagMapper(value, props?.tagDescription as DESC_TAGS_T))
}

const emitSelectionOnLocation = computed( () => props.selectDropdownCombination == 'location');
const emitSelectionOnObject = computed( () => props.selectDropdownCombination == 'location-object');
const emitSelectionOnTag = computed( () => props.selectDropdownCombination == 'location-object-tag' || props.selectDropdownCombination == 'object-tag');
const hiddenLocationVisibleObjTag = computed( () => props.selectDropdownCombination == 'object-tag')

watch( () => props.globallySelectedLocationId, (newlySelectedLocationId) => {
  if(locations.value) {
    selectedLocationFromGlobalSelection(locations, newlySelectedLocationId);
  }
})

function selectedLocationFromGlobalSelection(locations: Ref<LocationModel[]>, newlySelectedLocationId: string | null) {
  if(newlySelectedLocationId) {
    const foundLocationIndex = locations.value.findIndex( l => l.id == newlySelectedLocationId)
    if(foundLocationIndex != -1) {
      locationSelectedHandler(locations.value[foundLocationIndex] as LocationItemType, foundLocationIndex);
    }
  } else {
    locationSelectedHandler(null, -1);
  }
}

const locationObjectTagsService = useCachedDataStore();

const locations = ref<LocationModel[]>([]);
  onMounted(async () => {
  await locationObjectTagsService.getLocations()
    .then( locationsResponse => locations.value = locationsResponse)  
  selectedLocationFromGlobalSelection(locations, props.globallySelectedLocationId)
})

const objects = ref<ObjectModel[]>([]);
const tags = ref<TagModel[]>([]);


const locationKeysMapping = {
  title: 'description',
  desc: 'displaycode'              
}

type LocationKeysMapping = typeof locationKeysMapping;

type LocationItemType = SELECT_ITEM_TYPE<LocationKeysMapping> & LocationModel;

type SelectionMethod = 'manual' | 'automatic';

const selectedLocation = ref<LocationItemType | null>(null)

function selectLocation(location: LocationItemType | null) {
  if(selectedLocation.value != location) {
    selectedLocation.value = location;
    emit('location-selected', location?.id ?? null)
    if(objects.value.length != 0){
      objects.value = [];
    }
    if(selectedObject.value) {
      selectedObject.value = null;      
    }
    if(tags.value.length != 0) {
      tags.value = [];
    }
    if(selectedParam.value) {
      selectedParam.value = null;
    }
  }
}

function locationSelectedHandler(location: LocationItemType | null, index: number) {  
  selectLocation(location);
}


const objectKeysMapping = {
  title: 'description',
  desc: 'displaycode'              
}

type ObjectKeysMapping = typeof objectKeysMapping

type ObjectItemType = SELECT_ITEM_TYPE<ObjectKeysMapping> & ObjectModel;

const selectedObject = ref<ObjectModel | null>(null);
const lastObjectSelectionMethod = ref<SelectionMethod>('manual');

function selectObject(objectItem: ObjectItemType | null, selectionMethod: SelectionMethod = 'manual') {
  if(selectedObject.value != objectItem) {
    selectedObject.value = objectItem;
    lastObjectSelectionMethod.value = selectionMethod;
    emit('object-selected', objectItem?.id ?? null);
    if(selectedParam.value) {
      selectedParam.value = null;
    }
  }
}


function objectSelectedHandler(objectItem: ObjectItemType | null, index: number) {
  selectObject(objectItem);
}

const paramsKeysMapping = {
  title: 'description',
  desc: 'displaycode'              
};

type ParamsKeysMapping = typeof paramsKeysMapping;

type ParamsItemType = SELECT_ITEM_TYPE<ParamsKeysMapping> & TagModel;

const selectedParam = ref<ParamsItemType | null>(null);
const lastParamSelectionMethod = ref<SelectionMethod>('manual');

function selectParam(paramItem: ParamsItemType | null, selectionMethod: SelectionMethod = 'manual') {  
  selectedParam.value = paramItem;  
  lastParamSelectionMethod.value = selectionMethod;
  emit('tag-selected', paramItem?.id ?? null);
}

function paramSelectedHandler(paramItem: ParamsItemType | null, index: number) {  
  selectParam(paramItem);
}

const locationSelectEmitter = new EventEmitter();
const objectSelectEmitter = new EventEmitter();
const parametersSelectEmitter = new EventEmitter();

const CLOSE_DROP_DOWN_ACTION  = 'close-dropdown';

function closeAllDropDownsExcept(dropDownToBeOpen: 'locations' | 'objects' | 'parameters') {  
  switch(dropDownToBeOpen){
    case 'locations': {
      objectSelectEmitter.emit(CLOSE_DROP_DOWN_ACTION);
      parametersSelectEmitter.emit(CLOSE_DROP_DOWN_ACTION);
      break;
    }
    case 'objects': {
      locationSelectEmitter.emit(CLOSE_DROP_DOWN_ACTION);
      parametersSelectEmitter.emit(CLOSE_DROP_DOWN_ACTION);
      break;
    }
    case 'parameters': {
      locationSelectEmitter.emit(CLOSE_DROP_DOWN_ACTION);
      objectSelectEmitter.emit(CLOSE_DROP_DOWN_ACTION);
      break;
    }
    default: throw new Error("Unknow dropdown select control");
  }
}

const selectedLocationObjectParameters = ref<Tag[]>([]);

function extractSelectedTag(): Tag {
  if(
    (emitSelectionOnLocation.value && selectedLocation.value) ||
    (emitSelectionOnObject.value && selectedObject.value) ||
    (emitSelectionOnTag.value && selectedParam.value)) {
    const selectedTag = {
      location: selectedLocation.value!,
      object: selectedObject.value! ?? null,
      tag: selectedParam.value! ?? null
    };
    
    return selectedTag;
  } else {  
    throw new Error("Nothing still selected");
  }
}

function addToSelectionHandler() {
  if(
    (emitSelectionOnLocation.value && selectedLocation.value)  ||
    (emitSelectionOnObject.value && selectedObject.value) ||
    (emitSelectionOnTag.value && selectedParam.value)){
    const tagToAdd: Tag = extractSelectedTag();

    selectedLocationObjectParameters.value.push(tagToAdd);
    if(emitSelectionOnTag.value) {
      selectParam(null);
    }

    emit('selection-change', mapTags(toRaw(selectedLocationObjectParameters.value)))
  }
}

function removeFromSelectionHandler(tag: Tag) {
  const { location: {
      id: targetLocationId
    }, object: {
      id: targetObjectid
    }, tag: {
      id: targetTagId
    } 
  } = tag;

  const index = selectedLocationObjectParameters.value
    .findIndex( ({location: loc, object: obj, tag: t}) => loc.id == targetLocationId && obj.id == targetObjectid && t.id == targetTagId )
  if(index != -1) {
    selectedLocationObjectParameters.value.splice(index, 1);
    emit('selection-change', mapTags(toRaw(selectedLocationObjectParameters.value)))
  }
}

const alreadySelectedObjectParams = computed( () => {
  if(props.multiselect && selectedObject.value) {    
    const { id: targetObjetId } = selectedObject.value;

    return { 
      objectId: targetObjetId,
      locationIds: selectedLocationObjectParameters.value
        .filter( ({object: { id: objectId}}) => objectId == targetObjetId)
        .map(({tag}) => tag.id)
    }
  } else {
    return {
      objectId: null,
      locationIds: []
    };
  }  
});

const availableParams = computed<TagModel[]>(() => {  
  if(selectedObject.value && alreadySelectedObjectParams.value?.objectId && alreadySelectedObjectParams.value.objectId == selectedObject.value.id) {    
    const { locationIds } = alreadySelectedObjectParams.value;

    return tags.value.filter( parameter => !locationIds.includes(parameter.id) )
  }else {
    return tags.value;
  }
})

watch(selectedLocation, async(newLocation, previousLocation) => {
  if(newLocation) {
    return locationObjectTagsService.getObjectsForLocation(newLocation.id)
      .then( objectsResponse => objects.value = objectsResponse)      
  }
});

watch(selectedObject, async (newObject) => {
  if(newObject) {    
    return locationObjectTagsService.getTagsForObject(newObject.id)
      .then( tagsResponse => tags.value = tagsResponse)
  }
})

watch([selectedLocation, selectedObject, selectedParam] ,([newSelectedLocation, newSelectedObj, newSelectedParam]) => {
  if(!props.multiselect) {
    if( 
      (emitSelectionOnLocation.value && newSelectedLocation) ||
      (emitSelectionOnObject.value && newSelectedLocation && newSelectedObj) ||
      (emitSelectionOnTag.value && newSelectedLocation && newSelectedObj && newSelectedParam)) {
      const tagToAdd: Tag = extractSelectedTag();

      selectedLocationObjectParameters.value = [tagToAdd];            
    } else if(selectedLocationObjectParameters.value.length != 0) {
        if( 
          (emitSelectionOnLocation.value && !newSelectedLocation) ||
          (emitSelectionOnObject.value && (!newSelectedLocation || !newSelectedObj) ||
          (emitSelectionOnTag.value && (!newSelectedLocation || !newSelectedObj || !newSelectedParam))) ) {
            selectedLocationObjectParameters.value = [];
            parametersMatched.value = false;
        }        
    }
  }
})

watch(selectedLocationObjectParameters, (newSelection) => {
  if(!props.multiselect) {    
    emit('selection-change', mapTags(newSelection));
  }
});


const objectAutoSelectFinished = ref<boolean>(false);
const tagAutoSelectedFinished = ref<boolean>(false);
const parametersMatched = ref<boolean>(false);

function emitAndSetAutoSelectParamsMatched(isMatched: boolean){  
  parametersMatched.value = isMatched;
  emit('auto-select-params-matched', isMatched);
}

function setAutoSelectObjectFinished(isFinished: boolean) {
  objectAutoSelectFinished.value = isFinished;
}

function setAutoSelectTagFinished(isFinished: boolean) {
  tagAutoSelectedFinished.value = isFinished;
}

watch( () => props.autoParametersSelectorsTrigger, (trigger) => {
  if (isEmpty(toRaw(trigger))) {  
    selectParam(null);
    selectObject(null);
  }
  setAutoSelectObjectFinished(false);
  setAutoSelectTagFinished(false);
  parametersMatched.value = false;
})


watch([() => props.autoParametersSelectorsTrigger, objects, tags], ([newAutoParamsSelectorsTrigger, newObjects, newTags]) => {  
  if(newAutoParamsSelectorsTrigger && props.autoParametersSelectors 
      && (!objectAutoSelectFinished.value || !tagAutoSelectedFinished.value)) {
    if(props.autoParametersSelectors?.objectSelector && newObjects.length > 0 && !objectAutoSelectFinished.value) {
    
      const newObjectToSelectResult = props.autoParametersSelectors.objectSelector(
        toRaw(objects.value), 
        toRaw(props.autoParametersSelectorsTrigger),        
      );

      const foundObjIndex = newObjectToSelectResult.value
        ? newObjects.findIndex( newObj => newObj.id == newObjectToSelectResult.value!.id)
        : -1;

      if(!newObjectToSelectResult.isMatched) {
        //nothing should be selected
      }else if(newObjectToSelectResult.value) {
        
        if(foundObjIndex != -1) {
          if(selectedObject.value == null) {
            selectObject(newObjects[foundObjIndex] as ObjectItemType, 'automatic')
            setAutoSelectObjectFinished(true);
            if(newObjectToSelectResult?.stopMatchingAndEmit || !props.autoParametersSelectors?.tagSelector) {
              emitAndSetAutoSelectParamsMatched(true);   
              setAutoSelectTagFinished(true);              
            }
            //console.log("Parameters selection - object seleted", newObjects[foundObjIndex]);            

          } else if(selectedObject.value.id != newObjectToSelectResult.value.id){
            selectObject(newObjects[foundObjIndex] as ObjectItemType, 'automatic')
            setAutoSelectObjectFinished(true);      
            if(newObjectToSelectResult?.stopMatchingAndEmit || !props.autoParametersSelectors?.tagSelector) {
              emitAndSetAutoSelectParamsMatched(true);   
              setAutoSelectTagFinished(true);              
            }

            //console.log("Parameters selection - object seleted", newObjects[foundObjIndex]);                        
          }

        }

      } else {////newObjectToSelectResult.value is null
        if(selectedObject.value != null) {
          selectObject(null, 'automatic');
          setAutoSelectObjectFinished(true);
          if(newObjectToSelectResult?.stopMatchingAndEmit || !props.autoParametersSelectors?.tagSelector) {
              emitAndSetAutoSelectParamsMatched(true);   
              setAutoSelectTagFinished(true);              
            }

          //console.log("Parameters selection - object seleted", null);          
        }
      }
      
    }// end of object selection    

    if(props.autoParametersSelectors?.tagSelector && newTags.length > 0 && !tagAutoSelectedFinished.value) {      

      const newTagToSelectResult = props.autoParametersSelectors.tagSelector(
        toRaw(tags.value), 
        toRaw(props.autoParametersSelectorsTrigger));

      const foundTagIndex = newTagToSelectResult.value 
        ? newTags.findIndex( newTag => newTag.id == newTagToSelectResult.value!.id)
        : -1;

      if(!newTagToSelectResult.isMatched) {
        //nothing should be selected
      }else if(newTagToSelectResult.value) {
        
        if(foundTagIndex != -1) {
          if(selectedParam.value == null) {
            selectParam(newTags[foundTagIndex] as ParamsItemType, 'automatic')
            setAutoSelectTagFinished(true);
            emitAndSetAutoSelectParamsMatched(true);
            //console.log("Parameters selection - tag seleted", newTags[foundTagIndex]);            

          } else if(selectedParam.value.id != newTagToSelectResult.value.id){
            selectParam(newTags[foundTagIndex] as ParamsItemType, 'automatic')
            setAutoSelectTagFinished(true);
            emitAndSetAutoSelectParamsMatched(true);
            //console.log("Parameters selection - tag seleted", newTags[foundTagIndex]);            
          }

        }

      } else {////newTagToSelectResult.value is null
        if(selectedParam.value != null) {
          selectParam(null, 'automatic')
          setAutoSelectTagFinished(true);
          emitAndSetAutoSelectParamsMatched(true);
          //console.log("Parameters selection - tag seleted", null);          
        }
      }      
    }// end of tag selection 
  }

}, {
  immediate: true,  
  flush: 'post'
});

watch( [() => lastObjectSelectionMethod.value, () => lastParamSelectionMethod.value],([newObjSelectionMethod, newParamSelectionMethod], [oldObjSelectionMethod, oldParamSelMethod]) => {
  if(
    (oldObjSelectionMethod == 'automatic' && newObjSelectionMethod == 'manual') ||
    (oldParamSelMethod == 'automatic' && newParamSelectionMethod == 'manual')) {
      emitAndSetAutoSelectParamsMatched(false);
  }
});

</script> 

<template>
  <div class="px-2 flex flex-col station-obj-param-container" :class="{'h-[250px]': multiselect}">
    <div class="flex flex-row" v-if="title">
      <span class="inline-flex mt-3.5 mb-0.5 px-4 select-none station-obj-param-title">{{ title }}</span>
      <span v-if="parametersMatched" class="inline-flex mt-3.5 mb-0.5 select-none -ml-2 station-obj-param-title matched">matched</span>
    </div>
    <div class="flex flex-row gap-0 flex-nowrap justify-center my-2 px-2 search-dropdown-wrapper">
      <Select        
        :disabled="!!globallySelectedLocationId"
        class="w-1/3 z-10"                       
        :class="{
          'leftmost-select': !emitSelectionOnLocation,
          'one-select': emitSelectionOnLocation,                    
          'one-of-two-selects': emitSelectionOnObject,
          'hidden': hiddenLocationVisibleObjTag
        }"
        :button-label="selectedLocation?.[locationKeysMapping['title'] as keyof LocationModel] ?? 'Station...'"        
        :items="(locations as unknown as LocationItemType[])"
        @item-selected="locationSelectedHandler"
        :show-icon="false"
        :keys-map="locationKeysMapping as LocationKeysMapping"
        :external-control-event-emitter="locationSelectEmitter"        
        @drop-down-opened="closeAllDropDownsExcept('locations')"
      />
      <Select      
        v-if="emitSelectionOnTag || emitSelectionOnObject"      
        class="w-1/3 z-10 select-container-override"
        :class="{          
          'leftmost-select': emitSelectionOnTag && hiddenLocationVisibleObjTag,          
          'centermost-select': emitSelectionOnTag && !hiddenLocationVisibleObjTag,                     
          'select-drop-down-content-centermost': emitSelectionOnTag && !hiddenLocationVisibleObjTag,
          'rightmost-select': emitSelectionOnObject && !hiddenLocationVisibleObjTag,
          'select-drop-down-content-rightmost': emitSelectionOnObject && !hiddenLocationVisibleObjTag,          
          'one-of-two-selects': (emitSelectionOnObject && !hiddenLocationVisibleObjTag) || (emitSelectionOnTag && hiddenLocationVisibleObjTag)
        }"
        :v-if="emitSelectionOnTag || emitSelectionOnObject"
        :disabled="!selectedLocation"
        :button-label="selectedObject?.[objectKeysMapping['title'] as keyof ObjectModel] ?? 'Object...'"        
        :items="(objects as unknown as ObjectItemType[])"
        @item-selected="objectSelectedHandler"
        :show-icon="false"
        :keys-map="objectKeysMapping"
        :external-control-event-emitter="objectSelectEmitter"       
        @drop-down-opened="closeAllDropDownsExcept('objects')"
      />
      <Select
        v-if="emitSelectionOnTag"
        class="rightmost-select w-1/3 z-10 select-container-override select-drop-down-content-rightmost"
        :class="{
          'one-of-two-selects': emitSelectionOnTag && hiddenLocationVisibleObjTag
        }"
        :disabled="!selectedObject || availableParams.length == 0"           
        :button-label="selectedParam?.[paramsKeysMapping['title'] as keyof TagModel] ?? 'Parameter...'"        
        :items="(availableParams as unknown as ParamsItemType[])"
        @item-selected="paramSelectedHandler"
        :show-icon="false"
        :keys-map="paramsKeysMapping"
        :external-control-event-emitter="parametersSelectEmitter"
        @drop-down-opened="closeAllDropDownsExcept('parameters')"
      />
      <IconButton
        v-if="multiselect"        
        class="ml-[-30px] z-10 self-center"
        @click="addToSelectionHandler"
      />

    </div>
    <TagsList 
      class="px-2 mt-1"
      v-if="multiselect"
      :tags="selectedLocationObjectParameters"
      @tag-delete="removeFromSelectionHandler"    
    />
  </div>

</template>

<style scoped>
.station-obj-param-title {
  display: flex;
  /*width: 139.256px;*/
  height: 16.763px;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;

  color: #FFF;

  font-family: 'Avenir Next LT Pro';
  /*font-size: 10px;*/
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 150% */
}

.station-obj-param-title.matched {
  @apply text-green-600 ;  
}

.select-container-override {
  @apply flex /*items-center*/ flex-col;
}

.select-drop-down-content-centermost {
  @apply items-center;
}

.select-drop-down-content-rightmost {
  @apply items-end;
}
.select-container-override:deep(.drop-down-content-wrapper) {
  top: 33px !important;
}

.search-dropdown-wrapper:deep(.select-header-container) {  
  align-items: flex-start;  
  justify-content: flex-start;  
  @apply px-3.5;
}



.leftmost-select:deep(.select-header-container) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  @apply w-full;
}

.centermost-select:deep(.select-header-container) {
  border-radius: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  @apply w-full;
}

.rightmost-select:deep(.select-header-container) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;  
  @apply w-full;
}

.one-select {
  @apply w-3/4;
}
.one-select:deep(.select-header-container) {
  @apply w-full;
}

.one-select:deep(.drop-down-content-wrapper) {
  @apply w-[98%] ml-[1%];    
}

.one-of-two-selects {
  @apply w-[45%];
}

.one-of-two-selects:deep(.select-header-container) {
  /*@apply w-1/2;*/
}
/*min-[580px]:w-[460px] max-[580px]:w-full*/
@media screen and (min-width: 580px) {
  .station-obj-param-container {
    @apply w-[460px];
  }
}

@media screen and (max-width:580px) {
  .station-obj-param-container {
    @apply w-full;
  }
}

</style>