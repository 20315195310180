import { useApi, type ApiLoadingType } from "@/composables/api";
import type { UserInfoDto } from "./dtos/UserInfoDto";
import { AUTH_URL } from "@/config/environment";

export interface IUserInfo {
  getUserInfo(): Promise<UserInfoDto>;
}

export interface LocationQueryParams {
  code: string | string[]
  dept: string | string[]
  zone: string | string[]
}

export function useUserInfoApi() : IUserInfo & ApiLoadingType {
  const api = useApi();  
  const { loading,  errors, clearErrors } = api;

  async function getUserInfo(): Promise<UserInfoDto> {
    return api.get(`${AUTH_URL}info`);
  }  


  return {
    getUserInfo,
    loading,
    errors,
    clearErrors
  }

}