import { useConnectedModeStore } from "@/stores/connectedMode"
import { storeToRefs } from "pinia";
import { onBeforeUnmount, onMounted } from "vue";

export const useDashboardConnectedMode = function() {
  const connectedModeStore = useConnectedModeStore();
  const { startConnectedMode, stopConnectedMode } = connectedModeStore;
  const { connectedMode, connectedModeLoading, dataFromDate } = storeToRefs(connectedModeStore);

  onMounted(() => connectedModeStore.startStatusPullingService())
  onBeforeUnmount(() => connectedModeStore.stopStatusPullingService())

  return {
    startConnectedMode,
    stopConnectedMode,

    connectedMode,
    connectedModeLoading,
    dataFromDate
  }
}