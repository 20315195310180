<template>
  <svg
    width="11"
    height="13"
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.36035 12.1514C2.01499 12.1514 1.71924 12.0283 1.47309 11.7821C1.22694 11.536 1.10407 11.2405 1.10449 10.8955V2.73242H0.476562V1.47656H3.61621V0.848633H7.38379V1.47656H10.5234V2.73242H9.89551V10.8955C9.89551 11.2409 9.77243 11.5366 9.52629 11.7828C9.28014 12.0289 8.98459 12.1518 8.63965 12.1514H2.36035ZM8.63965 2.73242H2.36035V10.8955H8.63965V2.73242ZM3.61621 9.63965H4.87207V3.98828H3.61621V9.63965ZM6.12793 9.63965H7.38379V3.98828H6.12793V9.63965Z"
      fill="#F2F2F2"
    />
  </svg>
</template>
