<script lang="ts">
import { type SELECT_ITEM_TYPE, type DEFAULT_KEYS_MAPPING_TYPE } from './Select.vue';
import { computed, ref, type PropType, watch } from 'vue';

type MoreItemsEmitOptions = [
  'enter-edit-mode',
  'exit-edit-mode',
  'enter-connected-mode',
  'exit-connected-mode',
  'create-view',
  'delete-view',
  'copy-view',
  'save-view',
  'rename-view',
  'cancel-view-changes'
];
// typescript defineEmits troubles dodge
const moreItemsEmitOptions: MoreItemsEmitOptions = [
  'enter-edit-mode',
  'exit-edit-mode',
  'enter-connected-mode',
  'exit-connected-mode',
  'create-view',
  'delete-view',
  'copy-view',
  'save-view',
  'rename-view',
  'cancel-view-changes'
] as const;

type ArrayToTuple<T extends ReadonlyArray<string>, V = string> = keyof {
	[K in (T extends ReadonlyArray<infer U> ? U : never)]: V
};


export interface MoreItemsDropdownItem extends SELECT_ITEM_TYPE<DEFAULT_KEYS_MAPPING_TYPE> {  
  eventName: ArrayToTuple<MoreItemsEmitOptions>;
}
</script>

<script setup lang="ts">
import Select from './Select.vue';
import ThreeDotsButton from './ThreeDotsButton.vue';


const props = defineProps({
  selectedItem: Object as PropType<MoreItemsDropdownItem | null>,
  items: Object as PropType<MoreItemsDropdownItem[]>,
  dropDownContentHeightMaxItems: {
    type: Number,
    default: 10
  },
  dropDownContentHeightMinItems: {
    type: Number,
    default: 2
  },
  showIcons: {
    type: Boolean,
    default: true 
  }
})


const emit = defineEmits(moreItemsEmitOptions);  

function selectedItemHandler(item: MoreItemsDropdownItem,index: number) {  
  if(item.eventName) {
    emit(item.eventName);
  }  
}



const ONE_ITEM_HEIGHT = props.showIcons 
  ? 26
  : 22.75;
const GAP_BETWEEN_ITEMS = 12;
const PADDING_CONTAINER = 10;

const ADJUSTMENT = props.showIcons
  ? 15
  : 11;

function calculateDropdownHeight(numberOfItems: number) {
  return  numberOfItems * ONE_ITEM_HEIGHT + (numberOfItems - 1) * GAP_BETWEEN_ITEMS + (2 * PADDING_CONTAINER) + (
    numberOfItems == 1 && props.showIcons == true
    ? 11
    : ADJUSTMENT)
}
const MAX_MORE_ITEMS_HEIGHT = computed( () => {
  return calculateDropdownHeight(props.dropDownContentHeightMaxItems)
});

const dropDownContentHeight = computed(() => {
  const numberOfItems = props.items?.length ?? 0;
  const calculatedItemsHeight = calculateDropdownHeight(numberOfItems);
  if(calculatedItemsHeight != 0) {
    return `${calculatedItemsHeight < MAX_MORE_ITEMS_HEIGHT.value ? calculatedItemsHeight : MAX_MORE_ITEMS_HEIGHT.value}px`;      
  } else {
    return `${calculateDropdownHeight(props.dropDownContentHeightMinItems)}px`;
  }
});

const delayedContentHeightCalculation = ref();
watch( () => dropDownContentHeight.value, (newValue) => {
  setTimeout( () => delayedContentHeightCalculation.value = newValue, 1000 )
}, { flush: 'post'})

</script>

<template>
  <Select 
    class="more-items-drop-down"
    :header-component="ThreeDotsButton"    
    :show-icon="showIcons"    
    :icon-position="'right'"
    :show-desc="false"
    :drop-down-position="'top-right'"    
    :drop-down-content-height="dropDownContentHeight"    
    :items="items"            
    :title-only-item-content-additional-width="'34px'"
    @item-selected="selectedItemHandler"      
  />
</template>

<style scoped>
/*
.more-items-drop-down:deep(.drop-down-content-wrapper) {
  transition-delay: 0.25s;  
  transition-property: all;
}*/
.more-items-drop-down:deep(.drop-down-item-icon) {
  min-width: 16px;
  display: flex;
  justify-content: center;
}


</style>