import { ref, computed, toRef, toRaw, watch, type Ref, watchEffect, shallowRef } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useHeaderStore } from './header';
import { TaskId, TaskState, type TaskStatusDto } from '@/api/dtos/TaskDto';
import { useTaskService } from '@/services/taskService';
import type { TagDto, TagsResponse, TagSettingDotNetsted, TagSetting } from '@/api/dtos/TagDto';
import { useTagService } from '@/services/tagService';
import type { TagModel } from '@/models/TagModel';
import {createLogger } from '@/utils/logger';
import { IS_DEVELOPMENT } from '@/config/environment';
import { useLoading } from '@/composables/loading';
const logger = createLogger();
if(!IS_DEVELOPMENT) {
  logger.setLoggerEnabled(false);
}
logger.setLoggerEnabled(false);

const DEFAULT_PULLING_INTERVAL = 2000;
const FIELDS_FILTER: (keyof TagDto | TagSettingDotNetsted)[] = ['id', 'name', 'value', 'updated@uts','setting.value', 'setting.updated@uts'];

type UpdatedTagModel = Pick<TagModel,'id' | 'name' | 'value' |'updated@uts'> & { 'setting': Pick<TagSetting, 'value' | 'updated@uts'>};


export const useConnectedModeStore = defineStore('connectedMode', () => {  
  const headerStore = useHeaderStore();
  const { selectedLocation } = storeToRefs(headerStore);
  const taskService = useTaskService();
  const tagService = useTagService();
  

  const connectedStatus = ref<TaskStatusDto>();
  const updatedTagValues = ref<UpdatedTagModel[]>();

  //const connectedModeOn = computed(() => !!(connectedStatus.value && connectedStatus.value.state == TaskState.running) ) //triggers to often
  const connectedModeOn = ref<boolean>(false);
  const { 
    loading: connectedModeOnLoading,
    setLoading: setLoadingConnectedModeOn,    
   } = useLoading();


  watch( connectedStatus , (newValue) => {
    if(newValue && newValue?.state == TaskState.running) {
      connectedModeOn.value = true;
    }else {
      connectedModeOn.value = false;
    }    
  }, { immediate: true })

  const dataFromDate = computed( () => (connectedStatus.value?.['time@uts'] && String(connectedStatus.value?.['time@uts'])) ?? "")  

  watchEffect(() => logger.log("Connected mode loading", connectedModeOnLoading.value))
  const statusPullingInterval = ref();
  const pullingInterval = ref(DEFAULT_PULLING_INTERVAL);  

  const tagsPullingInterval = ref();

  const locationsTagsSubscribers = new LocationsTagsSubscribersMap();
  
  function setPullingInterval(millisecondsInterval: number) {
    pullingInterval.value = millisecondsInterval;
  }

  //protected api
  async function fetchTaskStatus() {
    if(selectedLocation.value && selectedLocation.value.id) {
      connectedStatus.value = await taskService.getStateForTask(selectedLocation.value.id, TaskId.Start_reading_realtime_values);

    } 
  }
  //protected api
  async function fetchTags() {    
    if(selectedLocation.value) {
      const locationId = selectedLocation.value.id;
      const tagsIdsObserving = locationsTagsSubscribers.getTagIdsForLocationId(locationId);

      if(tagsIdsObserving.length == 0) {
        return;
      }
      
      const result = await tagService.getTagsForLocation(locationId, { 
        fields: [...FIELDS_FILTER], 
        id: tagsIdsObserving
      }) as UpdatedTagModel[];
      
      updatedTagValues.value = result;
      notifyAllSubscribers(locationId, result);
    }
  }

  //protected api
  async function notifyAllSubscribers(locationId: string, updatedTagValues: UpdatedTagModel[]) {
    const tagMap = locationsTagsSubscribers.getTagMapForLocationId(locationId);
    if(tagMap) {
      updatedTagValues.forEach( updatedTagValue => {
        const connectedTagValues = tagMap.get(updatedTagValue.id);
        if(connectedTagValues) {
          Array.from(connectedTagValues.values())
            .forEach( connectedTagRef => {
              connectedTagRef.value.value = updatedTagValue.value              
              connectedTagRef.value['updated@uts'] = updatedTagValue['updated@uts'];
              if(updatedTagValue?.setting) {
                connectedTagRef.value.setting = {
                  ...(connectedTagRef.value?.setting ?? {}),
                  value: updatedTagValue.setting.value,
                  'updated@uts': updatedTagValue.setting['updated@uts']
                }
              }
            });            
        }      
      })
    }
  }
  
  
  async function startConnectedMode(): Promise<boolean> {
    if(selectedLocation.value) {
      setLoadingConnectedModeOn(true)
      try {
        const connectedTaskStatus = await taskService.getStateForTask(selectedLocation.value.id, TaskId.Start_reading_realtime_values);
        if(connectedTaskStatus && connectedTaskStatus.state != TaskState.running) {                  
            const result = await taskService.startConnectedMode(selectedLocation.value.id);                  
            const taskResult = result == 'success';
            /*
            if(taskResult) {
              await fetchTaskStatus();
            }*/

            return taskResult;
        }
      }
      finally {
        //setTimeout( () => setLoadingConnectedModeOn(false), (toRaw(pullingInterval.value) ?? DEFAULT_PULLING_INTERVAL) );
        setLoadingConnectedModeOn(false)
      }      
    }

    return false;
  }

  async function stopConnectedMode(): Promise<boolean> {
    if(selectedLocation.value) {
      setLoadingConnectedModeOn(true);
      try {
        const result = await taskService.stopConnectedMode(selectedLocation.value.id);
        const taskResult = result == 'success';
        /*
        if(taskResult) {
          await fetchTaskStatus();
        }*/

        return taskResult;
      }
      finally {
        //setTimeout( () => setLoadingConnectedModeOn(false), (toRaw(pullingInterval.value) ?? DEFAULT_PULLING_INTERVAL) );
        setLoadingConnectedModeOn(false)
      }
    }

    return false;
  }

  async function startStatusPullingService() {    
    if(!statusPullingInterval.value){
      statusPullingInterval.value = setInterval(fetchTaskStatus, toRaw(pullingInterval.value))
    }
  }

  async function stopStatusPullingService() {
    if(statusPullingInterval.value) {
      clearInterval(toRaw(statusPullingInterval.value));
      statusPullingInterval.value = null;
    }
  }

  watch( connectedModeOn,(nextValue) => {
    logger.log("Connected mode changed", nextValue)
    if(nextValue) {
      if(!tagsPullingInterval.value) {
        logger.log("Started connected mode")
        tagsPullingInterval.value = setInterval( fetchTags , 3000/*toRaw(tagsPullingInterval.value)*/);        
      }
    } else {
      if(tagsPullingInterval.value) {
        clearInterval(toRaw(tagsPullingInterval.value));
        tagsPullingInterval.value = null;
      }
    }
  }, { immediate: true})


  function subscribeToConnectedMode(locationId: string, tagId: string, valueRef: ConnectedValueRefType) {
    return locationsTagsSubscribers.putLocationTagSubscriber(locationId, tagId, valueRef);
  }

  function unsubscribeToConnectedMode(locationId: string, tagId: string, valueRef: ConnectedValueRefType) {
    return locationsTagsSubscribers.removeLocationTagSubscriber(locationId, tagId, valueRef);
  }

  return {
    startConnectedMode,
    stopConnectedMode,
    startStatusPullingService,
    stopStatusPullingService,

    subscribeToConnectedMode,
    unsubscribeToConnectedMode,

    setPullingInterval,

    connectedMode: connectedModeOn,
    connectedModeLoading: connectedModeOnLoading,

    dataFromDate
  }

});

export interface SubscribeTagDescriptor {
  locationId: string,
  objectId: string
  valueRef: Ref<string>
}

//type ObjectIdEntry = Set<Omit<SubscribeTagDescriptor, 'locationId' | 'objectId'>>;
export type ConnectedValueRefType = Ref<UpdatedTagModel>;

class LocationsTagsSubscribersMap {

  protected locationsMap: Map<
    string, 
    Map<string, Set<ConnectedValueRefType>
  >>

  constructor() {
    this.locationsMap = new Map<string, Map< string, Set<ConnectedValueRefType>>>();
  }

  putLocationTagSubscriber(locationId: string, tagId: string, valueRef: ConnectedValueRefType) {    
    logger.log("Tag Subscriber add - started") ;
    let locationMapValue: Map<string, Set<ConnectedValueRefType>>;
    if(!this.locationsMap.has(locationId)) {
      locationMapValue = new Map<string, Set<ConnectedValueRefType>>();                  
    } else {
      locationMapValue = this.locationsMap.get(locationId) as Map<string, Set<ConnectedValueRefType>>;
    }

    let tagMapValue: Set<ConnectedValueRefType>;
    if(!locationMapValue!.has(tagId) ) {      
      tagMapValue = new Set<ConnectedValueRefType>();
    }else {
      tagMapValue = locationMapValue.get(tagId) as Set<ConnectedValueRefType>;
    }

    tagMapValue.add(valueRef);    
    locationMapValue.set(tagId, tagMapValue)
    this.locationsMap.set(locationId, locationMapValue)

    logger.log("Tag Subscriber added", locationId, tagId, this.locationsMap )    
  }

  removeLocationTagSubscriber(locationId: string, tagId: string, valueRef: ConnectedValueRefType) {
    logger.log("Tag Subscriber remove - started");
    const locationMapValue = this.locationsMap.get(locationId);
    if(!locationMapValue)  {
      return;
    }

    const tagMapValue = locationMapValue.get(tagId);
    if(!tagMapValue) {
      return;
    }

    tagMapValue.delete(valueRef);

    if(tagMapValue.size == 0) {
      locationMapValue.delete(tagId);
    }

    if(locationMapValue.size == 0){
      this.locationsMap.delete(locationId);
    }

    logger.log("Tag Subscriber removed", locationId, tagId, this.locationsMap)
  }

  getValuesRefsForLocationId(locationId: string): Array<ConnectedValueRefType> {
    const locationMapValue = this.locationsMap.get(locationId);
    if(locationMapValue) {
      return Array.from(locationMapValue.values())
        .flatMap( refSet => Array.from(refSet.values()))
    } else {
      return [];
    }
  }

  getTagIdsForLocationId(locationId: string): Array<string> {
    const locationMapValue = this.locationsMap.get(locationId);
    if(locationMapValue) {
      return Array.from(locationMapValue.keys())
    } else {
      return [];
    }
  }

  getTagMapForLocationId(locationId: string): Map<string, Set<ConnectedValueRefType>> | undefined {
    return this.locationsMap.get(locationId);    
  }
}