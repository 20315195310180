import { useApi, type ApiLoadingType } from "@/composables/api";
import type { NotesResponse } from "./dtos/EventsDto";

export interface NotesQueryParams {
  s?: number
  e?: number
}


export interface IEvents {
  getNotesForLoaction(locationId: string, queryParams?: NotesQueryParams): Promise<NotesResponse>;
}


const API_VERSIONING = 'api/v1';

export function useNotesApi() : IEvents & ApiLoadingType {
  const api = useApi();
  const { loading,  errors, clearErrors } = api;

  async function getNotesForLoaction(locationId: string, queryParams?: NotesQueryParams): Promise<NotesResponse> {
    return api.get(`${API_VERSIONING}/locations/${locationId}/notes`, {}, {
      params: queryParams
    });
  }

  return {
    loading,
    errors,
    clearErrors,
    getNotesForLoaction
  }

}