import { useTagsApi, type ITagQueryParams } from "../api/tagsApi";
import { useServiceCallWithLoading } from "@/composables/serviceCallWithLoading";
import type { Ref } from "vue";
import type { TagResponse, TagsResponse } from "@/api/dtos/TagDto";
import type { TagModel } from "@/models/TagModel";



export interface ITagService {
    getTag(tagId: string): Promise<TagModel>;
    getTagLoading: Ref<boolean>;
    getTagErrors:  Ref<any | any[]>;
    getTagClearErrors: () => void;

    getTagsForLocation(locationId: string, queryParams?: ITagQueryParams): Promise<TagModel[]>;
    getTagsForLocationLoading: Ref<boolean>;
    getTagsForLocationErrors:  Ref<any | any[]>;
    getTagsForLocationClearErrors: () => void;

    modifyTagValue(tagId: string, value: string): Promise<Pick<TagModel, 'value'>>;

}

export const useTagService = function(): ITagService {
  const tagsApi = useTagsApi();

  const {
    serviceCall: getTag,
    loading: getTagLoading,
    errors: getTagErrors,
    clearErrors: getTagClearErrors,

  } = useServiceCallWithLoading<Parameters<ITagService['getTag']> ,TagResponse, TagModel>(
    tagsApi.getTag,
    async (tagDto ) => tagDto.tag
  );  

  const {
    serviceCall: getTagsForLocation,
    loading: getTagsForLocationLoading,
    errors: getTagsForLocationErrors,
    clearErrors: getTagsForLocationClearErrors,

  } = useServiceCallWithLoading<Parameters<ITagService['getTagsForLocation']> ,TagsResponse, TagModel[]>(
    tagsApi.getTagsForLocation,
    async (tagsDto ) => tagsDto.tags.map( oneTagResponse => oneTagResponse.tag)          
  );  

  async function modifyTagValue(tagId: string, value: string): Promise<Pick<TagModel, 'value'>> {
    return (await tagsApi.modifyTagValue(tagId, value)).tag;
  }


  return {
    getTag,
    getTagLoading,
    getTagErrors,
    getTagClearErrors,

    getTagsForLocation,
    getTagsForLocationLoading,
    getTagsForLocationErrors,
    getTagsForLocationClearErrors,
    
    modifyTagValue,
  }
}