<template>
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="3.99707"
      cy="4.20361"
      r="3.5"
      fill="#B51717"
    />
  </svg>
</template>
