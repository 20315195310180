<template>
  <svg
    width="33"
    height="41"
    viewBox="0 0 33 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.6148 40.7808C29.6785 40.7808 30.6986 40.3582 31.4507 39.6061C32.2028 38.8539 32.6254 37.8338 32.6254 36.7702V12.7065L20.5936 0.674683H4.55114C3.48746 0.674683 2.46734 1.09723 1.71521 1.84936C0.963072 2.6015 0.540527 3.62161 0.540527 4.68529V36.7702C0.540527 37.8338 0.963072 38.8539 1.71521 39.6061C2.46734 40.3582 3.48746 40.7808 4.55114 40.7808H28.6148ZM18.5883 4.68529L28.6148 14.7118H18.5883V4.68529ZM6.55644 12.7065H12.5724V16.7171H6.55644V12.7065ZM6.55644 20.7277H26.6095V24.7383H6.55644V20.7277ZM6.55644 28.7489H26.6095V32.7595H6.55644V28.7489Z"
      fill="#7C7F81"
    />
  </svg>
</template>
