
import { ref, computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useLocationAlarmsStore } from './locationAlarms';
import { welcomeOverlayService } from '@/services/WelcomeOverlayService';

export const useNotificationsStore = defineStore('notifications', () => {    
  const locationAlarmsStore = useLocationAlarmsStore();
  const { locationAlarms, alarmsInfo, loading: locationAlarmsLoading } = storeToRefs(locationAlarmsStore)  
  
  const overlaySeenInitialized = ref(false);
  const overlaySeen = ref(false);
  
  const isWelcomeOverlayOpen = computed(() => {
    return overlaySeenInitialized.value 
      ? !overlaySeen.value
      : false;
  });
  
  function setWelcomeOverlaySeen() {      
    overlaySeen.value = true;
    welcomeOverlayService.setWelcomeOverlaySeen(true);
  }

  function fetchWelcomeOverlaySeen() {
    if(!overlaySeenInitialized.value) {
      overlaySeen.value = welcomeOverlayService.getWelcomeOverlaySeen();
      overlaySeenInitialized.value = true;
    }
  }

  function clearWelcomeOverlaySeen() {
    overlaySeen.value = false;
    overlaySeenInitialized.value = false;
    welcomeOverlayService.setWelcomeOverlaySeen(false);
  }
      
  return { 
    isWelcomeOverlayOpen,    
    setWelcomeOverlaySeen,
    fetchWelcomeOverlaySeen,
    clearWelcomeOverlaySeen,
    alarmsInfo,
    locationAlarmsLoading
  }
});