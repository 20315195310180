<template>
    <svg width="16px" height="21px" viewBox="0 0 16 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>616C24F4-C677-4C8D-B511-9FCCB1E97DFD</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="InterAct-Dashboard-Pump-Login-User" transform="translate(-685, -623)" fill="#FFFFFF" fill-rule="nonzero">
                <g id="Group" transform="translate(666, 550)">
                    <g id="lock_FILL0_wght400_GRAD0_opsz24" transform="translate(19, 73)">
                        <path d="M2,21 C1.45,21 0.979166667,20.8041667 0.5875,20.4125 C0.195833333,20.0208333 0,19.55 0,19 L0,9 C0,8.45 0.195833333,7.97916667 0.5875,7.5875 C0.979166667,7.19583333 1.45,7 2,7 L3,7 L3,5 C3,3.61666667 3.4875,2.4375 4.4625,1.4625 C5.4375,0.4875 6.61666667,0 8,0 C9.38333333,0 10.5625,0.4875 11.5375,1.4625 C12.5125,2.4375 13,3.61666667 13,5 L13,7 L14,7 C14.55,7 15.0208333,7.19583333 15.4125,7.5875 C15.8041667,7.97916667 16,8.45 16,9 L16,19 C16,19.55 15.8041667,20.0208333 15.4125,20.4125 C15.0208333,20.8041667 14.55,21 14,21 L2,21 Z M2,19 L14,19 L14,9 L2,9 L2,19 Z M8,16 C8.55,16 9.02083333,15.8041667 9.4125,15.4125 C9.80416667,15.0208333 10,14.55 10,14 C10,13.45 9.80416667,12.9791667 9.4125,12.5875 C9.02083333,12.1958333 8.55,12 8,12 C7.45,12 6.97916667,12.1958333 6.5875,12.5875 C6.19583333,12.9791667 6,13.45 6,14 C6,14.55 6.19583333,15.0208333 6.5875,15.4125 C6.97916667,15.8041667 7.45,16 8,16 Z M5,7 L11,7 L11,5 C11,4.16666667 10.7083333,3.45833333 10.125,2.875 C9.54166667,2.29166667 8.83333333,2 8,2 C7.16666667,2 6.45833333,2.29166667 5.875,2.875 C5.29166667,3.45833333 5,4.16666667 5,5 L5,7 Z M2.0375,19 L2.0375,9 L2.0375,19 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>