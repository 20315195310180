import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import type { AlarmsQueryParams } from '@/api/alarmsApi';
import { useAlarmsApi } from '@/api/alarmsApi';
import type { AlarmResponse, AlarmLogResponse } from '@/api/dtos/AlarmDto';
import type { LocationDto } from '@/api/dtos/LocationDto';
import { useEventsStore } from './events';

export const useSelectedLocationStore = defineStore('selectedLocation', () => {
  const eventsStore = useEventsStore();
  const alarmsApi = useAlarmsApi();
  const locationId = ref<LocationDto | null>(null);
  const alarms = ref<AlarmResponse | null>(null);
  const location = ref();
  const loading = ref(false);
  const alarmId = ref();
  const alarmLogs = ref<AlarmLogResponse | null>(null);
  const selectedDate = ref({
    s: "",
    e: "",
  });
  const alarmDatesForLocation = ref([]);

  function setLocation(newLocation: LocationDto) {
    locationId.value = newLocation;
  }

  function setAlarm(newAlarm) {
    alarmId.value = newAlarm;
  }

  function setSelectedDate(newDate) {
    selectedDate.value = newDate;
  }

  watch(
    locationId,
    async (newLocationId) => {
      if (newLocationId) {
        try {
          const now = new Date();
          now.setHours(0, 0, 0, 0);
          const startTimestamp = now.getTime();
          now.setHours(23, 59, 59, 999);
          const endTimestamp = now.getTime();
          const queryParams: AlarmsQueryParams = {
            s: startTimestamp,
            e:endTimestamp,
          };
          loading.value = true;
          const alarmsResponse = await alarmsApi.getAlarmsForLocation(newLocationId.id, queryParams);
          const filteredData = alarmsResponse.alarms.filter(item => item.alarm.priorityid !== -1);

          const allDatesAlarmsResponse = await alarmsApi.getAlarmsForLocation(newLocationId.id);
          const filteredDatesList = allDatesAlarmsResponse.alarms.filter(item => {
              const priorityid = item.alarm.priorityid;
              return priorityid === 1 || priorityid === 10;
          }).map(item => ({
              priorityId: item.alarm.priorityid,
              up: item.alarm.up
          }));
          eventsStore.setEventsDate(new Date(), newLocationId.id);
          alarmDatesForLocation.value = filteredDatesList;
          alarms.value = filteredData;
          location.value = newLocationId;
        } catch (error) {
          console.error('Something went wrong: ', error);
        } finally {
          loading.value = false;
        }
      }
    },
    { immediate: true }
  );

  watch(
    selectedDate,
    async (newSelectedDate) => {
      if (newSelectedDate) {
        try {
          const startDate = selectedDate.value.s
          const endDate = selectedDate.value.e
          const queryParams: AlarmsQueryParams = {
            s: startDate,
            e: endDate,
          };
          loading.value = true;
          const alarmsResponse = await alarmsApi.getAlarmsForLocation(locationId.value.id, queryParams);
          const filteredData = alarmsResponse.alarms.filter(item => item.alarm.priorityid !== -1);
          alarms.value = filteredData;
        } catch (error) {
          console.error('Something went wrong: ', error);
        } finally {
          loading.value = false;
        }
      }
    },
  );

  watch(
    alarmId,
    async (newAlarmId) => {
      if (newAlarmId) {
        try {
          loading.value = true;
          const alarmLogResponse = await alarmsApi.getAlarmLog(newAlarmId.id);
          const sortedAlarms = alarmLogResponse.sort((a, b) => parseInt(b["timestamp@uts"]) - parseInt(a["timestamp@uts"]));
          alarmLogs.value = sortedAlarms;
        } catch (error) {
          console.error('Something went wrong: ', error);
        } finally {
          loading.value = false;
        }
      }
    }
  );

  return {
    locationId,
    setLocation,
    setAlarm,
    setSelectedDate,
    alarms,
    alarmLogs,
    loading,
    location,
    alarmId,
    alarmDatesForLocation,
    selectedDate,
  };
});
