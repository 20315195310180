<script setup lang="ts">
import TimeLineDots from '../icons/TimeLineDots.vue';
import AcceptAlarmLog from '../icons/AcceptAlarmLog.vue';
import AcceptedAlarmIcon from '../icons/AcceptedAlarmIcon.vue';

const props = defineProps({
  dataLoading: {
    type: Boolean,
    required: true,
  },
  singleAlarmLogs: {
    type: Array,
    required: true,
  },
  selectedAlarm: {
    type: Object,
    required: true,
  }
});

const convertTimeToHHMM = (date: string): string => {
  const dateString: string = date;
  const dateObject: Date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'UTC',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit'
  };
  let time: string = dateObject.toLocaleTimeString('en-US', options);
  if (time.startsWith('24:')) {
    time = '00' + time.slice(2);
  }
  return time;
};

const formatDate = (inputDateStr: string): string => {
  const inputDate = new Date(inputDateStr);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  };

  const formattedDate = inputDate.toLocaleDateString('en-US', options);
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  const seconds = inputDate.getSeconds();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedTime =
    `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;

  return `${formattedDate} ${formattedTime}`;
};

const emit = defineEmits(['acceptAlarm']);
</script>

<template>
  <div
    v-if="props.dataLoading"
    class="loader items-center"
  />
  <div
    v-else
    style="width: 100%; height: 100%"
  >
    <div
      v-if="!props.selectedAlarm.accept"
      class="flex"
      style="justify-content: center; margin: 12px 0px"
    >
      <component
        :is="AcceptAlarmLog"
        class="cursor-pointer"
        @click="emit('acceptAlarm', selectedAlarm)"
      />
    </div>
    <div
      v-else
      class="flex items-center"
      style="justify-content: center; margin: 12px 0px; flex-direction: column"
    >
      <component
        :is="AcceptedAlarmIcon"
      />
      <p
        class="time-up"
        style="margin-top: 8px"
      >
        {{ formatDate(selectedAlarm.accept) }}
      </p>
    </div>
    <div
      v-for="(log, index) in singleAlarmLogs"
      :key="log.id"
      class="log-item"
    >
      <div
        class="flex"
        style="justify-content: space-evenly; width: 100%"
      >
        <div>
          <p
            class="alarm-time"
            style="margin-bottom: 10px"
          >
            {{ convertTimeToHHMM(log.timestamp) }}
          </p>
          <component
            :is="index === singleAlarmLogs.length - 1 ? 'div' : TimeLineDots"
            style="margin: 0 auto;"
          />
        </div>
        <div style="width: 75%">
          <p class="alarm-upper">
            {{ log.text }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.alarm-time {
  opacity: 0.72;
  color: #FFFFFF;
  font-family: "Avenir Next";
  font-size: 12.8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}

.alarm-upper {
  opacity: 0.7;
  color: #C3C4C5;
  font-family: "Avenir Next LT Pro";
  font-size: 12.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
}

.loader {
    border: 4px solid #888;
    border-top: 4px solid #333;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.time-up {
  font-weight: 400;
  font-size: 10px;
  color: #C3C4C5;
}

.log-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
</style>
