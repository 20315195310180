export class ConsumedAction<KEY_T, VALUE_T> extends Map<KEY_T, VALUE_T> {
  constructor(...args: any) {
    super(...args)
  }

  consume(key: KEY_T): VALUE_T | undefined {
    const result = this.get(key);
    if(result) {
      this.delete(key);
    }
    return result;
  }
}


export type CommandTarget = 'dashboard' | 'name-input-field';
export type CommandActions<CMD_K extends CommandTarget> = CMD_K extends 'dashboard' 
  ? "enter-edit-mode"
  : (CMD_K extends 'name-input-field' 
    ? 'focus' 
    : never)
