<script setup lang="ts">
import { type PropTypepType, onMounted, ref } from 'vue';
import {fromLonLat, toLonLat} from 'ol/proj';
// DECK
import {Deck} from '@deck.gl/core/typed';
import {GeoJsonLayer, ArcLayer, IconLayer, TextLayer, SolidPolygonLayer, PolygonLayer, BitmapLayer , } from '@deck.gl/layers/typed';
import {ScenegraphLayer} from '@deck.gl/mesh-layers';
import { TileLayer } from '@deck.gl/geo-layers';
import type { ILocationAlarms } from './MapOpenLayers.vue';
import { filterEmptyCoordinatesLocations } from './MapOpenLayers.vue';
import type { Coordinate } from 'ol/coordinate';

const props = defineProps({
  startPosition: {    
    type: Array as PropType<Array<number>>,
    default: () => fromLonLat([ 5.2913, 52.1326])     
  },
  startZoom: {
    type: Number,
    default: 9
  },
  locationsAlarms: {
    type: Array as PropType<ILocationAlarms[]>,
    default: () => []
  }
});

function getData() {
  return filterEmptyCoordinatesLocations(props.locationsAlarms);  
}

function getIconUrl(la: ILocation): string {    
  switch (la.maxPriorityId) {
    case -1: return NODES['white'];
    case 0: return NODES['green'];
    case 1: return NODES['orange'];
    case 10: return NODES['red'];
    default: throw new Error('Node marker type not supported')
  }  
}

function getPosition(la) {
  return [Number(la.y), Number(la.x)]
}


const map = ref<Deck>();
const mapRef = ref();

const mapElementId = "map";
const MAP_STYLE_URL = 'https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json';

// Datasource: Natural Earth http://www.naturalearthdata.com/ via geojson.xyz
const AIR_PORTS = 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_airports.geojson';

const NOT_CONVERTED_COORDS = [ 5.2913, 52.1326];
const CENTER_MAP = fromLonLat(NOT_CONVERTED_COORDS);

const DATA = [
  {
  icon: NODES['orange'],
  coordinate: NOT_CONVERTED_COORDS,
  }
];

function createIconLayer() {
  return new IconLayer({
    id: 'clusterm-marker-icon-layer',
    data: getData(),
    getPosition: getPosition,    
    getIcon: d => ({          
      url: getIconUrl(d),
      width: 128,
      height: 128,
      //anchorY: 128
    }),
    sizeScale: 12,
    getSize: d => 10,    
  })
}

function createScenegraphLayer() {
  return new ScenegraphLayer({
    id: 'scenegraph-layer',
    data: getData(),
    pickable: true,
    scenegraph: 'https://raw.githubusercontent.com/KhronosGroup/glTF-Sample-Models/master/2.0/BoxAnimated/glTF-Binary/BoxAnimated.glb',
    getPosition: la => [...getPosition(la), 10],
    getOrientation: d => [0, Math.random() * 180, 90],
    sizeMinPixels: 0.1,
    sizeMaxPixels: 1.5,

    _animations: {
      '*': {speed: 5}
    },    
    sizeScale: 500,
    _lighting: 'pbr'
  });
}

function createTextLayer() {
  return new TextLayer({
    data: DATA,
    getPosition: la => la.coordinate,
    getText: la => "Hello"    
  });
}

const POLYGON_DATA = [
  //{polygon: [[0, 0], [0, 1], [1, 1], [1, 0], [0, 0]]},   // Simple polygon (array of coords)
  {
    polygon: [                                            // Complex polygon with one hole
     [[0, 0], [0, 2], [2, 2], [2, 0], [0, 0]],            // (array of array of coords)
     [[0, 0], [0, 1], [1, 1], [1, 0], [0, 0]]
  ]
  }
];

function convertToPolygon(coords: Coordinate) {
  const [x, y] = coords;
  const halfLen = 0.090;

  const topLeftX = x - halfLen;
  const topLeftY = y - halfLen;
  const bottomLeftX = x - halfLen;
  const bottomLeftY = y + halfLen;
  const bottomRightX = x + halfLen;
  const bottomRightY = y + halfLen;
  const topRightX = x + halfLen;
  const topRightY = y - halfLen;

  return [
    [
      topLeftX,
      topLeftY,
    ],
    [
      bottomLeftX,
      bottomLeftY
    ],
    [
      bottomRightX,
      bottomRightY,      
    ],
    [
      topRightX,
      topRightY
    ]    
  ];  
}

const POLYGON_DATA3 = [
          [
            [
              5.1066997152835825,
              51.74304495337984
            ],
            [
              5.1066997152835825,
              51.48274024650556
            ],
            [
              5.676860877001786,
              51.48274024650556
            ],
            [
              5.676860877001786,
              51.74304495337984
            ],
            [
              5.1066997152835825,
              51.74304495337984
            ]
          ]
        ];

const landCover = [
  [
    [-123.0, 49.196],
    [-123.0, 49.324],
    [-123.306, 49.324],
    [-123.306, 49.196]
  ]
];

const POLIGON_DATA2 = [
  {
    // Simple polygon (array of coords)
    contour: [[-122.4, 37.7], [-122.4, 37.8], [-122.5, 37.8], [-122.5, 37.7], [-122.4, 37.7]],
    zipcode: 94107,
    population: 26599,
    area: 6.11
  },
  {
    // Complex polygon with holes (array of rings)
    contour: [
      [[-122.4, 37.7], [-122.4, 37.8], [-122.5, 37.8], [-122.5, 37.7], [-122.4, 37.7]],
      [[-122.45, 37.73], [-122.47, 37.76], [-122.47, 37.71], [-122.45, 37.73]]
    ],
    zipcode: 94107,
    population: 26599,
    area: 6.11
  },
      
];

function createSolidPoligonLayer() {
  /*
  return new PolygonLayer({
    id: 'polygon-layer',
    POLIGON_DATA2,
    pickable: true,
    stroked: true,
    filled: true,
    wireframe: true,
    lineWidthMinPixels: 1,
    getPolygon: d => d.contour,
    getElevation: d => d.population / d.area / 10,
    getFillColor: d => [d.population / d.area / 60, 140, 0],
    getLineColor: [80, 80, 80],
    getLineWidth: 1
  });
     
  return new PolygonLayer({
      id: 'ground',
      data: landCover,
      stroked: false,
      getPolygon: f => f,
      getFillColor: [0, 0, 0, 0]
    });
*/
    const ORANGE_COLOR = [255, 165, 0];
    const GREEN_COLOR = [0, 128, 0];
    const RED_COLOR = [255, 0, 0];
    const SILVER_COLOR = [192, 192, 192];

    function getBoxColor(la: ILocationAlarms): Array<number> {    
      switch (la.maxPriorityId) {
        case -1: return SILVER_COLOR;
        case 0: return GREEN_COLOR;
        case 1: return ORANGE_COLOR;
        case 10: return RED_COLOR;
        default: throw new Error('Node marker type not supported')
     }  
    }

    function getBoxElevation(la: ILocationAlarms) {
      const base = 10000;
      const increment = 0.9;
      switch (la.maxPriorityId) {
          case -1: return base * (1 + 0 * increment);
          case 0: return base * (1 +  1 * increment);
          case 1: return base * (1 + 2 * increment);
          case 10: return base * (1 + 3 * increment);
          default: throw new Error('Node marker type not supported')
      }  
    }


  return new PolygonLayer({
    id: 'polygon-layer',
    //data: POLYGON_DATA3,
    data: getData(),
    opacity: 0.8,
    pickable: true,
    stroked: true,
    filled: true,
    
    extruded: true,
    wireframe: true,
    lineWidthMinPixels: 1,
    //getPolygon: d => d,
    getPolygon: f => convertToPolygon( getPosition(f)),
    //getElevation: f => 10000,    
    getElevation: getBoxElevation,
    //getFillColor: d => [26123 / 6.11 / 60, 140, 0],
    getFillColor: getBoxColor,
    getLineColor: [80, 80, 80],
    getLineWidth: 2
  })

  return new SolidPolygonLayer({
    id: 'solid-polygon-layer',
    data: POLYGON_DATA3,
    getPolygon: d => d,
    getColor: [255, 0, 0],
    extruded: true
  });
}

const INITIAL_VIEW_STATE = {
  width: window.innerWidth,
  height: window.innerHeight,

  latitude: 51.47,
  longitude: 0.45,
  zoom: 4,
  bearing: 0,
  pitch: 16,
};

const colorRange = [
  [1, 152, 189],
  [73, 227, 206],
  [216, 254, 181],
  [254, 237, 177],
  [254, 173, 84],
  [209, 55, 78]
];

function createTileLayer( options?: { 
  data?: any
  minZoom?: number,
  maxZoom?: number,
  tileSize?: number
} ) {
  return new TileLayer({
    data: options?.data ?? "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",

    minZoom: options?.minZoom ?? 0,
    maxZoom: options?.maxZoom ?? 19,
    tileSize: options?.tileSize ?? 256,

    renderSubLayers: (props) => {
      const {
        bbox: { west, south, east, north }
      } = props.tile;

      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north]
      });
    }
  });
}

function createBaseLayers(props: { data: any}) {
  const { data } = props;

  const tileLayer = createTileLayer();
  const darkLayer = createTileLayer(
    { data: 'https://c.tile.jawg.io/jawg-dark/{z}/{x}/{y}.png?access-token=87PWIbRaZAGNmYDjlYsLkeTVJpQeCfl2Y61mcHopxXqSdxXExoTLEv7dwqBwSWuJ'}
  )
/*
  const layes = [
    new HexagonLayer({
      id: "hexagon-layer",
      data,
      colorRange,
      radius: 1000,
      extruded: true,
      elevationScale: 40,
      elevationRange: [0, 3000],
      getPosition: (d) => d.position
    })
  ];

  return [layes, tileLayer];
  */
  return [darkLayer, tileLayer]
}

const ENABLE_3D_LAYER = true;
function initMap() {
  
map.value = new Deck({
  parent: document.getElementById(mapElementId),
  initialViewState: INITIAL_VIEW_STATE,
  controller: true,
  layers: [
    /*
    new GeoJsonLayer({
      id: 'base-map',
      data: COUNTRIES,
      // Styles
      stroked: true,
      filled: true,
      lineWidthMinPixels: 2,
      opacity: 0.4,
      getLineColor: [60, 60, 60],
      getFillColor: [200, 200, 200]
    }),
    new GeoJsonLayer({
      id: 'airports',
      data: AIR_PORTS,
      // Styles
      filled: true,
      pointRadiusMinPixels: 2,
      pointRadiusScale: 2000,
      getPointRadius: f => 11 - f.properties.scalerank,
      getFillColor: [200, 0, 80, 180],
      // Interactive props
      pickable: true,
      autoHighlight: true,
      onClick: info =>
        // eslint-disable-next-line
        info.object && alert(`${info.object.properties.name} (${info.object.properties.abbrev})`)
    }),
    new ArcLayer({
      id: 'arcs',
      data: AIR_PORTS,
      dataTransform: d => d.features.filter(f => f.properties.scalerank < 4),
      // Styles
      getSourcePosition: f => [-0.4531566, 51.4709959], // London
      getTargetPosition: f => f.geometry.coordinates,
      getSourceColor: [0, 128, 200],
      getTargetColor: [200, 0, 80],
      getWidth: 1
    })*/
    ...createBaseLayers({data: {}}),
      createIconLayer(),
      //createScenegraphLayer(),
      ENABLE_3D_LAYER && createSolidPoligonLayer()
  ]
});
}

onMounted(() => {
  initMap();
})

</script>

<template>
  <div class="map-container">
    <div 
      :id="mapElementId" 
      class="map" 
    >
      <div className="attribution">
          <a
            href="http://www.openstreetmap.org/about/"
            target="_blank"
            rel="noopener noreferrer"
          >
          © OpenStreetMap
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.map-container {
  margin: 0;
  height: 100vh;  
}
.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

body {
  height: 100vh;
  width: 100vw;

}
.deck-gl-map-container {
  height: 100vh;
  width: 100vw;
}

.deck-gl-map {
  height: 100vh;
  width: 100vw;
}

.attribution {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  font: 12px Helvetica Neue, Arial, Helvetica, sans-serif;
  line-height: 12px;
  padding: 4px;
  z-index: 9;
}

</style>

<script lang="ts">
  const NODES = Object.fromEntries( 
    Object.entries(
      getNodesSvg()).map( ([key, value]) => [
        key,
        svgToDataURL(value)
  ]));
  function svgToDataURL(svg: any) {
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
  }


  function getNodesSvg(){
    return {
      white: `<svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 184 184" xml:space="preserve">
    <path style="stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:4;fill:#fff;fill-rule:nonzero;opacity:1" vector-effect="non-scaling-stroke" transform="translate(72 72.215) scale(1.5)" d="M13.485 29.646 0 22.012V7.226L13.485 0l13.486 7.226v14.786l-13.486 7.634ZM9.215 11.19a7.339 7.339 0 0 1 1.967-1.39 5.206 5.206 0 0 1 4.607 0 7.339 7.339 0 0 1 1.967 1.39l5.731-3.335-10.002-5.262L3.484 7.856l5.731 3.335Zm3.147 15.268V20.27c-1.299-.346-2.373-1.013-3.222-2a5.127 5.127 0 0 1-1.274-3.447c0-.272.02-.55.057-.834a3.88 3.88 0 0 1 .206-.834L2.248 9.71v11.006l10.114 5.744Zm1.123-8.301c.95 0 1.748-.321 2.398-.963.649-.643.974-1.433.974-2.372 0-.939-.325-1.73-.974-2.372-.65-.642-1.449-.963-2.398-.963-.949 0-1.748.32-2.397.963-.65.643-.974 1.433-.974 2.372 0 .939.325 1.73.974 2.372.65.642 1.448.963 2.397.963Zm1.124 8.3 10.114-5.743V9.709l-5.881 3.446c.1.272.169.55.206.834.038.284.056.562.056.834 0 1.31-.424 2.458-1.273 3.446-.85.988-1.923 1.655-3.222 2.001v6.189Z"/>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#fff" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#fff" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.4s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.4s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#fff" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.8s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.8s" repeatCount="indefinite"/>
    </circle>
  </svg>
  `,
    green: `
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 184 184" xml:space="preserve">
      <path style="stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:4;fill:#fff;fill-rule:nonzero;opacity:1" vector-effect="non-scaling-stroke" transform="matrix(1.5 0 0 1.5 72 72.215)" d="M13.485 29.646 0 22.012V7.226L13.485 0l13.486 7.226v14.786l-13.486 7.634ZM9.215 11.19a7.339 7.339 0 0 1 1.967-1.39 5.206 5.206 0 0 1 4.607 0 7.339 7.339 0 0 1 1.967 1.39l5.731-3.335-10.002-5.262L3.484 7.856l5.731 3.335Zm3.147 15.268V20.27c-1.299-.346-2.373-1.013-3.222-2a5.127 5.127 0 0 1-1.274-3.447c0-.272.02-.55.057-.834a3.88 3.88 0 0 1 .206-.834L2.248 9.71v11.006l10.114 5.744Zm1.123-8.301c.95 0 1.748-.321 2.398-.963.649-.643.974-1.433.974-2.372 0-.939-.325-1.73-.974-2.372-.65-.642-1.449-.963-2.398-.963-.949 0-1.748.32-2.397.963-.65.643-.974 1.433-.974 2.372 0 .939.325 1.73.974 2.372.65.642 1.448.963 2.397.963Zm1.124 8.3 10.114-5.743V9.709l-5.881 3.446c.1.272.169.55.206.834.038.284.056.562.056.834 0 1.31-.424 2.458-1.273 3.446-.85.988-1.923 1.655-3.222 2.001v6.189Z"/>
      <circle cx="20" cy="20" fill="none" r="10" stroke="#ffffffe6" stroke-width=".5" transform="scale(4.6)"/>
      <!-- <circle cx="23" cy="23" fill="none" r="10" stroke="#44b39b" stroke-width=".5" transform="scale(4)"/>
      <circle cx="27" cy="27" fill="none" r="10" stroke="#44b39b" stroke-width=".5" transform="scale(3.4)"/> -->
    </svg>

    `,
    orange: `
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 184 184" xml:space="preserve">
    <path style="stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:4;fill:#fff;fill-rule:nonzero;opacity:1" vector-effect="non-scaling-stroke" transform="translate(72 72.215) scale(1.5)" d="M13.485 29.646 0 22.012V7.226L13.485 0l13.486 7.226v14.786l-13.486 7.634ZM9.215 11.19a7.339 7.339 0 0 1 1.967-1.39 5.206 5.206 0 0 1 4.607 0 7.339 7.339 0 0 1 1.967 1.39l5.731-3.335-10.002-5.262L3.484 7.856l5.731 3.335Zm3.147 15.268V20.27c-1.299-.346-2.373-1.013-3.222-2a5.127 5.127 0 0 1-1.274-3.447c0-.272.02-.55.057-.834a3.88 3.88 0 0 1 .206-.834L2.248 9.71v11.006l10.114 5.744Zm1.123-8.301c.95 0 1.748-.321 2.398-.963.649-.643.974-1.433.974-2.372 0-.939-.325-1.73-.974-2.372-.65-.642-1.449-.963-2.398-.963-.949 0-1.748.32-2.397.963-.65.643-.974 1.433-.974 2.372 0 .939.325 1.73.974 2.372.65.642 1.448.963 2.397.963Zm1.124 8.3 10.114-5.743V9.709l-5.881 3.446c.1.272.169.55.206.834.038.284.056.562.056.834 0 1.31-.424 2.458-1.273 3.446-.85.988-1.923 1.655-3.222 2.001v6.189Z"/>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#CD8136" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#CD8136" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.4s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.4s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#CD8136" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.8s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.8s" repeatCount="indefinite"/>
    </circle>
    </svg>
    `,
    red: `
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 184 184" xml:space="preserve">
    <path style="stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-dashoffset:0;stroke-linejoin:miter;stroke-miterlimit:4;fill:#fff;fill-rule:nonzero;opacity:1" vector-effect="non-scaling-stroke" transform="translate(72 72.215) scale(1.5)" d="M13.485 29.646 0 22.012V7.226L13.485 0l13.486 7.226v14.786l-13.486 7.634ZM9.215 11.19a7.339 7.339 0 0 1 1.967-1.39 5.206 5.206 0 0 1 4.607 0 7.339 7.339 0 0 1 1.967 1.39l5.731-3.335-10.002-5.262L3.484 7.856l5.731 3.335Zm3.147 15.268V20.27c-1.299-.346-2.373-1.013-3.222-2a5.127 5.127 0 0 1-1.274-3.447c0-.272.02-.55.057-.834a3.88 3.88 0 0 1 .206-.834L2.248 9.71v11.006l10.114 5.744Zm1.123-8.301c.95 0 1.748-.321 2.398-.963.649-.643.974-1.433.974-2.372 0-.939-.325-1.73-.974-2.372-.65-.642-1.449-.963-2.398-.963-.949 0-1.748.32-2.397.963-.65.643-.974 1.433-.974 2.372 0 .939.325 1.73.974 2.372.65.642 1.448.963 2.397.963Zm1.124 8.3 10.114-5.743V9.709l-5.881 3.446c.1.272.169.55.206.834.038.284.056.562.056.834 0 1.31-.424 2.458-1.273 3.446-.85.988-1.923 1.655-3.222 2.001v6.189Z"/>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#FE1717" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#FE1717" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.4s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.4s" repeatCount="indefinite"/>
    </circle>
    <circle cx="20" cy="20" fill="none" r="10" stroke="#FE1717" stroke-width=".5" transform="scale(4.6)">
      <animate attributeName="r" from="8" to="20" dur="2s" begin="0.8s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0.8s" repeatCount="indefinite"/>
    </circle>
  </svg>
    `
    };
  }


</script>