import { useApi, type ApiLoadingType } from '@/composables/api';
import type { TagResponse, TrendResponse } from './dtos/TrendDto';

export interface ITrend {
	getDailyTotalTags(): Promise<TagResponse>;
	getMeasuredValueTags(): Promise<TagResponse>;
	getTrendForTag(tagId: string, params?: TrendQueryParams): Promise<TrendResponse>;
}

export interface TrendQueryParams {
	s: number;
	e?: number;
	r?: number;
}

const API_VERSIONING = 'api/v1';

export function useTrendApi(): ITrend & ApiLoadingType {
	const api = useApi();
	const { loading, errors, clearErrors } = api;

	async function getDailyTotalTags(): Promise<TagResponse> {
		const response = await api.get(`${API_VERSIONING}/locations/50533036/tags?type=12`);
		return response;
	}

	async function getMeasuredValueTags(): Promise<TagResponse> {
		const response = await api.get(`${API_VERSIONING}/locations/50533036/tags?type=10,13`);
		return response;
	}

	// Only this should stay
	async function getTrendForTag(tagId: string, params?: TrendQueryParams): Promise<TrendResponse> {
		const response = await api.get(`${API_VERSIONING}/trend/${tagId}`, {}, {params});
		return response;
	}

	return {
		loading,
		errors,
		clearErrors,
		getTrendForTag,
	
		getDailyTotalTags,
		getMeasuredValueTags,
		
	};
}
