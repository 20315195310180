import type { File } from "@/api/dtos/FilesDto";
import { useFilesApi } from "@/api/filesApi";

export function useFilesService() {
  const filesApi = useFilesApi();

  async function getFilesForLocation(locationId: string) {
    return await filesApi.getFilesForLocation(locationId);
  }

  async function getFileFromSubFolder(locationId: string, subFolderId: string) {
    return await filesApi.getFileFromSubFolder(locationId, subFolderId);
  }

  async function processDirectoryData(data: [File]) {
    const processedData = data.map((item) => {
      if (item.isDir) {
        item.isExpanded = false;
        item.subfolder = [];
      }
      return item;
    });
    const folders = processedData.filter((item) => item.isDir);
    const files = processedData.filter((item) => !item.isDir);
    return { folders, files };
  }

  return {
    getFilesForLocation,
    getFileFromSubFolder,
    processDirectoryData,
  };
}
