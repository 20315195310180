export type TasksStatusesResponse = Array<TaskStatusDto & { action: number}>;  

export type TaskStatusResponse = TaskStatusDto;

export interface TaskActionRequestBody {
  action: number;
  time: number;
}

export type ScheduledTaskResponse = (string & 'success') | [];

export interface TaskStatusDto {    
  locationcode: string
  state: number
  time: string
  "time@uts": string | number
  applicationcode: string
  application: string
  action: number
  locationid: string
}

export enum TaskId {
  Start_read_settings = 1,
  Stop_reading_settings = 2,
  Start_reading_realtime_values = 5,
  Stop_reading_realtime_values = 6,

  Start_fetching_trend_data = 12,
  Stop_fetching_trend_data = 13,

  Start_fetching_counters = 22,
  Stop_fetching_counters = 23,
  Start_fetching_temporary_values = 32,
  Stop_fetching_temporary_values = 33,
  Start_fetching_trend_data_2 = 42,
  Stop_fetching_trend_data_2 = 43,
  Stop_fetching_alarm_data = 53,
  Start_fetching_alarm_data = 54
}


export enum TaskState {
  initalizing = 0,
  execute = 1,
  started = 2,
  busy = 3,
  running = 4, 
  paused = 5,
  stopped = 6,
  done = 7,  
  quit = 8,
  error = 9,
  crash = 10
}