<script setup lang="ts">
import { ref, computed, onMounted, defineProps } from 'vue';
import { useLocationService } from '../../../smarthub/src/services/locationService';
import { onClickOutside } from '@vueuse/core';
import type { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { type LocationsResponse, type LocationDto } from '../../../smarthub/src/api/dtos/LocationDto';
import { useHeaderStore } from '../../../smarthub/src/stores/header';
import { storeToRefs } from 'pinia';
import MagnifyingGlassIcon from '@wisionmonorepo/ui/src/icons/MagnifyingGlassIcon.vue';
import { useChartsStore } from '../stores/chart';

const props = defineProps({
	disabled: {
		type: Boolean,
		default: false,
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
	showSidebar: {
		type: Boolean,
		default: true,
	}
});

const emit = defineEmits(['closeSidebar']);

const locationsData = ref<LocationsResponse>();
const searchQuery = ref('');
const isOpen = ref(false);
const dropdownSearchRef = ref();
const inputRef = ref();
const headerStore = useHeaderStore();
const { selectedLocation } = storeToRefs(headerStore);
const chartStore = useChartsStore();
const { selectedLocationForCharts } = storeToRefs(chartStore);

const filteredLocations = computed(() => {
	if (!locationsData.value || !locationsData.value.locations) {
		return [];
	}
	const query = searchQuery.value.toLowerCase();
	if (query === '') return locationsData.value.locations;
	return locationsData.value.locations.filter((item) => {
		const nameMatch = item.location.name.toLowerCase().includes(query);
		const descriptionMatch = item.location.code.toLowerCase().includes(query);

		return nameMatch || descriptionMatch;
	});
});

function selectLocation(location: LocationDto) {
	chartStore.setSelectedLocation(location);
	searchQuery.value = '';
	isOpen.value = false;
	emit('closeSidebar');
}

const placeholderText = computed(() => {
	if (selectedLocationForCharts.value && selectedLocation.value) {
		return `${selectedLocationForCharts.value.name}`;
	} else {
		return 'Search for a location...';
	}
});

async function fetchLocations() {
	const { getLocations } = useLocationService();
	const result = await getLocations();
	locationsData.value = result;
}

const handleClose = () => (isOpen.value = false);

onMounted(() => {
	fetchLocations();
	onClickOutside(dropdownSearchRef, handleClose, { ignore: [inputRef] });
});
</script>
<template>
	<div class="w-[80%] shub-lg:w-[90%] mx-auto">
		<div class="relative">
			<div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
				<MagnifyingGlassIcon />
			</div>
			<input
				ref="inputRef"
				v-model="searchQuery"
				type="search"
				class="h-full w-full text-[12px] rounded-full py-2 max-shub-md:pl-7 pl-9 text-sm text-white placeholder:text-white max-shub-lg:placeholder:text-[11px] bg-transparent border-2 border-[#757678] placeholder:opacity-60"
				:class="{'opacity-50': isLoading}"
				placeholder="Search for a location..."
				:tabindex="disabled ? -1 : undefined"
				@focus="isOpen = !isOpen"
				:disabled="isLoading"
			/>
			<div
				ref="dropdownSearchRef"
				:class="isOpen ? 'visible' : 'invisible'"
				class="sidebar__dropdown absolute top-10 left-0 right-0 py-5 pl-5 pr-1 rounded-[15px] border-2 border-[#797979] bg-[#34383b] overflow-y-hidden z-10"
			>
				<ul>
					<PerfectScrollbar :options="{ suppressScrollX: true }" :style="{ height: filteredLocations.length === 0 ? '0' : '212px' }">
						<li
							v-for="item in filteredLocations"
							:key="item.location.id"
							class="text-[12px] cursor-pointer text-white"
							@click="selectLocation(item.location)"
						>
							{{ item.location.name }}
							<span class="block text-[14px] opacity-50">
								{{ item.location.code }}
							</span>
						</li>
					</PerfectScrollbar>
				</ul>
				<span v-if="filteredLocations.length === 0" class="text-[12px]">No location</span>
				</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.sidebar__dropdown {
	visibility: hidden;
	transition: all 0.1s ease-out;
	max-height: 0;

	&.visible {
		max-height: 262px;
		visibility: visible;
	}

	li + li {
		margin-top: 15px;
	}
}
</style>
